import PropTypes from 'prop-types'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import { ChevronDown } from 'react-feather'
import { useState } from 'react'
import Box from '@mui/material/Box'
import { numberOrString, rubricCriteriaShape } from 'core/shapes'
import { isEmptyString } from 'fp/strings'
import { importantPx } from 'styling/theming/base/mixins'
import { noop } from 'fp/utils'
import SimpleStarRating from './SimpleStarRating'

const ReviewAccordion = (props) => {
  const {
    accordionProps,
    accordionSummaryProps,
    comment = '',
    disabled = false,
    displayMode = 'star',
    expandable = false,
    featured = false,
    onChange,
    ratingOrRatingId,
    readOnly = false,
    render,
    rubricCriteria,
    title = 'Anonymous',
  } = props

  const [expanded, setExpanded] = useState(false)

  return (
    <Accordion
      {...{
        expanded,
        onChange: expandable ? (_, exp) => setExpanded(exp) : null,
        'data-featured': featured ? 'true' : 'false',
        variant: 'peer-review',
        ...accordionProps,
      }}
    >
      <AccordionSummary
        data-disabled={disabled ? 'true' : 'false'}
        data-featured={featured ? 'true' : 'false'}
        expandIcon={expandable ? <ChevronDown /> : null}
        variant="peer-review"
        {...accordionSummaryProps}
      >
        <Typography
          sx={{ width: '50%', flexShrink: 0 }}
          variant="large-semibold"
        >
          {title}
        </Typography>

        {displayMode === 'numeric' && (
          <Typography variant="large-semibold">
            {Number.isNaN(ratingOrRatingId)
              ? '–'
              : Number(ratingOrRatingId).toFixed(1)}
          </Typography>
        )}

        {displayMode === 'star' && (
          <SimpleStarRating
            disabled={disabled}
            onChange={onChange}
            readOnly={readOnly}
            rubricCriteria={rubricCriteria}
            value={ratingOrRatingId}
          />
        )}
      </AccordionSummary>

      <AccordionDetails>
        {Boolean(!isEmptyString(comment) || render === noop) && (
          <Box
            component="p"
            margin={importantPx(8)}
          >
            {comment || <i>no comment provided</i>}
          </Box>
        )}

        {render()}
      </AccordionDetails>
    </Accordion>
  )
}

ReviewAccordion.propTypes = {
  accordionProps: PropTypes.object,
  accordionSummaryProps: PropTypes.object,
  comment: PropTypes.string,
  disabled: PropTypes.bool,
  displayMode: PropTypes.oneOf(['numeric', 'star']),
  expandable: PropTypes.bool,
  featured: PropTypes.bool,
  onChange: PropTypes.func,
  ratingOrRatingId: numberOrString,
  readOnly: PropTypes.bool,
  render: PropTypes.func.isRequired,
  rubricCriteria: rubricCriteriaShape,
  title: PropTypes.string,
}

export default ReviewAccordion
