import { ChevronDown } from 'react-feather'

const overrides = ({ mixins: { importantRem, rem }, typography }) => ({
  MuiAutocomplete: {
    defaultProps: {
      popupIcon: <ChevronDown />,
    },

    styleOverrides: {
      groupLabel: {
        ...typography.variants['tab-title'],
        padding: rem(2, 1),
      },

      groupUl: {
        paddingLeft: rem(2),
      },

      input: { padding: importantRem(0.4, 0.3) },

      popper: {
        '.multi-select-group': {
          '.MuiButton-root': typography.variants['tab-title'],
          '.multi-select-group-children': {
            marginLeft: rem(3),
          },
        },
      },

      popupIndicator: {
        left: -3,
      },

      root: {
        // using the same top margin as MuiFormControl-root
        marginTop: rem(3.2),
        label: {
          paddingRight: rem(3), // Don't overlap the dropdown button.
          top: 0, // Override InputLabel's `top: -4px`, which works for TextFields but causes misalignment here.
        },
        '*[variant="data-table"] table &, li &': {
          marginTop: 0,
        },
        '.MuiFormControl-root': {
          marginTop: 0,
        },
        '.MuiOutlinedInput-notchedOutline': {
          height: '100%', // Override for notched outline since they are set at 55
        },
      },
    },
  },
})

export default overrides
