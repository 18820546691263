import { useFormState } from 'react-hook-form'
import { compose } from 'redux'
import { set } from 'fp/objects'
import { fallbackTo, identity } from 'fp/utils'
import { useDeepCompareMemo } from './useDeepCompare'

const hasAnyTrueValuesRecursive = (obj) => {
  const objectProps = []
  const values = Object.values(obj)

  // Using a for loop so we can immediately stop when the first true value is found
  for (let i = 0; i < values.length; i += 1) {
    const val = values[i]
    if (val === true) {
      return true
    }
    if (typeof val === 'object') {
      objectProps.push(val)
    }
  }

  return objectProps.some(hasAnyTrueValuesRecursive)
}

const setToFalse = propPaths => obj => propPaths.reduce(
  (result, path) => set(path, false)(result),
  obj,
)

const useIsFormDirty = (fieldsToIgnore) => {
  // Can't trust formState.isDirty
  // https://github.com/react-hook-form/react-hook-form/issues/3213
  const { dirtyFields } = useFormState()

  /**
   * This isn't explicitly described in the docs (https://www.react-hook-form.com/api/useformstate/),
   * but the structure of `dirtyFields` mimics the structure of the form data.
   * So it may have nested fields and not necessarily be just a flat set of key/value pairs.
   * Each leaf prop set to true represents a dirty field.
   * e.g.
   * {
   *   name1: true,
   *   data: {
   *     name2: true,
   *     otherSettings: {
   *       name3: true,
   *     },
   *   },
   * }
   */

  return useDeepCompareMemo(
    () => compose(
      hasAnyTrueValuesRecursive,
      Array.isArray(fieldsToIgnore) ? setToFalse(fieldsToIgnore) : identity,
      fallbackTo({}),
    )(dirtyFields),
    [dirtyFields, fieldsToIgnore],
  )
}

export default useIsFormDirty
