import { useSelector } from 'react-redux'
import { contentShape } from 'core/shapes'
import { getAggregatedStandardsFromContentChildren } from 'selectors/standards'
import StandardsList from './StandardsList'

const DisplayChildStandards = ({ content }) => {
  const { contentType, id: contentId } = content
  const aggregateStandards = useSelector(getAggregatedStandardsFromContentChildren({ contentType, contentId }))

  const { applicationStandards, instructionStandards } = aggregateStandards

  return (
    <>
      <b>The following standards are being utilized by this item's content blocks:</b>
      <table>
        <tbody>
          <tr>
            <th>Application:</th>
            <td>
              <StandardsList standards={applicationStandards} />
              {!applicationStandards.length && '[none]'}
            </td>
          </tr>
          <tr>
            <th>Instruction:</th>
            <td>
              <StandardsList standards={instructionStandards} />
              {!instructionStandards.length && '[none]'}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

DisplayChildStandards.propTypes = {
  content: contentShape.isRequired,
}

export default DisplayChildStandards
