import { Maybe } from 'monet'
// import compareAsc from 'date-fns/compareAsc'
// import compareDesc from 'date-fns/compareDesc'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import enUS from 'date-fns/locale/en-US'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { compose } from 'redux'
import { pluralize, split } from 'fp/strings'
import { map } from 'fp/arrays'
import { curryRight } from 'fp/utils'
// import { tzAbbr } from './timezones'

/**
 * TODO: Remove hardcoded locale
 * https://date-fns.org/v1.29.0/docs/I18n
 */

// const dateCompareDirection = {
//   asc: compareAsc,
//   desc: compareDesc,
// }

export const dateShortFormatString = 'MM/dd/yyyy'
export const timeFormatString = 'h:mm a'
const dateLongFormatString = 'MMM d, yyyy'

// const formatDateIso = date => format(date, 'yyyy-MM-dd', { locale: enUS })
const formatDateLong = date => format(date, dateLongFormatString, { locale: enUS })
const formatDateShort = date => format(date, dateShortFormatString, { locale: enUS })
// const formatDateTimeShort = date => format(date, `${dateShortFormatString} ${timeFormatString}`, { locale: enUS })
const formatDateWithFormatString = formatString => date => format(date, formatString, { locale: enUS })

const formatDateTimeLong = date => format(date, `${dateLongFormatString} ${timeFormatString}`, { locale: enUS })
const formatTime = date => format(date, timeFormatString, { locale: enUS })

const pad = (value, digits = 2) => String(value).padStart(digits, '0')
const msToSeconds = ms => Math.trunc(ms / 1000)
const minutes = sec => Math.floor(sec / 60)

export const msToTime = compose(
  sec => `${pad(minutes(sec), 1)}:${pad(sec % 60)}`,
  msToSeconds,
)

export const parseDate = (date) => {
  if ((date instanceof Date) && !Number.isNaN(date.valueOf())) {
    // this is already a date, so just return it as-is
    return date
  }
  return parseISO(date)
}

// export const dateCompare = (date1, date2, direction = 'asc') => dateCompareDirection[direction](date1, date2)

// export const formatDateStrIso = date => Maybe.fromNull(date)
//   .map(parseDate)
//   .map(formatDateIso)
//   .orNull()

export const formatDateStrShort = date => Maybe.fromNull(date)
  .map(parseDate)
  .map(formatDateShort)
  .orNull()

export const formatDateStrLong = date => Maybe.fromNull(date)
  .map(parseDate)
  .map(formatDateLong)
  .orNull()

// export const formatDateTimeStrShort = date => Maybe.fromNull(date)
//   .map(parseDate)
//   .map(formatDateTimeShort)
//   .orNull()

export const formatDateTimeStrWithFormatString = formatString => date => Maybe.fromNull(date)
  .map(parseDate)
  .map(formatDateWithFormatString(formatString))
  .orNull()

export const formatDateTimeStrLong = date => Maybe.fromNull(date)
  .map(parseDate)
  .map(formatDateTimeLong)
  .orNull()

// export const formatDateTimeStrLongTZ = date => Maybe
//   .fromNull(formatDateTimeStrLong(date))
//   .map(suffix(` ${tzAbbr(date)}`))
//   .orNull()

export const formatRelativeTime = value => Maybe.fromFalsy(value)
  .map(parseDate)
  .map(curryRight(formatDistanceToNow, { addSuffix: true }))
  .orNull()

export const formatSec = compose(
  msToTime,
  v => v * 1000,
)

export const formatTimeStr = value => Maybe.fromFalsy(value)
  .map(parseDate)
  .map(formatTime)
  .orNull()

export const msToTimeText = compose(
  ([m, s]) => `${pluralize('minute')(m)} ${pluralize('second')(s)}`,
  map(Number.parseFloat),
  split(':'),
  msToTime,
)

// export const parseDateFromField = obj => field => Maybe.fromNull(obj[field]) //
//   .map(parseDate)
//   .orNull()
