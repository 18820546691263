import { Routes } from 'react-router-dom'
import { mapValues, set } from 'fp/objects'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import { configToRoutes } from 'routing/TraverseRoute'
import Courses from 'hss/views/Curriculum/Courses'
import Units from 'hss/views/Curriculum/Units'
import {
  ABILITY_TEACHER_INTERFACE,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_UNIT,
} from 'core/consts'
import Chapters from 'hss/views/Curriculum/Chapters'
import Chapter from 'hss/views/Curriculum/Chapter'
import Route404 from '../Route404'

const config = mapValues(set('abilityFlags', ABILITY_TEACHER_INTERFACE))({

  base: {
    path: '/',
    element: <Courses />,
  },

  course: {
    element: <Units />,
    path: `${CONTENT_TYPE_COURSE}/:contentId`,
  },

  unit: {
    element: <Chapters />,
    path: `${CONTENT_TYPE_UNIT}/:contentId`,
  },

  chapter: {
    element: <Chapter />,
    path: `${CONTENT_TYPE_CHAPTER}/:contentId`,
  },

  404: {
    path: '*',
    element: <Route404 collectionName="Curriculum" />,
  },

})

const routes = configToRoutes(config)

const CurriculumRoutes = () => (
  <ErrorBoundary moduleName="CurriculumRoutes">

    <Routes>

      {routes}

    </Routes>

  </ErrorBoundary>
)

export default CurriculumRoutes
