import { border } from 'polished'

const overrides = ({ mixins: { em, rem }, palette, typography }) => ({
  MuiTable: {
    styleOverrides: {
    },

    variants: [
      {
        props: { variant: 'pacing-guide' },
        style: {
          'th, td': {
            border: 'none',
            padding: rem(2),
            verticalAlign: 'middle',
          },
          th: {
            padding: rem(1),
            textAlign: 'center',
          },
          td: {
            ...border(1, 'dashed', palette.grey[3]),

            '&:not(.day-column)': {
              background: palette.common.white,
            },

            '&.day-column': {
              padding: rem(2, 1),
              textAlign: 'center',
            },

            ul: {
              padding: rem(0, 2),

              li: {
                // StyledContent increases font size for list items. Override that.
                '&:not([variant])': typography.variants.paragraph,

                listStyleType: 'disc',
                padding: 0,

                '&:last-child': {
                  paddingBottom: 0,
                },
              },
            },
          },
        },
      },

      {
        props: { variant: 'pacing-guide', 'data-subvariant': 'unit' },
        style: {
          thead: {
            th: {
              fontWeight: 600,
              letterSpacing: em(0.1),
              textTransform: 'uppercase',
              padding: rem(2.2, 1),
              '&:not(.pacing-option-header)': {
                ...border(1, 'dashed', palette.grey[3]),
                background: palette.grey[5],
              },
            },
          },
          'td.special-segment': {
            background: palette.grey[5],
            textAlign: 'center',
          },
        },
      },
    ],
  },
})

export default overrides
