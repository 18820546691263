const overrides = ({ mixins: { rem }, palette, shadows, typography }) => ({
  MuiTooltip: {
    defaultProps: {
      arrow: true,
      enterDelay: 500,
      enterNextDelay: 300,
    },

    styleOverrides: {
      arrow: {
        color: palette.text.primary,
        fontSize: 24,
      },

      tooltip: {
        ...typography.variants.body2,
        color: palette.primary.contrastText,
        backgroundColor: palette.text.primary,
        borderRadius: 0,
        boxShadow: shadows[3],
        maxWidth: 495,
        padding: rem(1.2, 2.4),
      },

      popper: {
        '&[data-variant="inverted"]': {
          '.MuiTooltip-tooltip': {
            backgroundColor: palette.background.paper,
            color: palette.text.primary,
            padding: 0,
          },
          '.MuiTooltip-arrow': {
            color: palette.common.white,
          },
        },
        '&[data-variant="image-annotation"]': {
          '.MuiTooltip-tooltip': {
            backgroundColor: palette.background.paper,
            color: palette.text.primary,
          },
          '.MuiTooltip-arrow': {
            display: 'none',
          },
        },
        '&[data-image-position="left"]': {
          '.MuiTooltip-tooltip': {
            width: 'auto',
            maxWidth: 700,
          },
        },
        '&[data-image-position="top"]': {
          '.MuiTooltip-tooltip': {
            width: 'auto',
            maxWidth: 640,
          },
        },
      },
    },
  },
})

export default overrides
