import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import { componentShape, contentTypeShape } from 'core/shapes'
import useContent from 'hooks/useContent'
import { getContentViewerUrl } from 'selectors/contentViewer'
import { renameKeys, set } from 'fp/objects'
import { alter, assert } from 'core/store/search/squery'
import Link from './Link'

/**
 * Depending on the contentType, we may need access to the immediate child, and
 * either the parent or grandparent (for instance, subsections need to see the
 * chapter).  Here we just ensure we have both available in Redux.
 */
const queryParams = compose(
  set('childDepth', 1),
  search => ({ search }),
  alter.set.modifier('withParents').is(true),
  assert,
)()

const ContentLink = ({ contentId, children, contentType }) => {
  const content = useContent({ contentId, contentType, queryParams })

  const url = useSelector(getContentViewerUrl(renameKeys({ id: 'contentId' }, content), 'current'))

  if (!content) return children

  return (
    <Link
      style={{ fontWeight: 400 }}
      to={url}
    >
      {children}
    </Link>
  )
}

ContentLink.propTypes = {
  children: componentShape.isRequired,
  contentId: PropTypes.string.isRequired,
  contentType: contentTypeShape.isRequired,
}

export default ContentLink
