import { set } from 'fp/objects'
import { suffix } from 'fp/strings'
import actionTypes from 'reducers/actionTypes'

export const success = suffix('_SUCCESS')
export const failure = suffix('_FAILURE')

export const actionSucceeded = ({ type }) => String(type).endsWith('_SUCCESS')
export const actionFailed = ({ type }) => String(type).endsWith('_FAILURE')

/**
 * DO NOT USE
 * This is here as a bridge between the useApiFromEffect hook and the api saga.
 *
 * To call the api from a saga/redux, see dangerouslyCallApi()
 *
 * To call the api from anywhere else in the app, use the useApiFromEffect() hook
 */
export const apiCall = set('type', actionTypes.CALL_API)

export const contentRecordBoolFields = [
  'teacherEdition',
]
