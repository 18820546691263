import { styled } from '@mui/material/styles'
import { contentShape } from 'core/shapes'
import UploadsPreview from './UploadsPreview'

const MediaPreview = styled(
  ({ className, content }) => {
    const { uploadsMap } = content

    return (
      <div className={className}>
        <UploadsPreview uploadsMap={uploadsMap} />
      </div>
    )
  },
  { name: 'interactivePlugin-MediaPreview' },
)(({ theme: { mixins: { rem } } }) => ({
  marginRight: rem(2),
}))

MediaPreview.propTypes = {
  content: contentShape.isRequired,
}

export default MediaPreview
