import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'
import CollapsibleSection from 'common/layout/CollapsibleSection'
import { AcademicVocabPicker, ContentVocabPicker, FootnoteVocabPicker } from './VocabularyPicker'

const Vocabulary = ({ disabled = false, namePrefix = '', ...rest }) => {
  const existingVocabulary = useWatch({ name: [
    `${namePrefix}contentVocabPhraseIds`,
    `${namePrefix}academicVocabPhraseIds`,
    `${namePrefix}footnoteVocabPhraseIds`,
  ] })

  const empty = !existingVocabulary.flat().length

  return (
    <CollapsibleSection
      empty={empty}
      initiallyExpanded={false}
      label="Vocabulary"
      mt={3}
      style={{ padding: 0 }}
      {...rest}
    >

      <ContentVocabPicker
        disabled={disabled}
        name={`${namePrefix}contentVocabPhrases`}
        style={{ marginTop: 0 }}
        valueField={`${namePrefix}contentVocabPhraseIds`}
      />

      <AcademicVocabPicker
        disabled={disabled}
        name={`${namePrefix}academicVocabPhrases`}
        style={{ marginTop: 0 }}
        valueField={`${namePrefix}academicVocabPhraseIds`}
      />

      <FootnoteVocabPicker
        disabled={disabled}
        name={`${namePrefix}footnoteVocabPhrases`}
        style={{ marginTop: 0 }}
        valueField={`${namePrefix}footnoteVocabPhraseIds`}
      />

    </CollapsibleSection>
  )
}

Vocabulary.propTypes = {
  disabled: PropTypes.bool,
  namePrefix: PropTypes.string,
}

export default Vocabulary
