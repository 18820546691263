import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import FormHelperText from '@mui/material/FormHelperText'
import { compose } from 'redux'
import { CONTENT_STATE_PUBLISHED, CONTENT_TYPE_INTERACTIVE, INTERACTIVE_TYPE_LINK } from 'core/consts'
import useReduxCallback, { BUSY } from 'hooks/useReduxCallback'
import useReduxPromise from 'hooks/useReduxPromise'
import actionTypes from 'reducers/actionTypes'
import TextFieldPrime from 'common/formControls/textInputs/TextField'
import { get, mapValues, omit, set } from 'fp/objects'
import { isDefined, when } from 'fp/utils'
import { contentShape, contentTypeShape } from 'core/shapes'
import { urlRegex } from 'fp/internet'
import useToggleState from 'hooks/useToggleState'
import useContent from 'hooks/useContent'
import Warning from 'common/layout/Warning'
import BusySpinner from 'common/indicators/BusySpinner'
import { isEmpty } from 'fp/arrays'
import withProps from 'hoc/withProps'

const TextField = withProps(TextFieldPrime, { style: { marginTop: 48 } })

const tests = {
  checkPhrase: /.+/,
  href: urlRegex,
}

export const contentStubShape = PropTypes.shape({
  contentId: PropTypes.string.isRequired,
  contentType: contentTypeShape.isRequired,
})

const CreateEditExternalLinkDialogContents = (props) => {
  const { contentId, editing, onCancel, onComplete, parentContent } = props

  const [isDirty, toggleIsDirty] = useToggleState(false)
  const [hasErrors, setHasErrors] = useState(false)

  const isEditing = isDefined(contentId)
  const [fetched, setFetched] = useState(false)

  const existingLink = useContent({ contentId, contentType: CONTENT_TYPE_INTERACTIVE }) || {}

  const [data, setData] = useState({
    checkPhrase: { value: existingLink?.data?.checkPhrase || '', hasError: true },
    href: { value: existingLink?.data?.href || '', hasError: true },
    isBinary: { value: Boolean(existingLink?.data?.isBinary) },
    notes: { value: existingLink?.data?.notes || '' },
    tooltip: { value: existingLink?.data?.tooltip || '' },
  })

  useEffect(() => {
    if (existingLink.data) {
      if (!fetched) {
        setFetched(true)
        setData(compose(
          set('checkPhrase.hasError', data.isBinary.value
            ? false
            : !tests.checkPhrase.test(existingLink.data.checkPhrase)),
          set('checkPhrase.value', existingLink.data.checkPhrase),
          set('href.hasError', !tests.href.test(existingLink.data.href)),
          set('href.value', existingLink.data.href),
          set('isBinary.value', existingLink.data.isBinary),
          set('notes.value', existingLink.data.notes),
          set('tooltip.value', existingLink.data.tooltip),
        ))
      }
    }
  }, [data.isBinary.value, existingLink.data, fetched])

  const createLinkSuccess = ({ newChildResponse }) => {
    onComplete(newChildResponse)
  }

  const createLinkFailure = () => {
    /**
     * Do we need to do anything here?  Any transport errors would have already
     * been show as notifications.
     */
  }

  const [dispatchCreateLink, status] = useReduxCallback({
    actionType: actionTypes.CONTENT_CREATE_AND_ADD_CHILD,
    onError: createLinkFailure,
    onSuccess: createLinkSuccess,
  })

  const updateLink = useReduxPromise(actionTypes.CONTENT_SAVE)

  const handleBlur = ({ target }) => {
    const { name } = target
    if (tests[name]) {
      setData(set(`${name}.hasError`, !tests[name].test(data[name].value)))
    }
  }

  const handleChange = ({ target }) => {
    const { checked, name, value: targetValue } = target
    const value = name === 'isBinary' ? checked : targetValue

    setData(set(`${name}.value`, value))
    if (isDirty && tests[name]) {
      // possibly clear existing error state
      setData(set(`${name}.hasError`, !tests[name]?.test?.(value)))
    }
    when(!isDirty, toggleIsDirty)
  }

  const handleSubmit = () => {
    if (isEditing) {
      updateLink({
        payload: {
          ...existingLink,
          data: mapValues(get('value'))(data),
        },
        suppressAlert: true,
      })
        .then(onComplete)
    } else {
      dispatchCreateLink({
        contentState: CONTENT_STATE_PUBLISHED,
        contentSubType: INTERACTIVE_TYPE_LINK,
        contentType: CONTENT_TYPE_INTERACTIVE,
        data: mapValues(get('value'))(data),
        parentContent,
      })
    }
  }

  const disabled = status === BUSY

  useEffect(() => {
    setHasErrors(Object.values(omit(data.isBinary.value ? 'checkPhrase' : '')(data))
      .map(get('hasError'))
      .filter(isDefined)
      .some(Boolean))
  }, [data])

  return !isEmpty(existingLink) || !editing
    ? (
      <>
        <TextField
          autoFocus
          disabled={disabled}
          error={data.href.hasError}
          helperText={data.href.hasError
            ? 'A valid URL is required'
            : (
              <>
                Please be sure to always use&nbsp;
                <Typography variant="small-semibold">https://</Typography>&nbsp;
                whenever possible
              </>
            )}
          label="URL"
          name="href"
          onBlur={handleBlur}
          onChange={handleChange}
          required
          type="url"
          value={data.href.value}
          variant="filled"
        />

        <FormControl disabled={disabled}>
          <FormControlLabel
            control={(
              <Switch
                checked={data.isBinary.value}
                data-color="cobalt"
                name="isBinary"
                onChange={handleChange}
              />
            )}
            label="Destination is a file"
          />
          <FormHelperText data-variant="switch">
            Enable this if the item you are linking to is a download and not a web page
          </FormHelperText>
        </FormControl>

        {!data.isBinary.value && (
          <TextField
            disabled={disabled}
            error={data.checkPhrase.hasError}
            helperText={`
              The system will look for this text within the linked page when it routinely
              checks to make sure the link isn't broken and that the content has not changed
              since the link was originally created`}
            label="Check Phrase"
            maxRows={2}
            multiline
            name="checkPhrase"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={data.checkPhrase.value}
            variant="filled"
          />
        )}

        <TextField
          disabled={disabled}
          helperText={`
            This supplemental text will appear when the link is hovered. Since not
            all users can hover (e.g. when using touch devices or some screen readers),
            it should not contain critically important information`}
          label="Tooltip (optional)"
          maxRows={2}
          multiline
          name="tooltip"
          onChange={handleChange}
          value={data.tooltip.value}
          variant="filled"
        />

        <TextField
          aria-label="notes"
          disabled={disabled}
          helperText={`
            This text is searchable by internal users and is not publicly visible`}
          label="Notes"
          maxRows={4}
          multiline
          name="notes"
          onChange={handleChange}
          value={data.notes.value}
          variant="filled"
        />

        {!!isEditing && (
          <Warning>
            <strong>NOTE:</strong> the link will be saved immediately after you hit apply.
            Cancelling your changes to the block will not undo this
          </Warning>
        )}

        <DialogActions sx={{ mb: 2, mt: 6, button: { width: 120 } }}>
          <Button
            disabled={disabled}
            onClick={onCancel}
            variant="secondary"
          >
            Cancel
          </Button>

          <Button
            disabled={disabled || hasErrors || !isDirty}
            onClick={handleSubmit}
            variant="primary"
          >
            Apply
          </Button>
        </DialogActions>
      </>
    )
    : <BusySpinner size={48} />
}

CreateEditExternalLinkDialogContents.propTypes = {
  contentId: PropTypes.string,
  editing: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  parentContent: contentShape.isRequired,
}

export default CreateEditExternalLinkDialogContents
