/* eslint-disable react-hooks/rules-of-hooks */
import { createContext, useContext, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import useComponentSize from 'hooks/useComponentSize'

const assertInput = (key, keys) => {
  if (!keys.includes(key)) {
    throw new Error(`useContainerQuery - key "${key}" is unknown.  Must be one of "${keys.join(',')}"`)
  }
}

export const containerQueryContext = createContext()

export const useContainerQuery = () => {
  const context = useContext(containerQueryContext)
  const { breakpoints } = useTheme()

  if (!context) {
    // just fallback to using the full viewport (aka use material hook instead)
    return {
      up: /* istanbul ignore next */ key => useMediaQuery(breakpoints.up(key)),
      down: /* istanbul ignore next */ key => useMediaQuery(breakpoints.down(key)),
      between: /* istanbul ignore next */ (start, end) => useMediaQuery(breakpoints.between(start, end)),
      only: /* istanbul ignore next */ key => useMediaQuery(breakpoints.only(key)),
    }
  }

  const { width } = context
  const { keys, values } = breakpoints

  const up = (key) => {
    assertInput(key, keys)
    return width >= values[key]
  }

  const down = (key) => {
    assertInput(key, keys)
    return width < values[key]
  }

  const between = (start, end) => up(start) && down(end)

  const only = (key) => {
    const idx = keys.indexOf(key)

    switch (idx) {
      case -1: return assertInput(key, keys)

      case 0: return down(keys[1])

      case keys.length - 1: return up(keys[keys.length - 1])

      default: return between(keys[idx], keys[idx + 1])
    }
  }

  return { up, down, between, only }
}

const ContainerQuery = ({ children }) => {
  const ref = useRef()
  const { width } = useComponentSize(ref)
  const { breakpoints: { values } } = useTheme()

  const value = useMemo(() => {
    const size = Object.entries(values).reduce((acc, [key, val]) => (width < val)
      ? acc
      : key, 'xs')

    return {
      size,
      width,
    }
  }, [values, width])

  return (
    <containerQueryContext.Provider value={value}>
      <div
        ref={ref}
        style={{ position: 'relative' }}
      >
        {children}
      </div>
    </containerQueryContext.Provider>
  )
}

ContainerQuery.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ContainerQuery
