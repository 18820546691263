import Box from '@mui/material/Box'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Alert from '@mui/material/Alert'
import { AUTH_PROVIDER_IMPERSONATE } from 'core/consts'
import { appUrl, assessmentUrl } from 'routing/consts'
import { getLocation } from 'selectors/routing'
import useSession from 'hooks/useSession'
import { SidebarProvider } from '../SidebarProvider'
import OuterViewport from '../OuterViewport'
import NavSidebar from './NavSidebar'

const NavShell = () => {
  const { pathname } = useSelector(getLocation)
  const { session: { authProvider } } = useSession()
  const isImpersonatedSession = authProvider === AUTH_PROVIDER_IMPERSONATE

  return (

    <SidebarProvider variant="nav">

      <Box display="flex">

        <NavSidebar homeUrl={appUrl} />

        <OuterViewport pb={pathname.startsWith(assessmentUrl) ? 0 : 8}>
          {
            Boolean(isImpersonatedSession)
            && <Alert severity="warning">This is an impersonated session. Use caution: you are acting as if you were this user.</Alert>
          }
          <Outlet />

        </OuterViewport>

      </Box>

    </SidebarProvider>

  )
}

export default NavShell
