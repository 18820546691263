import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'
import { HelpCircle } from 'react-feather'
import { position, size } from 'polished'
import HelpCircleFilled from 'hss/images/controls/textfield/help-circle-filled.svg'
import useHoverFocus from 'hooks/useHoverFocus'

const KeyBindingsHelpButton = styled(
  ({ className, disabled, onClick }) => {
    const [hoverFocusRef, isHovered, isFocused] = useHoverFocus()

    return (
      <IconButton
        aria-label="Keyboard Shortcuts"
        className={className}
        disabled={disabled}
        disableFocusRipple
        disableRipple
        onClick={onClick}
        ref={hoverFocusRef}
        size="medium"
        title="Keyboard Shortcuts"
        type="button"
      >
        <Icon style={size(24)}>
          {isHovered || isFocused
            ? <HelpCircleFilled width={24} />
            : <HelpCircle />}
        </Icon>
      </IconButton>
    )
  },
  { name: 'RichTextEdit-KeyBindingsHelpButton' },
)(({ theme: { mixins: { important }, zIndex } }) => ({
  ...position('absolute', 'auto', 10, 5, 'auto'),
  position: important('absolute'),
  zIndex: zIndex.appBar + 1,
}))

KeyBindingsHelpButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default KeyBindingsHelpButton
