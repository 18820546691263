import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import Box from '@mui/material/Box'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { debounce, isDefined, isUndefined } from 'fp/utils'
import Slider from 'common/formControls/sliders/Slider'
import Instructions from 'common/layout/Instructions'
import { nameValuePairShape } from 'core/shapes'
import { camelCaseToWords, titleCase } from 'fp/strings'

const HFSlider = ({
  helperText,
  label,
  marks = [],
  min,
  max,
  name,
  valueLabelDisplay = 'on',
  variant,
  ...rest
}) => {
  const currentValue = useWatch({ name })
  const [initialValue, setInitialValue] = useState()

  const { setValue } = useFormContext()

  const prettyLabel = label || titleCase(camelCaseToWords(name))

  useEffect(() => {
    if (!initialValue) {
      setInitialValue(isUndefined(currentValue) ? min : currentValue)
    }
  }, [currentValue, initialValue, min])

  const onChange = (value) => {
    setValue(name, value)
  }

  const debouncedOnChange = debounce(200, onChange)

  return (
    <>
      {variant && variant === 'gradient'
        ? (
          <>
            <FormLabel>
              <Instructions>{prettyLabel}</Instructions>
            </FormLabel>
            <Box
              display="flex"
              justifyContent="center"
              mb={2}
              mt={6}
            >
              <FormHelperText>{helperText}</FormHelperText>
            </Box>
          </>
        )
        : (
          <>
            <FormLabel>{prettyLabel}</FormLabel>
            <FormHelperText>{helperText}</FormHelperText>
          </>
        )}

      {Boolean(isDefined(initialValue)) && (
        <Slider
          aria-label={prettyLabel}
          aria-valuemax={max}
          aria-valuemin={min}
          aria-valuenow={currentValue}
          defaultValue={initialValue}
          marks={marks}
          max={max}
          min={min}
          onChange={(_, v) => debouncedOnChange(v)}
          valueLabelDisplay={valueLabelDisplay}
          variant={variant}
          {...rest}
        />
      )}
    </>
  )
}

HFSlider.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  marks: nameValuePairShape,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  valueLabelDisplay: PropTypes.string,
  variant: PropTypes.string,
}

export default HFSlider
