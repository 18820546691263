import { produce } from 'immer'
import { compose } from 'redux'
import { adminUserPreferencesDefaults, studentUserPreferencesDefaults } from 'shared/schema/user'
import { failure, success } from 'sagas/utils'
import { curry } from 'fp/utils'
import { get, merge, set } from 'fp/objects'
import { filter, flatten, map } from 'fp/arrays'
import { ROLE_STUDENT } from 'core/consts'
import { alter, assert } from '../search/squery'
import {
  createReducer,
  handleFetchListSuccess,
  handleTableCellFieldChange,
  handleTableCellFieldRollback,
  listReducerInitialState,
  updateLoaded,
} from './utils'
import actionTypes from './actionTypes'

export const actions = {
  fetchMyStudents: (props) => {
    const { queryParams } = props || {}
    return ({
      type: actionTypes.USER_FETCH_LIST,
      queryParams,
      criteria: compose(
        alter.set.modifier('studentsInMyGroups').is(true),
        alter.set.limit(999),
      )(queryParams?.search || assert()),
    })
  },
  fetchUserById: ({ userId }) => ({
    userId,
    type: actionTypes.USER_FETCH,
  }),
  fetchUsers: ({ queryParams } = {}) => ({
    type: actionTypes.USER_FETCH_LIST,
    queryParams,
  }),
  changeTheme: ({ user, theme }) => ({ type: actionTypes.USER_CHANGE_THEME, user, theme }),
  save: user => ({ type: actionTypes.USER_SAVE, user }),
}

const userUpdated = (state, { response }) => updateLoaded(state, response)

const handleLoginSuccess = (state, { response: { user } }) => userUpdated(
  state,
  {
    response: set(
      'preferences',
      merge(
        user.roleId === ROLE_STUDENT ? studentUserPreferencesDefaults : adminUserPreferencesDefaults,
        user.preferences,
      ),
    )(user),
  },
)

const handleFetchUserListSuccess = (state, response) => handleFetchListSuccess(
  state,
  response,
)

const handleFetchItemSuccess = (state, { response }) => updateLoaded(state, response)

const handleChangeTheme = produce((draft, { user, theme }) => {
  draft[user.id].theme = theme
})

const handleUserAssignmentFetchListSuccess = (state, args) => compose(
  curry(handleFetchListSuccess, 2, state),
  users => ({ response: { data: users, metadata: {} } }),
  filter(Boolean), // not all API responses contain user objects
  map(get('user')),
  get('response.data'),
)(args)

const handleAssignmentFetchSuccess = (state, args) => compose(
  curry(handleFetchListSuccess, 2, state),
  users => ({ response: { data: users, metadata: {} } }),
  flatten,
  map(get('user')),
  get('response.userAssignments'),
)(args)

const users = createReducer(
  listReducerInitialState(),
  {
    [actionTypes.TABLE_CELL_FIELD_CHANGE]: handleTableCellFieldChange('users'),
    [actionTypes.USER_CHANGE_THEME]: handleChangeTheme,

    [failure(actionTypes.TABLE_CELL_FIELD_CHANGE)]: handleTableCellFieldRollback('users'),

    [success(actionTypes.ASSIGNMENT_FETCH)]: handleAssignmentFetchSuccess,
    [success(actionTypes.SESSION_CHECK)]: handleLoginSuccess,
    [success(actionTypes.SESSION_LOGIN)]: handleLoginSuccess,
    [success(actionTypes.USER_ASSIGNMENT_FETCH_LIST)]: handleUserAssignmentFetchListSuccess,
    [success(actionTypes.USER_FETCH_LIST)]: handleFetchUserListSuccess,
    [success(actionTypes.USER_FETCH)]: handleFetchItemSuccess,
    [success(actionTypes.USER_SAVE)]: userUpdated,
  },
)

export default users
