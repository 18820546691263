import { compose } from 'redux'
import { isUndefined } from './utils'

// parseFloat removes trailing insignificant zeros (so 1.00 will become "1", 1.50 will become "1.5")
export const asFloatWithoutTrailingZeros = (value, decimalPlaces = 2) => parseFloat((value).toFixed(decimalPlaces))

export const asPercentageString = value => (isUndefined(value) || compose(Number.isNaN, Number)(value))
  ? null
  : `${Math.round(value * 100)}%`

export const assertRange = (value, min, max) => Math.min(Math.max(value, min), max) || min

export const average = (...args) => {
  const arr = args.length === 1 && Array.isArray(args[0])
    ? args[0]
    : args
  // eslint-disable-next-line no-use-before-define
  return sum(arr) / arr.length
}

export const decrement = value => value - 1

// export const fallsBetween = (value, start, end) => start < value && value < end
export const fallsWithin = (value, start, end) => start <= value && value <= end

export const increment = value => value + 1

export const radian = Math.PI / 180

// export const rangesOverlap = (s1, e1, s2, e2) => fallsWithin(s2, s1, e1) || fallsWithin(s1, s2, e2)

export const sum = (...args) => {
  const arr = args.length === 1 && Array.isArray(args[0])
    ? args[0]
    : args
  return arr?.length ? arr.reduce((acc, v) => acc + v) : 0
}
