import { deepMerge, get } from 'fp/objects'
import { curry } from 'fp/utils'

export const white = ({ common }) => common.white

export const svgColor = color => ({ svg: { color } })

const boxShadow = color => `0px 4px 4px ${color}`

const withDefaults = (palette, accentColor) => curry(deepMerge)({
  backgroundColor: accentColor === 'cobalt' ? palette.cobalt[0] : palette.common.black,
  borderColor: palette.grey[4],
  activeBoxShadow: boxShadow(palette.common.black),
  color: palette.primary.contrastText,
  disabledBackground: palette.disabled.background,
  disabledBorderColor: palette.grey[3],
  disabledColor: palette.grey[2],
  hoverBackground: accentColor === 'cobalt' ? palette.cobalt[0] : palette.common.black,
  hoverBorderColor: 'transparent',
  hoverColor: white(palette),
  svgColor: svgColor(white(palette)),

  default: {
    backgroundColor: white(palette),
    color: palette.text.primary,
    disabledBackground: 'transparent',
  },

  primary: {
    disabledBorderColor: palette.disabled.border,
    borderColor: 'transparent',
    hoverColor: accentColor === 'cobalt' ? palette.contrastText : palette.grey.contrastText,
    hoverBackground: accentColor === 'cobalt' ? palette.cobalt.hover : palette.grey.hover,
  },

  secondary: {
    backgroundColor: white(palette),
    borderColor: palette[accentColor][4],
    color: palette[accentColor][0],
    disabledBackground: 'transparent',
    disabledBorderColor: palette.grey[3],
    disabledColor: palette.grey[3],
  },

  tertiary: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: palette.text.primary,
    disabledBackground: 'transparent',
    disabledBorderColor: 'transparent',
    hoverBackground: white(palette),
    hoverColor: palette.text.primary,
  },
})

const metaAttributes = (palette, shadows) => ({
  body1: {
    secondary: withDefaults(palette, 'grey')({
      activeBoxShadow: boxShadow(palette.grey[0]),

      primary: {
        activeBoxShadow: boxShadow(palette.common.black),
      },

      tertiary: {
        // create borders
        activeBoxShadow: `${shadows.border}  ${palette.grey[0]},${shadows.activeBorder} ${palette.grey[0]}`,
        hoverBoxShadow: `${shadows.border} ${palette.grey[0]}`,
      },
    }),

    primary: withDefaults(palette, 'cobalt')({
      activeBoxShadow: boxShadow(palette.cobalt[1]),

      primary: {
        activeBoxShadow: boxShadow(palette.cobalt[0]),
      },

      tertiary: {
        color: palette.cobalt[0],
        hoverColor: palette.cobalt[0],
        // create borders
        activeBoxShadow: `${shadows.border} ${palette.cobalt[0]}, ${shadows.activeBorder} ${palette.cobalt[0]}`,
        hoverBoxShadow: `${shadows.border} ${palette.cobalt[0]}`,
      },
    }),
  },

  body2: {
    secondary: withDefaults(palette, 'grey')({
      activeBoxShadow: boxShadow(palette.grey[0]),

      primary: {
        activeBoxShadow: boxShadow(palette.common.black),
      },

      tertiary: {
        // create borders
        activeBoxShadow: boxShadow(palette.tangerine[1]),
        hoverBoxShadow: `${shadows.border} ${palette.grey[0]}`,
      },
    }),

    primary: withDefaults(palette, 'cobalt')({
      activeBoxShadow: boxShadow(palette.cobalt[1]),

      default: {
        backgroundColor: palette.grey[1],
        color: palette.primary.contrastText,
        borderColor: palette.grey[2],
      },

      primary: {
        activeBoxShadow: boxShadow(palette.cobalt[0]),
      },

      secondary: {
        backgroundColor: palette.grey[1],
      },

      tertiary: {
        color: palette.primary.contrastText,
        backgroundColor: 'transparent',
        // create borders
        activeBoxShadow: `${shadows.borderInset} ${palette.primary.contrastText}, ${shadows.activeBorder} ${palette.primary.contrastText}`,
      },
    }),
  },

  body3: {
    secondary: withDefaults(palette, 'grey')({
      activeBoxShadow: boxShadow(palette.grey[0]),
      primary: {
        activeBoxShadow: boxShadow(palette.common.black),
      },
      tertiary: {
        // create borders
        borderColor: palette.grey[0],
        hoverBorderColor: palette.grey[0],
        activeBoxShadow: `${shadows.borderInsetButton}  ${palette.grey[0]},${shadows.activeBorder} ${palette.grey[0]}`,
        hoverBoxShadow: `${shadows.borderInsetButton} ${palette.grey[0]}`,
      },
    }),
    primary: withDefaults(palette, 'cobalt')({
      activeBoxShadow: boxShadow(palette.cobalt[1]),
      primary: {
        activeBoxShadow: boxShadow(palette.cobalt[0]),
      },
      tertiary: {
        borderColor: palette.cobalt[0],
        hoverBorderColor: palette.cobalt[0],
        activeBoxShadow: `${shadows.borderInsetButton} ${palette.cobalt[0]}, ${shadows.activeBorder} ${palette.cobalt[0]}`,
        hoverBoxShadow: `${shadows.borderInsetButton} ${palette.cobalt[0]}`,
      },
    }),
  },

})

export const themeColorVariants = (theme, applicator, bodyVariant = 'body1') => {
  const { palette, shadows } = theme
  const allMeta = metaAttributes(palette, shadows)

  const variants = ['default', 'primary', 'secondary', 'tertiary']
  const colors = ['primary', 'secondary']

  return colors
    .map(color => variants.map((variant) => {
      const meta = allMeta[bodyVariant][color]
      const getProperty = propertyName => get(`${variant}.${propertyName}`)(meta) || meta[propertyName]

      return applicator({
        color,
        getProperty,
        meta,
        bodyVariant,
        theme,
        variant,
      })
    }))
    .flat()
    .filter(Boolean)
}
