import { styled } from '@mui/material/styles'
import Draggable from 'common/Draggable'

const StyledLineReader = styled(
  Draggable,
  { name: 'LineReader-LineReaderTool' },
)(({ theme: { mixins: { borderS, important, rem }, palette, shadows, zIndex } }) => ({
  padding: 0,
  backgroundColor: 'transparent',
  ...borderS(palette.grey[2]),
  borderLeftWidth: rem(2),
  borderRadius: rem(0.5),
  boxShadow: shadows[2],
  cursor: 'move',
  height: rem(15),
  zIndex: zIndex.tooltip + 1,
  position: important('fixed'),
  h1: {
    color: palette.common.white,
    paddingTop: rem(2),
    paddingBottom: rem(2),
    marginRight: rem(2),
    backgroundColor: palette.grey[2],
    borderBottom: `1px solid ${palette.grey[2]}`,
  },
  '.right-border': {
    width: rem(2),
    height: '100%',
    top: 0,
    right: 0,
    cursor: 'e-resize',
    position: 'absolute',
    display: 'block',
    background: palette.grey[2],
  },
  '.bottom-border': {
    height: rem(6),
    bottom: 0,
    left: 0,
    right: rem(2),
    cursor: 's-resize',
    borderTop: `1px solid ${palette.grey[2]}`,
    position: 'absolute',
    display: 'block',
    background: palette.grey[2],
  },
}))

export default StyledLineReader
