import { px } from 'styling/theming/base/mixins'
import { componentShape } from 'core/shapes'
import Well from './Well'

const ErrorWell = ({ children, ...rest }) => (
  <Well
    sxProps={{
      borderColor: 'error.main',
      bgcolor: 'error.light',
      button: {
        padding: px(5, 0),
      },
    }}
    {...rest}
  >
    {children}
  </Well>
)

ErrorWell.propTypes = {
  children: componentShape.isRequired,
}

export default ErrorWell
