import { produce } from 'immer'
import { border, position, rgba, size as polishedSize, transparentize } from 'polished'
import { Maybe } from 'monet'
import { isString, suffix } from 'fp/strings'
import { isDefined } from 'fp/utils'
import { acumin, inter } from './typography'
import { containedPaddingLeft, containedPaddingRight, featuredContentMaxWidth } from '.'

// -----------------------------------------------------------------------------
// Helper methods
// -----------------------------------------------------------------------------

const unitPrefixer = unit => size => isString(size)
  ? size
  : size
    ? suffix(unit)(size)
    : size

const spreadValues = asUnit => (...values) => values
  .slice(0, 4)
  .map(v => values.length > 1
    ? isDefined(v) ? v : 'initial'
    : v)
  .map(asUnit)
  .join(' ') || undefined

// -----------------------------------------------------------------------------
// Atomic methods that are later exported
// -----------------------------------------------------------------------------

const asEm = unitPrefixer('em')
const asPx = unitPrefixer('px')
const asRem = unitPrefixer('rem')

export const important = suffix(' !important')

const em = spreadValues(asEm)
export const px = spreadValues(asPx)
export const rem = spreadValues(asRem)

const textSizeR = (fontSize, lineHeight, fontWeight) => ({
  fontSize: asRem(fontSize),
  lineHeight: asRem(lineHeight),
  fontWeight,
})

// -----------------------------------------------------------------------------
// Sorted from here on down
// -----------------------------------------------------------------------------

export const absHeight = height => ({
  height,
  minHeight: height,
  maxHeight: height,
})

const absWidth = width => ({
  width,
  minWidth: width,
  maxWidth: width,
})

export const acuminTextSizeR = (size, height, weight = 600) => ({
  ...textSizeR(size, height, weight),
  fontFamily: acumin,
  fontStyle: 'normal',
  textTransform: 'uppercase',
})

const backgroundImage = url => ({
  background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${url})`,
})

const borderS = (color = 'currentColor', width = 1) => border(width, 'solid', color)

const clearfix = (display = 'block') => ({
  clear: 'both',
  content: '""',
  display,
})

const featuredMaxWidth = important(`calc(${px(featuredContentMaxWidth)} + ${containedPaddingLeft} + ${containedPaddingRight})`)

const featuredContainer = /* istanbul ignore next */ () => ({
  maxWidth: featuredMaxWidth,
  marginRight: 'auto',
  marginLeft: 'auto',
})

const featuredContentContainer = /* istanbul ignore next */ () => ({
  maxWidth: featuredContentMaxWidth,
  marginRight: 'auto',
  marginLeft: 'auto',
})

export const firefox = content => ({
  '@supports (-moz-appearance:none)': content,
})

const hideForPrint = () => ({
  '@media print': {
    display: important('none'),
  },
})

export const hideForScreen = () => ({
  '@media screen': {
    display: important('none'),
  },
})

const icon = (content, extras = true) => ({
  content: `'${content}'`,
  ...(extras ? {
    width: 'auto',
    margin: 0,
    fontStyle: 'normal',
    fontWeight: 'normal',
    speak: 'none',
    display: 'inline-block',
    textDecoration: 'inherit',
    lineHeight: em(1),
    textAlign: 'center',
    fontVariant: 'normal',
    textTransform: 'none',
    verticalAlign: 'middle',
    position: 'relative',
    top: -1,
    textRendering: 'optimizeLegibility',
  } : {}),
})

const iconAfter = (content, extras = true, more = {}) => ({
  whiteSpace: 'nowrap',
  '&::after': {
    ...icon(content, extras),
    transition: 'all 150ms ease-in-out 0ms',
    marginLeft: asRem(0.5),
    ...more,
  },
})

const importantEm = (...args) => Maybe.fromUndefined(em(...args))
  .map(important)
  .orJust(undefined)
export const importantPx = (...args) => Maybe.fromUndefined(px(...args))
  .map(important)
  .orJust(undefined)
export const importantRem = (...args) => Maybe.fromUndefined(rem(...args))
  .map(important)
  .orJust(undefined)

const interTextSizeR = (size, height, weight = 400) => ({
  ...textSizeR(size, height, weight),
  fontFamily: inter,
  fontStyle: 'normal',
})

const percentage = suffix('%')

const resetList = (clearfixToo = true, marginToo = true) => ({
  ...(clearfixToo ? clearfix() : {}),
  ...(marginToo ? { margin: 0 } : {}),
  padding: 0,
  listStyle: 'none',
  '&>li, &>dd': {
    margin: 0,
  },
})

const safari = content => ({
  '@media not all and (min-resolution:.001dpcm)': {
    '@supports (-webkit-appearance:none)': content,
  },
})

const size = (height, width = height) => ({
  ...absHeight(height),
  ...absWidth(width),
})

const sizeR = (...args) => polishedSize(...args.map(asRem))

const transition = (property = 'all', duration = 300, timing = '', delay = 0) => ({
  transition: `${property} ${duration}ms ${timing} ${delay}ms`,
})

const vertCenter = (pos = 'relative', offset = '-50%') => ({
  transform: `translateY(${offset})`,
  position: pos,
  top: '50%',
})

const vertScrollFade = (bgColor = '#fff') => ({
  position: 'relative',
  '&::after': {
    ...position('absolute', null, null, 0, 0),
    content: "''",
    width: percentage(100),
    height: asEm(5),
    backgroundImage: `linear-gradient( ${transparentize(1, bgColor)}, ${bgColor})`,
    pointerEvents: 'none',
  },
})

const vertScrollIndicator = (bgColor, shadowColor) => ({
  background: `
    linear-gradient(${bgColor} 30%, ${rgba(bgColor, 0)}),
    linear-gradient(${rgba(bgColor, 0)}, ${bgColor} 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, ${rgba(shadowColor, 0.2)}, ${rgba(shadowColor, 0)}),
    radial-gradient(farthest-side at 50% 100%, ${rgba(shadowColor, 0.2)}, ${rgba(shadowColor, 0)}) 0 100%`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: bgColor,
  backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
  backgroundAttachment: 'local, local, scroll, scroll',
})

const when = theme => (name, content, altContent = null) => {
  const pass = theme.name === name || name?.includes(theme.name)
  return pass ? content : altContent
}

const plugin = theme => produce(theme, (draft) => {
  draft.mixins = {
    absHeight,
    absWidth,
    acuminTextSizeR,
    backgroundImage,
    borderS,
    clearfix,
    em,
    featuredContainer,
    featuredContentContainer,
    featuredMaxWidth,
    firefox,
    hideForPrint,
    hideForScreen,
    icon,
    iconAfter,
    important,
    importantEm,
    importantPx,
    importantRem,
    interTextSizeR,
    percentage,
    px,
    rem,
    resetList,
    safari,
    size,
    sizeR,
    textSizeR,
    transition,
    vertCenter,
    vertScrollFade,
    vertScrollIndicator,
    when: when(theme),
  }
})

export default plugin
