import { produce } from 'immer'
import { compose } from 'redux'
import * as processors from './processors/index'

const runner = () => next => action => next(produce(
  action,
  compose(...Object.values(processors)),
))

const setup = () => runner

const contentPreprocessorMiddleware = setup

export default contentPreprocessorMiddleware
