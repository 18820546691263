import { withOptions } from '@comfy/redux-selectors'
import { compose } from 'redux'
import { PROFICIENCIES, ROLE_STAFF, ROLE_STUDENT, ROLE_SYSTEM } from 'core/consts'
import { curry, matches } from 'fp/utils'
import { filter, find } from 'fp/arrays'
import { equals, get, hasProperty } from 'fp/objects'
import { actions } from 'reducers/users'
import { callWith } from 'fp/call'
import { omitReduxMetadata } from './utils'
import { createSelector, stateSession, stateUsers } from '.'

export const getCurrentUser = createSelector('getCurrentUser')(
  stateUsers,
  stateSession,
  (users = [], { userId } = {}) => users[userId],
)

export const getCurrentUserProficiency = createSelector('getCurrentUserProficiency')(
  getCurrentUser,
  compose(
    callWith(PROFICIENCIES),
    compose(find, curry(matches, 2, 'id')),
    get('proficiencyId'),
  ),
)

export const getCurrentRoleId = createSelector('getCurrentRoleId')(
  getCurrentUser,
  get('roleId'),
)

export const getDistrictId = createSelector('getDistrictId')(
  getCurrentUser,
  get('districtId'),
)

// export const getSchools = createSelector('getCurrentSchool')(
//   getCurrentUser,
//   get('schools'),
// )

const getCurrentSchool = createSelector('getCurrentSchool')(
  getCurrentUser,
  get('schools.0'),
)

// export const getSchoolId = createSelector('getSchoolId')(
//   getCurrentSchool,
//   get('id'),
// )

const hasRole = withOptions(({ roleId }) => createSelector('hasRole')(
  getCurrentRoleId,
  equals(roleId),
))

export const getDecoratedUser = createSelector('getDecoratedUser')(
  getCurrentUser,
  getCurrentSchool,
  /**
   *    ! ! !   Please refrain from adding to this selector   ! ! !
   *
   * The rationale is that anything added would probably be related to
   * permissions.  We're keeping everything like that in one central location
   * to prevent spaghettification, or at worst accidentally allowing someone to
   * see content or perform actions that they are not supposed to.
   *
   * Please refer to the feature flag documentation for more insight.
   */
  (user, school) => ({
    actions,
    school,
    user,
  }),
)

export const isStaff = hasRole({ roleId: ROLE_STAFF })
export const isStudent = hasRole({ roleId: ROLE_STUDENT })
export const isSysAdmin = hasRole({ roleId: ROLE_SYSTEM })

const getUsers = createSelector('getUsers')(
  stateUsers,
  compose(
    Object.values,
    omitReduxMetadata,
  ),
)

export const getStudents = createSelector('getStudents')(
  getUsers,
  filter(matches('roleId', ROLE_STUDENT)),
)

export const getUserById = withOptions(({ userId }) => createSelector('getUserById')(
  stateUsers,
  get(String(userId)),
))

export const isUserLoaded = withOptions(({ userId }) => createSelector('isUserLoaded')(
  stateUsers,
  compose(
    hasProperty(String(userId)),
    get('loaded'),
  ),
))
