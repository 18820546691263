import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { push } from 'redux-first-history'
import actionTypes from 'reducers/actionTypes'
import { restEndpoint } from 'reducers/utils'
import { getCurrentUser, getDistrictId } from 'selectors/users'
import { getParentFromContentId } from 'selectors/contentViewer'
import { findSiblings } from 'projections/content'
import { actions } from 'reducers/content'
import { abilityCheck } from 'selectors/userAbility'
import { ABILITY_DISTRICT_ADMINISTRATION } from 'core/consts'
import { buildUrl } from 'fp/internet'
import { get, pick } from 'fp/objects'
import { getContentById, getContentType } from 'selectors/content'
import { dangerouslyCallApi } from './api'
import { success } from './utils'

const endpoint = id => buildUrl(`${restEndpoint.contentInsertions}/${id}`)

function* handleCreate(action) {
  const {
    payload: {
      insertionContentId,
      level,
      placement,
      relativeContent,
    },
  } = action

  const districtId = yield select(getDistrictId)
  const user = yield select(getCurrentUser)
  const userId = user?.id

  const wantDistrict = yield select(abilityCheck(ABILITY_DISTRICT_ADMINISTRATION))

  const immediateParent = yield select(getContentById({ content: relativeContent.parent }))
  const immediateGrandparent = yield select(getParentFromContentId(immediateParent))

  const { prev: previousSibling } = yield call(findSiblings, level === 'parent'
    ? {
      contentId: immediateParent?.id,
      items: immediateGrandparent?.children,
    }
    : {
      contentId: relativeContent.id,
      items: immediateParent?.children,
    })

  const afterContentId = placement === 'above'
    ? previousSibling?.id || 0
    : level === 'parent'
      ? immediateParent.id
      : relativeContent.id

  const body = {
    parentContentId: level === 'parent'
      ? immediateGrandparent?.id
      : immediateParent?.id,
    afterContentId,
    insertionContentId,
    ...wantDistrict
      ? { districtId }
      : { userId }, // otherwise we're simply staff, pass the user id
  }

  yield call(
    dangerouslyCallApi,
    {
      action,
      options: {
        method: 'POST',
        body,
      },
      passThrough: pick('parentContentId')(body),
      url: restEndpoint.contentInsertions,
    },
  )
}

function* handleDelete(action) {
  const { payload: { insertionData: { id } } } = action

  yield call(
    dangerouslyCallApi,
    {
      action,
      options: {
        method: 'DELETE',
        body: {
          id,
        },
      },
      url: endpoint(id),
    },
  )
}

export function* handleAlteration(action) {
  if (action.payload.insertionData) {
    yield call(handleDelete, action)
  } else {
    yield call(handleCreate, action)
  }
}

function* handleSuccess({ response: { parentContentId }, passThrough }) {
  const destUrl = get('action.passThrough.destUrl')(passThrough)

  if (parentContentId) {
    const contentType = yield select(getContentType({ id: parentContentId }))

    yield put(actions.fetchContentById({
      contentId: parentContentId,
      contentType,
    }))
  } else {
    yield put(push(destUrl))
  }
}

/* istanbul ignore next line */
function* contentInsertionsSaga() {
  yield takeLatest(actionTypes.CONTENT_INSERTION_ALTERATION, handleAlteration)
  yield takeEvery(success(actionTypes.CONTENT_INSERTION_ALTERATION), handleSuccess)
}

export default contentInsertionsSaga
