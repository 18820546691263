import { withOptions } from '@comfy/redux-selectors'
import { compose } from 'redux'
import { get } from 'fp/objects'
import { dedupe, filter, map } from 'fp/arrays'
import { getDescendants } from 'projections/content'
import { getAllContentAsObject, getContentById } from './content'
import { createSelector, stateStandards } from '.'

const emptyStandards = { applicationStandardIds: [], instructionStandardIds: [] }

export const getStandardById = withOptions(({ id }) => createSelector('getStandardById')(
  stateStandards,
  get(id),
))

export const getStandardsForContent = withOptions(({ contentType, contentId }) => createSelector('getStandardsForContent')(
  getContentById({ contentType, contentId }),
  stateStandards,
  (content, standards) => {
    const { applicationStandardIds, instructionStandardIds } = content || emptyStandards
    return ({
      applicationStandards: applicationStandardIds.map(id => standards[id]),
      instructionStandards: instructionStandardIds.map(id => standards[id]),
    })
  },
))

export const getAggregatedStandardsFromContentChildren = withOptions(({
  contentType,
  contentId,
}) => createSelector('getAggregatedStandardsFromContentChildren')(
  getContentById({ contentType, contentId }),
  getAllContentAsObject,
  stateStandards,
  (content, allContent, standards) => {
    const { applicationStandardIds, instructionStandardIds } = content
      ? getDescendants([content], allContent, { followLinks: false })
        .reduce((acc, next) => {
          const {
            applicationStandardIds: nextApplicationStandardIds = [],
            instructionStandardIds: nextInstructionStandardIds = [],
          } = next
          return ({
            applicationStandardIds: dedupe(acc.applicationStandardIds.concat(nextApplicationStandardIds)),
            instructionStandardIds: dedupe(acc.instructionStandardIds.concat(nextInstructionStandardIds)),
          })
        }, emptyStandards)

      : emptyStandards

    const getStandards = compose(filter(Boolean), map(id => standards[id]))

    return ({
      applicationStandards: getStandards(applicationStandardIds),
      instructionStandards: getStandards(instructionStandardIds),
    })
  },
))
