import { forwardRef } from 'react'
import DebouncedTextField from 'common/formControls/textInputs/DebouncedTextField'

const AnnotationTextField = forwardRef((props, ref) => (
  <DebouncedTextField
    ref={ref}
    sx={{
      marginTop: 0,
      padding: 0,
      '& .MuiInputBase-root': {
        padding: 2,
      },
      '& textarea': {
        height: 'unset',
        minHeight: 'unset',
      },
    }}
    {...props}
  />
))

export default AnnotationTextField
