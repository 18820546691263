const overrides = ({ mixins: { px, size, transition }, palette, typography }) => ({
  MuiDrawer: {
    styleOverrides: { },

    variants: [
      {
        props: { 'data-sidebar': 'yes' },
        style: {
          ...transition('width'),
          flexShrink: 0,
          position: 'relative',
          '> div:first-of-type': {
            backgroundColor: palette.drawer,
            ...transition('width'),
            overflow: 'hidden',
          },

          '.MuiListItem-root': {
            marginTop: 48,
          },

          '.MuiListItem-root, .MuiListItem-root a': {
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'column',
          },

          '.MuiListItemIcon-root': {
            position: 'relative',
            color: palette.grey[3],
            minWidth: 0,
            marginBottom: 10,
          },

          '.active': {
            color: palette.grey.contrastText,
            ...typography.variants['nav-item-semibold'],
            '.MuiListItemIcon-root': {
              color: palette.grey.contrastText,
            },
            '.MuiListItemIcon-root::before': {
              content: "''",
              background: palette.grey[0],
              borderRadius: '50%',
              position: 'absolute',
              ...size(40),
              zIndex: -1,
              transform: 'translate(-8px,-8px)',
            },
          },

          'a:hover, a:hover svg': {
            color: palette.grey.contrastText,
          },

          '.icon-container': {
            position: 'relative',
            ...size(24),
            svg: {
              ...transition('opacity'),
              left: 0,
              position: 'absolute',
              top: 0,
            },
          },

          '.icon-filled': { opacity: 0 },

          'a:hover .icon-unfilled': { opacity: 0 },
          'a:hover .icon-filled': { opacity: 1 },
        },
      },

      {
        props: { 'data-sidebarstate': 'full' },
        style: {
          '> div:first-of-type': {
            padding: px(32, 12, 32, 32),
          },
        },
      },

      {
        props: { 'data-sidebarstate': 'shrunk' },
        style: {
          '> div:first-of-type': {
            padding: px(32, 0),
          },
        },
      },

      {
        props: { 'data-toc': 'yes' },
        style: {
          '> div:first-of-type': {
            padding: 0,
          },
        },
      },
    ],
  },
})

export default overrides
