import actionTypes from 'reducers/actionTypes'
import { failure, success } from 'sagas/utils'

export const resolve = ({ type }) => type === success(actionTypes.UPLOAD_MARK_COMPLETE)

export const reject = ({ type }) => [
  failure(actionTypes.UPLOAD_INITIATE),
  failure(actionTypes.UPLOAD_MARK_COMPLETE),
  failure(actionTypes.UPLOAD_REQUEST),
].includes(type)

export const acceptMap = {
  image: 'image/*',
  video: 'video/*',
  audio: 'audio/*',
  vtt: 'text/plain,text/vtt,.txt,.vtt',
}

export const openFileDialog = (inputRef) => {
  inputRef?.current?.click()
}
