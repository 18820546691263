import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import CloseSmall from 'hss/images/controls/close-small.svg'

const MultiValueRemove = ({ data, innerProps }) => {
  const ariaLabel = `Remove ${data.label}`

  return (
    <IconButton
      {...innerProps}
      aria-label={ariaLabel}
    >
      <CloseSmall />
    </IconButton>
  )
}

MultiValueRemove.propTypes = {
  data: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
}

export default MultiValueRemove
