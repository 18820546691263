import actionTypes from 'reducers/actionTypes'
import { reject, resolve } from 'common/formControls/selects/AssetUploader/FileUploader/utils'
import { whenPresent } from 'fp/utils'
import useReduxCallback from './useReduxCallback'

const useFileUpload = ({ onUploadSuccess }) => {
  const onSuccess = ({ response }) => {
    whenPresent(onUploadSuccess, response)
  }

  const [startUpload, status] = useReduxCallback({
    actionType: actionTypes.UPLOAD_REQUEST,
    onSuccess,
    reject,
    resolve,
  })

  return { startUpload, status }
}

export default useFileUpload
