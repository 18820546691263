import { withOptions } from '@comfy/redux-selectors'
import { compose } from 'redux'
import { get, hasProperty } from 'fp/objects'
import { createSelector, stateDistricts } from '.'

export const getDistrictById = withOptions(({ districtId }) => createSelector('getDistrictById')(
  stateDistricts,
  get(String(districtId)),
))

export const isDistrictLoaded = withOptions(({ districtId }) => createSelector('isDistrictLoaded')(
  stateDistricts,
  compose(
    hasProperty(String(districtId)),
    get('loaded'),
  ),
))
