import { success } from 'sagas/utils'
import actionTypes from './actionTypes'
import {
  createReducer,
  handleFetchListSuccess,
  listReducerInitialState,
  updateLoaded,
} from './utils'

export const actions = {
  fetchDistricts: ({ queryParams }) => ({
    type: actionTypes.DISTRICT_FETCH_LIST,
    queryParams,
  }),
  fetchDistrictById: ({ districtId }) => ({
    districtId,
    type: actionTypes.DISTRICT_FETCH,
  }),
  saveDistrict: ({ payload }) => ({
    type: actionTypes.DISTRICT_SAVE,
    payload,
  }),
}

const handleFetchItemSuccess = (state, { response }) => updateLoaded(state, response)

const districts = createReducer(
  listReducerInitialState(),
  {
    [success(actionTypes.DISTRICT_FETCH_LIST)]: handleFetchListSuccess,
    [success(actionTypes.DISTRICT_FETCH)]: handleFetchItemSuccess,
    [success(actionTypes.DISTRICT_SAVE)]: handleFetchItemSuccess,
  },
)

export default districts
