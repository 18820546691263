import { compose } from 'redux'
import PropTypes from 'prop-types'
import * as consts from 'core/consts'
import { assertProp, deepMerge, omit, pick, set } from 'fp/objects'
import { generateId, noop, pipe } from 'fp/utils'
import { toggleItem } from 'fp/arrays'
import Interactive from 'hss/sections/contentBlocks/Interactive'
import InteractiveRenderer from 'hss/sections/contentBlocks/Interactive/InteractiveRenderer'

/**
 * This unit is here largely to deal with providing an interaction "island" for
 * Jest and Storybook, so that interactives can be spun up in isolation.
 *
 * It also is used for Previewing interactives during creation, so don't be tempted
 * to move it outside of /src
 *
 * See: src/apps/hss/ContentBuilder/interactives/Form/Preview/index.js
 */

const topLevelFields = [
  'academicVocabPhraseIds',
  'academicVocabPhrases',
  'applicationStandardIds',
  'applicationStandards',
  'contentState',
  'contentSubType',
  'contentType',
  'contentVocabPhraseIds',
  'contentVocabPhrases',
  'data',
  'id',
  'instructionStandardIds',
  'instructionStandards',
  'name',
  'rubric',
  'scoring',
  'tagIds',
  'tags',
  'uploadsMap',
  'vocabContent',
  'vocabContentIds',
]

const ephemeralFields = [
  'children',
  'onInteract',
  'previewing',
]

const configAssertor = pipe(
  assertProp('academicVocabPhraseIds', []),
  assertProp('academicVocabPhrases', []),
  assertProp('applicationStandardIds', []),
  assertProp('applicationStandards', []),
  assertProp('contentState', consts.CONTENT_STATE_DRAFT),
  assertProp('contentType', consts.CONTENT_TYPE_INTERACTIVE),
  assertProp('contentVocabPhraseIds', []),
  assertProp('contentVocabPhrases', []),
  assertProp('data', {}),
  assertProp('id', generateId()),
  assertProp('instructionStandardIds', []),
  assertProp('instructionStandards', []),
  assertProp('tagIds', []),
  assertProp('tags', []),
  assertProp('uploadsMap', {}),
  assertProp('vocabContent', []),
  assertProp('vocabContentIds', []),
)

const configFromProps = (Component, options) => {
  const {
    childrenAreContentChildren,
    contentSubType,
  } = options

  const ef = childrenAreContentChildren
    ? toggleItem(ephemeralFields)('children')
    : ephemeralFields

  const tlf = childrenAreContentChildren
    ? toggleItem(topLevelFields)('children')
    : topLevelFields

  return compose(
    assertProp('contentSubType', contentSubType),
    props => set('id', String(props.id))(props),
    configAssertor,
    props => ({
      scoring: {},
      ...pick(tlf)(props),
      data: {
        ...props.data,
        ...omit(...ef, ...tlf)(props),
      },
    }),
  )
}

/**
 * About `childrenAreContentChildren`
 *
 * Basically any interactive that has react children should pass `true` for
 * `options.childrenAreContentChildren = true` so that the react children are not
 *  mixed up with `content.children`.
 *
 * Currently this only applies to Rubric and Timeline interactives, but may extend
 * to Echo too.
 */

const withDetachedInteraction = (Component, options = {}) => {
  const Enhanced = ({ isStoryBook = false, ...props }) => {
    const interactive = configFromProps(Component, options)(props)

    const { children, initialTestingState, markComplete, onInteract, previewing = false } = props

    /* istanbul ignore next */
    if (isStoryBook) {
      // mock the assets props having gone through the uploads api
      interactive.uploadsMap = {
        video: {
          createdById: '1',
          dateModified: '2023-03-30T21:01:50.490Z',
          id: '60',
          type: 'video',
          url: interactive.data.fallbackUrl,
        },
        image: {
          createdById: '1',
          dateModified: '2023-03-30T21:11:47.610Z',
          generated: {
            thumb: {
              createdById: null,
              dateModified: '2023-03-30T21:11:49.970Z',
              id: '63',
              type: 'image',
              url: interactive.data.posterUrl,
            },
          },
          id: '61',
          type: 'image',
          url: interactive.data.posterUrl,
        },
        cc: interactive.data.ccUrl,
        audioDescription: interactive.data.description,
      }
    }

    const providerProps = {
      children,
      Renderer: InteractiveRenderer,
      initialTestingState: {
        contentId: interactive.id,
        interactive: deepMerge(interactive, initialTestingState?.interactive),
        interactiveData: interactive.data,
        markComplete: markComplete || noop,
        onInteract: onInteract || noop,
        previewing,
        uploadsMap: interactive.uploadsMap,
        ...omit('interactive')(initialTestingState),
      },
    }

    return <Interactive {...providerProps} />
  }

  Enhanced.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.elementType, PropTypes.node]),
    initialTestingState: PropTypes.object,
    isStoryBook: PropTypes.bool,
    markComplete: PropTypes.func,
    onInteract: PropTypes.func,
    previewing: PropTypes.bool,
  }

  return Enhanced
}

export default withDetachedInteraction
