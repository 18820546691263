import { forwardRef } from 'react'
import Collapse from '@mui/material/Collapse'
import VideoPlayer from './lib/VideoPlayer'
import Transcription from './lib/Transcription'
import { videoPlayerContext } from './context'
import VideoAnnotations from './lib/VideoAnnotations'

export const Player = forwardRef((props, ref) => (
  <videoPlayerContext.Consumer>
    {({ video }) => (
      <VideoPlayer
        video={video}
        {...props}
        ref={ref}
      />
    )}
  </videoPlayerContext.Consumer>
))

export const Annotations = props => (
  <videoPlayerContext.Consumer>
    {({ annotationsOn }) => (
      <Collapse
        collapsedSize={0}
        in={annotationsOn}
        orientation="horizontal"
        sx={{ height: annotationsOn ? 'unset' : 0 }}
      >
        <VideoAnnotations {...props} />
      </Collapse>
    )}
  </videoPlayerContext.Consumer>
)

export const Transcript = props => (
  <videoPlayerContext.Consumer>
    {({ video, vtt, transcriptOpen }) => (
      transcriptOpen && vtt.valid)
      ? (
        <Transcription
          description={video.description}
          vtt={vtt}
          {...props}
        />
      )
      : null}
  </videoPlayerContext.Consumer>
)
