import { forwardRef, useEffect, useId, useState } from 'react'
import PropTypes from 'prop-types'
import { TreeView } from '@mui/x-tree-view'
import { useSelector } from 'react-redux'
import useContent from 'hooks/useContent'
import { CONTENT_TYPE_SUBSECTION } from 'core/consts'
import { first, push } from 'fp/arrays'
import { getContentViewerParams } from 'selectors/contentViewerParams'
import { getContentNav } from 'selectors/contentViewer'
import { set } from 'fp/objects'
import Item from './Item'
import Sections from './Sections'

// TODO: Is this really so different from ChapterTree? Could we merge them and make them more content type agnostic?
const TreeWithRoot = forwardRef(({ actionsHeight = 0, contentId, maxHeight }, ref) => {
  // Using getContentNav instead of useContent, because getContentNav calculates completion status.
  const content = first(useSelector(getContentNav({ contentId, includeRoot: true })))
  const pages = content.children

  const { navTreeSubsectionId, subsectionId } = useSelector(getContentViewerParams())

  const currentSubsection = useContent({
    contentType: CONTENT_TYPE_SUBSECTION,
    contentId: subsectionId,
    disableFetch: true,
  })

  const currentSection = content

  const [expanded, setExpanded] = useState([])
  const [selected, setSelected] = useState([])
  const id = useId()

  const isMultiPage = content?.children?.[0]?.contentType === CONTENT_TYPE_SUBSECTION

  useEffect(
    () => {
      setExpanded(push(currentSection?.id))
      setSelected([navTreeSubsectionId || currentSubsection?.id])
    },
    [currentSection?.id, currentSubsection?.id, navTreeSubsectionId],
  )

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds)
  }

  return (isMultiPage ? pages : content)
    ? (
      <TreeView
        aria-label="navigation tree"
        data-subvariant="content-viewer"
        id={id}
        {...isMultiPage
          ? {
            expanded,
            onNodeToggle: handleToggle,
            selected,
          }
          : null}
        ref={ref}
        style={{
          /**
           * This component is restricted in height in order to cause a scrollbar
           * to appear when needed.
           *
           * A bit of padding is added to the bottom so things feel less cramped.
           * Padding equal to the height of the `actions` area is also added so
           * that the scrollbar appears "behind" the `actions` area.
           *
           * SEE ALSO:
           *
           *    ChapterTree - This is pretty much the same as this component.
           *                  Make sure to apply any required edits to both files
           *
           *    Nav/Actions - This is where the `actions` are defined
           *
           *    SectionTree - This is where the `actions` height is defined
           */
          height: maxHeight,
          maxHeight,
          overflowY: 'auto',
          paddingBottom: 40 + actionsHeight,
        }}
        variant="nav-shell"
      >

        {isMultiPage
          ? <Sections sections={[set('children', pages)(content)]} />
          : (
            <Item
              idx={1}
              item={content}
              key={content.id}
              nodeId={content.id}
              parentContent={content}
            />
          )}

      </TreeView>
    )
    : null
})

TreeWithRoot.propTypes = {
  actionsHeight: PropTypes.number,
  contentId: PropTypes.string.isRequired,
  maxHeight: PropTypes.string.isRequired,
}

export default TreeWithRoot
