import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { styled } from '@mui/material/styles'
import { position } from 'polished'
import DataTable from 'common/tables/DataTable'
import CardRenderer from './CardRenderer'

const columns = [
  {
    accessorKey: 'id',
    header: 'id',
    id: 'id',
  },
  {
    accessorKey: 'url',
    header: 'url',
    id: 'url',
  },
  {
    accessorKey: 'tags',
    header: 'tags',
    id: 'tags',
  },
]

const StyledTable = styled(
  'div',
  { name: 'imageBackSelector-SearchResults' },
)(({ theme: { mixins: { rem, size, transition }, palette, typography } }) => ({
  overflowY: 'auto',
  paddingRight: 0,
  marginTop: rem(1),
  position: 'relative',
  height: 'auto',
  maxHeight: '50vh',
  '> div:first-of-type': {
    marginTop: 0,
  },
  ul: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  '> ul:first-of-type': {
    overflowY: 'auto',
    marginBottom: 20,
    padding: rem(0, 1.5, 0, 3),
    ...position('absolute', 0, 0, 0, 0),
  },
  img: {
    aspectRatio: '9 / 16',
  },
  '.MuiPaper-root.MuiCard-root': {
    ...transition('outline', 100),
    padding: 0,
    margin: '0 3px 1rem',
    position: 'relative',
    '&:hover, &:focus-within': {
      outline: `3px solid ${palette.common.black}`,
      outlineOffset: -1,
      '&::after': {
        ...position('absolute', null, 0, 30, 0),
        ...size(46, 89),
        ...typography.variants.body1,
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 'auto',
        content: '"Select"',
        backgroundColor: palette.common.black,
        color: palette.common.white,
        cursor: 'pointer',
        pointerEvents: 'none',
      },
    },
  },
  li: {
    position: 'relative',
    maxWidth: 172,
  },
}))

const SearchResults = forwardRef(({ dataset: { data }, onComplete }, ref) => (
  <StyledTable>
    <DataTable
      CardRenderer={CardRenderer}
      cardRendererProps={{ onComplete }}
      columns={columns}
      data={data}
      id="ImageBankSelector-SearchResults"
      preferCardView
      ref={ref}
    />
  </StyledTable>
))

SearchResults.propTypes = {
  dataset: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default SearchResults
