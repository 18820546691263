import { success } from 'sagas/utils'
import actionTypes from './actionTypes'
import { createReducer, handleFetchListSuccess, listReducerInitialState } from './utils'

/**
 * Reactions are listed by interacTION id, not content or interacTIVE ids, though
 * you can look them up easily enough via selectors if need be.
 */

const reactions = createReducer(
  listReducerInitialState(),
  {
    [success(actionTypes.REACTIONS_RECEIVED)]: handleFetchListSuccess,
  },
)

export default reactions
