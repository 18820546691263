import PropTypes from 'prop-types'
import TabPanel from '@mui/lab/TabPanel'
import { compose } from 'redux'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import DialogContent from '@mui/material/DialogContent'
import { alter, assert } from 'core/store/search/squery'
import useDataset from 'hooks/useDataset'
import { restEndpoint } from 'reducers/utils'
import { getContentViewerParams } from 'selectors/contentViewerParams'
import { getAnyAncestorIsTe, getParentOfContentByType } from 'selectors/contentViewer'
import {
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SOURCE,
} from 'core/consts'
import useContent from 'hooks/useContent'
import { getLocation } from 'selectors/routing'
import { pipe } from 'fp/utils'
import useToggleState from 'hooks/useToggleState'
import withProps from 'hoc/withProps'
import { contentTypeShape } from 'core/shapes'
import DialogPagination from '../interactivePlugin/DialogPagination'
import ContentFilter from './ContentFilter'
import ContentTable, { modeShape } from './ContentTable'

const InternalLinkTab = (props) => {
  const {
    contentTypes,
    mode = 'internal-link',
    onComplete,
    setInternalTabLabel,
  } = props

  const tableRef = useRef()
  const [squery, setSquery] = useState(compose(
    alter.set.limit(10),
    alter.set.orderBy('name', 'asc'),
    alter.set.where('contentType').in(contentTypes),
  )(assert()))
  const dataset = useDataset({ restEndpoint: restEndpoint.content, squery })
  const params = useSelector(getContentViewerParams())
  const { search } = useSelector(getLocation)
  const block = useContent(params)
  const hasTeSection = useSelector(getAnyAncestorIsTe({ content: block }))
  const [constrainToNearest, toggleConstrainToNearest] = useToggleState(false)

  const chapter = useSelector(getParentOfContentByType({ ...params, parentContentType: CONTENT_TYPE_CHAPTER }))
  const course = useSelector(getParentOfContentByType({ ...params, parentContentType: CONTENT_TYPE_COURSE }))
  const echo = useSelector(getParentOfContentByType({ ...params, parentContentType: CONTENT_TYPE_ECHO }))
  const source = useSelector(getParentOfContentByType({ ...params, parentContentType: CONTENT_TYPE_SOURCE }))

  // MAINTAIN THIS ORDERING!!
  // These are all the assignable (standalone) content types.  Update if we ever add more.
  // 'Course' receives precedence over the others, so it's listed first.
  const uberParent = course || chapter || source || echo
  const closestParent = echo || source || chapter || course

  const teacherEdition = block.teacherEdition || hasTeSection || String(search).includes('type=te')
    ? null // passing null will give us both TE and SE content
    : false // passing false excludes TE content

  useEffect(() => {
    if (mode === 'internal-link') {
      const constrainToId = constrainToNearest ? closestParent.id : uberParent.id

      setInternalTabLabel?.(`Within this ${ uberParent.contentType}`)
      setSquery(pipe(
        alter.set.modifier('withParents').is(true),
        alter.set.modifier('withinContent').is({
        // you cannot link to content outside of the assigned content or course
          id: constrainToId,
          // you can only link to TE items from within other TE items
          teacherEdition,
        }),
        alter.remove.modifier('notWithinContent'),
      ))
    }
  }, [
    mode,
    teacherEdition,
    uberParent.contentType,
    uberParent.id,
    setInternalTabLabel,
    constrainToNearest,
    closestParent.id,
  ])

  useEffect(() => {
    if (mode === 'content-insertion') {
      setSquery(pipe(
        alter.set
          .modifier('notWithinContent')
          .is(uberParent.id),
        alter.remove.modifier('withinContent'),
      ))
    }
  }, [mode, uberParent.id])

  const Wrapper = setInternalTabLabel
    ? withProps(TabPanel, {
      style: { paddingTop: 0, paddingBottom: 0 },
      value: 'internal',
    })
    : DialogContent

  return (
    <Wrapper>

      <ContentFilter
        {...{
          closestParent,
          constrainToNearest,
          contentTypes,
          mode,
          squery,
          setSquery,
          toggleConstrainToNearest,
        }}
      />

      <ContentTable
        {...{
          dataset,
          mode,
          onComplete,
          ref: tableRef,
        }}
      />

      <DialogPagination
        {...{
          dataset,
          squery,
          setSquery,
          scrollToResultsRef: tableRef,
        }}
      />

    </Wrapper>
  )
}

InternalLinkTab.propTypes = {
  contentTypes: PropTypes.arrayOf(contentTypeShape).isRequired,
  mode: modeShape,
  onComplete: PropTypes.func.isRequired,
  setInternalTabLabel: PropTypes.func,
}

export default InternalLinkTab
