import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'
import { ApplicationStandardsPicker, InstructionStandardsPicker } from 'common/standards/StandardsPickers'
import CollapsibleSection from 'common/layout/CollapsibleSection'

const Standards = ({ disabled = false, namePrefix = '', ...rest }) => {
  const existingStandards = useWatch({ name: [
    `${namePrefix}applicationStandardIds`,
    `${namePrefix}instructionStandardIds`,
  ] })
  const empty = !existingStandards.flat().length

  return (
    <CollapsibleSection
      empty={empty}
      initiallyExpanded={false}
      label="Standards"
      style={{ padding: 0 }}
      {...rest}
    >

      <ApplicationStandardsPicker
        disabled={disabled}
        name={`${namePrefix}applicationStandards`}
        style={{ marginTop: 0 }}
        valueField={`${namePrefix}applicationStandardIds`}
      />

      <InstructionStandardsPicker
        disabled={disabled}
        name={`${namePrefix}instructionStandards`}
        style={{ marginTop: 0 }}
        valueField={`${namePrefix}instructionStandardIds`}
      />

    </CollapsibleSection>
  )
}

Standards.propTypes = {
  disabled: PropTypes.bool,
  namePrefix: PropTypes.string,
}

export default Standards
