import PropTypes from 'prop-types'
import { EditorState } from 'draft-js'
import { removeBlock } from '@studysync/draft-js-modifiers'
import { useFormContext, useWatch } from 'react-hook-form'
import { compose } from 'redux'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import useContent from 'hooks/useContent'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import BusySpinner from 'common/indicators/BusySpinner'
import { labels } from 'hss/ContentBuilder/consts'
import { get, notEquals } from 'fp/objects'
import { curry } from 'fp/utils'
import { contentBuilderUrl } from 'routing/consts'
import CustomBlockWrapper from '../helpers/CustomBlockWrapper'
import { customBlockPropsShape } from '../helpers/utils'
import MediaPreview from './MediaPreview'

const rows = {
  assetCode: 'Asset code',
  standards: 'Standards',
  tags: 'Tags',
  notes: 'Notes',
}

const InteractivePlaceholder = ({ block, blockProps }) => {
  const { getEditorState, onChange, readOnly } = blockProps

  const children = useWatch({ name: 'children' })
  const { setValue } = useFormContext()

  const contentId = block.getData().get('contentId')

  const content = useContent({ contentType: CONTENT_TYPE_INTERACTIVE, contentId })

  const handleRemove = () => {
    const filterById = compose(
      curry(notEquals)(contentId),
      get('id'),
    )

    const editorState = getEditorState()
    const newContentState = removeBlock(editorState.getCurrentContent(), block.getKey())
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'remove-block',
    )

    setValue('children', children.filter(filterById))
    onChange(newEditorState)
  }

  const openEditWindow = () => {
    window.open(
      `${contentBuilderUrl}/${CONTENT_TYPE_INTERACTIVE}/${content.contentSubType}/${contentId}`,
      '_blank',
    )
  }

  return content
    ? (
      <CustomBlockWrapper
        block={block}
        blockProps={blockProps}
        draggable
        onRemove={handleRemove}
        readOnly={readOnly}
        title={labels[content.contentSubType]}
        titleAddendum={(
          <Tooltip title="Edit this interactive in a new tab...">
            <Button
              color="primary"
              onClick={openEditWindow}
              size="small"
              variant="tertiary"
            >
              [edit]
            </Button>
          </Tooltip>
        )}
      >

        <MediaPreview content={content} />

        <table>
          <tbody>
            {Object.entries(rows).map(([key, value]) => (
              <tr key={key}><th>{value}</th><td>{content[key]}</td></tr>
            ))}
          </tbody>
        </table>
      </CustomBlockWrapper>
    )
    : <BusySpinner />
}

InteractivePlaceholder.propTypes = {
  block: PropTypes.object.isRequired,
  blockProps: customBlockPropsShape.isRequired,
}

export default InteractivePlaceholder
