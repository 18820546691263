import { appendScript } from 'fp/internet'

const init = clientId => new Promise((resolve, reject) => {
  window.googleReady = (e) => {
    if (e) {
      reject(e)
    } else {
      // gapi will be attached to global scope by the script
      gapi.load('auth2', () => {
        gapi.auth2
          .init({ client_id: clientId })
          .then(resolve)
          .catch(reject)
      })
    }
  }

  appendScript({
    src: 'https://apis.google.com/js/platform.js?onload=googleReady',
    async: true,
    defer: true,
  })
})

export default init
