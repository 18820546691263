import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'
import RichTextEdit from 'common/formControls/textInputs/HFRichTextEdit'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import CollapsibleFormSection from 'common/layout/CollapsibleFormSection'
import { isEmptyString } from 'fp/strings'
import SeeAlsoHelperLink from 'common/text/SeeAlsoHelperLink'
import { componentShape } from 'core/shapes'

const ImageA11ySection = (props) => {
  const {
    RichTextComponent = RichTextEdit,
    TextComponent = HFTextField,
    altTextName = 'imageAltText',
    altTextProps,
    altTextValue,
    children,
    controlled = true,
    longTextName = 'longTextAlternative',
    longTextProps,
    longTextValue,
  } = props

  const [
    controlledImageAltText,
    controlledLongTextAlternative,
  ] = useWatch({ name: [altTextName, longTextName] })

  const imageAltText = controlled ? controlledImageAltText : altTextValue
  const longTextAlternative = controlled ? controlledLongTextAlternative : longTextValue

  return (
    <CollapsibleFormSection
      empty={Boolean(isEmptyString(imageAltText) && isEmptyString(longTextAlternative))}
      label="Accessibility"
    >
      <TextComponent
        helperText={(
          <SeeAlsoHelperLink href="https://www.w3.org/WAI/WCAG21/Techniques/general/G94#description">
            guidelines for short text alternatives
          </SeeAlsoHelperLink>
        )}
        label="Image Alt Text"
        name={altTextName}
        required
        {...altTextProps}
      />

      <RichTextComponent
        excludeToolButtons={['Format', 'Highlight', 'Indentation', 'Link']}
        features={{ tables: true }}
        helperText={(
          <SeeAlsoHelperLink href="https://www.w3.org/WAI/WCAG21/Techniques/general/G92#description">
            guidelines for long text alternatives
          </SeeAlsoHelperLink>
        )}
        label="Long Text Alternative"
        name={longTextName}
        {...longTextProps}
      />

      {children}
    </CollapsibleFormSection>
  )
}

ImageA11ySection.propTypes = {
  altTextName: PropTypes.string,
  altTextProps: PropTypes.object,
  altTextValue: PropTypes.string,
  children: componentShape,
  controlled: PropTypes.bool,
  longTextName: PropTypes.string,
  longTextProps: PropTypes.object,
  longTextValue: PropTypes.string,
  RichTextComponent: componentShape,
  TextComponent: componentShape,
}

export default ImageA11ySection
