// eslint-disable-next-line @studysync/persnickety/use-pinnable-components
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useSelector } from 'react-redux'
import { Titled } from 'react-titled'
import { Suspense, lazy } from 'react'
import App from 'common/App'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import Login from 'hss/sections/Login'
import Logout from 'hss/sections/Login/Logout'
import Session from 'hss/sections/Login/Session'
import { isSessionChecked } from 'selectors/session'
import SkipToMainContent from 'common/navigation/SkipToMainContent'
import { identity } from 'fp/utils'
import AccentColorProvider from 'styling/theming/AccentColorProvider'
import AppBusy from 'common/indicators/AppBusy'
import Home from 'hss/sections/Home'
import { suffix } from 'fp/strings'
import ContentViewerRoutes from 'hss/ContentViewer/ContentViewerRoutes'
import AssignmentEditorRoutes from 'hss/AssignmentEditor/AssignmentEditorRoutes'
import AssignmentCreatorRoutes from 'hss/AssignmentEditor/AssignmentCreatorRoutes'
import AssignmentRoutes from 'hss/sections/Assignments/AssignmentRoutes'
import { isStudent } from 'selectors/users'
import Assignments from 'hss/sections/Assignments'
import Binder from 'hss/sections/Binder'
import ResetPassword from 'hss/sections/Login/ResetPassword'
import BackendSocketProvider from 'hss/BackendSocketProvider'
import { ABILITY_STUDENT_INTERFACE } from 'core/consts'
import TocShell, {
  AssignmentCreatorTocShell,
  AssignmentEditorTocShell,
  StudentAssignmentTocShell,
} from './shells/TocShell'
import NavShell from './shells/NavShell'
import {
  assessmentUrlFrag,
  assignmentCreatorUrl,
  assignmentEditorUrl,
  classroomUrlFrag,
  contentBuilderUrlFrag,
  contentViewerUrl,
  curriculumUrlFrag,
  libraryUrlFrag,
  settingsUrlFrag,
  studentAssignmentsUrl,
  studentAssignmentsUrlFrag,
  studentBinderUrl,
  studentBinderUrlFrag,
} from './consts'
import Route403 from './Route403'
import Route404 from './Route404'
import AssessmentLegacyNavShell from './shells/NavShell/AssessmentLegacyNavShell'
import CurriculumNavShell from './shells/NavShell/CurriculumNavShell'
import LibraryNavShell from './shells/NavShell/LibraryNavShell'
import DefaultNavShell from './shells/NavShell/DefaultNavShell'
import HomeNavShell from './shells/NavShell/HomeNavShell'
import ClassroomNavShell from './shells/NavShell/ClassroomNavShell'
import AssessmentNavShell from './shells/NavShell/AssessmentNavShell'
import { configToRoutes } from './TraverseRoute'

const AssessmentLegacyRoutes = lazy(() => import(/* webpackChunkName: "AssessmentLegacyRoutes" */ 'routing/collections/AssessmentLegacyRoutes'))
const AssessmentRoutes = lazy(() => import(/* webpackChunkName: "AssessmentRoutes" */ 'routing/collections/AssessmentRoutes'))
const ClassroomRoutes = lazy(() => import(/* webpackChunkName: "ClassroomRoutes" */ 'routing/collections/ClassroomRoutes'))
const ContentBuilderRoutes = lazy(() => import(/* webpackChunkName: "ContentBuilderRoutes" */ 'hss/ContentBuilder/ContentBuilderRoutes'))
const CurriculumRoutes = lazy(() => import(/* webpackChunkName: "CurriculumRoutes" */ 'routing/collections/CurriculumRoutes'))
const LibraryRoutes = lazy(() => import(/* webpackChunkName: "LibraryRoutes" */ 'routing/collections/LibraryRoutes'))
const SettingsRoutes = lazy(() => import(/* webpackChunkName: "SettingsRoutes" */ 'routing/collections/SettingsRoutes'))

const PageLayout = () => (
  <Session>

    <SkipToMainContent />

    <BackendSocketProvider>

      <Outlet />

    </BackendSocketProvider>

  </Session>
)

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const TraverseRoutes = () => {
  const sessionChecked = useSelector(isSessionChecked)
  const currentUserIsStudent = useSelector(isStudent)

  return (
    <App>
      <ErrorBoundary moduleName="Routes">

        <SentryRoutes>

          <Route
            element={<Route403 />}
            path="/403"
          />

          <Route
            element={<Logout />}
            path="/logout"
          />

          <Route
            element={<ResetPassword />}
            path="/reset-password"
          />

          {Boolean(sessionChecked) && (
            <>

              <Route
                element={<Login />}
                path="/login"
              />

              <Route
                element={<PageLayout />}
                path="/"
              >

                {/* *********** START SHELLS *********** */}

                <Route
                  element={<TocShell />}
                  path={`${contentViewerUrl}/*`}
                >
                  <Route
                    element={<ContentViewerRoutes />}
                    path="*"
                  />
                </Route>

                <Route
                  element={<AssignmentCreatorTocShell />}
                  path={`${assignmentCreatorUrl}/*`}
                >
                  <Route
                    element={<AssignmentCreatorRoutes />}
                    path="*"
                  />
                </Route>

                <Route
                  element={<AssignmentEditorTocShell />}
                  path={`${assignmentEditorUrl}/:assignmentId/*`}
                >
                  <Route
                    element={<AssignmentEditorRoutes />}
                    path="*"
                  />
                </Route>

                <Route
                  element={<StudentAssignmentTocShell />}
                  path={`${studentAssignmentsUrl}/:userAssignmentId/*`}
                >
                  <Route
                    element={<AssignmentRoutes />}
                    path="*"
                  />
                </Route>

                <Route
                  element={<StudentAssignmentTocShell />}
                  path={`${studentBinderUrl}/:userAssignmentId/*`}
                >
                  <Route
                    element={<AssignmentRoutes />}
                    path="*"
                  />
                </Route>

                {/* ------------------------------------ */}

                <Route
                  element={<NavShell />}
                  path="/*"
                >

                  <Route
                    element={<AssessmentNavShell />}
                    path={`${assessmentUrlFrag}/assessment/*`}
                  >
                    <Route
                      element={(
                        <Titled title={suffix(' - Assessment')}>
                          <AccentColorProvider accentColor="cobalt">
                            <Suspense fallback={<AppBusy />}>
                              <AssessmentRoutes />
                            </Suspense>
                          </AccentColorProvider>
                        </Titled>
                      )}
                      path="*"
                    />

                  </Route>

                  <Route
                    element={<AssessmentLegacyNavShell />}
                    path={`${assessmentUrlFrag}/*`}
                  >
                    <Route
                      element={(
                        <Titled title={suffix(' - Assessment')}>
                          <AccentColorProvider accentColor="cobalt">
                            <Suspense fallback={<AppBusy />}>
                              <AssessmentLegacyRoutes />
                            </Suspense>
                          </AccentColorProvider>
                        </Titled>
                      )}
                      path="*"
                    />

                  </Route>

                  <Route
                    element={<CurriculumNavShell />}
                    path={`${curriculumUrlFrag}/*`}
                  >
                    <Route
                      element={(
                        <Titled title={suffix(' - Curriculum')}>
                          <AccentColorProvider accentColor="tangerine">
                            <Suspense fallback={<AppBusy />}>
                              <CurriculumRoutes />
                            </Suspense>
                          </AccentColorProvider>
                        </Titled>
                      )}
                      path="*"
                    />

                  </Route>

                  <Route
                    element={<LibraryNavShell />}
                    path={`${libraryUrlFrag}/*`}
                  >
                    <Route
                      element={(
                        <Titled title={suffix(' - Library')}>
                          <AccentColorProvider accentColor="cobalt">
                            <Suspense fallback={<AppBusy />}>
                              <LibraryRoutes />
                            </Suspense>
                          </AccentColorProvider>
                        </Titled>
                      )}
                      path="*"
                    />

                  </Route>

                  <Route
                    element={<ClassroomNavShell />}
                    path={`${classroomUrlFrag}/*`}
                  >
                    <Route
                      element={(
                        <Titled title={suffix(' - Classroom')}>
                          <AccentColorProvider accentColor="cobalt">
                            <Suspense fallback={<AppBusy />}>
                              <ClassroomRoutes />
                            </Suspense>
                          </AccentColorProvider>
                        </Titled>
                      )}
                      path="*"
                    />

                  </Route>

                  <Route
                    element={<DefaultNavShell />}
                    path="*"
                  >

                    <Route
                      element={(
                        <Titled title={suffix(' - Library')}>
                          <AccentColorProvider accentColor="cobalt">
                            <Suspense fallback={<AppBusy />}>
                              <ContentBuilderRoutes />
                            </Suspense>
                          </AccentColorProvider>
                        </Titled>
                      )}
                      path={`${contentBuilderUrlFrag}/*`}
                    />

                    <Route
                      element={(
                        <Titled title={suffix(' - Settings')}>
                          <AccentColorProvider accentColor="teal">
                            <Suspense fallback={<AppBusy />}>
                              <SettingsRoutes />
                            </Suspense>
                          </AccentColorProvider>
                        </Titled>
                      )}
                      path={`${settingsUrlFrag}/*`}
                    />

                    {/**
                     * These routes contain components that link to content viewer routes,
                     * which means when you click the content viewer's exit/back button you'll come back here.
                     *
                     * While the user is in the content viewer, they might pin some content,
                     * which adds an `ssc` param to the URL.
                     * The ssc param will stay in the URL unless we use configToRoutes.
                     * We use configToRoutes here because it creates instances of TraverseRoute.
                     *
                     * TraverseRoute takes care of removing the ssc param from the query string.
                     */}
                    {configToRoutes({
                      assignments: {
                        element: (
                          <Titled title={identity}>
                            <AccentColorProvider accentColor="cobalt">
                              <Suspense fallback={<AppBusy />}>
                                <Assignments />
                              </Suspense>
                            </AccentColorProvider>
                          </Titled>
                        ),
                        path: `${studentAssignmentsUrlFrag}/*`,
                      },
                      binder: {
                        element: (
                          <Titled title={identity}>
                            <AccentColorProvider accentColor="cobalt">
                              <Suspense fallback={<AppBusy />}>
                                <Binder />
                              </Suspense>
                            </AccentColorProvider>
                          </Titled>
                        ),
                        path: `${studentBinderUrlFrag}/*`,
                      },
                    }, { abilityFlags: [ABILITY_STUDENT_INTERFACE] })}

                    <Route
                      element={<Route404 collectionName="Home" />}
                      path="*"
                    />
                  </Route>

                  <Route
                    element={<HomeNavShell />}
                    path="*"
                  >
                    <Route
                      element={(
                        <Titled title={identity}>
                          <AccentColorProvider accentColor="cobalt">
                            <Suspense fallback={<AppBusy />}>
                              {currentUserIsStudent
                                ? (
                                  <Navigate
                                    replace
                                    to={studentAssignmentsUrl}
                                  />
                                )
                                : <Home />}

                            </Suspense>
                          </AccentColorProvider>
                        </Titled>
                      )}
                      index
                    />
                  </Route>
                </Route>

                {/* *********** END SHELLS *********** */}

              </Route>

            </>
          )}

        </SentryRoutes>

      </ErrorBoundary>
    </App>
  )
}

export default TraverseRoutes
