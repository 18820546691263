
/* istanbul ignore file */
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useScrollToTopOnRouteChange = (ref, options) => {
  const location = useLocation()

  useEffect(() => {
    (ref?.current || window).scrollTo(options || {
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [location, options, ref])
}

export default useScrollToTopOnRouteChange
