import { useContext } from 'react'
import SwitchFormControl from 'common/formControls/switches/SwitchFormControl'
import useCurrentUser from 'hooks/useCurrentUser'
import {
  FEATURE_FLAG_GOOGLE_INTEGRATIONS_CLASSROOM,
  FEATURE_FLAG_GOOGLE_INTEGRATIONS_DOCS,
} from 'core/consts'
import useAbilityChecker from 'hooks/useAbilityChecker'
import { context } from './context'
import EnableIntegrationsButton from './EnableIntegrationsButton'
import Ready from './Ready'
import CheckIntegrationsButton from './CheckIntegrationsButton'

const Settings = () => {
  const {
    setWantClassroomOn,
    setWantDriveOn,
    status,
    wantClassroomOn,
    wantDriveOn,
  } = useContext(context)

  const has = useAbilityChecker()
  const { isStudent } = useCurrentUser()

  return status === 'CHECK_SCOPES' || status === 'READY' || status === 'ADD_SCOPES' ? (
    <>
      {!isStudent && (
        <>
          {!!has(FEATURE_FLAG_GOOGLE_INTEGRATIONS_DOCS) && (
            <SwitchFormControl
              checked={wantDriveOn}
              helperText="When students complete assignments using Google Doc, add to your Google Drive"
              label="Google Drive"
              onChange={() => {
                setWantDriveOn(!wantDriveOn)
              }}
            />
          )}

          {!!has(FEATURE_FLAG_GOOGLE_INTEGRATIONS_CLASSROOM) && (
            <SwitchFormControl
              checked={wantClassroomOn}
              helperText="Send assignments and grades created within StudySync to Google Classroom"
              label="Google Classroom"
              onChange={() => {
                setWantClassroomOn(!wantClassroomOn)
              }}
            />
          )}
        </>
      )}

      <CheckIntegrationsButton />
      <EnableIntegrationsButton />
      <Ready />
    </>
  ) : null
}

export default Settings
