import { Outlet } from 'react-router-dom'
import { useMemo } from 'react'
import { compose } from 'redux'
import { useSelector } from 'react-redux'
import SearchItemRenderer from 'hss/views/Library/SearchItemRenderer'
import Banner from 'routing/shells/NavShell/Banner'
import { SearchProvider, context } from 'hss/views/Search/SearchProvider'
import ContainerQuery from 'common/layout/ContainerQuery'
import SearchBar from 'hss/views/Search/Filters/SearchBar'
import { SEARCH_TYPE_LIBRARY } from 'hss/views/Search/consts'
import {
  ABILITY_CONTENT_CREATION,
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_VIDEO,
} from 'core/consts'
import withContextProps from 'hoc/withContextProps'
import Card from 'hss/views/Card'
import withProps from 'hoc/withProps'
import PosterImage from 'hss/views/Card/PosterImage'
import { alter, assert } from 'core/store/search/squery'
import { restEndpoint } from 'reducers/utils'
import { getSearchableLibraryContentTypes } from 'selectors/library'
import useAbilityCheck from 'hooks/useAbilityCheck'

const contentSubTypes = [
  INTERACTIVE_TYPE_AUDIO,
  INTERACTIVE_TYPE_IMAGE,
  INTERACTIVE_TYPE_VIDEO,
]

const LibraryNavShell = () => {
  const isContentCreator = useAbilityCheck(ABILITY_CONTENT_CREATION)

  const contentTypes = useSelector(getSearchableLibraryContentTypes)

  const searchConfig = useMemo(() => ({
    initialSquery: compose(
      alter.set.limit(10),
      alter.set.orderBy('name', 'asc'),
      alter.set.where('contentType').in(contentTypes),
      alter.set.modifier('withParents').is(true),
      alter.set.modifier('forLibrary').is(true),
    )(assert()),
    restEndpoint: restEndpoint.content,
  }), [contentTypes])

  const CardRenderer = withContextProps(
    Card,
    context,
    ['viewMode'],
  )

  const ItemRenderer = withProps(
    CardRenderer,
    {
      ContentRenderer: SearchItemRenderer,
      gridModeDirection: 'ltr',
      gridModeImageAspectRatio: '16/9',
      ImageRenderer: withContextProps(
        withProps(PosterImage, { includeLinkToContent: true }),
        context,
        ['viewMode'],
      ),
      listModeDirection: 'ltr',
      listModeImageAspectRatio: '1/1',
    },
  )

  return (
    <SearchProvider
      contentSubTypes={isContentCreator ? contentSubTypes : []}
      contentTypes={contentTypes}
      ItemRenderer={ItemRenderer}
      searchConfig={searchConfig}
      searchType={SEARCH_TYPE_LIBRARY}
      tableId="library-search"
      viewMode="list"
    >

      <Banner SearchComponent={<SearchBar />} />

      <ContainerQuery>
        <Outlet />
      </ContainerQuery>

    </SearchProvider>
  )
}

export default LibraryNavShell
