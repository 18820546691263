import { call, takeLeading } from 'redux-saga/effects'
import actionTypes from 'reducers/actionTypes'
import { buildUrl } from 'fp/internet'
import { restEndpoint } from 'reducers/utils'
import { dangerouslyCallApi } from './api'

export function* handleFetch(action) {
  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(restEndpoint.credits),
  })
}

/* istanbul ignore next line */
function* creditsSaga() {
  yield takeLeading(actionTypes.CREDITS_FETCH, handleFetch)
}

export default creditsSaga
