import { useContext, useMemo } from 'react'
import Tooltip from '@mui/material/Tooltip'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import { Volume2, VolumeX } from 'react-feather'
import { audioContext } from './AudioContextProvider'

const MuteButton = () => {
  const { loaded, muted, setMuted } = useContext(audioContext)

  const label = muted ? 'Unmute' : 'Mute'

  return useMemo(() => (
    <Tooltip title={loaded ? label : ''}>
      <IconButton
        aria-label={label}
        aria-pressed={muted}
        color="secondary"
        disabled={!loaded}
        onClick={() => setMuted(!muted)}
        size="small"
        variant="tertiary"
      >
        <Icon>
          {muted
            ? <VolumeX />
            : <Volume2 />}
        </Icon>
      </IconButton>
    </Tooltip>
  ), [label, loaded, muted, setMuted])
}

export default MuteButton
