import { Fragment } from 'react'
import { Section } from 'react-headings'
import { componentShape } from 'core/shapes'

/** ****************************************************************************
 *                                                                             *
 *                                N O T I C E                                  *
 *                                                                             *
 * There should rarely be a need for this component!                           *
 *                                                                             *
 * Most situations can be solved by using it's sister component:               *
 * <HeadlineStyleOffset />                                                     *
 *                                                                             *
 * If you are properly using <Headline /> everywhere then it is automatically  *
 * incrementing headline levels based simply on how they are nested.           *
 *                                                                             *
 * For example, the following...                                               *
 *                                                                             *
 * <Headline title="first">                                                    *
 *   content                                                                   *
 *   <Headline title="second" />                                               *
 * </Headline>                                                                 *
 *                                                                             *
 * ... would yield something like:                                             *
 *                                                                             *
 * <h1>first</h1>                                                              *
 * content                                                                     *
 * <h2>second</h2>                                                             *
 *                                                                             *
 * If you wanted the h2 to appear more like an h3, then you could utilize      *
 * HeadlineStyleOffset to achieve that.  Note that the actual DOM output would *
 * still be identical to the first example, only the styles are affected. This *
 * is important for a11y concerns -- we should never actually skip a level.    *
 *                                                                             *
 * <Headline title="first">                                                    *
 *   content                                                                   *
 *   <HeadlineStyleOffset offset={1}>                                          *
 *     // this still renders as an h2, but the style is offset by one,         *
 *     // making it appear as an h3                                            *
 *     <Headline title="second" />                                             *
 *   </HeadlineStyleOffset>                                                    *
 * </Headline>                                                                 *
 *                                                                             *
 * Now say for instance that due to the composition of components on your      *
 * page, that it is not possible to nest one <Headline />  within another:     *
 *                                                                             *
 * <Headline title="first">                                                    *
 *   content                                                                   *
 * </Headline>                                                                 *
 * // something here is preventing you from nesting 'second' within 'first     *
 * <Headline title="second" />                                                 *
 *                                                                             *
 * ... this would yield the following.  Notice how there are two h1s at the    *
 * same level:                                                                 *
 *                                                                             *
 * <h1>first</h1>                                                              *
 * content                                                                     *
 * <h1>second</h1>                                                             *
 *                                                                             *
 * In some cases this may be okay, for instance if these were h2s and really   *
 * should be at the same level in the table of contents.  However, in the      *
 * above case there are two h1s, which is bad, m'kay?                          *
 *                                                                             *
 * This is where <HeadlineLevelOffset /> comes into play.  If you wrap         *
 * headlines with it and provide an offset greater than zero, those headlines  *
 * will behave as if they were nested.                                         *
 *                                                                             *
 * So the previous example could be solved like so:                            *
 *                                                                             *
  * <Headline title="first">                                                   *
 *   content                                                                   *
 * </Headline>                                                                 *
 * // something here is preventing you from nesting 'second' within 'first     *
 * <HeadlineLevelOffset>                                                       *
 *   <Headline title="second" />                                               *
 * </HeadlineLevelOffset>                                                      *
 *                                                                             *
 * ... and would yield the intended result:                                    *
 *                                                                             *
 * <h1>first</h1>                                                              *
 * content                                                                     *
 * <h2>second</h2>                                                             *
 *                                                                             *
 * If by now you're all like "woah, I don't want to affect the DOM, I just     *
 * want to change the styling", then see <HeadlineStyleOffset /> component     *
 * instead.                                                                    *
 *                                                                             *
 * ************************************************************************** */

const HeadlineLevelOffset = ({ children }) => (
  <Section component={Fragment}>
    {children}
  </Section>
)

HeadlineLevelOffset.propTypes = {
  children: componentShape,
}

export default HeadlineLevelOffset
