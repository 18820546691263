// istanbul ignore file
import { compose } from 'redux'
import { get } from 'fp/objects'
import { fallbackTo } from 'fp/utils'
import actionTypes from 'reducers/actionTypes'
import { storage } from 'fp/internet'

const initialize = () => (next) => {
  const settings = compose(
    JSON.parse,
    fallbackTo('{}'),
    get('hss'),
    storage,
  )()

  next({
    type: actionTypes.LOCAL_SETTINGS_INIT,
    settings,
  })
}

const localSettingsMiddleware = (store) => {
  let initialized = false

  return next => (action) => {
    if (!initialized) {
      initialized = true
      initialize(store)(next)
    }

    next(action)
  }
}

export default localSettingsMiddleware
