import { transparentize } from 'polished'
import { arraySequence } from 'fp/arrays'

const plugin = ({ shadow }) => {
  const shadowOpacity = 0.90

  const createShadow = (...px) => `${px[0]}px ${px[1]}px ${px[2]}px ${px[3]}px ${transparentize(px[4] || shadowOpacity, shadow)}`
  const border = '0 0 0 2px'
  const borderInset = '0 0 0 2px inset'
  const borderInsetButton = '0 0 0 1px inset'
  const activeBorder = '0 4px 4px 0'
  /**
   * NOTE:
   * Only use shadows 0 through 4.  The rest are there just to satisfy MUI and are
   * all copies of #4
   */
  const activeShadows = [
    'none',
    createShadow(0, 0, 0, 1),
    createShadow(0, 4, 10, 0),
    createShadow(0, 4, 16, 0),
    createShadow(0, 4, 40, 0),
    createShadow(0, 4, 10, 0, 0.2),
    border,
    borderInset,
    borderInsetButton,
    activeBorder,
  ]

  const shadows = [
    ...activeShadows,
    ...arraySequence(19).map(() => activeShadows[4]),
  ]

  // adding it both in the array as well as a named option for ease
  shadows.border = border
  shadows.borderInset = borderInset
  shadows.borderInsetButton = borderInsetButton
  shadows.activeBorder = activeBorder
  return shadows
}

export default plugin
