import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'reducers/users'
import { getStudents } from 'selectors/users'
import useEffectOnce from './useEffectOnce'

const useStudents = () => {
  const dispatch = useDispatch()

  useEffectOnce(() => {
    dispatch(actions.fetchMyStudents())
  }, [dispatch])

  return useSelector(getStudents)
}

export default useStudents
