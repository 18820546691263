import { useCallback, useEffect, useState } from 'react'

const useWindowSize = () => {
  const isClient = typeof window === 'object'

  const getSize = useCallback(() => ({
    width: isClient ? window.innerWidth : /* istanbul ignore next */ undefined,
    height: isClient ? window.innerHeight : /* istanbul ignore next */ undefined,
  }), [isClient])

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    /* istanbul ignore else */
    if (isClient) {
      const handleResize = /* istanbul ignore next */ () => {
        setWindowSize(getSize())
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
    /* istanbul ignore next */
    return false
  }, [getSize, isClient])

  return windowSize
}

export default useWindowSize
