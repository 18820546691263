import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import { compose } from 'redux'
import getNavItems from 'selectors/nav'
import { set } from 'fp/objects'
import AsList from './AsList'
import AsTree from './AsTree'

const Nav = ({ isSidebarShrunk }) => {
  const rawEntries = useSelector(getNavItems)
  const location = useLocation()

  const entries = useMemo(() => {
    const decorate = compose(
      entry => entry.items
        ? set('items', entry.items.map(decorate))(entry)
        : entry,
      entry => set('matches', Boolean(entry.to) && Boolean(matchPath({ path: entry.to, end: false }, location.pathname)))(entry),
      entry => set('key', `${entry.label}--${entry.to}`)(entry),
    )

    return rawEntries.map(decorate)
  }, [location.pathname, rawEntries])

  return isSidebarShrunk
    ? <AsList entries={entries} />
    : <AsTree entries={entries} />
}

Nav.propTypes = {
  isSidebarShrunk: PropTypes.bool.isRequired,
}

export default Nav
