import { call, put, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'
import actionTypes from 'reducers/actionTypes'
import { restEndpoint } from 'reducers/utils'
import { buildUrl } from 'fp/internet'
import { actions } from 'reducers/notifications'
import { dangerouslyCallApi } from './api'
import { success } from './utils'

function* handleFetch(action) {
  const { userId } = action

  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(`${restEndpoint.users}/${userId}`),
  })
}

function* handleFetchList(action) {
  const { queryParams } = action

  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(restEndpoint.users, queryParams, false),
  })
}

export function* recoverUser(action) {
  const { payload: { email } } = action

  yield call(dangerouslyCallApi, {
    action,
    url: `${restEndpoint.users}/request-password-recovery`,
    options: {
      method: 'post',
      body: { email },
    },
  })
}

export function* resetPasswordUser(action) {
  const { payload: { password, resetCode } } = action

  yield call(dangerouslyCallApi, {
    action,
    url: `${restEndpoint.users}/reset-password`,
    options: {
      method: 'post',
      body: { password, resetCode },
    },
  })
}

export function* handleSaveUser(action) {
  const { payload: { id, ...user } } = action
  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(`${restEndpoint.users}/${id}`, false),
    options: {
      method: 'PATCH',
      body: user,
    },
  })
}

export function* handleSaveUserSuccess() {
  yield put(actions.addAlert({ message: 'Your settings have been saved.' }))
}

/* istanbul ignore next line */
function* userSaga() {
  yield takeEvery(actionTypes.USER_FETCH, handleFetch)
  yield takeEvery(actionTypes.USER_FETCH_LIST, handleFetchList)
  yield takeEvery(actionTypes.USER_RECOVERY, recoverUser)
  yield takeLeading(actionTypes.USER_RESET_PASSWORD, resetPasswordUser)
  yield takeLatest(actionTypes.USER_SAVE, handleSaveUser)
  yield takeLatest(success(actionTypes.USER_SAVE), handleSaveUserSuccess)
}

export default userSaga
