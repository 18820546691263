/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from 'react'
import { compose } from 'redux'
import { fallbackTo } from 'fp/utils'
import useStateWithDynamicDefault from './useStateWithDynamicDefault'

/**
 *
 * This state hook isolates a value from within a squery. Its purpose is to
 * allow a component to use a value from the squery and be able to modify it,
 * but without the underlying squery being affected.
 *
 * It takes a getter function and a fallback value. The getter function should
 * take a squery and return a value from within it. The fallback value is used
 * when the squery is undefined.  It's also used if the getter function returns
 * undefined.
 *
 * The state value is seeded from the query and also will update if the squery
 * changes.  However, changes to the state value do not affect the squery.
 * This is handy for storing local user edits until an "execute" button is
 * clicked.
 *
 */

const useStateIsolatedFromSquery = (getter, fallback = null) => (squery) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const valueWithinSquery = useMemo(() => squery
    ? compose(
      fallbackTo(fallback),
      getter,
    )(squery)
    : fallback, [squery])

  return useStateWithDynamicDefault(valueWithinSquery)
}

export default useStateIsolatedFromSquery
