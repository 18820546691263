const overrides = ({ mixins: { rem }, palette, transitions }) => {
  const inputStyle = {
    padding: rem(1.2, 1.5, 1.2, 0),
    '&::placeholder': {
      color: palette.text.primary,
      fontStyle: 'italic',
    },
  }

  return ({
    MuiInputBase: {
      styleOverrides: {
        root: {
          'input::placeholder': {
            opacity: 1,
          },
        },
      },
      variants: [
        {
          props: { variant: 'search' },
          style: {
            input: inputStyle,
          },
        },
        {
          props: { variant: 'search-expand' },
          style: {
            input: {
              ...inputStyle,
              transition: transitions.create('width'),
              width: 0,
              '&:focus': {
                width: '20ch',
              },
            },
          },
        },
      ],
    },
  })
}

export default overrides
