/* istanbul ignore file */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { createRoot } from 'react-dom/client'
import videojs from 'video.js'
import cl from 'classnames'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import TheaterIcon from 'hss/images/controls/video/theater.svg'
import { whenPresent } from 'fp/utils'

const TheaterToggle = (props) => {
  const {
    setTheaterMode: setTheaterModeInContext,
    setVideosInTheaterMode,
  } = props
  const [theaterMode, setTheaterMode] = useState(false)

  const label = 'Theater Mode'
  const iconColor = theaterMode ? 'white' : 'transparent'

  const handleClick = () => {
    whenPresent(setVideosInTheaterMode, !theaterMode)
    setTheaterModeInContext(!theaterMode)
    setTheaterMode(!theaterMode)
  }

  return (
    <Tooltip
      arrow
      title={<Typography fontSize="1.4rem">{label}</Typography>}
    >
      <IconButton
        aria-label={label}
        onClick={handleClick}
        type="button"
      >
        <TheaterIcon color={iconColor} />
      </IconButton>
    </Tooltip>
  )
}

TheaterToggle.propTypes = {
  setTheaterMode: PropTypes.func.isRequired,
  setVideosInTheaterMode: PropTypes.func,
}

const vjsComponent = videojs.getComponent('Component')

class ssTheaterToggle extends vjsComponent {
  constructor(player, options = {}) {
    super(player, options)

    this.options = options
    this.theaterMode = false
    this.mount = this.mount.bind(this)

    this.el().className = cl('vjs-control', 'ss-theater-toggle')

    this.root = createRoot(this.el())

    player.ready(() => {
      this.mount()
    })
  }

  handleClick(e) {
    e.preventDefault()
    e.stopImmediatePropagation()

    this.theaterMode = !this.theaterMode
  }

  mount() {
    this.root.render(<TheaterToggle
      vjsComponent={this}
      {...this.options}
    />)
  }
}

vjsComponent.registerComponent('ssTheaterToggle', ssTheaterToggle)

export default TheaterToggle
