import { useMemo, useState } from 'react'
import { insertNewBlock } from '@studysync/draft-js-modifiers'
import Icon from '@mui/material/Icon'
import ViewInAr from '@mui/icons-material/ViewInAr'
import { useFormContext, useWatch } from 'react-hook-form'
import { push } from 'fp/arrays'
import { get, set } from 'fp/objects'
import useContent from 'hooks/useContent'
import { CONTENT_TYPE_ECHO } from 'core/consts'
import { toolbarButtonPropTypes } from '../../utils/misc'
import DraftMenuButton from '../../toolbar/DraftMenuButton'
import ChooseInteractiveDialog from './ChooseInteractiveDialog'

const options = [
  { label: 'Select interactive to insert...', key: 'interactive' },
  { label: 'Insert Number Crunch', key: 'number-crunch' },
]

const ChooseInteractiveButton = ({ allowedInteractives, editorState, features, setEditorState }) => {
  const [open, setOpen] = useState(false)
  const children = useWatch({ name: 'children' })
  const { setValue } = useFormContext()

  const content = useContent()

  const menuItems = useMemo(() => {
    const isEcho = content?.parent?.contentType === CONTENT_TYPE_ECHO
    return options.map((item) => {
      switch (item.key) {
        case 'number-crunch':
          return set('disabled', !isEcho)(item)
        /* istanbul ignore next */
        default:
          return item
      }
    })
  }, [content?.parent?.contentType])

  const handleChange = ({ key }) => {
    let newEditorState
    switch (key) {
      case 'interactive':
        setOpen(true)
        break
      case 'number-crunch':
        newEditorState = insertNewBlock(
          editorState,
          'atomic',
          ' ',
          {
            answer: '',
            question: '',
            source: '',
            type: 'number-crunch',
          },
        )
        setEditorState(newEditorState, false)
        break
      /* istanbul ignore next */
      default:
    }
  }

  const handleInsert = ({ id }) => {
    const newEditorState = insertNewBlock(
      editorState,
      'atomic',
      ' ',
      {
        type: 'interactive',
        contentId: id,
      },
    )

    setEditorState(newEditorState, false)

    setValue('children', push({ id })(children))

    setOpen(false)
  }

  return (
    <>
      <DraftMenuButton
        editorState={editorState}
        featureMenuKey="interactives"
        features={features}
        label="Interactives"
        onChange={handleChange}
        options={menuItems}
      >
        <Icon><ViewInAr /></Icon>
      </DraftMenuButton>

      {Boolean(open) && (
        <ChooseInteractiveDialog
          excludeIds={children?.map(get('id')) || []}
          onClose={() => setOpen(false)}
          onComplete={handleInsert}
          subContentTypes={allowedInteractives}
        />
      )}
    </>
  )
}

ChooseInteractiveButton.propTypes = toolbarButtonPropTypes

export default ChooseInteractiveButton
