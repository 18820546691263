import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { replace } from 'redux-first-history'
import { useEffect } from 'react'
import useAbilityCheck from 'hooks/useAbilityCheck'

/** ****************************************************************************
 *                                                                             *
 *                               !!!!!!!!!!!!!!!!!!                            *
 *                               !!!   NOTICE   !!!                            *
 *                               !!!!!!!!!!!!!!!!!!                            *
 *                                                                             *
 * DO NOT USE DIRECTLY                                                         *
 *                                                                             *
 * Use <TraverseRoute /> instead                                               *
 *                                                                             *
 ***************************************************************************** */

const PermissableRoute = ({ element, abilityFlags = [] }) => {
  const dispatch = useDispatch()

  const hasPermission = useAbilityCheck(abilityFlags)

  useEffect(() => {
    if (!hasPermission) {
      dispatch(replace('/403'))
    }
  }, [dispatch, hasPermission])

  return hasPermission ? element : null
}

PermissableRoute.propTypes = {
  abilityFlags: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  element: PropTypes.element.isRequired,
}

export default PermissableRoute
