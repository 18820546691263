import PropTypes from 'prop-types'
import { Children, cloneElement } from 'react'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { omit, set } from 'fp/objects'
import { pipe } from 'fp/utils'
import { minimumTextFieldHeight } from 'styling/theming/base/components/TextField'
import Select from './Select'

const SwatchSelect = (props) => {
  const { children, renderSwatch, ...rest } = props
  const items = Children.toArray(children)

  return (
    <Select {...{ ...rest, 'data-variant': 'swatch' }}>

      {items.map((child, idx) => (
        /**
         * A little tricky, but all this does is unwrap the passed <MenuItem />
         * so that we can re-wrap its children with a <Stack />.
         * The swatch will be the first item in the stack and the MenuItem's
         * original child becomes the second.
         */
        cloneElement(
          child,
          pipe(
            omit('children'),
            set('key', child.key || idx),
            set('data-variant', 'swatch'),
          )(child.props),
          <Stack
            alignItems="center"
            direction="row"
            gap={1.5}
            height="100%"
          >

            {renderSwatch({ ...child.props, size: minimumTextFieldHeight })}

            {child.props.children}

          </Stack>,
        )
      ))}

    </Select>
  )
}

SwatchSelect.propTypes = {
  children: PropTypes.node,
  renderSwatch: PropTypes.func.isRequired,
}

export const BorderedSwatch = styled(
  Box,
  {
    name: 'FeaturePictureThis-FontSizeSelection',
    shouldForwardProp: Boolean,
  },
)(({ size, theme: { mixins: { borderS, size: sizer }, palette } }) => ({
  ...borderS(palette.border[0]),
  ...sizer(size),
  boxSizing: 'border-box',
  position: 'relative',
}))

export default SwatchSelect
