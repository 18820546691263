import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'
import { rem } from 'styling/theming/base/mixins'

const StyledButton = styled(
  'button',
  { name: 'navigation-SkipToButton' },
)(({ theme: { mixins: { transition }, palette, shadows, zIndex } }) => ({
  backgroundColor: palette.background.default,
  boxShadow: shadows[3],
  padding: rem(1.5, 2.5),
  zIndex: zIndex.drawer + 1,
  ...transition('transform'),
}))

const SkipToButton = ({ children, findTarget, placement }) => {
  const sx = useMemo(() => {
    switch (placement) {
      case 'top':
        return {
          margin: rem(1, 0, 0, 1),
          position: 'absolute',
          transform: 'translateY(-130%)',
          '&:focus': {
            transform: 'translateY(0)',
          },
        }
      case 'right':
        return {
          margin: rem(0, 0, 1, 1),
          position: 'fixed',
          bottom: rem(10),
          right: rem(1),
          transform: 'translateX(120%)',
          '&:focus': {
            transform: 'translateX(0)',
          },
        }
      case 'bottom':
        return {
          margin: rem(0, 0, 1, 1),
          position: 'absolute',
          transform: 'translateY(10rem)',
          bottom: 0,
          '&:focus': {
            transform: 'translateY(0)',
          },
        }
      case 'left':
        return {
          margin: rem(0, 0, 1, 1),
          position: 'relative',
          transform: 'translateX(-120%)',
          '&:focus': {
            transform: 'translateX(0)',
          },
        }
      default:
        return null
    }
  }, [placement])

  return (
    <StyledButton
      onClick={() => findTarget()?.focus()}
      sx={sx}
      type="button"
    >
      <Typography variant="navigation">
        {children}
      </Typography>
    </StyledButton>
  )
}

SkipToButton.propTypes = {
  children: PropTypes.node.isRequired,
  findTarget: PropTypes.func.isRequired,
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
}

export default SkipToButton
