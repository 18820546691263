import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import NavLink from 'common/navigation/links/NavLink'
import { navItemChildShape, navItemShape } from 'core/shapes'
import useNavigation from 'hooks/useNavigation'
import { get } from 'fp/objects'
import { preventDefault } from 'fp/dom'
import EntryIcon from './EntryIcon'

const MenuEntry = ({ childEntry: { label, matches, to } }) => {
  const { hrefFromTo, navigate } = useNavigation()

  return (
    <MenuItem
      onClick={() => navigate(hrefFromTo(to))}
      selected={Boolean(matches)}
    >
      {label}
    </MenuItem>
  )
}
MenuEntry.propTypes = {
  childEntry: navItemChildShape.isRequired,
}

const ListEntry = ({ entry }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const selected = entry.matches || entry.items?.some(get('matches'))

  const handleClose = (e) => {
    preventDefault(e)
    setAnchorEl(null)
  }

  const navProps = entry.items.length
    ? {
      'aria-haspopup': true,
      href: '#',
      onClick: (e) => {
        preventDefault(e)
        setAnchorEl(e.currentTarget)
      },
    }
    : {
      // without this, the drawer will open unexpectedly
      onClick: e => e.stopPropagation(),
    }

  return (
    <>
      <ListItem className={selected ? 'active' : ''}>
        <Tooltip
          placement="right"
          title={entry.label}
        >
          <NavLink
            to={entry.to || '/404'}
            {...navProps}
            {...(!entry.to && { onClick: (e) => {
              e.preventDefault()
              e.stopPropagation()
              // eslint-disable-next-line no-restricted-globals
              location.href = entry.href
            } })}
          >
            <ListItemIcon>
              <EntryIcon entry={entry} />
            </ListItemIcon>
          </NavLink>

        </Tooltip>
      </ListItem>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        onClick={handleClose}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        {entry.items.map(childEntry => (
          <MenuEntry
            childEntry={childEntry}
            key={childEntry.key}
          />
        ))}
      </Menu>
    </>
  )
}

ListEntry.propTypes = {
  entry: navItemShape.isRequired,
}

export default ListEntry
