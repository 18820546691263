import { useContext } from 'react'
import Box from '@mui/material/Box'
import { context } from './context'

const Ready = () => {
  const { status } = useContext(context)

  return status === 'READY'
    ? (
      <Box
        bgcolor="common.lightGrey"
        sx={{ padding: '2rem 3rem' }}
      >
        Google integration setup complete.
      </Box>
    )
    : null
}

export default Ready
