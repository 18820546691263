import { applyMiddleware, combineReducers, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension'
import { createReduxHistoryContext } from 'redux-first-history'
import { createBrowserHistory, createMemoryHistory } from 'history'
import sessionHelperMiddleware from 'core/middleware/sessionHelper'
import httpMiddleware from '../middleware/http'
import contentPreprocessorMiddleware from '../middleware/contentPreprocessors'
import localSettingsMiddleware from '../middleware/localSettings'
import gnsHelperMiddleware from '../middleware/gnsHelper'
import { sortObject } from '../fp/objects'
import { isTestEnv } from './selectors/index'
import sagas from './sagas/index'
import * as reducers from './reducers/index'
import promiseListener from './promiseListener'

const sagaMiddleware = createSagaMiddleware()

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: isTestEnv()
    ? createMemoryHistory({ initialEntries: ['/'] })
    : createBrowserHistory(),
})

const combinedReducers = combineReducers(sortObject({
  router: routerReducer,
  ...reducers,
}))

const buildMiddlewares = () => [
  httpMiddleware,
  contentPreprocessorMiddleware(),
  gnsHelperMiddleware,
  localSettingsMiddleware,
  sessionHelperMiddleware,
  promiseListener.middleware,
  sagaMiddleware,
  routerMiddleware,
].filter(Boolean)

const storeFactory = (initialState = {}) => {
  const middlewares = buildMiddlewares()

  return createStore(
    combinedReducers,
    initialState,
    composeWithDevToolsDevelopmentOnly(applyMiddleware(...middlewares)),
  )
}

const store = storeFactory()

if (!isTestEnv()) sagaMiddleware.run(sagas)

export const history = createReduxHistory(store)

export default store
