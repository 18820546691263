import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import MuiCheckbox from '@mui/material/Checkbox'
import { useId } from 'react'
import Typography from '@mui/material/Typography'
import { rem } from 'styling/theming/base/mixins'
import { componentShape } from 'core/shapes'

const Checkbox = ({ description, label, ...rest }) => {
  const descriptionId = useId()

  return label
    ? (
      <>
        <FormControlLabel
          aria-describedby={description ? descriptionId : null}
          control={(
            <MuiCheckbox
              {...rest}
              sx={{
                padding: 2,
                width: rem(6),
              }}
            />
          )}
          data-controltype="checkbox"
          label={(
            <Typography variant="multiple-choice">
              {label}
            </Typography>
          )}
        />
        {Boolean(description) && (
          <Box
            id={descriptionId}
            mb={1}
            ml={6.2}
          >
            {description}
          </Box>
        )}
      </>
    )
    : (
      <MuiCheckbox
        className="no-label"
        {...rest}
      />
    )
}

Checkbox.propTypes = {
  description: componentShape,
  label: componentShape.isRequired,
}

export default Checkbox
