import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'
import useSession from 'hooks/useSession'
import { actions as sessionActions } from 'reducers/session'

const LogoutMenuItems = () => {
  const { session: { logoutLink, returnLink } } = useSession()
  const dispatch = useDispatch()

  const handleLogout = redirect => () => {
    dispatch(sessionActions.logout(redirect))
  }

  return (
    <>
      {Boolean(returnLink) && <MenuItem onClick={handleLogout(returnLink)}>Return to Imagine Learning</MenuItem>}
      <MenuItem onClick={handleLogout(logoutLink)}>Logout</MenuItem>
    </>
  )
}

export default LogoutMenuItems
