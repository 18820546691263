import { withOptions } from '@comfy/redux-selectors'
import { compose } from 'redux'
import { get } from 'fp/objects'
import { fallbackTo } from 'fp/utils'
import { omitReduxMetadata } from './utils'
import { createSelector, stateReactions } from '.'

// eslint-disable-next-line import/prefer-default-export
export const getReactionsForContent = withOptions(({ interactionId }) => createSelector('getReactionsForContent')(
  stateReactions,
  compose(
    fallbackTo({}),
    get(interactionId),
    omitReduxMetadata,
  ),
))
