import { useFormContext, useFormState } from 'react-hook-form'
import { get } from 'fp/objects'

const useHookFormError = (name) => {
  const { clearErrors, setError: setErrors } = useFormContext()
  const { errors } = useFormState()

  const error = get(`${name}.message`)(errors)
  const clearError = () => clearErrors(name)
  const setError = (message, shouldFocus = true, type = 'custom') => setErrors(
    name,
    { message, type },
    { shouldFocus },
  )

  return { error, clearError, setError }
}

export default useHookFormError
