import { useTheme } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import ReactSelect from 'react-select'
import PropTypes from 'prop-types'
import { forwardRef, useId } from 'react'
import { componentShape } from 'core/shapes'
import { isUndefined } from 'fp/utils'
import ClearIndicator from './ClearIndicator'
import DropdownIndicator from './DropDownIndicator'
import Input from './Input'
import MultiValueRemove from './MultiValueRemove'

const OldMultiSelectReplaceMe = forwardRef(({
  ariaLabelledby,
  formControlProps,
  helperText,
  label,
  required = false,
  ...rest
}, ref) => {
  const labelId = useId()
  const descriptionId = useId()

  const { palette, shadows, zIndex } = useTheme()

  const selectStyles = {
    control: styles => ({
      ...styles,
      minHeight: 50,
      borderRadius: 0,
      borderColor: palette.grey[4],
      background: 'none',
      boxShadow: 'none',
      color: palette.primary.main,
      svg: {
        color: palette.primary.main,
      },
      span: {
        background: 'none',
      },
      '&:hover': {
        borderColor: palette.grey[0],
        boxShadow: shadows.borderInsetButton,
        transition: '225ms',
      },
    }),
    placeholder: styles => ({
      ...styles,
      color: palette.primary.main,
    }),
    menu: styles => ({
      ...styles,
      marginTop: 0,
      zIndex: zIndex.modal,
      border: 'none',
      borderRadius: 0,
      color: palette.primary.main,
      div: {
        color: palette.primary.main,
      },
    }),
    multiValueLabel: styles => ({
      ...styles,
      marginTop: 3,
    }),
    option: styles => ({
      ...styles,
      backgroundColor: palette.grey.contrastText,
      '&:hover': {
        backgroundColor: palette.grey[5],
      },
    }),
  }

  const components = {
    ClearIndicator,
    DropdownIndicator,
    Input,
    MultiValueRemove,
  }

  if (isUndefined(label || ariaLabelledby)) {
    throw Error('MultiSelect requires either a label or ariaLabelledby prop')
  }

  return (
    <FormControl {...formControlProps}>
      {!ariaLabelledby && (
        <InputLabel
          id={labelId}
          required={required}
          shrink
        >
          {label}
        </InputLabel>
      )}
      <ReactSelect
        aria-labelledby={ariaLabelledby || labelId}
        components={components}
        inputAriaDescribedby={helperText ? descriptionId : null}
        isMulti
        ref={ref}
        styles={selectStyles}
        {...rest}
      />
      {Boolean(helperText?.length) && <FormHelperText id={descriptionId}>{helperText}</FormHelperText>}
    </FormControl>
  )
})

OldMultiSelectReplaceMe.propTypes = {
  ariaLabelledby: PropTypes.string,
  formControlProps: PropTypes.object,
  helperText: componentShape,
  label: PropTypes.string,
  required: PropTypes.bool,
}

export default OldMultiSelectReplaceMe
