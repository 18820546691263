import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import DialogContent from '@mui/material/DialogContent'
import { position } from 'polished'
import { forwardRef } from 'react'
import DataTable from 'common/tables/DataTable'
import { items } from 'hss/views/Library/AddToLibrary/assetTypes/Interactives'
import { findObj } from 'fp/arrays'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import CardIcon from 'hss/views/Card/CardIcon'
import { get } from 'fp/objects'
import TableCardRenderer from './TableCardRenderer'

const lookupSubTypeName = ({ getValue }) => {
  const contentSubType = getValue()

  return (
    <>
      <CardIcon contentType={CONTENT_TYPE_INTERACTIVE} />
      {findObj('contentSubType', contentSubType)(items)?.label}
    </>
  )
}

const columns = [
  {
    accessorKey: 'assetCode',
    header: 'Asset',
    id: 'assetCode',
  },

  {
    accessorKey: 'contentSubType',
    cell: lookupSubTypeName,
    header: 'Type',
    id: 'contentSubType',
  },

  {
    accessorKey: 'name',
    header: 'Name',
    id: 'name',
  },

  {
    accessorFn: get('data.notes'),
    header: 'Notes',
    id: 'data.notes',
  },

  {
    accessorKey: 'uploadsMap',
    header: 'Preview',
    id: 'uploadsMap',
  },

  {
    accessorKey: 'id',
    header: 'ID',
    id: 'id',
  },
]

const StyledTable = styled(
  DialogContent,
  { name: 'interactivePlugin-InteractivesTable' },
)(({ theme: { mixins: { rem } } }) => ({
  overflowY: 'auto',
  paddingRight: 0,
  position: 'relative',
  height: 'auto',
  maxHeight: '65vh',
  '> ul:first-of-type': {
    overflowY: 'auto',
    marginBottom: 20,
    padding: rem(0, 1.5, 0, 3),
    ...position('absolute', 0, 0, 0, 0),
  },
  li: {
    position: 'relative',
    '.library-card-icon': {
      right: 10,
      top: 0,
    },
  },
}))

const InteractivesTable = forwardRef(({ dataset: { data }, onComplete }, ref) => (
  <StyledTable dividers>
    <DataTable
      CardRenderer={TableCardRenderer}
      cardRendererProps={{
        forSystemUse: true,
        onComplete,
      }}
      columns={columns}
      data={data}
      id="rte-choose-interactive-table"
      preferCardView
      ref={ref}
    />
  </StyledTable>
))

InteractivesTable.propTypes = {
  dataset: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default InteractivesTable
