import { border } from 'polished'

const overrides = ({ mixins: { rem }, palette, typography }) => ({
  MuiTab: { defaultProps: {
    wrapped: true,
  },

  styleOverrides: {
    root: {
      ...typography.variants['tab-title'],
      ...border('top', 1, 'solid', palette.grey[4]),
      ...border('right', 1, 'solid', palette.grey[4]),
      padding: rem(2.4, 2.55),
      color: palette.grey[2],
      minWidth: 190,
    },
  },
  variants: [
    {
      props: { variant: 'timeline-tab' },
      style: {
        '&.MuiTab-root.Mui-selected': {
          color: palette.accent.light,
        },
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    }] },
})

export default overrides
