import { call, put, takeLeading } from 'redux-saga/effects'
import actionTypes from 'reducers/actionTypes'
import { restEndpoint } from 'reducers/utils'
import { actions as notificationActions } from 'reducers/notifications'
import { omit } from 'fp/objects'
import { success } from './utils'
import { dangerouslyCallApi } from './api'

export function* handlePost(action) {
  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: 'POST',
      body: omit('type')(action),
    },
    url: restEndpoint.reactions,
  })
}

export function* handlePostSuccess() {
  yield (put(notificationActions.addAlert({
    message: 'Your review has been saved.',
  })))
}

/* istanbul ignore next line */
function* reactionSaga() {
  yield takeLeading(actionTypes.REACTION_POST, handlePost)
  yield takeLeading(success(actionTypes.REACTION_POST), handlePostSuccess)
}

export default reactionSaga
