import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import { forwardRef } from 'react'
import { compose } from 'redux'
import FormControlLabel from '@mui/material/FormControlLabel'
import { fallbackTo } from 'fp/utils'
import { get } from 'fp/objects'
import Autocomplete from './Autocomplete'

const MultiPicker = forwardRef((props, ref) => {
  const {
    getOptionLabel,
    renderOption: renderOptionFromProps,
    renderOptionsWithCheckboxes = false,
    ...rest
  } = props

  const getCheckboxLabel = option => compose(
    fallbackTo(option),
    getOptionLabel || get('label'),
  )(option)

  const renderOption = renderOptionFromProps || (renderOptionsWithCheckboxes
    ? ({ key, ...optionProps }, option, { selected }) => (
      <FormControlLabel
        control={<Checkbox checked={selected} />}
        key={key}
        label={getCheckboxLabel(option)}
        {...optionProps}
      />
    )
    : undefined)

  return (
    <Autocomplete
      disableCloseOnSelect
      multiple
      ref={ref}
      {...rest}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
    />
  )
})

MultiPicker.propTypes = {
  getOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  renderOptionsWithCheckboxes: PropTypes.bool,
}

export default MultiPicker
