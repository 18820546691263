import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { useContext } from 'react'
import { componentShape } from 'core/shapes'
import Breadcrumbs from 'hss/sections/Breadcrumbs'
import { navDialogsContext } from '../NavDialogsProvider'
import UserMenu from './UserMenu'
import ProfileUserAlertsMenu from './ProfileUserAlertsMenu'

const Banner = ({ SearchComponent }) => {
  const {
    profileUserAlertsIsOpen: showProfileAlerts,
    toggleProfileUserAlertsIsOpen,
  } = useContext(navDialogsContext)
  return (
    <AppBar position="sticky">

      <Toolbar variant="appbar">

        <Breadcrumbs />

        <div style={{ display: 'flex' }}>

          {SearchComponent}

          <ProfileUserAlertsMenu
            showProfileAlerts={showProfileAlerts}
            toggleProfileUserAlertsIsOpen={toggleProfileUserAlertsIsOpen}
          />

          <UserMenu />

        </div>

      </Toolbar>

    </AppBar>
  )
}

Banner.propTypes = {
  SearchComponent: componentShape,
}

export default Banner
