/* eslint-disable @studysync/material-ui/tree-shakeable-imports */
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { getPanelId, getTabId, useTabContext } from '@mui/lab'
import { Children, Fragment, forwardRef, isValidElement, useRef } from 'react'
import { Container } from '@mui/material'
import { compose } from 'redux'
import { equals, get, pick } from 'fp/objects'
import Dropdown from './Dropdown'
import SpinButton from './SpinButton'

/**
 * NOTE:
 *
 * This component makes use of some internal lab features and is probably subject
 * to change.
 *
 * If things get weird, check to see what's changed in:
 * https://github.dev/mui-org/material-ui/packages/mui-lab/src/TabList/TabList.js
 *
 * At the time of this writing, we're doing things in exactly the same way.
 */

const StyleWrapper = styled(
  'div',
  { name: 'TabList-AsDropdown' },
)({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  width: '100%',
  gap: 5,
  '> div:nth-of-type(2)': {
    flexGrow: 1,
  },
})

const AsDropdown = forwardRef(({ children, contained, onChange }, ref) => {
  const context = useTabContext()
  const fallbackRef = useRef()
  if (context === null) {
    throw new Error('No TabContext provided')
  }

  const { value } = context
  const Wrapper = contained ? Container : Fragment

  const items = Children.map(children, (child) => {
    if (!isValidElement(child)) {
      return null
    }

    return {
      ...pick('disabled', 'label', 'value')(child.props),
      'aria-controls': getPanelId(context, child.props.value),
      id: getTabId(context, child.props.value),
    }
  })

  const selectedIdx = items.findIndex(compose(
    equals(value),
    get('value'),
  ))

  const handleButtonClick = (e, newValue) => {
    onChange(e, newValue);
    (ref || fallbackRef).current.focus()
  }

  return (
    <Wrapper>
      <StyleWrapper>
        <SpinButton
          direction="back"
          onClick={handleButtonClick}
          {...{ items, selectedIdx }}
        />

        <Dropdown {...{ items, onChange, ref: ref || fallbackRef, selectedIdx }} />

        {/** TODO:
          *   need to disable these spinbuttons if the next available item is
          *   disabled.  Should skip over any disabled items to get to the next
          *   available one.
          */}
        <SpinButton
          direction="forward"
          onClick={handleButtonClick}
          {...{ items, selectedIdx }}
        />
      </StyleWrapper>
    </Wrapper>
  )
})

AsDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  contained: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default AsDropdown
