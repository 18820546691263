const overrides = ({ breakpoints, mixins: { em, rem } }) => ({
  MuiDialogActions: {
    styleOverrides: {
      root: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        margin: rem(2.8, 2.5, 3.2),
        padding: 0,
        [breakpoints.down('md')]: {
          margin: rem(2.8, 2.5, 3.2),
        },
      },

      spacing: {
        '> :not(*:first-of-type)': {
          marginLeft: em(1),
        },
      },
    },
  },
})

export default overrides
