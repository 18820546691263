import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Left from 'hss/images/nav/arrow-left-small.svg'
import Right from 'hss/images/nav/arrow-right-small.svg'

const SpinButton = ({ direction, items, onClick, selectedIdx }) => {
  const goingBack = direction === 'back'
  const Image = goingBack ? Left : Right

  const disabled = (selectedIdx === 0 && goingBack)
    || (selectedIdx === items.length - 1 && !goingBack)

  const item = disabled ? {} : items[selectedIdx + (goingBack ? -1 : 1)]

  const handleClick = (e) => {
    onClick(e, item.value)
  }

  return (
    <Tooltip title={disabled ? '' : item.label}>
      <IconButton
        aria-controls={item['aria-controls']}
        disabled={disabled}
        onClick={handleClick}
        variant="borderless"
      >
        <Image />
      </IconButton>
    </Tooltip>
  )
}

SpinButton.propTypes = {
  direction: PropTypes.oneOf([
    'back',
    'forward',
  ]).isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  selectedIdx: PropTypes.number.isRequired,
}

export default SpinButton
