import { border } from 'polished'
import { fontFamily } from '../theming/base/typography'

const plugin = ({ mixins: { important, px, rem }, palette }) => ({
  button: {
    borderStyle: 'none',
    background: 'transparent',
  },

  fieldset: {
    margin: 0,
    border: 'none',
    padding: 0,
    borderColor: important(palette.border[0]),

    '.MuiFormHelperText-root:empty': {
      height: 5,
    },
  },

  "input[type='radio']": {
    float: 'left',
    ...border('0.0625em', 'solid', palette.border[3]),
    borderRadius: '0.25em',
    background: 'rgb(224, 224, 224)',
  },

  select: {
    fontFamily,
    fontWeight: 300,
  },

  textarea: {
    resize: important('none'),
    width: important('100%'),
    minHeight: 50,
    maxHeight: 240,
    margin: important(0),
    padding: important(0),
    ...border(px(1), 'solid', palette.border[0]),
    borderRadius: 3,
    boxSizing: 'border-box',
    fontSize: rem(1.7),
    fontFamily,
  },
})
export default plugin
