import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import MuiAutocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import Chip from '@mui/material/Chip'
import { componentShape } from 'core/shapes'
import TextField from 'common/formControls/textInputs/TextField'
import { suffix } from 'fp/strings'

const Autocomplete = forwardRef((props, ref) => {
  const {
    disabled = false,
    error = false,
    helperText,
    inputProps,
    inputRef,
    label,
    loading = false,
    name,
    required = false,
    ...rest
  } = props

  return (
    <MuiAutocomplete
      renderInput={({ key, ...params }) => (
        <TextField
          key={key}
          {...{
            ...params,
            disabled,
            error,
            helperText,
            inputProps: {
              ...params.inputProps,
              ...inputProps,
            },
            label: required ? suffix(' *')(label) : label,
            name: name || label,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          ref={ref}
        />
      )}
      renderTags={(value, getTagProps) => value.map((option, index) => {
        const { key, ...tagProps } = getTagProps({ index })

        return (
          <Chip
            key={key}
            label={option.label}
            {...tagProps}
          />
        )
      })}
      {...rest}
      disabled={disabled}
      loading={loading}
      ref={inputRef}
      required={required}
    />
  )
})

Autocomplete.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: componentShape,
  inputProps: PropTypes.object,
  inputRef: PropTypes.func,
  label: componentShape,
  loading: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
}

export default Autocomplete
