import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { useId } from 'react'
import Headline from 'common/text/Headline'
import ScreenReaderText from 'common/text/ScreenReaderText'
import { vttShape } from 'core/shapes'
import Well from 'apps/common/layout/Well'
import { formatSec } from 'locale/i18n'

const Transcription = ({ description, vtt, ...rest }) => {
  const id = useId()

  return (
    <Well
      variant="contained"
      {...rest}
    >
      {Boolean(description) && (
        <>
          <Headline
            hr
            size={3}
            title="Scene Description"
          />

          <p>
            {description}
          </p>
        </>
      )}

      <Headline
        hr
        size={3}
        title="Transcript"
      >
        <Box
          sx={{
            '> p span:first-of-type': {
              marginRight: '2rem',
              fontWeight: 300,
            },
          }}
        >
          {vtt.cues.map(({ start, text }, idx) => (
            <p key={`${id}-${idx}`}>
              <span aria-hidden>{formatSec(start)}</span>
              <ScreenReaderText>{text}</ScreenReaderText>
              {text}
            </p>
          ))}
        </Box>
      </Headline>

    </Well>
  )
}

Transcription.propTypes = {
  description: PropTypes.string,
  vtt: vttShape.isRequired,
}

export default Transcription
