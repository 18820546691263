import { border } from 'polished'
import { containedPaddingLeft, containedPaddingRight, echoTabSize } from 'styling/theming/base'

const overrides = ({ mixins: { absWidth, important, rem }, palette }) => ({
  MuiTabs: {
    defaultProps: {
      variant: 'scrollable',
      scrollButtons: 'auto',
    },

    styleOverrides: {
      indicator: border('bottom', 6, 'solid', palette.accent.dark),
      root: {
        ...border('bottom', 1, 'solid', palette.grey[4]),
        '.MuiTab-root:first-of-type': border('left', 1, 'solid', palette.grey[4]),
      },
    },

    variants: [
      {
        props: { 'data-contained': true },
        style: {
          paddingLeft: important(containedPaddingLeft),
          paddingRight: important(containedPaddingRight),
        },
      },
      {
        props: { 'data-variant': 'echo' },
        style: {
          ...absWidth(echoTabSize),
          border: 'none',
          button: {
            minWidth: 'unset',
            border: important('none'),
            paddingLeft: 10,
            paddingRight: 10,
            borderStyle: 'none',
            svg: {
              marginBottom: 8,
            },
          },
          '.MuiTabs-indicator': {
            width: 0,
          },
          '&.is-collapsed': {
            'button.Mui-selected:not(:hover)': {
              'svg[data-filled="true"]': {
                display: 'none',
              },
              'svg[data-filled="false"]': {
                display: 'block',
              },
            },
          },
          '&:not(.is-collapsed)': {
            'button.Mui-selected': {
              background: palette.background.default,
              svg: {
                color: palette.accent.dark,
              },
            },
            '.MuiTabs-indicator': {
              right: 0,
              width: 8,
              backgroundColor: palette.accent.dark,
            },
          },
        },
      },
      {
        props: { 'data-variant': 'timeline' },
        style: {
          overflow: 'visible',
          background: palette.background.dark,
          '.MuiTabScrollButton-root': {
            color: palette.primary.contrastText,
            opacity: 1,
          },
          '.MuiTab-root': {
            backgroundColor: palette.background.dark,
            border: 'none',
            color: palette.primary.contrastText,
            borderTop: 0,
            minWidth: 78,
            '&:first-of-type': {
              borderLeft: 0,
            },
            '&.Mui-selected': {
              color: palette.primary.contrastText,
            },
          },

          '.MuiTabs-indicator': {
            backgroundColor: palette.border[5],
            clipPath: 'polygon(100% 33%, 75% 50%, 100% 66%)',
            width: 30,
          },
          '.MuiTabs-flexContainerVertical': {
            display: 'flex',
            alignItems: 'center',

            '&> button': {
              lineHeight: rem(1.6),
            },
          },
        },
      },
    ],
  },
})

export default overrides
