import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import useEffectOnce from 'hooks/useEffectOnce'
import { floatTypesShape } from 'core/shapes'
import { when, whenPresent } from 'fp/utils'
import AudioProvider, { audioContext } from './AudioContextProvider'
import MinimalPlayer from './MinimalPlayer'
import RegularPlayer from './RegularPlayer'

export const AudioPlayer = (props) => {
  const {
    autoplay = false,
    contentWrappingAllowed = false,
    float = 'none',
    minimal = false,
    narrow = false,
    onPlay,
    url = null,
  } = props

  const {
    playStatus,
    setPlayStatus,
    updateUrl,
  } = useContext(audioContext)

  useEffect(
    () => { when(playStatus === 'PLAYING', whenPresent, onPlay) },
    [onPlay, playStatus],
  )

  useEffect(() => {
    updateUrl(url)
  }, [updateUrl, url])

  useEffectOnce(() => {
    if (autoplay) {
      setTimeout(() => {
        setPlayStatus('PLAYING')
      })
    }
  })

  if (!url) return null

  const playerProps = {
    float,
    contentWrappingAllowed,
  }

  return minimal
    ? <MinimalPlayer {...playerProps} />
    : (
      <RegularPlayer
        {...playerProps}
        narrow={narrow}
      />
    )
}

AudioPlayer.propTypes = {
  autoplay: PropTypes.bool,
  contentWrappingAllowed: PropTypes.bool,
  float: floatTypesShape,
  minimal: PropTypes.bool,
  narrow: PropTypes.bool,
  onPlay: PropTypes.func,
  url: PropTypes.string,
}

const withAudioProvider = WrappedComponent => props => (
  <AudioProvider>
    <WrappedComponent {...props} />
  </AudioProvider>
)

const WiredAudioPlayer = withAudioProvider(AudioPlayer)

export default WiredAudioPlayer
