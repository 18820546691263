import { Routes } from 'react-router-dom'
import AssignmentReport from 'hss/views/Classroom/Assignments/Report'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import AssignmentsList from 'hss/views/Classroom/Assignments/List'
import DistrictsList from 'hss/views/Classroom/Districts/List'
import DistrictMembers from 'hss/views/Classroom/Districts/Report'
import GroupsList from 'hss/views/Classroom/Groups/List'
import GroupMembers from 'hss/views/Classroom/Groups/Report'
import SchoolsList from 'hss/views/Classroom/Schools/List'
import StudentsList from 'hss/views/Classroom/Students/List'
import StudentReport from 'hss/views/Classroom/Students/Report'
import { configToRoutes } from 'routing/TraverseRoute'
import Grades from 'hss/views/Classroom/Grades'
import Gradebook from 'hss/views/Classroom/Grades/Gradebook'
import { ABILITY_ADMINISTRATION_INTERFACE, ABILITY_ASSIGNMENT_CREATION } from 'core/consts'
import Route404 from '../Route404'

const config = {
  assignments: {
    abilityFlags: ABILITY_ASSIGNMENT_CREATION,
    element: <AssignmentsList />,
    path: 'assignments',
  },

  assignment: {
    abilityFlags: ABILITY_ASSIGNMENT_CREATION,
    element: <AssignmentReport />,
    path: 'assignments/:assignmentId',
  },

  district: {
    abilityFlags: ABILITY_ADMINISTRATION_INTERFACE,
    element: <DistrictMembers />,
    path: 'districts/:districtId',
  },

  districts: {
    abilityFlags: ABILITY_ADMINISTRATION_INTERFACE,
    element: <DistrictsList />,
    path: 'districts',
  },

  grades: {
    element: <Grades />,
    path: 'grades',
  },

  groups: {
    abilityFlags: ABILITY_ASSIGNMENT_CREATION,
    element: <GroupsList />,
    path: 'groups',
  },

  groupGradebook: {
    element: <Gradebook />,
    path: 'groups/:groupId/grades',
  },

  groupMembers: {
    abilityFlags: ABILITY_ASSIGNMENT_CREATION,
    element: <GroupMembers />,
    path: 'groups/:groupId',
  },

  schools: {
    abilityFlags: ABILITY_ADMINISTRATION_INTERFACE,
    element: <SchoolsList />,
    path: 'schools',
  },

  students: {
    abilityFlags: ABILITY_ASSIGNMENT_CREATION,
    element: <StudentsList />,
    path: 'students',
  },

  student: {
    abilityFlags: ABILITY_ASSIGNMENT_CREATION,
    element: <StudentReport />,
    path: 'students/:studentId',
  },

  studentGradebook: {
    element: <Gradebook />,
    path: 'students/:studentId/grades',
  },

  404: {
    path: '*',
    element: <Route404 collectionName="Classroom" />,
  },
}

const routes = configToRoutes(config)

const ClassroomRoutes = () => (
  <ErrorBoundary moduleName="ClassroomRoutes">

    <Routes>

      {routes}

    </Routes>

  </ErrorBoundary>
)

export default ClassroomRoutes
