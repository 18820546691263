/* eslint-disable no-promise-executor-return */
/* eslint-disable no-use-before-define */

import { Either } from 'monet'
import { generateId as sharedGenerateId } from 'shared/utils'

export const binary = fn => (a, b) => fn(a, b)

export const curry = (fn, arity = fn.length, ...args) => arity <= args.length
  ? fn(...args)
  : curry.bind(null, fn, arity, ...args)

export const curryRight = (fn, ...partials) => (...args) => fn(...args, ...partials)

export const debounce = (wait, callback) => {
  let timeout
  return (...args) => {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      callback.apply(context, args)
    }, wait)
  }
}

export const eitherToPromise = either => new Promise((resolve, reject) => either.cata(reject, resolve))

export const fallbackTo = (fallback, allowFalsy) => value => allowFalsy
  ? value || fallback
  : isDefined(value) ? value : fallback

export const generateId = sharedGenerateId

export const identity = f => f

export const isDefined = item => item !== undefined && item !== null && item !== Math.NaN

// _isMockFunction is a property added by jest to mock functions, which are not instanceof Function
// eslint-disable-next-line no-underscore-dangle
export const isFunction = f => (f instanceof Function) || !!f?._isMockFunction

export const matches = (fieldName, value) => (obj = {}) => obj[fieldName] === value

export const matchesOneOf = (fieldName, values) => obj => Array.isArray(values)
  ? values.includes(obj[fieldName])
  : false

/* istanbul ignore next line */
export const noop = () => undefined

export const not = value => !value

const pipeTwo = (fn1, fn2) => (...args) => fn2(fn1(...args))
export const pipe = (...fns) => fns.reduce(pipeTwo)

export const promiseToEither = async promise => promise //
  .then(Either.Right)
  .catch(Either.Left)

export const takeX = idx => (...args) => args?.[idx]

export const takeSecond = takeX(1)

export const throttle = (wait, callback) => {
  let waiting = false
  return (...args) => {
    if (!waiting) {
      waiting = true
      callback.apply(this, args)
      setTimeout(() => { waiting = false }, wait)
    }
  }
}

export const toggleBetween = (value, option1, option2) => value === option1 ? option2 : option1

export const unary = fn => arg => fn(arg)

export const isUndefined = pipe(isDefined, not)

export const unless = (predicate, fn, ...args) => {
  if (!predicate) fn(...args)
}

export const when = (predicate, fn, ...args) => predicate ? fn?.(...args) : undefined

export const whenPresent = (fn, ...args) => when(isFunction(fn), fn, ...args)

// export const Wire = ({ children, ...rest }) => children(rest)
