const overrides = ({ palette }) => ({
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: palette.background.default,
        boxShadow: 'none',
        color: palette.text.primary,
      },
    },
  },
})

export default overrides
