import DeleteIcon from '@mui/icons-material/DeleteForever'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { compose } from 'redux'
import { get, pick } from 'fp/objects'
import { isDefined } from 'fp/utils'
import { isNotEmptyString } from 'fp/strings'
import TextField from '../textInputs/TextField'
import AdvancedList, { itemRendererProps } from './AdvancedList'

const commonProps = ['margin', 'minRows', 'multiline', 'name', 'value', 'variant']

const ItemRenderer = forwardRef(({
  allowDeletion,
  disabled,
  index,
  onChange,
  removeItem,
  ...rest
}, ref) => (
  <TextField
    {...{ disabled, ref, ...pick(commonProps)(rest) }}
    InputProps={allowDeletion ? {
      endAdornment: (
        <InputAdornment position="end">
          <IconButton
            disabled={disabled}
            onClick={() => removeItem(index)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </InputAdornment>),
    } : undefined}
    onChange={({ target }) => onChange(target.value)}
  />
))
ItemRenderer.propTypes = itemRendererProps

const filterOutEmptyString = compose(
  isNotEmptyString,
  get('label'),
)

const StringList = (props) => {
  const { addButtonLabel = 'Add Item', maximumItems, minimumItems } = props
  const rules = {
    validate: ({ value }) => {
      const numberOfFilledItems = value.filter(filterOutEmptyString).length

      return isDefined(minimumItems) && numberOfFilledItems < minimumItems
        ? `A minimum of ${minimumItems} items are required.`
        : true
    },
  }

  return (
    <AdvancedList {...{ ItemRenderer, rules, ...props }}>
      {({ addItem, disabled, items }) => (
        <Button
          disabled={Boolean(disabled) || items.length >= maximumItems}
          onClick={() => addItem({ label: '' })}
          style={{ marginTop: '1.5rem' }}
        >
          {addButtonLabel}
        </Button>

      )}
    </AdvancedList>
  )
}

StringList.propTypes = {
  addButtonLabel: PropTypes.string,
  maximumItems: PropTypes.number,
  minimumItems: PropTypes.number,
}

export default StringList
