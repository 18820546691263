/**
 * NOTE:
 * This component assumes there will be two and only two values.
 *
 * If ever we run into a need to have three or more values on a single slider, then
 * this will need to be refactored (I highly doubt such a need will ever arise).
 */
import { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import cl from 'classnames'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'
import { debounce as debounceIt, isDefined } from 'fp/utils'
import { assertRange } from 'fp/numbers'
import Slider from './Slider'

const Container = styled(
  Box,
  { name: 'sliders-RangeSlider' },
)(({ theme: { mixins: { rem, transition }, palette } }) => ({
  '.slider': {
    'span:nth-of-type(3)>.MuiSlider-valueLabel': {
      left: 'calc(-50% - 12px)',
      ...transition('left'),
    },
    'span:nth-of-type(4)>.MuiSlider-valueLabel': {
      left: 'calc(-50% - 10px)',
      ...transition('left'),
    },
  },
  '.withinLeftPercentage': {
    'span:nth-of-type(3)>.MuiSlider-valueLabel': {
      right: 'unset',
      left: 0,
      textAlign: 'left',
      'span:first-of-type': {
        width: 'unset',
      },
    },
  },
  '.withinRightPercentage': {
    'span:nth-of-type(4)>.MuiSlider-valueLabel': {
      right: 0,
      left: 'unset',
    },
  },
  '.trackLabels': {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    fontSize: rem(1.3),
    color: palette.text.secondary,
    position: 'relative',
    top: -6,
  },
  '.leftTrackLabelHidden': { visibility: 'hidden' },
  '.rightTrackLabelHidden': { visibility: 'hidden' },
}))

const RangeSlider = (props) => {
  const {
    className,
    debounce = false,
    fenceMax,
    fenceMin,
    fixedLeft = false,
    getMaxLabel,
    getMinLabel,
    leftCenteredAfterPercentage = 10,
    leftTrackLabelAfterPercentage = NaN,
    max = 100,
    min = 0,
    onChange,
    readOnly = false,
    rightCenteredAfterPercentage = 10,
    rightTrackLabelAfterPercentage = NaN,
    value: originalValue,
    valueLabelFormat,
    ...rest
  } = props

  const [value, setValue] = useStateWithDynamicDefault(originalValue)
  const [valueWithinLeftPercentage, setValueWithinLeftPercentage] = useState(false)
  const [valueWithinRightPercentage, setValueWithinRightPercentage] = useState(false)
  const [trackLabelWithinLeftPercentage, setTrackLabelWithinLeftPercentage] = useState(false)
  const [trackLabelWithinRightPercentage, setTrackLabelWithinRightPercentage] = useState(false)

  const assertNewValue = useCallback(([begin, end]) => {
    const v = [
      isDefined(fenceMin) ? assertRange(begin, fenceMin, max) : begin,
      isDefined(fenceMax) ? assertRange(end, min, fenceMax) : end,
    ]

    return fixedLeft
      ? [value[0], v[1]]
      : v
  }, [fenceMax, fenceMin, fixedLeft, max, min, value])

  const assertChange = useCallback((v) => {
    onChange(assertNewValue(v))
  }, [assertNewValue, onChange])

  const debouncedOnChange = useMemo(() => debounceIt(250, assertChange), [assertChange])

  useEffect(() => {
    const len = max - min
    const [vL, vR] = value
    const posL = vL - min
    const posR = max - vR
    const percentL = (posL / len) * 100
    const percentR = (posR / len) * 100
    setValueWithinLeftPercentage(percentL <= leftCenteredAfterPercentage)
    setValueWithinRightPercentage(percentR <= rightCenteredAfterPercentage)
    setTrackLabelWithinLeftPercentage(fixedLeft
      ? (100 - percentR) <= leftTrackLabelAfterPercentage
      : percentL <= leftTrackLabelAfterPercentage)
    setTrackLabelWithinRightPercentage(percentR <= rightTrackLabelAfterPercentage)
  }, [
    fixedLeft,
    leftCenteredAfterPercentage,
    leftTrackLabelAfterPercentage,
    max,
    min,
    rightCenteredAfterPercentage,
    rightTrackLabelAfterPercentage,
    value,
  ])

  const trackLabel = useCallback(
    (...args) => isDefined(valueLabelFormat) && !Number.isNaN(leftTrackLabelAfterPercentage)
      ? valueLabelFormat(...args)
      : '',
    [leftTrackLabelAfterPercentage, valueLabelFormat],
  )

  const thumbLabel = useCallback((val, idx) => {
    const otherVal = value[1 - idx]
    const diff = Math.abs(val - otherVal)
    const label1 = valueLabelFormat(val)
    const label2 = valueLabelFormat(otherVal)

    return diff === 0
      ? idx === 0 ? label1 : ''
      : diff <= 10
        ? idx === 0 ? `${label1} - ${label2}` : ''
        : label1
  }, [value, valueLabelFormat])

  const handleChange = useCallback((_, v) => {
    assertChange(v)
  }, [assertChange])

  return (
    <Container>
      <Slider
        aria-labelledby="range-slider"
        className={cl(className, {
          slider: true,
          withinLeftPercentage: valueWithinLeftPercentage,
          withinRightPercentage: valueWithinRightPercentage,
        })}
        max={max}
        min={min}
        onChange={(_, v) => {
          if (!readOnly) {
            setValue(assertNewValue(v))
            if (debounce) {
              debouncedOnChange(v)
            }
          }
        }}
        onChangeCommitted={handleChange}
        readOnly={readOnly}
        value={value}
        valueLabelFormat={thumbLabel}
        {...rest}
      />
      <div className="trackLabels">
        <span className={cl({ leftTrackLabelHidden: trackLabelWithinLeftPercentage })}>
          {trackLabel(min, 0)}
        </span>
        <span className={cl({ rightTrackLabelHidden: trackLabelWithinRightPercentage })}>
          {trackLabel(max, 1)}
        </span>
      </div>
    </Container>
  )
}

RangeSlider.propTypes = {
  debounce: PropTypes.bool,
  fenceMax: PropTypes.number,
  fenceMin: PropTypes.number,
  fixedLeft: PropTypes.bool,
  getMaxLabel: PropTypes.func,
  getMinLabel: PropTypes.func,
  leftCenteredAfterPercentage: PropTypes.number,
  leftTrackLabelAfterPercentage: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  rightCenteredAfterPercentage: PropTypes.number,
  rightTrackLabelAfterPercentage: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  valueLabelFormat: PropTypes.func,
}

export default RangeSlider
