import { set } from 'fp/objects'
import actionTypes from './actionTypes'
import { createReducer } from './utils'

export const SETTING_KEY_CONTENT_VIEWER_EXIT = 'content-viewer-exit'
export const SETTING_KEY_CONTENT_VIEWER_LAST_VIEWED = 'content-viewer-last-viewed'

export const localSettingsActions = {
  init: settings => ({ type: actionTypes.LOCAL_SETTINGS_INIT, settings }),
  set: (rootKey, key, value) => ({ type: actionTypes.LOCAL_SETTINGS_SET, rootKey, key, value }),
}

const handleInit = (state, { settings }) => ({ ...state, ...settings })

const handleSet = (state, { rootKey, key, value }) => set(`${rootKey}.${key}`, value)(state)

const localSettings = createReducer(
  {},
  {
    [actionTypes.LOCAL_SETTINGS_INIT]: handleInit,
    [actionTypes.LOCAL_SETTINGS_SET]: handleSet,
  },
)

export default localSettings
