import { produce } from 'immer'
import { set } from 'fp/objects'
import { generateId } from '../../fp/utils'
import { createReducer } from './utils'
import actionTypes from './actionTypes'

/**
 * TODO refactor this file (and related files) to be known as 'alerts' and remove 'notifications' from the store
 * 'Notifications' here was temp code; the content known as 'notifications' is now stored in userAlerts
 * (to match api endpoint naming).
 */
export const initialState = {
  alerts: [],
  notifications: [],
}

export const actions = {
  addAlert: ({ key, message, options }) => ({
    type: actionTypes.ALERT_ADD,
    key: key || generateId(),
    message,
    options,
  }),

  removeAlert: ({ key }) => ({ type: actionTypes.ALERT_REMOVE, key }),

  removeAllAlerts: () => ({ type: actionTypes.ALERT_REMOVE_ALL }),
}

const handleAddAlert = produce((draft, { key, message, options }) => {
  draft.alerts.push({ key, message, options })
})

const handleRemoveAlert = produce((draft, { key }) => {
  draft.alerts = draft.alerts.map(alert => alert.key === key
    ? set('dismissed', true)(alert)
    : alert)
})

const handleRemoveAll = produce((draft) => {
  draft.alerts = draft.alerts.map(set('dismissed', true))
})

const notifications = createReducer(initialState, {
  [actionTypes.ALERT_ADD]: handleAddAlert,
  [actionTypes.ALERT_REMOVE]: handleRemoveAlert,
  [actionTypes.ALERT_REMOVE_ALL]: handleRemoveAll,
})

export default notifications
