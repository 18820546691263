import { ContentBlock, EditorState, RichUtils, genKey } from 'draft-js'
// eslint-disable-next-line import/no-extraneous-dependencies
import { List } from 'immutable'
import {
  HANDLED,
  NOT_HANDLED,
  atEndOfBlock as isAtEndOfBlock,
  atStartOfBlock as isAtStartOfBlock,
} from '@studysync/draft-js-modifiers'
import { equals } from 'fp/objects'

/**
 * Prior art:
 * https://github.com/icelab/draft-js-block-breakout-plugin
 */
/* istanbul ignore file */

const breakoutBlockType = 'paragraph'

const breakoutBlocks = [
  'header-one',
  'header-two',
  'header-three',
  'header-four',
  'header-five',
  'header-six',
]
const doubleBreakoutBlocks = [
  'blockquote',
  'unordered-list-item',
  'ordered-list-item',
  'code-block',
]

const handleReturn = (/* features */) => ({ setEditorState }) => (event, editorState) => {
  const currentBlockType = RichUtils.getCurrentBlockType(editorState)
  const isSingleBreakoutBlock = breakoutBlocks.indexOf(currentBlockType) > -1
  const isDoubleBreakoutBlock = doubleBreakoutBlocks.indexOf(currentBlockType) > -1

  // Does the current block type match a type we care about?
  if (isSingleBreakoutBlock || isDoubleBreakoutBlock) {
    const selection = editorState.getSelection()

    // Check if the selection is collapsed
    if (selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent()
      const currentBlock = contentState.getBlockForKey(selection.getEndKey())
      const atEndOfBlock = isAtEndOfBlock(editorState)
      const atStartOfBlock = isAtStartOfBlock(editorState)

      // Check we’re at the start/end of the current block
      if ((atEndOfBlock && isSingleBreakoutBlock)
        || (atStartOfBlock && isSingleBreakoutBlock)
        || (atStartOfBlock && !currentBlock.getLength())
      ) {
        const emptyBlockKey = genKey()
        const emptyBlock = new ContentBlock({
          key: emptyBlockKey,
          text: '',
          type: breakoutBlockType,
          characterList: List(),
          depth: 0,
        })
        const blockMap = contentState.getBlockMap()
        // Split the blocks
        const blocksBefore = blockMap.toSeq().takeUntil(equals(currentBlock))

        const blocksAfter = blockMap.toSeq().skipUntil(equals(currentBlock)).rest()

        let augmentedBlocks
        let focusKey
        // Choose which order to apply the augmented blocks in depending
        // on whether we’re at the start or the end
        if (atEndOfBlock) {
          if (isDoubleBreakoutBlock) {
            // Discard Current as it was blank
            augmentedBlocks = [
              [emptyBlockKey, emptyBlock],
            ]
          } else {
            // Current first, empty block afterwards
            augmentedBlocks = [
              [currentBlock.getKey(), currentBlock],
              [emptyBlockKey, emptyBlock],
            ]
          }
          focusKey = emptyBlockKey
        } else {
          // Empty first, current block afterwards
          augmentedBlocks = [
            [emptyBlockKey, emptyBlock],
            [currentBlock.getKey(), currentBlock],
          ]
          focusKey = currentBlock.getKey()
        }
        // Join back together with the current + new block
        const newBlocks = blocksBefore.concat(augmentedBlocks, blocksAfter).toOrderedMap()
        const newContentState = contentState.merge({
          blockMap: newBlocks,
          selectionBefore: selection,
          selectionAfter: selection.merge({
            anchorKey: focusKey,
            anchorOffset: 0,
            focusKey,
            focusOffset: 0,
            isBackward: false,
          }),
        })
        // Set the state
        setEditorState(EditorState.push(editorState, newContentState, 'split-block'))

        return HANDLED
      }
    }
  }
  return NOT_HANDLED
}
const handleKeyCommand = (/* features */) => ({ toggleBlockType }) => (command, editorState) => {
  if (command === 'backspace') {
    const currentBlockType = RichUtils.getCurrentBlockType(editorState)
    const blocks = editorState.getCurrentContent().getBlocksAsArray()

    // if there is only one block and it is empty, then we want to remove the block style
    if (blocks.length === 1
       && doubleBreakoutBlocks.includes(currentBlockType)
       && blocks[0].getText() === '') {
      toggleBlockType('unstyled')()
      return HANDLED
    }
    return NOT_HANDLED
  }
  return NOT_HANDLED
}
const blockBreakoutPlugin = {
  handleReturn,
  handleKeyCommand,
}

export default blockBreakoutPlugin
