import { createElement, forwardRef, useId } from 'react'
import PropTypes from 'prop-types'
import MuiTextField from '@mui/material/TextField'

const TextField = forwardRef(({ id, name, ...rest }, inputRef) => {
  const generatedId = useId()

  return createElement(MuiTextField, {
    fullWidth: true,
    id: id || generatedId,
    name,
    inputRef,
    ...rest,
  })
})

TextField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export default TextField
