import withFormControl from 'common/formControls/selects/ConnectedMultiSelect/withFormControl'
import withProps from 'hoc/withProps'
import ConnectedStandardsPicker from './ConnectedStandardsPicker'

const StandardsPicker = withFormControl(ConnectedStandardsPicker)({
  helperText: 'Type short code for standard item: (e.g. CA.RL.6.1, ELD.PI.K.12.a.Em)',
  preserveSpaces: false,
  label: 'Standards',
  labelField: 'shortCode',
  name: 'standards',
  placeholder: 'Search for standards',
})

export const InstructionStandardsPicker = withProps(StandardsPicker, {
  label: 'Instructional Standards',
  name: 'instructionStandards',
  valueField: 'instructionStandardIds',
})

export const ApplicationStandardsPicker = withProps(StandardsPicker, {
  label: 'Practice / Application Standards',
  name: 'applicationStandards',
  valueField: 'applicationStandardIds',
})

export const RubricStandardsPicker = withProps(StandardsPicker, {
  helperText: 'Type short code for standard item: (e.g. RL.6.1)',
  label: 'Rating Standards',
  valueField: 'standardIds',
})
