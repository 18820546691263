import { useSelector } from 'react-redux'
import { createSelector } from '@comfy/redux-selectors'
import { actions } from 'reducers/session'
import { stateSession } from 'selectors/index'

const mapState = createSelector(
  stateSession,
  session => ({
    actions,
    session,
  }),
)

const useSession = () => useSelector(mapState)

export default useSession
