const overrides = ({ mixins: { rem } }) => ({
  MuiTablePagination: {
    styleOverrides: {
      root: {
        '.MuiToolbar-root': {
          alignItems: 'baseline',
          justifyContent: 'flex-end',
          p: { marginBottom: 0 },
        },
        '.MuiTablePagination-spacer': {
          display: 'none',
        },

        '.MuiTablePagination-select': {
          marginRight: rem(2),
        },
      },
    },
    variants: [
      {
        props: { variant: 'dialog' },
        style: {
          '.MuiToolbar-root': {
            justifyContent: 'center',
          },
        },
      },
    ],
  },
})

export default overrides
