import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import useBlockInsight from './utils/useBlockInsight'

const CurrentBlockInsight = ({ editorState }) => {
  const blockType = useBlockInsight(editorState)

  return (
    <Box
      sx={{
        float: 'right',
        color: 'grey.2',
      }}
    >
      {blockType}
    </Box>
  )
}

CurrentBlockInsight.propTypes = {
  editorState: PropTypes.object.isRequired,
}

export default CurrentBlockInsight
