const overrides = ({ mixins: { important }, palette, shadows }) => ({
  MuiToolbar: {
    styleOverrides: {
      root: {
        backgroundColor: palette.background.default,
        boxShadow: 'none',
      },
    },

    variants: [
      {
        props: { variant: 'appbar' },
        style: {
          display: 'flex',
          flexFlow: 'row nowrap',
          height: 80,
          justifyContent: 'space-between',
          paddingLeft: important('var(--containedPaddingLeft)'),
          paddingRight: important('var(--containedPaddingRight)'),
        },
      },
      {
        props: { variant: 'home' },
        style: {
          boxShadow: shadows[3],
          height: 72,
        },
      },
      {
        props: { variant: 'subsection' },
        style: {
          paddingLeft: important(0),
          paddingRight: important(0),
        },
      },
    ],
  },
})

export default overrides
