const overrides = ({ mixins: { important, transition }, palette, shadows }) => ({
  MuiFilledInput: {
    defaultProps: {
      disableUnderline: true,
      margin: 'dense',
    },

    styleOverrides: {
      root: {
        backgroundColor: palette.common.white,
        borderRadius: 0,
        boxShadow: `${shadows.borderInsetButton} ${palette.grey[4]}`,
        ...transition(),
        fieldset: { borderColor: important(palette.grey[4]) },
        '&:not(.Mui-disabled):hover, &:focus-within': {
          boxShadow: shadows.borderInset,
          backgroundColor: palette.common.white,
        },
        '&.Mui-focused fieldset': {
          border: 'none',
          boxShadow: shadows.borderInset,
        },

        '&.Mui-disabled': {
          backgroundColor: palette.common.white,
          borderStyle: 'dashed',
          borderColor: palette.grey[5],

          '.MuiInputAdornment-root': {
            color: palette.grey[3],
          },
        },

        '&.Mui-error': {
          borderColor: palette.error.main,
          backgroundColor: palette.error.light,
        },

        '.MuiInputAdornment-root svg': {
          position: 'relative',
          top: -8,
        },
      },

      input: {
        borderRadius: 0,
        padding: 12,
        '&.MuiInputBase-inputAdornedStart': {
          paddingLeft: 0,
          position: 'relative',
          left: -2,
        },
      },
    },

    variants: [],
  },
})

export default overrides
