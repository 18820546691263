import PropTypes from 'prop-types'
import { useContext, useMemo } from 'react'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Backward from 'hss/images/controls/audio/backward.svg'
import Forward from 'hss/images/controls/audio/forward.svg'
import { audioContext } from './AudioContextProvider'

const SkipButton = ({ forwards = false }) => {
  const { goBackwards, goForwards, loaded } = useContext(audioContext)

  const label = `Go ${forwards ? 'forwards' : 'backwards'} 15 seconds`

  const onClick = forwards
    ? goForwards
    : goBackwards

  return useMemo(() => (
    <Tooltip title={loaded ? label : ''}>
      <IconButton
        aria-label={label}
        disabled={!loaded}
        onClick={onClick}
        size="small"
      >
        {forwards ? <Forward /> : <Backward />}
      </IconButton>
    </Tooltip>
  ), [forwards, label, loaded, onClick])
}

SkipButton.propTypes = {
  forwards: PropTypes.bool,
}

export default SkipButton
