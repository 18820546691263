import { compose } from 'redux'
import { success } from 'sagas/utils'
import { alter, assert } from '../search/squery'
import actionTypes from './actionTypes'
import {
  createReducer,
  handleFetchListSuccess,
  listReducerInitialState,
  updateLoaded,
} from './utils'

export const actions = {
  fetchSchools: ({ queryParams }) => ({
    type: actionTypes.SCHOOL_FETCH_LIST,
    queryParams,
  }),

  fetchSchoolsByDistrict: ({ districtId }) => ({
    type: actionTypes.SCHOOL_FETCH_LIST,
    queryParams: {
      search: compose(
        alter.set.where('districtId').is(districtId),
        alter.set.limit(999),
      )(assert()),
    },
  }),

  fetchSchoolById: ({ schoolId }) => ({
    schoolId,
    type: actionTypes.SCHOOL_FETCH,
  }),

  saveSchool: ({ payload }) => ({
    type: actionTypes.SCHOOL_SAVE,
    payload,
  }),
}

const handleFetchItemSuccess = (state, { response }) => updateLoaded(state, response)

const schools = createReducer(
  listReducerInitialState(),
  {
    [success(actionTypes.SCHOOL_FETCH_LIST)]: handleFetchListSuccess,
    [success(actionTypes.SCHOOL_FETCH)]: handleFetchItemSuccess,
    [success(actionTypes.SCHOOL_SAVE)]: handleFetchItemSuccess,
  },
)

export default schools
