import Fab from '@mui/material/Fab'
import Tooltip from '@mui/material/Tooltip'
import PropTypes from 'prop-types'
import { Trash } from 'react-feather'
import withConfirm from 'hoc/withConfirm'

const Button = withConfirm(Fab)

const RemoveAsset = ({ onClick }) => (
  <Tooltip title="Remove asset">
    <Button
      color="error"
      confirmationMessage={(
        <>
          Are you sure you want to remove this asset?<br /><br />
          You will need to re-upload it should it ever need to be restored.
        </>
      )}
      confirmationTitle="Remove?"
      onClick={onClick}
      variant="xs"
    >
      <Trash />
    </Button>
  </Tooltip>
)

RemoveAsset.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default RemoveAsset
