import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import ILLogo from 'hss/images/home/il-logo.png'
import FooterLinks from 'hss/sections/Home/FooterLinks'

const StyledStack = styled('div')(({ theme: { breakpoints, mixins: { rem } } }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginTop: rem(6),
  textAlign: 'center',
  div: {
    alignItems: 'flex-end',
    [breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  [breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    img: {
      marginBottom: 12,
    },
  },

}))

const Footer = () => (
  <StyledStack>
    <div>
      <img
        alt="Imagine Learning Logo"
        src={ILLogo}
        style={{
          maxWidth: '100%',
          width: 100,
          alignItems: 'center',
          display: 'flex',
        }}
      />
    </div>

    <Stack maxWidth="100%">
      Copyright © {new Date().getFullYear()} Imagine Learning, LLC.
      <FooterLinks />
    </Stack>
  </StyledStack>
)

Footer.propTypes = {}

export default Footer
