import { compose } from 'redux'
import { BarChart2, BookOpen, Grid, Layers, Tool } from 'react-feather'
import BookOpenFilled from 'hss/images/nav/book-open-filled.svg'
import GridFilled from 'hss/images/nav/grid-filled.svg'
import BarChart2Filled from 'hss/images/nav/bar-chart2-filled.svg'
import LayersFilled from 'hss/images/nav/layers-filled.svg'
import ToolFilled from 'hss/images/nav/tool-filled.svg'
import {
  assessmentUrl,
  classroomUrl,
  curriculumUrl,
  libraryUrl,
  studentAssignmentsUrl,
  studentBinderUrl,
} from 'routing/consts'
import { get } from 'fp/objects'
import {
  ABILITY_ADMINISTRATION_INTERFACE,
  ABILITY_ASSESSMENT_INTERFACE,
  ABILITY_ASSIGNMENT_CREATION,
  ABILITY_CURRICULUM_ACCESS,
  ABILITY_LIBRARY_ACCESS,
  ABILITY_STUDENT_INTERFACE,
} from 'core/consts'
import { createAbilityChecker } from './userAbility'
import { createSelector } from '.'

const navItems = [
  {
    abilityFlags: ABILITY_STUDENT_INTERFACE,
    Icon: Grid,
    IconActive: GridFilled,
    items: [],
    label: 'Assignments',
    to: `${studentAssignmentsUrl}`,
  },

  {
    abilityFlags: ABILITY_STUDENT_INTERFACE,
    Icon: Grid,
    IconActive: GridFilled,
    items: [],
    label: 'Binder',
    to: `${studentBinderUrl}`,
  },

  {
    abilityFlags: ABILITY_CURRICULUM_ACCESS,
    Icon: BookOpen,
    IconActive: BookOpenFilled,
    items: [],
    label: 'Curriculum',
    to: curriculumUrl,
  },

  {
    Icon: BarChart2,
    IconActive: BarChart2Filled,
    items: [
      {
        abilityFlags: [
          ABILITY_ASSESSMENT_INTERFACE,
          ABILITY_ASSIGNMENT_CREATION,
        ],
        label: 'Assessments',
        to: `${assessmentUrl}/assessments`,
      },
      {
        abilityFlags: [
          ABILITY_ASSESSMENT_INTERFACE,
          ABILITY_ASSIGNMENT_CREATION,
        ],
        label: 'Documents',
        to: `${assessmentUrl}/passages`,
      },
      {
        abilityFlags: [
          ABILITY_ASSESSMENT_INTERFACE,
          ABILITY_ASSIGNMENT_CREATION,
        ],
        label: 'Questions',
        to: `${assessmentUrl}/questions`,
      },
    ],
    abilityFlags: [
      ABILITY_ASSESSMENT_INTERFACE,
      ABILITY_ASSIGNMENT_CREATION,
    ],
    label: 'Assess',
    to: assessmentUrl,
  },

  {
    Icon: Grid,
    IconActive: GridFilled,
    items: [
      {
        abilityFlags: ABILITY_ASSIGNMENT_CREATION,
        label: 'Assignments',
        to: `${classroomUrl}/assignments`,
      },
      // TODO: Put back when functional (postMVP)
      //   abilityFlags: [ABILITY_ASSIGNMENT_CREATION],
      // {
      //   label: 'Grades',
      //   to: `${classroomUrl}/grades`,
      // },
      {
        label: 'Groups',
        to: `${classroomUrl}/groups`,
        abilityFlags: ABILITY_ASSIGNMENT_CREATION,
      },
      {
        abilityFlags: ABILITY_ASSIGNMENT_CREATION,
        label: 'Students',
        to: `${classroomUrl}/students`,
      },
      {
        abilityFlags: ABILITY_ADMINISTRATION_INTERFACE,
        label: 'Districts',
        to: `${classroomUrl}/districts`,
      },
      {
        abilityFlags: ABILITY_ADMINISTRATION_INTERFACE,
        label: 'Schools',
        to: `${classroomUrl}/schools`,
      },
    ],
    abilityFlags: [
      ABILITY_ADMINISTRATION_INTERFACE,
      ABILITY_ASSIGNMENT_CREATION,
    ],
    label: 'Classroom',
    to: classroomUrl,
  },

  {
    Icon: Layers,
    IconActive: LayersFilled,
    items: [],
    label: 'Library',
    to: `${libraryUrl}`,
    abilityFlags: ABILITY_LIBRARY_ACCESS,
  },

  {
    href: '/api/admin',
    Icon: Tool,
    IconActive: ToolFilled,
    items: [],
    label: 'Admin',
    abilityFlags: ABILITY_ADMINISTRATION_INTERFACE,
  },
]

const getNavItems = createSelector('getNavItems')(
  createAbilityChecker,
  (has) => {
    const allowed = compose(has, get('abilityFlags'))

    return navItems.map(item => allowed(item)
      ? {
        ...item,
        items: item.items
          .filter(allowed)
          .filter(Boolean),
      }
      : null)

      .filter(Boolean)
  },
)

export default getNavItems
