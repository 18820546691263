import Drawer from '@mui/material/Drawer'
import { useContext } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { sidebarContext } from 'routing/shells/SidebarProvider'
import Centered from 'common/layout/Centered'
import useContent from 'hooks/useContent'
import { CONTENT_TYPE_COURSE } from 'core/consts'
import { get } from 'fp/objects'
import Nav from './Nav'
import CollapseButton from './CollapseButton'

const TocSidebar = () => {
  const chapter = useContent({ disableFetch: true })
  const subtitle = get('data.subtitle')(chapter)
  const courseId = get('parent.parent.id')(chapter)
  const { name: courseName } = useContent({ contentId: courseId, contentType: CONTENT_TYPE_COURSE }) || {}
  const { isSidebarShrunk, setSidebarShrunk, sidebarRef, sidebarWidth } = useContext(sidebarContext)

  return (
    <Drawer
      anchor="left"
      data-sidebar="yes"
      data-sidebarstate={isSidebarShrunk ? 'shrunk' : 'full'}
      data-toc="yes"
      onClick={
        isSidebarShrunk
          ? () => setSidebarShrunk(false)
          : undefined
      }
      open
      ref={sidebarRef}
      sx={{
        width: sidebarWidth,
        '> div:first-of-type': {
          width: sidebarWidth,
        },
      }}
      variant="permanent"
    >
      <Typography
        component="div"
        sx={{ fontSize: 14 }}
        variant="body2"
      >

        <nav>

          {isSidebarShrunk
            ? (
              <Centered
                flexDirection="column"
                pt="20px"
              >
                <CollapseButton />
                <Box
                  margin="0"
                  position="absolute"
                  style={{
                    cursor: 'default',
                    transform: 'rotate(-90deg)',
                  }}
                  top="50%"
                  whiteSpace="nowrap"
                >
                  {courseName} {subtitle}
                  <Typography
                    display="inline"
                    ml="10px"
                    variant="toc-title-semibold"
                  >
                    {chapter?.name}
                  </Typography>
                </Box>
              </Centered>
            )
            : <Nav isSidebarShrunk={isSidebarShrunk} />}

        </nav>

      </Typography>
    </Drawer>
  )
}

export default TocSidebar
