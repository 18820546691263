import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import MuiTabList from '@mui/lab/TabList'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { breakpointShape } from 'core/shapes'
import AsDropdown from './AsDropdown'

const TabList = forwardRef((props, ref) => {
  const { contained = false, minSize = 'md', ...rest } = props
  const isSmallScreen = useContainerQuery().down(minSize)

  return isSmallScreen
    ? <AsDropdown {...{ ...props, ref }} />
    : (
      <MuiTabList
        data-contained={contained}
        {...{ ...rest, ref }}
      />
    )
})

TabList.propTypes = {
  contained: PropTypes.bool,
  minSize: breakpointShape,
}

export default TabList
