import Grid from '@mui/material/Grid'
import PropTypes from 'prop-types'
import { Children, cloneElement, useLayoutEffect, useState } from 'react'
import { identity } from 'fp/utils'
import { useContainerQuery } from './ContainerQuery'

const ContainerGrid = (props) => {
  const {
    alignRightWhenSplit = true,
    children,
    leftSize = 6,
    leftWrappedProps,
    rightWrappedProps,
    splitBelow = 'sm',
    sx,
    ...rest
  } = props

  const { down } = useContainerQuery()
  const [size, setSize] = useState(leftSize)
  const [wrapping, setWrapping] = useState(false)

  const newWrap = down(splitBelow)
  useLayoutEffect(() => {
    setWrapping(newWrap)
    setSize(newWrap ? 12 : leftSize)
  }, [leftSize, newWrap, setSize])

  const wrappingProps = [leftWrappedProps, rightWrappedProps]

  const items = Children.map(children, identity)

  if (items.length !== 2) {
    throw new Error(`ContainerGrid must have exactly (2) children.  Found (${items.length})`)
  }

  return (
    <Grid
      container
      {...rest}
    >
      {items.map((child, idx) => (
        <Grid
          item
          key={idx}
          sx={alignRightWhenSplit && idx === 1 && size !== 12
            ? {
              display: 'flex',
              justifyContent: 'flex-end',
            }
            : null}
          xs={idx === 0 || size === 12 ? size : 12 - size}
        >
          {cloneElement(child, wrapping ? wrappingProps[idx] : null)}
        </Grid>
      ))}
    </Grid>
  )
}

ContainerGrid.propTypes = {
  alignRightWhenSplit: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  leftSize: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  leftWrappedProps: PropTypes.object,
  rightWrappedProps: PropTypes.object,
  splitBelow: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  sx: PropTypes.object,
}

export default ContainerGrid
