import RadioGroup from '@mui/material/RadioGroup'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { toInt } from 'fp/strings'
import { rem } from 'styling/theming/base/mixins'
import { colorSwatchShape } from '../ColorPicker/ColorSwatch'
import ColorSwatch from './ColorSwatch'

const ColorSwatchPicker = (props) => {
  const { colors = [], onChange, value } = props

  const handleChange = useCallback((e, newValue) => {
    onChange?.(toInt(newValue))
  }, [onChange])

  return (
    <RadioGroup
      onChange={handleChange}
      sx={{
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        margin: rem(2, 0),
        '.MuiFormControlLabel-root': {
          marginRight: '12px',
        },
      }}
      value={value}
    >
      {colors.map(color => (
        <ColorSwatch
          color={color}
          key={color.colorId}
          selected={value === color.colorId}
        />
      ))}
    </RadioGroup>
  )
}

ColorSwatchPicker.propTypes = {
  colors: PropTypes.arrayOf(colorSwatchShape),
  onChange: PropTypes.func,
  value: PropTypes.number.isRequired,
}

export default ColorSwatchPicker
