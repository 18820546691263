import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import {
  Search as SearchIcon,
  XCircle as ClearSearchIcon,
} from 'react-feather'
import { margin } from 'polished'
import { compose } from 'redux'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import withDebounce from 'hoc/withDebounce'
import { noop } from 'fp/utils'
import { get } from 'fp/objects'
import { important } from 'styling/theming/base/mixins'
import { isEmptyString } from 'fp/strings'

const containerStyle = ({ theme: { breakpoints, mixins: { rem }, palette } }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  borderRadius: '9999999px', // large px value displays as pill shape
  ...margin(0, 10),
  width: '100%',
  [breakpoints.up('sm')]: {
    marginLeft: rem(1),
    width: 'auto',
  },
  'input::placeholder': {
    color: important(palette.text.disabled),
  },
  'svg[viewBox="0 0 24 24"]': {
    marginRight: 10,
    marginLeft: 15,
    width: 32,
    height: 32,
  },
})

const ContainerWithPersistentBorder = styled(
  'div',
  { name: 'SearchInput-Container' },
)(({ theme }) => {
  const { mixins: { borderS }, palette, shadows } = theme
  return ({
    ...containerStyle({ theme }),
    ...borderS(palette.border[1]),
    '&:hover, &:focus-within': {
      backgroundColor: 'white',
      boxShadow: shadows.border,
      transition: '250ms',
      borderColor: 'transparent',
    },
  })
})

const ContainerWithFocusBorder = styled(
  'div',
  { name: 'SearchInput-Container' },
)(({ theme }) => {
  const { mixins: { borderS }, palette, transitions } = theme
  return ({
    ...containerStyle({ theme }),
    ...borderS('transparent'),
    '&:focus-within': {
      borderColor: palette.border[0],
    },
    transition: transitions.create(['border-color', 'boxShadow']),
  })
})

const ContainerWithFormBorder = styled(
  'div',
  { name: 'SearchInput-Container' },
)(({ theme }) => {
  const { breakpoints, mixins: { borderS, rem }, palette } = theme
  return ({
    ...containerStyle({ theme }),
    ...borderS(palette.border[1]),
    background: palette.common.white,
    direction: 'rtl',
    borderRadius: 0,
    justifyItems: 'flex-start',
    '> div:first-of-type': {
      flexGrow: 1,
      direction: 'ltr',
    },
    margin: 0,
    input: {
      padding: rem(1.2, 1.5, 1.2, 0),
    },
    [breakpoints.up('sm')]: {
      marginLeft: 0,
      width: 'auto',
    },
  })
})

const Adornment = styled(
  InputAdornment,
  { name: 'textInputs-SearchInput' },
)(({ theme: { mixins: { size }, palette } }) => ({
  button: {
    ...size(40),
    marginRight: 4,
  },
  svg: {
    color: palette.text.disabled,
    ...size(22),
  },
}))

const DebouncedInput = withDebounce()(InputBase)

const Containers = {
  search: ContainerWithPersistentBorder,
  'search-expand': ContainerWithFocusBorder,
  'search-form': ContainerWithFormBorder,
}

const SearchInput = ({
  containerSx = {},
  label,
  onChange = noop,
  placeholder,
  value,
  variant = 'search',
  ...rest
}) => {
  const handleValueChange = compose(
    onChange,
    get('target.value'),
  )

  const Container = Containers[variant]

  const handleClearSearch = () => onChange('')

  return (
    <Container sx={containerSx}>
      <DebouncedInput
        endAdornment={isEmptyString(value)
          ? null
          : (
            <Adornment position="end">
              <IconButton
                onClick={handleClearSearch}
                size="small"
              >
                <ClearSearchIcon />
              </IconButton>
            </Adornment>
          )}
        inputProps={{ 'aria-label': label }}
        onChange={handleValueChange}
        placeholder={placeholder || label}
        startAdornment={<SearchIcon />}
        value={value}
        variant={variant}
        {...rest}
      />
    </Container>
  )
}

SearchInput.propTypes = {
  containerSx: PropTypes.object,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
}

export default SearchInput
