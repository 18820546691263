import { rem } from '../mixins'

const overrides = () => ({
  MuiAvatarGroup: {
    variants: [
      {
        props: { variant: 'small' },
        style: {
          '& .MuiAvatar-root': {
            width: 24,
            height: 24,
            fontSize: rem(1.5),
          },
        },
      },
    ],
  },
})

export default overrides
