import { useSelector } from 'react-redux'
import { contentIsViewableByUser, contentStateIsDraft } from 'selectors/content'
import useAbilityChecker from 'hooks/useAbilityChecker'

const withAbilityCheck = (WrappedComponent, abilityFlags = undefined, content = undefined) => {
  const Enhanced = (props) => {
    const has = useAbilityChecker()

    const viewable = useSelector(contentIsViewableByUser(content || {}))
    const isDraft = useSelector(contentStateIsDraft(content || {}))

    return (!abilityFlags || has(abilityFlags)) && (!content || viewable)
      ? (
        <WrappedComponent
          {...content ? { 'data-draft': isDraft ? 'true' : 'false' } : null}
          {...props}
        />
      )
      : null
  }

  return Enhanced
}

export default withAbilityCheck
