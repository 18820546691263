/**
 * Borrowed from https://www.npmjs.com/package/react-spring-3d-carousel
 * (and HEAVILY modified in order to modernize it and to better fit our needs)
 */

import { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { size } from 'polished'
import Stack from '@mui/material/Stack'
import { assertRange } from 'fp/numbers'
import { element } from 'core/shapes'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'
import { generateId, whenPresent } from 'fp/utils'
import Navigation from './Navigation'
import Slide from './Slide'
import Pagination from './Pagination'

const Wrapper = styled('div')(() => ({
  position: 'relative',
  minHeight: 540,
  overflow: 'hidden',
  ...size('100%'),
}))

const DEFAULT_ANIMATION_CONFIG = { tension: 120, friction: 14 }
const DEFAULT_GO_TO_SLIDE_DELAY = 200

const mod = (a, b) => ((a % b) + b) % b

const getShortestDirection = (from, to, slides) => {
  if (from > to) {
    return (from - to > slides - 1 - from + to) ? 1 : -1
  }
  if (to > from) {
    return (to - from > from + slides - 1 - to) ? -1 : 1
  }
  return 0
}

const Carousel3D = (props) => {
  const {
    animationConfig = DEFAULT_ANIMATION_CONFIG,
    /**
     * `currentSlide` is really the "initial slide".  Don't confuse this with `index`,
     * which is the internal "selected slide".
     *
     * `currentSlide` can be modified externally which is why it's called that and
     * not `initialSlide`.
     */
    currentSlide: initialSlide = 0,
    goToSlideDelay = DEFAULT_GO_TO_SLIDE_DELAY,
    offsetRadius = 2,
    onSlideChanged,
    showNavigation = true,
    slides: originalSlides,
  } = props

  const [index, setIndex] = useState(0)
  const [newSlide, setNewSlide] = useState(false)
  // setCurrentSlide is only used externally (in Navigation)
  const [currentSlide, setCurrentSlide] = useStateWithDynamicDefault(initialSlide)

  const slides = useMemo(
    () => originalSlides.map((slide, idx) => ({
      ...slide,
      key: generateId(),
      index: idx,
    })),
    [originalSlides],
  )

  const modBySlidesLength = useCallback(i => mod(i, slides.length), [slides.length])

  useEffect(() => {
    let goToSlideTimer

    const handleGoToSlide = () => {
      const newSlideIndex = mod(currentSlide, slides.length)

      if (newSlideIndex !== index) {
        const direction = getShortestDirection(index, newSlideIndex, slides.length)
        const isFinished = modBySlidesLength(index + direction) === newSlideIndex

        setIndex(modBySlidesLength(index + direction))
        setNewSlide(isFinished)
        whenPresent(onSlideChanged, newSlideIndex)
      }
    }

    if (newSlide) {
      handleGoToSlide()
    } else if (index !== currentSlide) {
      clearTimeout(goToSlideTimer)
      goToSlideTimer = setTimeout(handleGoToSlide, goToSlideDelay)
    } else {
      clearTimeout(goToSlideTimer)
    }

    return () => { clearTimeout(goToSlideTimer) }
  }, [currentSlide, goToSlideDelay, index, modBySlidesLength, newSlide, onSlideChanged, slides.length])

  const clampedOffsetRadius = useMemo(() => {
    const upperBound = Math.floor((slides.length - 1) / 2)
    return assertRange(offsetRadius, 0, upperBound)
  }, [offsetRadius, slides.length])
  const presentableSlides = useMemo(() => {
    const result = []

    // eslint-disable-next-line no-plusplus
    for (let i = -clampedOffsetRadius; i < 1 + clampedOffsetRadius; i++) {
      result.push(slides[modBySlidesLength(index + i)])
    }

    return result
  }, [clampedOffsetRadius, index, modBySlidesLength, slides])

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
      >
        {Boolean(showNavigation) && (
          <Navigation
            direction="prev"
            setCurrentSlide={setCurrentSlide}
          />
        )}
        <Wrapper>
          {presentableSlides.map((slide, presentableIndex) => (
            <Slide
              animationConfig={animationConfig}
              index={presentableIndex}
              key={slide.key}
              offsetRadius={clampedOffsetRadius}
              // ordinalIndex is the original slide index, not presentableIndex
              ordinalIndex={slide.index}
              setCurrentSlide={setCurrentSlide}
            >
              {slide.content}
            </Slide>
          ))}
        </Wrapper>
        {Boolean(showNavigation) && (
          <Navigation
            direction="next"
            setCurrentSlide={setCurrentSlide}
          />
        )}
      </Stack>
      <Pagination
        currentSlide={index}
        slides={slides}
      />
    </>
  )
}

Carousel3D.propTypes = {
  animationConfig: PropTypes.object,
  currentSlide: PropTypes.number,
  goToSlideDelay: PropTypes.number,
  offsetRadius: PropTypes.number,
  onSlideChanged: PropTypes.func,
  showNavigation: PropTypes.bool,
  slides: PropTypes.arrayOf(PropTypes.shape({
    content: element.isRequired,
  })).isRequired,
}

export default Carousel3D
