/* istanbul ignore file */
/* eslint-disable no-console */
import PropTypes from 'prop-types'
import cl from 'classnames'
import { Section, useLevel } from 'react-headings'
import MuiTypography from '@mui/material/Typography'
import { compose } from 'redux'
import { forwardRef, useContext, useMemo } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import { componentShape, displayShape, muiColorShape, textAlignShape } from 'core/shapes'
import { identity, isDefined, isUndefined } from 'fp/utils'
import { filterKeyedObject, omit } from 'fp/objects'
import { prefix as prefixer, suffix } from 'fp/strings'
import { headlineStyleOffsetContext } from './HeadlineStyleOffset'
import withColorSwatch from './withColorSwatch'

const base = 'tr-headline--'
const prefix = prefixer(base)

export const StyledHeadline = forwardRef((props, ref) => {
  const {
    className,
    contained = false,
    disabled = false,
    dividerBelow = false,
    hasSubtitle = false,
    hr = false,
    light = false,
    mb,
    mt,
    size,
    swatch = false,
    textTransform,
    variant,
    weight,
    ...rest
  } = props

  const { offset } = useContext(headlineStyleOffsetContext) || { offset: 0 }
  const Wrapper = swatch
    ? withColorSwatch(Box, ref)
    : Box

  const { Component, level } = useLevel()

  const builtClassName = useMemo(
    () => {
      const boolOptions = {
        disabled,
        dividerBelow,
        contained,
        hasSubtitle,
        hr,
        swatch,
      }

      const boolOrNumberOptions = {
        mb,
        mt,
      }

      const trueSize = Math.min(6, (size || 0) + offset)

      return cl(
        className,
        'tr-typography',
        {
          [prefix(`textTransform-${textTransform}`)]: isDefined(textTransform),
          [prefix(`weight-${weight}`)]: weight,
          [prefix('weight-light')]: light,
        },
        !variant && {
          [prefix('size-1')]: trueSize === 1,
          [prefix('size-2')]: trueSize === 2,
          [prefix('size-3')]: trueSize === 3,
          [prefix('size-4')]: trueSize === 4,
          [prefix('size-5')]: trueSize === 5,
          [prefix('size-6')]: trueSize === 6,
        },
        Object.keys(filterKeyedObject(boolOptions, identity)).map(prefix),
        Object.keys(filterKeyedObject(boolOrNumberOptions, identity))
          .map(key => typeof boolOrNumberOptions[key] === 'boolean'
            ? key
            : compose(
              suffix(boolOrNumberOptions[key]),
              suffix('-'),
            )(key))
          .map(prefix),
      )
    },
    [
      className,
      contained,
      disabled,
      dividerBelow,
      hasSubtitle,
      hr,
      light,
      mb,
      mt,
      offset,
      size,
      swatch,
      textTransform,
      variant,
      weight,
    ],
  )

  return (
    <Wrapper>
      <MuiTypography
        className={builtClassName}
        component={rest.Component || Component}
        ref={ref}
        variant={variant || `h${Math.min(level, 6)}`}
        {...omit('Component')({ textAlign: 'inherit', ...rest })}
      />
    </Wrapper>
  )
})

StyledHeadline.propTypes = {
  color: muiColorShape,
  contained: PropTypes.bool,
  disabled: PropTypes.bool,
  display: displayShape,
  dividerBelow: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  hasSubtitle: PropTypes.bool,
  hr: PropTypes.bool,
  light: PropTypes.bool,
  mb: PropTypes.number,
  mt: PropTypes.number,
  noWrap: PropTypes.bool,
  size: PropTypes.number,
  swatch: PropTypes.bool,
  textAlign: textAlignShape,
  textTransform: PropTypes.oneOf(['capitalize', 'lowercase', 'none', 'uppercase']),
  variant: PropTypes.oneOf(['advanced-heading', 'eyebrow', 'feature-paragraph-semibold', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'list-header']),
  weight: PropTypes.oneOf([
    100,
    200,
    300,
    400,
    500,
    600,
    700,
  ]),
}

const Headline = forwardRef(({
  children,
  component,
  containerProps,
  title,
  ...rest
}, ref) => {
  const { contained = false } = rest
  if (isUndefined(component) && isUndefined(title)) {
    console.error(
      '%c INVALID PROPS! %c Headline requires either `component` or `title` be provided',
      'color: red',
      '',
    )
    return '!! ERROR !!'
  }

  if (isDefined(component) && isDefined(title)) {
    console.error(
      '%c INVALID PROPS! %c Headline requires either `component` or `title` be provided, but not both!',
      'color: red',
      '',
    )
    return '!! ERROR !!'
  }

  return isDefined(title)
    ? (
      <Section
        component={(
          <StyledHeadline
            ref={ref}
            {...rest}
          >
            {title}
          </StyledHeadline>
        )}
      >
        {contained ? <Container {...containerProps}>{children}</Container> : children}
      </Section>
    )
    : (
      <Section
        {...rest}
        component={component}
      >
        {contained ? <Container {...containerProps}>{children}</Container> : children}
      </Section>
    )
})

Headline.propTypes = {
  children: componentShape,
  component: componentShape,
  contained: PropTypes.bool,
  containerProps: PropTypes.object,
  title: componentShape,
}

export default Headline
