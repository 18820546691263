import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'reducers/users'
import { when } from 'fp/utils'
import { getUserById, isUserLoaded } from 'selectors/users'

const useUser = ({ userId }) => {
  const dispatch = useDispatch()
  const isLoaded = useSelector(isUserLoaded({ userId }))

  useEffect(() => {
    when(!isLoaded, () => dispatch(actions.fetchUserById({ userId })))
  }, [userId, dispatch, isLoaded])

  return useSelector(getUserById({ userId }))
}

export default useUser
