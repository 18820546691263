import PropTypes from 'prop-types'
import { useContext } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import RegularPlay from 'hss/images/controls/audio/regular-play.svg'
import RegularPause from 'hss/images/controls/audio/regular-pause.svg'
import MinimalPlay from 'hss/images/controls/audio/minimal-play.svg'
import MinimalPause from 'hss/images/controls/audio/minimal-pause.svg'
import { audioContext } from './AudioContextProvider'

const fullSvgProps = {
  viewBox: '0 0 48 48',
  width: 48,
  height: 48,
  overflow: 'visible',
}

const PlayButton = ({ minimal = false }) => {
  const { loaded, playStatus, setPlayStatus } = useContext(audioContext)

  const { palette, shadows } = useTheme()

  const playing = playStatus === 'PLAYING'

  const handleClick = () => {
    setPlayStatus(playing ? 'STOPPED' : 'PLAYING')
  }

  const PlayIcon = minimal
    ? MinimalPlay
    : RegularPlay

  const PauseIcon = minimal
    ? MinimalPause
    : RegularPause

  const progressSize = minimal
    ? '3rem'
    : '2rem'

  const svgProps = minimal
    ? null
    : fullSvgProps

  return (

    <IconButton
      aria-label={playing ? 'Pause' : 'Play'}
      aria-pressed={playing}
      color="secondary"
      data-bodyvariant="body3"
      disabled={!loaded}
      onClick={handleClick}
      sx={{
        padding: 0,
        borderStyle: 'none',
        '&:hover': {
          svg: {
            strokeWidth: 2,
          },
        },
        ...(minimal && {
          borderStyle: 'solid',
          width: 48,
          borderRadius: 0,
          ...(playing && {
            backgroundColor: palette.grey[0],
            svg: {
              margin: '0 !important',
              path: {
                fill: palette.grey.contrastText,
                stroke: palette.grey.contrastText,
              },
            },
            '&:hover': {
              backgroundColor: palette.background.paper,
              boxShadow: shadows.borderInsetButton,
              svg: {
                path: {
                  fill: palette.grey[1],
                  stroke: palette.grey[1],
                },
              },
            },
          }),
        }),
      }}
      value={`playing-${playing}`}
    >
      {loaded
        ? playing ? <PauseIcon {...svgProps} /> : <PlayIcon {...svgProps} />
        : <CircularProgress size={progressSize} />}
    </IconButton>
  )
}

PlayButton.propTypes = {
  minimal: PropTypes.bool,
}

export default PlayButton
