import PropTypes from 'prop-types'
import List from '@mui/material/List'
import { navItemShape } from 'core/shapes'
import ListEntry from './ListEntry'

const AsList = ({ entries }) => (
  <List
    color="secondary"
    dense
    disablePadding
  >

    {entries.map(entry => (
      <ListEntry
        entry={entry}
        key={entry.key}
      />
    ))}

  </List>
)

AsList.propTypes = {
  entries: PropTypes.arrayOf(navItemShape).isRequired,
}

export default AsList
