import { flexRender } from '@tanstack/react-table'
import { ChevronDown, ChevronUp } from 'react-feather'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { filter, first } from 'fp/arrays'
import { get } from 'fp/objects'
import { filterTopLevelColumns, filterTopLevelHeaderGroup, hasData } from '../Cells'
import HeadData from './HeadData'
import DiagonalColumnHeader from './DiagonalColumnHeader'

const sortIconStyle = { height: '1.8rem' }
const sortValues = { asc: 'ascending', desc: 'descending' }

export const ariaSortValue = column => column.getIsSorted()
  ? sortValues[column.getAutoSortDir()]
  : null

const Headers = (props) => {
  const { enableRowSelection, handleSort, headDataRows, headerGroups, tableId } = props
  const topLevelHeaders = compose(
    filter(filterTopLevelColumns),
    get('headers'),
    first,
    filter(filterTopLevelHeaderGroup),
  )(headerGroups)
  const headDataStartColumnIndex = headDataRows?.length
    ? topLevelHeaders.findIndex(hasData) + (enableRowSelection ? 1 : 0)
    : undefined

  return (
    <thead>
      {Boolean(headDataRows?.length) && (
        <HeadData
          headers={topLevelHeaders}
          rows={headDataRows}
        />
      )}

      {headerGroups
        .filter(filterTopLevelHeaderGroup)
        .map(headerGroup => (

          <tr key={headerGroup.id}>

            {headerGroup.headers
              .filter(filterTopLevelColumns)
              .slice(0, headDataStartColumnIndex)
              .map(header => hasData(header)
                ? (
                  <DiagonalColumnHeader key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </DiagonalColumnHeader>
                )
                : (
                  <th
                    aria-sort={ariaSortValue(header.column)}
                    id={header.id}
                    key={header.id}
                    scope="col"
                    style={header.column.columnDef.data ? undefined : {
                      width: header.getSize(),
                    }}
                  >
                    {header.column.getCanSort()
                      ? (
                        <Button
                          aria-describedby={`${tableId}-sort-instructions`}
                          className="sort-button"
                          onClick={handleSort(header.column.getToggleSortingHandler())}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}

                          {{
                            asc: <ChevronDown style={sortIconStyle} />,
                            desc: <ChevronUp style={sortIconStyle} />,
                          }[header.column.getIsSorted()] ?? null}
                        </Button>
                      )
                      : header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </th>
                ))}

            {Boolean(headDataRows?.length) && Array(headerGroup.headers.filter(filterTopLevelColumns).length - headDataStartColumnIndex).fill('').map((_, index) => (
              <td
                className="divider"
                key={index}
              />
            ))}

          </tr>
        ))}

    </thead>
  )
}

Headers.propTypes = {
  enableRowSelection: PropTypes.bool.isRequired,
  handleSort: PropTypes.func.isRequired,
  headDataRows: PropTypes.array, // Headers component doesn't care what's in the array. HeadData does.
  headerGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableId: PropTypes.string.isRequired,
}

export default Headers
