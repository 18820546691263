import { call, put, takeLeading } from 'redux-saga/effects'
import actionTypes from 'reducers/actionTypes'
import { buildUrl } from 'fp/internet'
import { restEndpoint } from 'reducers/utils'
import { actions } from 'reducers/notifications'
import { isEmptyString } from 'fp/strings'
import { dangerouslyCallApi } from './api'
import { success } from './utils'

function* handleFetch(action) {
  const { districtId } = action

  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(`${restEndpoint.districts}/${districtId}`),
  })
}

function* handleFetchList(action) {
  const { queryParams } = action
  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(restEndpoint.districts, queryParams, false),
  })
}

function* handleSave(action) {
  const { payload: { id, ...district } } = action
  const editing = !isEmptyString(id)

  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: editing ? 'PATCH' : 'POST',
      body: {
        ...district,
        id,
      },
    },
    passThrough: {
      editing,
    },
    url: editing
      ? `${restEndpoint.districts}/${id}`
      : restEndpoint.districts,
  })
}

export function* handleSaveSuccess({ passThrough: { editing } }) {
  yield put(actions.addAlert({
    message: editing
      ? 'District updated'
      : 'District created',
  }))
}

/* istanbul ignore next line */
function* districtSaga() {
  yield takeLeading(actionTypes.DISTRICT_FETCH, handleFetch)
  yield takeLeading(actionTypes.DISTRICT_FETCH_LIST, handleFetchList)
  yield takeLeading(actionTypes.DISTRICT_SAVE, handleSave)
  yield takeLeading(success(actionTypes.DISTRICT_SAVE), handleSaveSuccess)
}

export default districtSaga
