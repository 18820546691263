const actionTypes = {
  ALERT_ADD: 'ALERT_ADD',
  ALERT_REMOVE: 'ALERT_REMOVE',
  ALERT_REMOVE_ALL: 'ALERT_REMOVE_ALL',

  ASSIGNMENT_FETCH: 'ASSIGNMENT_FETCH',
  ASSIGNMENT_FETCH_LIST: 'ASSIGNMENT_FETCH_LIST',
  ASSIGNMENT_SAVE: 'ASSIGNMENT_SAVE',
  ASSIGNMENT_CONTENT_REOPEN: 'ASSIGNMENT_CONTENT_REOPEN',

  CALL_API: 'CALL_API',

  CANTO_IMPORT_INITIATE: 'CANTO_IMPORT_INITIATE',
  GDRIVE_IMPORT_INITIATE: 'GDRIVE_IMPORT_INITIATE',

  CONTENT_CREATE_AND_ADD_CHILD: 'CONTENT_CREATE_AND_ADD_CHILD',
  CONTENT_DELETE: 'CONTENT_DELETE',
  CONTENT_ECHO_SAVE: 'CONTENT_ECHO_SAVE',
  CONTENT_FETCH_ASSORTED: 'CONTENT_FETCH_ASSORTED',
  CONTENT_FETCH: 'CONTENT_FETCH',
  CONTENT_FETCH_BY_ASSET_CODE: 'CONTENT_FETCH_BY_ASSET_CODE',
  CONTENT_FETCH_LIST: 'CONTENT_FETCH_LIST',
  CONTENT_SAVE: 'CONTENT_SAVE',

  CONTENT_INSERTION_ALTERATION: 'CONTENT_INSERTION_ALTERATION',
  CONTENT_RESTRICTION_ALTERATION: 'CONTENT_RESTRICTION_ALTERATION',

  CREDITS_FETCH: 'CREDITS_FETCH',

  DISTRICT_FETCH: 'DISTRICT_FETCH',
  DISTRICT_FETCH_LIST: 'DISTRICT_FETCH_LIST',
  DISTRICT_SAVE: 'DISTRICT_SAVE',

  ERROR_CLEAR_ALL: 'ERROR_CLEAR_ALL',
  ERROR_CLEAR: 'ERROR_CLEAR',
  ERROR_OCCURRED: 'ERROR_OCCURRED',
  ERROR_SHOW_LOG: 'ERROR_SHOW_LOG',

  GROUP_FETCH: 'GROUP_FETCH',
  GROUP_FETCH_LIST: 'GROUP_FETCH_LIST',
  GROUP_SAVE: 'GROUP_SAVE',

  INTERACTION_DELETE: 'INTERACTION_DELETE',
  INTERACTION_FETCH_LIST: 'INTERACTION_FETCH_LIST',
  INTERACTION_POST: 'INTERACTION_POST',
  INTERACTION_POST_MULTIPLE: 'INTERACTION_POST_MULTIPLE',

  LOCAL_SETTINGS_INIT: 'LOCAL_SETTINGS_INIT',
  LOCAL_SETTINGS_SET: 'LOCAL_SETTINGS_SET',

  LOG_SYSTEM_EVENT: 'LOG_SYSTEM_EVENT',

  METADATA_FETCH: 'METADATA_FETCH',
  METADATA_FETCH_LIST: 'METADATA_FETCH_LIST',

  REACTION_POST: 'REACTION_POST',
  REACTIONS_RECEIVED: 'REACTIONS_RECEIVED',

  ROUTE_LOCATION_CHANGE_MAY_PROCEED: 'ROUTE_LOCATION_CHANGE_MAY_PROCEED',
  ROUTE_LOCATION_WILL_CHANGE: 'ROUTE_LOCATION_WILL_CHANGE',
  ROUTE_PIN_CURRENT_PAGE: 'ROUTE_PIN_CURRENT_PAGE',
  ROUTE_REMOVE_PIN: 'ROUTE_REMOVE_PIN',
  ROUTE_SET_IS_FIRST_RENDERING: 'ROUTE_SET_IS_FIRST_RENDERING',
  ROUTE_SET_SQUERY_ENDPOINT: 'ROUTE_SET_SQUERY_ENDPOINT',
  ROUTE_TE_PRESENTER_MODE_REDIRECT: 'ROUTE_TE_PRESENTER_MODE_REDIRECT',

  SESSION_CHECK: 'SESSION_CHECK',
  SESSION_KEEP_ALIVE: 'SESSION_KEEP_ALIVE',
  SESSION_LOGIN: 'SESSION_LOGIN',
  SESSION_LOGOUT: 'SESSION_LOGOUT',
  SESSION_RESET_TIMEOUT: 'SESSION_RESET_TIMEOUT',
  SESSION_SHOW_TIMEOUT_WARNING: 'SESSION_SHOW_TIMEOUT_WARNING',

  SCHOOL_FETCH: 'SCHOOL_FETCH',
  SCHOOL_FETCH_LIST: 'SCHOOL_FETCH_LIST',
  SCHOOL_SAVE: 'SCHOOL_SAVE',

  SOCKET_INTERACTION_RANKING_RECEIVED: 'SOCKET_INTERACTION_RANKING_RECEIVED',
  SOCKET_INTERACTION_RECEIVED: 'SOCKET_INTERACTION_RECEIVED',
  SOCKET_MESSAGE_RECEIVED: 'SOCKET_MESSAGE_RECEIVED',
  SOCKET_REACTIONS_RECEIVED: 'SOCKET_REACTIONS_RECEIVED',

  TAG_ADD: 'TAG_ADD',
  TABLE_CELL_FIELD_CHANGE: 'TABLE_CELL_FIELD_CHANGE',

  UPLOAD_ATTACH: 'UPLOAD_ATTACH',
  UPLOAD_INITIATE: 'UPLOAD_INITIATE',
  UPLOAD_MARK_COMPLETE: 'UPLOAD_MARK_COMPLETE',
  UPLOAD_REQUEST: 'UPLOAD_REQUEST',

  USER_ALERT_CONFIRM: 'USER_ALERT_CONFIRM',
  USER_ALERT_FETCH: 'USER_ALERT_FETCH',
  USER_ALERT_FETCH_NEW_BY_TYPE: 'USER_ALERT_FETCH_NEW_BY_TYPE',

  USER_ASSIGNMENT_FETCH: 'USER_ASSIGNMENT_FETCH',
  USER_ASSIGNMENT_FETCH_LIST: 'USER_ASSIGNMENT_FETCH_LIST',
  USER_ASSIGNMENT_SUBMIT: 'USER_ASSIGNMENT_SUBMIT',
  USER_ASSIGNMENT_REOPEN: 'USER_ASSIGNMENT_REOPEN',
  USER_ASSIGNMENT_UPDATE_PROGRESS: 'USER_ASSIGNMENT_UPDATE_PROGRESS',
  USER_ASSIGNMENT_UPDATE_LAST_VIEWED_CONTENT: 'USER_ASSIGNMENT_UPDATE_LAST_VIEWED_CONTENT',

  USER_FETCH: 'USER_FETCH',
  USER_FETCH_LIST: 'USER_FETCH_LIST',
  USER_RECOVERY: 'USER_RECOVERY',

  USER_RESET_PASSWORD: 'USER_RESET_PASSWORD',
  USER_SAVE: 'USER_SAVE',
  USER_CHANGE_THEME: 'USER_CHANGE_THEME',

  VOCAB_ADD: 'VOCAB_ADD',
}

export default actionTypes
