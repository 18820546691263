import { User as UserIcon } from 'react-feather'
import MenuItem from '@mui/material/MenuItem'
import { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import useNavigation from 'hooks/useNavigation'
import MenuButton from 'common/menus/MenuButton'
import LogoutMenuItems from 'routing/shells/LogoutMenuItems'
import { important } from 'styling/theming/base/mixins'
import { ABILITY_ADMINISTRATION_INTERFACE, ABILITY_RECEIVE_USER_ALERTS, ABILITY_STUDENT_INTERFACE } from 'core/consts'
import { navDialogsContext } from 'routing/shells/NavShell/NavDialogsProvider'
import NotificationsDialog from 'hss/sections/Home/userAlertDialogs/NotificationsDialog'
import useEffectOnce from 'hooks/useEffectOnce'
import { actions } from 'reducers/userAlerts'
import useAbilityCheck from 'hooks/useAbilityCheck'
import CreditsDialog from 'hss/sections/Home/CreditsDialog'
import { getUserAlertsFetchedStatus } from 'selectors/userAlerts'
import { when } from 'fp/utils'
import HelpCenterLink from 'common/navigation/links/HelpCenterLink'

const StyledMenuItem = styled(
  MenuItem,
  { name: 'ResponsiveItem' },
)(({ theme: { breakpoints } }) => ({
  [breakpoints.up('sm')]: {
    display: 'none',
  },
}))

const UserMenu = () => {
  const { navigate } = useNavigation()

  const canReceiveUserAlerts = useAbilityCheck(ABILITY_RECEIVE_USER_ALERTS)
  // This ability check allows teachers and sysadmins to view (ROLE_STAFF and ROLE_SYSTEM)
  const userIsNotStudent = !useAbilityCheck(ABILITY_STUDENT_INTERFACE)

  // TODO Credits data is a WIP right now; only allow sysadmin users to view until data is accurate.
  // Once data is updated, this line should be deleted, and `userIsNotStudent` check should be used.
  const userIsAdmin = useAbilityCheck(ABILITY_ADMINISTRATION_INTERFACE)

  const handleShowSettings = () => {
    navigate('/settings')
  }

  const launchCommunity = () => {
    window.open('//www.imaginelearning.com/community/', '_blank')
  }

  const {
    creditsDialogIsOpen: showCredits,
    notificationsIsOpen: showUserAlerts,
    toggleContactFormDialogIsOpen: showContactForm,
    toggleCreditsDialogIsOpen,
    toggleNotificationsIsOpen,
  } = useContext(navDialogsContext) || {}

  const interruptAlertsFetched = useSelector(getUserAlertsFetchedStatus({ type: 'interrupt' }))
  const profileAlertsFetched = useSelector(getUserAlertsFetchedStatus({ type: 'profile' }))

  const dispatch = useDispatch()
  useEffectOnce(() => {
    // These need to be fetched on initial page load
    if (userIsNotStudent) {
      when(!interruptAlertsFetched, dispatch, actions.fetchNewUserAlertsByType({ mode: 'interrupt' }))
      when(!profileAlertsFetched, dispatch, actions.fetchNewUserAlertsByType({ mode: 'profile' }))
    }
  })

  return (
    <>
      <MenuButton
        color="secondary"
        data-bodyvariant="body1"
        data-testid="user-menu"
        icon={<UserIcon />}
        label="Profile Menu"
        sx={{ 'svg[viewBox="0 0 24 24"]': { 'circle, path': { strokeWidth: important(1.5) } } }}
        variant="tertiary"
      >
        <MenuItem onClick={handleShowSettings}>Settings</MenuItem>
        {Boolean(userIsNotStudent) && <StyledMenuItem onClick={launchCommunity}>Community</StyledMenuItem>}
        {Boolean(userIsNotStudent) && <HelpCenterLink Renderer={MenuItem}>Help Center</HelpCenterLink>}
        {Boolean(userIsNotStudent) && <StyledMenuItem onClick={showContactForm}>Contact</StyledMenuItem>}
        {Boolean(canReceiveUserAlerts) && <MenuItem onClick={toggleNotificationsIsOpen}>Notifications</MenuItem>}
        {Boolean(userIsAdmin) && <MenuItem onClick={toggleCreditsDialogIsOpen}>Credits</MenuItem>}
        <LogoutMenuItems />
      </MenuButton>

      {Boolean(showCredits) && (
        <CreditsDialog />
      )}

      {Boolean(showUserAlerts) && (
        <NotificationsDialog />
      )}
    </>
  )
}

export default UserMenu
