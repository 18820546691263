import PropTypes from 'prop-types'
import { useMemo } from 'react'
import {
  changeBlockType,
  getCurrentBlock,
  getSimilarAdjacentBlocks,
  selectBlockByKey,
} from '@studysync/draft-js-modifiers'
import Box from '@mui/material/Box'
import { set } from 'fp/objects'
import { isEmptyString } from 'fp/strings'
import { acuminTextSizeR } from 'styling/theming/base/mixins'
import { fauxEvent } from '../utils/misc'
import DraftMenuButton from './DraftMenuButton'

const isIndent = ({ key }) => ['decrease', 'increase'].includes(key)

const canIndent = variant => isEmptyString(variant)

const listBlockTypes = ['ordered-list-item', 'unordered-list-item']

const similarBlockOptions = {
  includeDepth: true,
  onlyForBlockTypes: [...listBlockTypes, 'paragraph'],
}

const allAvailableOptions = [

  {
    label: '• Unordered List',
    key: 'unordered-list-item',
    blockType: 'unordered-list-item',
    variant: '',
  },

  {
    label: '1. Numbered List',
    key: 'ordered-list-item',
    blockType: 'ordered-list-item',
    variant: '',
  },

  {
    label: () => (
      <>
        <Box sx={acuminTextSizeR(2.5, 1)}>01</Box>
        &nbsp;
        <Box fontWeight={300}>Ordered Cards</Box>
      </>
    ),
    key: 'lists.card',
    feature: 'lists.card',
    blockType: 'ordered-list-item',
    variant: 'numbered-card',
  },

  {
    label: () => <Box fontWeight={300}>source | header</Box>,
    key: 'lists.sourceHeader',
    feature: 'lists.sourceHeader',
    blockType: 'ordered-list-item',
    variant: 'source-header',
  },

  {
    label: 'Simple List',
    key: 'lists.unstyled',
    feature: 'lists.unstyled',
    blockType: 'ordered-list-item',
    variant: 'unstyled',
  },

  {
    label: '< Outdent',
    key: 'decrease',
  },

  {
    label: '> Indent',
    key: 'increase',
  },
]

const ListMenuButton = ({ changeIndent, editorState, features, label, setEditorState, toggleBlockType, ...rest }) => {
  const handleChange = ({ key, blockType, variant }) => {
    if (['decrease', 'increase'].includes(key)) {
      changeIndent(fauxEvent(), key)
      return
    }

    const blockAndSiblings = getSimilarAdjacentBlocks(
      editorState,
      getCurrentBlock(editorState),
      similarBlockOptions,
    ).all

    const nextState = blockAndSiblings.reduce((state, block) => {
      /**
       * This might not be the correct way to combine multiple block updates.
       * Seems to work, though it does add each operation to the undo stack 😒
       */
      const result = selectBlockByKey(state, block.getKey())
      return changeBlockType(result, blockType, { variant })
    }, editorState)

    setEditorState(nextState, true)
  }

  const options = useMemo(() => {
    const block = getCurrentBlock(editorState)
    const currentBlockType = block.get('type')

    const isListBlock = listBlockTypes.includes(currentBlockType)

    const firstListItem = isListBlock
      ? getSimilarAdjacentBlocks(
        editorState,
        getCurrentBlock(editorState),
        similarBlockOptions,
      ).all[0]
      : block

    const data = firstListItem.getData()
    const variant = data.get('variant')

    const isIndented = getSimilarAdjacentBlocks(editorState, block, similarBlockOptions).all.length
      !== getSimilarAdjacentBlocks(editorState, block, { ...similarBlockOptions, includeDepth: false }).all.length

    return allAvailableOptions
      .filter(({ feature }) => !feature || features[feature])
      .map(item => set(
        'disabled',
        [
          isIndent(item) && !isListBlock,
          isIndent(item) && !canIndent(variant),
          isIndented && !canIndent(item.variant),
        ].some(Boolean),
      )(item))
      .map(item => set(
        'selected',
        currentBlockType === item.blockType && (item.variant === '' || variant === item.variant),
      )(item))
  }, [editorState, features])

  return (
    <DraftMenuButton
      editorState={editorState}
      features={features}
      label={label}
      onChange={handleChange}
      options={options}
      setEditorState={setEditorState}
      {...rest}
    />
  )
}

ListMenuButton.propTypes = {
  changeIndent: PropTypes.func.isRequired,
  editorState: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  setEditorState: PropTypes.func.isRequired,
  toggleBlockType: PropTypes.func.isRequired,
}

export default ListMenuButton
