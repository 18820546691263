import { Maybe } from 'monet'
import { compose } from 'redux'
import { parseDocument } from 'htmlparser2'
import render from 'dom-serializer'
import { find } from 'domutils'
import {
  ABILITY_TEACHER_INTERFACE,
  AUTO_SUBMITTABLE_INTERACTIVES,
  CONTENT_SUBTYPE_CHAPTER_SUMMARY,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_PAGE,
  CONTENT_TYPE_RUBRIC,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  CONTENT_TYPE_UNIT,
  CONTENT_TYPE_VOCABULARY,
  INTERACTION_STATE_COMPLETED,
  ROLE_STUDENT,
  SCORING_FEEDBACK_CONTENT_NONE,
  SCORING_FEEDBACK_CONTENT_TOTAL_SCORE,
} from 'core/consts'
import {
  escapeStringRegexp,
  isEmptyString,
  isNotEmptyString,
  wrap,
} from 'fp/strings'
import { equals, get, pick, set } from 'fp/objects'
import {
  curry,
  curryRight,
  fallbackTo,
  isDefined,
  matches,
  matchesOneOf,
  not,
} from 'fp/utils'
import { filter, first, flatten, join, map, reduce } from 'fp/arrays'
import {
  assignmentCreatorUrlFrag,
  assignmentEditorUrlFrag,
  contentBuilderUrl,
  contentViewerUrl,
  contentViewerUrlFrag,
  curriculumUrl,
  curriculumUrlFrag,
  libraryUrl,
  libraryUrlFrag,
  studentAssignmentsUrl,
} from 'routing/consts'
import { callWith } from 'fp/call'
import { mutexFeatures } from 'hss/ContentBuilder/Curriculum/Echo/utils'
import {
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_TALK_BACK,
  SCORING_MODE_NONE,
} from 'hss/ContentBuilder/consts'

export const assembleContentHierarchy = ({ contentId, options }) => (
  allContentAsObject,
  assignment,
  excludedContentIds,
  routing,
  has,
  roleId,
  inPresenterMode,
) => {
  const isContentViewTeacher = has(ABILITY_TEACHER_INTERFACE)
  const shouldIncludeTeacherEdition = options?.includeTeacherEdition
    || (isContentViewTeacher && !inPresenterMode)
  const pathname = compose(fallbackTo(''), get('currentLocation.pathname'))(routing)
  let chapterSummary
  const settings = get('data.settings')(assignment) || {}

  /**
   * Assignment editors have an edge case.
   * Here we always want to include the summary.  It will have the EyeOff icon
   * added to the tree item if summaries are disabled.
   * We also need to always include it for teachers when outside of an assignment.
   */
  const includeChapterSummary = settings.includeChapterSummary || !!(
    pathname.includes(assignmentCreatorUrlFrag)
      || pathname.includes(assignmentEditorUrlFrag)
      || (isContentViewTeacher && pathname.includes(contentViewerUrlFrag))
  )

  const applyChildren = item => Maybe

    .fromUndefined(allContentAsObject[item?.id])

    .filter(({ id }) => !excludedContentIds.includes(id))

    .filter(({ teacherEdition }) => !teacherEdition || shouldIncludeTeacherEdition)

    .filter((monad) => {
      if (monad.contentSubType === CONTENT_SUBTYPE_CHAPTER_SUMMARY) {
        chapterSummary = { ...monad }
        return false
      }
      return true
    })

    .map(monad => ({
      ...monad,
      children: monad
        ?.children
        ?.filter(child => !excludedContentIds.includes(child.id))
        ?.filter((child) => {
          if (item.contentType === CONTENT_TYPE_BLOCK && child.contentType !== CONTENT_TYPE_INTERACTIVE) {
            /**
             * TODO:
             *
             * This is a temporary fix for the fact that blocks can only have
             * interactives as children, but we have a large number that instead
             * have subsections.
             *
             * Once the root cause has been identified and fixed, then this TODO
             * message should be removed. The commented out `console.error` line
             * should be uncommented (it would just flood the console right now, but
             * it will work as cheap insurance against regression later).
             *
             * The affected blocks seem to be the ones that contain internal links
             * back to subsections.  The working theory is that a migration created
             * these false child references.
             */
            // eslint-disable-next-line no-console
            // console.error(`Blocks can only have interactives as children!  Check content id ${item.id}`)
            return false
          }

          return true
        })
        ?.map(applyChildren)
        .filter(Boolean),
    }))

    .map(monad => compose(
      callWith(monad),
      curry(set, 2, 'childrenCount'),
      get('children.length'),
    )(monad))

    .orUndefined()

  const contentTree = applyChildren(allContentAsObject[contentId])

  if (chapterSummary && includeChapterSummary) {
    const firstSeSection = contentTree?.children.find(compose(not, get('teacherEdition')))
    const firstTeSection = contentTree?.children.find(get('teacherEdition'))

    /* istanbul ignore next line */
    if (roleId === ROLE_STUDENT) {
      /**
       * Summary is placed as the last subsection within the first section
       *
       * NOTE: if it should come to pass that the summary tab can be placed anywhere
       * instead of being hardcoded like this, then that information about where to
       * place it will probably be placed into `assignment.data.settings`
       */
      firstSeSection?.children.push(chapterSummary)
    } else {
      if (shouldIncludeTeacherEdition) {
        /**
         * Place as the second tab of the first TE section (which is probably 'Chapter Preview')
         *
         * We're prefixing the id with 'TE' so that we can tell its location in the nav tree
         * apart from the SE copy.
         * So if the chapter summary subsection is id `171`, the TE copy will be `TE-171`.
         * Some of the content viewer selectors accept a boolean option named `preferTeCopy`
         * which can be used to find the TE version within the chapter nav tree. They otherwise
         * default to locating the normal version.
         */
        firstTeSection?.children.splice(1, 0, set('id', `TE-${chapterSummary.id}`)(chapterSummary))
      }
      // Also place it in the same place the student will/would see it
      firstSeSection?.children.push(chapterSummary)
    }
  }

  return contentTree
}

export const assembleNav = ({ includeRoot, leafContentTypes }) => (
  contentTree,
  interactions,
  bags,
  roleId,
) => {
  const contentItems = includeRoot
    ? [contentTree]
    : get('children')(contentTree) || []

  const isLeaf = leafContentTypes
    ? matchesOneOf('contentType', leafContentTypes)
    : () => false

  const recursivePrune = map(item => ({
    ...item,
    children: isLeaf(item)
      ? []
      : recursivePrune(item.children || /* istanbul ignore next */ []),
  }))

  return recursivePrune(roleId === ROLE_STUDENT
    // eslint-disable-next-line no-use-before-define
    ? contentItems.map(compileCompleteness(bags, interactions))
    : contentItems)
}

export const buildContentUrl = context => (content, topLevelParent, parent, child, location, userAssignment) => {
  /**
   * This will return an URL that is suitable for all user types, given a particular
   * content id and type.
   *
   * Only relevant types have been included as some content types wouldn't have
   * a viable url -- only the ones that make sense are here.
   *
   * There are some that are missing just because we didn't have routes to them
   * at the time.  Feel free to add any that are missing.
   *
   * The fallback for a missing content type (or bad combination of id, etc.)
   * is a 404
   */

  const { contentType } = content || {}

  /**
   * This handles the edge-case that can occur within the content editor.
   * The content tree most likely won't be available, however the parent
   * decoration will be there.
   */
  const { contentType: topLevelType, id: topLevelId } = topLevelParent || content?.parent?.parent || {}
  const { id: parentId } = parent || content?.parent || {}

  const asAssignment = context === 'current'
    && String(location.pathname).startsWith(studentAssignmentsUrl)
    && userAssignment

  const asLibraryItem = context === libraryUrlFrag
    && String(location?.pathname).startsWith(libraryUrl)

  switch (contentType) {
    // SPECIAL CASE (builder, not the viewer)
    case CONTENT_TYPE_BLOCK:
      return `${contentBuilderUrl}/${curriculumUrlFrag}/${CONTENT_TYPE_BLOCK}/${content?.id}`

    case CONTENT_TYPE_CHAPTER:
      return `${contentViewerUrl}/${contentType}/${content?.id}`

    case CONTENT_TYPE_COURSE:
      return `${curriculumUrl}/${contentType}/${content?.id}`

    case CONTENT_TYPE_ECHO:
      return asAssignment
        ? `${studentAssignmentsUrl}/${userAssignment.id}/${CONTENT_TYPE_ECHO}/${content?.id}`
        : isDefined(topLevelId) && !asLibraryItem
          ? `${contentViewerUrl}/${CONTENT_TYPE_CHAPTER}/${topLevelId}/${CONTENT_TYPE_ECHO}/${content?.id}`
          : `${contentViewerUrl}/${CONTENT_TYPE_ECHO}/${content?.id}`

    // SPECIAL CASE (builder, not the viewer)
    case CONTENT_TYPE_INTERACTIVE:
      return `${contentBuilderUrl}/${CONTENT_TYPE_INTERACTIVE}/${content.contentSubType}/${content?.id}`

    case CONTENT_TYPE_RUBRIC:
      return `${contentBuilderUrl}/${contentType}/${content?.id}`

    case CONTENT_TYPE_SECTION:
      return asAssignment
        ? `${studentAssignmentsUrl}/${userAssignment.id}/${CONTENT_TYPE_SUBSECTION}/${child?.id}`
        : `${contentViewerUrl}/${CONTENT_TYPE_CHAPTER}/${parentId}/${CONTENT_TYPE_SUBSECTION}/${child?.id}`

    case CONTENT_TYPE_SOURCE:
      return `${contentViewerUrl}/${contentType}/${content?.id}`

    case CONTENT_TYPE_SUBSECTION:
      return asAssignment
        ? `${studentAssignmentsUrl}/${userAssignment.id}/${CONTENT_TYPE_SUBSECTION}/${content?.id}`
        : `${contentViewerUrl}/${topLevelType}/${topLevelId}/${CONTENT_TYPE_SUBSECTION}/${content?.id}`

    case CONTENT_TYPE_UNIT:
      return `${curriculumUrl}/${CONTENT_TYPE_COURSE}/${parentId}`

    case CONTENT_TYPE_VOCABULARY:
      return `${libraryUrl}/${CONTENT_TYPE_VOCABULARY}/${content?.id}`

    default:
      return `${contentViewerUrl}/404`
  }
}

const SUBMITTABLE_ECHOES = [
  ECHO_FEATURE_BLASTY_BLAST,
  ECHO_FEATURE_PICTURE_THIS,
  ECHO_FEATURE_POLL,
  ECHO_FEATURE_TALK_BACK,
]

export const COMPLETION_NONE = 0
export const COMPLETION_INDETERMINATE = 1
export const COMPLETION_DONE = 2
const isComplete = compose(
  equals(COMPLETION_DONE),
  get('calculatedCompletion'),
)
const isIncomplete = compose(
  equals(COMPLETION_INDETERMINATE),
  get('calculatedCompletion'),
)

const extractInteractives = subsection => subsection
  .children
  ?.reduce((acc, item) => item.contentType === CONTENT_TYPE_INTERACTIVE
    ? [...acc, item]
    : [...acc, ...extractInteractives(item)], [])
  || /* istanbul ignore next */ []

const extractInteractions = (subsection, interactions) => interactions
  .filter(({ contentId, contextContentId }) => [contentId, contextContentId]
    .includes(subsection.id))

const contentPageCompleteness = (bags, chapterInteractions) => (contentPage) => {
  if (!contentPage.children?.length) return COMPLETION_DONE
  /**
   * We're taking advantage of two properties of interactives here:
   *   1 - every content page will have a "page-read" interaction
   *   2 - every interactive will have only one interaction
   *
   * Therefore, if the number of (completed) interactions is one more than the
   * number of completable interactives, then the page is done.  The "one
   * more" being the page-view interaction.
   * Otherwise if there is at least one interaction, the page is partially done
   * and of course if there are NO interactions, then the user has not viewed
   * nor started on that page.
   *
   * Should these properties ever change, then this projection is the place to
   * make adaptions.
   *
   * Some accommodations may need to be made for non-completable interactives or
   * ones that *could* have more than one interaction, but we'll cross that bridge
   * if we come to it.
   */
  const completableInteractives = extractInteractives(contentPage)
    .flat()
    .filter((interactive) => {
      const { contentSubType } = interactive

      // there is no "allowSubmission" choice for echoes
      const isSubmissionAllowed = !!get('data.allowSubmission')(interactive)
        || SUBMITTABLE_ECHOES.includes(contentSubType)
      const isCompletable = AUTO_SUBMITTABLE_INTERACTIVES.includes(contentSubType)
      const featureIsEnabled = (contentPage.contentType !== CONTENT_TYPE_ECHO)
        || contentPage.data.features[contentSubType]?.enabled

      return (isSubmissionAllowed || isCompletable)
        && featureIsEnabled
        && (contentSubType === contentPage.data.responseType
          || !mutexFeatures.includes(contentSubType))
    })

  const interactions = extractInteractions(contentPage, chapterInteractions)
  const completedInteractions = interactions
    .filter(matches('state', INTERACTION_STATE_COMPLETED))

  if (!completedInteractions.length) return COMPLETION_NONE

  // TODO: enable once we have more user interactions
  /* istanbul ignore next line */
  if (completedInteractions.length === completableInteractives.length + 1) return COMPLETION_DONE

  return COMPLETION_INDETERMINATE
}

const compileCompleteness = (bags, interactions) => (section) => {
  const result = { ...section }
  let completion = COMPLETION_NONE

  const isPageLike = first(result.children)?.contentType === CONTENT_TYPE_BLOCK
    || result.contentType === CONTENT_TYPE_ECHO
  if (isPageLike) {
    completion = contentPageCompleteness(bags, interactions)(result)
  } else {
    result.children = result.children?.map(compileCompleteness(bags, interactions))

    completion = result.children.every(isComplete)
      // TODO: enable once we have more user interactions
      ? /* istanbul ignore next */ COMPLETION_DONE
      : result.children.some(isComplete) || result.children.some(isIncomplete)
        ? COMPLETION_INDETERMINATE
        : COMPLETION_NONE
  }

  return set('calculatedCompletion', completion)(result)
}

export const flattenHierarchy = (content) => {
  const result = []

  const pullChildren = (item) => {
    result.push(item)
    item?.children?.forEach(pullChildren)
  }

  pullChildren(content)

  return result.filter(Boolean)
}

export const getDescendants = (parents, allContent, options = { followLinks: true }) => {
  const filterLinks = items => items.map(item => ({
    ...item,
    children: item.children?.filter((child) => {
      if (options.followLinks) return true
      // ... else remove any children that are referenced by a hyperlink
      return [CONTENT_TYPE_BLOCK, CONTENT_TYPE_PAGE].includes(item.contentType)
        ? !String(item.data?.body).includes(`<a data-contentid="${child.id}"`)
        : true
    }),
  }))

  const children = compose(
    filter(Boolean),
    map(id => allContent[id]),
    map(get('id')),
    flatten,
    fallbackTo([]),
    map(get('children')),
    filterLinks,
    map(pick('children', 'contentType', 'data')),
    fallbackTo([]),
  )(parents)

  if (!children.length) {
    return []
  }

  return children.concat(getDescendants(children, allContent, options))
}

export const findSiblings = ({ contentId, items = [] }) => {
  let prev
  let next

  items.forEach((item, idx) => {
    if (item.id === contentId) {
      prev = idx > 0
        ? items[idx - 1]
        : undefined

      next = items.length > idx + 1
        ? items[idx + 1]
        : undefined
    }
  })

  /**
   * the first child of standalone items will have no upper sibling
   */
  prev = prev?.contentType === CONTENT_TYPE_SOURCE
    ? prev?.parent ? prev : undefined
    : prev

  return { prev, next }
}

export const assembleSideBySideSeAndTeBlocks = (blocks) => {
  let foundTe = false
  let count = 0
  const hasTe = compose(
    isNotEmptyString,
    get('block.teacherEditionContent.data.body'),
  )

  const result = blocks
    .map(block => ({
      block,
      seRowSpan: 1,
      teRowSpan: 0,
    }))
    .reverse()
    .map(compose(
      (row) => {
        count = hasTe(row) ? 0 : count
        return row
      },
      row => set('teRowSpan', hasTe(row) ? count : 0)(row),
      (row) => {
        count += 1
        return row
      },
    ))
    .reverse()
    .map((row) => {
      foundTe = foundTe || hasTe(row)
      return foundTe
        ? row
        : set('teRowSpan', 1)(row)
    })

  return result
}

export const isEmptyBlock = s => isEmptyString(s) || String(s).trim() === '<div></div>'

export const markVocabPhrasesInBlocks = (vocabPhrases, preferredBodyProp) => {
  const blockHasPreferredBody = compose(
    not,
    isEmptyBlock,
    get(`data.${preferredBodyProp}`),
    fallbackTo({}),
  )
  const getBlockBodyPath = preferredBodyProp
    ? block => blockHasPreferredBody(block)
      ? `data.${preferredBodyProp}`
      : 'data.body'
    : () => 'data.body'

  return (blocks) => {
    const vocabPhrasesWithDefinitions = filter(get('data.definition'))(vocabPhrases)

    if (!vocabPhrasesWithDefinitions?.length) {
      return blocks
    }

    const vocabPhraseLookup = vocabPhrasesWithDefinitions.reduce(
      (lookup, vocabPhrase) => {
        const lookupItem = { vocabPhrase, hasBeenFound: false }
        return compose(
          reduce(
            (newLookup, nextKey) => ({
              ...newLookup,
              [nextKey]: lookupItem,
            }),
            lookup,
          ),
          map(key => key.toLowerCase()),
          ({ name, data: { otherMatches = [] } }) => [
            name,
            ...otherMatches.map(get('value')),
          ],
        )(vocabPhrase)
      },
      {},
    )

    const headingRegExp = /h[1-6]/
    const vocabRegExp = compose(
      curryRight(RegExp, 'i'),
      join('|'),
      map(wrap('\\b')),
      map(escapeStringRegexp),
      Object.keys,
    )(vocabPhraseLookup)

    const maybeMarkVocabPhrase = (vocabPhraseName) => {
      const lookupItem = vocabPhraseLookup[vocabPhraseName.toLowerCase()]

      if (lookupItem.hasBeenFound) {
        return vocabPhraseName
      }

      lookupItem.hasBeenFound = true

      // EnhancedContentHtml's `processVocabPhrases` instruction searches for this format.
      return `__vocab${lookupItem.vocabPhrase.id}__${vocabPhraseName}__`
    }

    const markVocab = (dom) => {
      const nodesContainingVocab = find(
        elem => elem.type === 'text'
        && !headingRegExp.test(elem.parent?.name)
        && vocabRegExp.test(elem.data),
        dom.children,
        true,
      )

      nodesContainingVocab.forEach((node) => {
        const newTextSegments = []
        let unprocessedText = node.data
        let match = unprocessedText.match(vocabRegExp)

        while (match) {
          const [vocabPhraseName] = match
          const { index } = match

          if (index > 0) {
            newTextSegments.push(unprocessedText.substring(0, index))
          }
          newTextSegments.push(maybeMarkVocabPhrase(vocabPhraseName))
          unprocessedText = unprocessedText.substring(index + vocabPhraseName.length)

          match = unprocessedText.match(vocabRegExp)
        }
        newTextSegments.push(unprocessedText)

        // eslint-disable-next-line no-param-reassign
        node.data = newTextSegments.join('')
      })

      return dom
    }

    return blocks.map((block) => {
      const bodyPath = getBlockBodyPath(block)

      const newBody = Maybe.fromFalsy(block)
        .flatMap(compose(Maybe.fromFalsy, get(bodyPath)))
        .map(parseDocument)
        .map(markVocab)
        .map(render)
        .orNull()

      return newBody
        ? set(bodyPath, newBody)(block)
        : block
    })
  }
}

const scoringFeedbackOptionsThatShouldHideResponse = [
  SCORING_FEEDBACK_CONTENT_NONE,
  SCORING_FEEDBACK_CONTENT_TOTAL_SCORE,
]

export const detailsShouldBeHiddenFromStudent = (isStudent, userAssignment, assignment, interactive) => {
  const scoringMode = get('scoring.mode')(interactive)
  const scorable = scoringMode && scoringMode !== SCORING_MODE_NONE
  const assignmentIsSubmitted = !!userAssignment?.submittedDate
  const scoringFeedbackContent = get('data.settings.scoringFeedbackContent')(assignment)

  return isStudent
    && scorable
    && assignmentIsSubmitted
    && scoringFeedbackOptionsThatShouldHideResponse.includes(scoringFeedbackContent)
}
