import { MoreVertical } from 'react-feather'
import IconButton from '@mui/material/IconButton'
import { useContext, useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import PlayButton from './PlayButton'
import MuteButton from './MuteButton'
import SkipButton from './SkipButton'
import { audioContext } from './AudioContextProvider'

const MinimalPlayer = () => {
  const [anchorEl, setAnchorEl] = useState()
  const { goBackwards, goForwards, muted, setMuted } = useContext(audioContext)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Stack
      bgcolor="background.paper"
      direction="row"
      height={48}
      sx={{
        borderRightColor: 'accent.dark',
        borderRightWidth: 6,
        borderRightStyle: 'solid',
        '& svg': {
          m: 1.5,
        },
      }}
      width={102}
    >

      <IconButton
        aria-controls={open ? 'more-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        aria-label="Audio Options"
        color="secondary"
        data-bodyvariant="body3"
        data-subvariant="square"
        id="more-button"
        onClick={handleClick}
        style={{
          left: 1,
        }}
        value={`more-open-${open}`}
        variant="tertiary"
      >
        <MoreVertical />

      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id="more-menu"
        MenuListProps={{
          'aria-labelledby': 'more-button',
        }}
        onClose={handleClose}
        open={open}
      >
        <MenuItem onClick={() => setMuted(!muted)}><MuteButton /></MenuItem>
        <MenuItem onClick={goForwards}><SkipButton forwards /></MenuItem>
        <MenuItem onClick={goBackwards}><SkipButton /></MenuItem>
      </Menu>

      <PlayButton minimal />

    </Stack>
  )
}

export default MinimalPlayer
