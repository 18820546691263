import PropTypes from 'prop-types'
import List from '@mui/material/List'
import { standardShape } from 'core/shapes'
import StandardItem from './StandardItem'

const StandardsList = ({ standards }) => (
  <List variant="csv">
    {standards.map(standard => (
      <li key={standard.id}>
        <StandardItem standard={standard} />
      </li>
    ))}

  </List>
)

StandardsList.propTypes = {
  standards: PropTypes.arrayOf(standardShape).isRequired,
}

export default StandardsList
