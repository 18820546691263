import { call, put, takeEvery, takeLeading } from 'redux-saga/effects'
import { compose } from 'redux'
import actionTypes from 'reducers/actionTypes'
import { buildUrl } from 'fp/internet'
import { restEndpoint } from 'reducers/utils'
import { actions } from 'reducers/notifications'
import { alter, assert } from '../search/squery'
import { dangerouslyCallApi } from './api'

// queryParams need to be enclosed in a "search" obj before being passed to the api
export const asSearch = search => ({ search })

export const defaultUserAlertsQueryParams = compose(
  alter.set.limit(10),
  alter.set.orderBy('dateCreated', 'desc'),
)(assert())

export function* handleFetch(action) {
  const { queryParams: customQueryParams } = action.payload || {}
  const searchParams = customQueryParams || defaultUserAlertsQueryParams

  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(restEndpoint.userAlerts, asSearch(searchParams), false),
  })
}

export const newAlertsQueryParams = ({ mode }) => compose(
  alter.set.orderBy('dateCreated', 'desc'),
  alter.set.modifier('isNew').is(true),
  alter.set.modifier('forAlertMode').is(mode),
)(assert())

export function* handleFetchNewByType(action) {
  const { mode } = action

  if (!mode) {
    // If no 'mode' is specified, show an error
    yield put(actions.addAlert({
      message: 'New notifications were not fetched; "mode" must be specified.',
      options: { variant: 'error' },
    }))
  } else {
    yield call(dangerouslyCallApi, {
      action,
      url: buildUrl(restEndpoint.userAlerts, asSearch(newAlertsQueryParams({ mode })), false),
    })
  }
}

export function* handleConfirmAlert(action) {
  const { id } = action

  if (!id) {
    // If no 'id' is specified, show an error
    yield put(actions.addAlert({
      message: 'There was an error confirming the notification; "id" must be specified.',
      options: { variant: 'error' },
    }))
  } else {
    yield call(dangerouslyCallApi, {
      action,
      options: {
        method: 'PATCH',
        body: {
          dateConfirmed: new Date(),
        },
      },
      suppressNotification: true,
      url: `${restEndpoint.userAlerts}/${id}`,
    })
  }
}

/* istanbul ignore next line */
function* userAlertSaga() {
  yield takeLeading(actionTypes.USER_ALERT_FETCH, handleFetch)
  // using takeEvery as fetching new 'profile' and new 'interrupt' types of alerts uses the same action
  yield takeEvery(actionTypes.USER_ALERT_FETCH_NEW_BY_TYPE, handleFetchNewByType)
  yield takeEvery(actionTypes.USER_ALERT_CONFIRM, handleConfirmAlert)
}

export default userAlertSaga
