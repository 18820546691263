import { forwardRef, useContext } from 'react'
import { containerQueryContext } from 'common/layout/ContainerQuery'

/**
 * Use this to wrap a component that may appear within a container that you'll
 * later need to target via CSS in order to setup values based on the container's
 * breakpoints.  Normal media queries won't work within a container query, so
 * this is the functional equivalent.
 *
 * An attribute named "data-containersize" will be added to the DOM element. The
 * value of this attribute will be a standard MUI media query breakpoint name,
 * such as 'xs', 'sm', 'md' or 'lg'.
 *
 * Use an attribute selector to apply styling based on the breakpoint:
 *
 * '&[data-containersize="xs"] li': {
 *   flex: '0 0 100%',
 * }
 *
 * Note that only exact matches are supported at this time, so you'll probably
 * want to apply general styling and then use this mechanism to surgically make
 * exceptions.
 *
 */

const withContainerQuerySize = WrappedComponent => forwardRef((props, ref) => {
  const { size } = useContext(containerQueryContext) || {}

  return (
    <WrappedComponent
      {...props}
      data-containersize={size}
      ref={ref}
    />
  )
})

export default withContainerQuerySize
