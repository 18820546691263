import SkipToButton from './SkipToButton'

const SkipToEchoSidebar = () => (
  <SkipToButton
    findTarget={() => document.getElementById('echo-sidebar-toggle-btn')}
    placement="left"
  >
    Skip to activities
  </SkipToButton>
)

export default SkipToEchoSidebar
