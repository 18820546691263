import { Routes } from 'react-router-dom'
import Container from '@mui/material/Container'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import Account from 'hss/sections/settings/Account'
import Nav from 'hss/sections/settings/Nav'
import Accessibility from 'hss/sections/settings/Accessibility'
import AssignmentsAndGrading from 'hss/sections/settings/AssignmentsAndGrading'
import Integrations from 'hss/sections/settings/Integrations'
import Notifications from 'hss/sections/settings/Notifications'
import ContainerGrid from 'common/layout/ContainerGrid'
import { configToRoutes } from 'routing/TraverseRoute'
import Route404 from '../Route404'

const config = {

  base: {
    path: '/',
    element: <Account />,
  },

  account: {
    path: 'account',
    element: <Account />,
  },

  assignments: {
    path: 'assignments',
    element: <AssignmentsAndGrading />,
  },

  accessibility: {
    path: 'accessibility',
    element: <Accessibility />,
  },

  integrations: {
    path: 'integrations',
    element: <Integrations />,
  },

  notifications: {
    path: 'notifications',
    element: <Notifications />,
  },

  404: {
    path: '*',
    element: <Route404 collectionName="Settings" />,
  },

}

const routes = configToRoutes(config)

const SettingsRoutes = () => (
  <ErrorBoundary moduleName="SettingsRoutes">

    <Container>
      <ContainerGrid leftSize={3}>

        <Nav />

        <div style={{ flexGrow: 1, marginTop: '2rem' }}>

          <Routes>

            {routes}

          </Routes>

        </div>

      </ContainerGrid>
    </Container>

  </ErrorBoundary>
)

export default SettingsRoutes
