import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { actions } from 'reducers/interactions'
import { CONTENT_TYPE_ECHO, CONTENT_TYPE_SUBSECTION, INTERACTION_TYPE_ANNOTATION } from 'core/consts'
import { getAnnotationsForUserAssignment } from 'selectors/interactions'
import { getContentNav } from 'selectors/contentViewer'
import { getContentViewerParams } from 'selectors/contentViewerParams'
import { filter, flatten, map, reduce } from 'fp/arrays'

/* istanbul ignore next */
const getSubsectionContent = content => !content
  ? null
  : content.contentType === CONTENT_TYPE_SUBSECTION || content.contentType === CONTENT_TYPE_ECHO
    ? [content]
    : compose(flatten, map(getSubsectionContent))(content.children)

const useAnnotations = ({ userAssignmentId }) => {
  const dispatch = useDispatch()
  const { contentId } = useSelector(getContentViewerParams()) || {}
  const sections = useSelector(getContentNav({ contentId }))

  useEffect(() => {
    dispatch(actions.fetchInteractions({ interactionType: INTERACTION_TYPE_ANNOTATION, userAssignmentId }))
  }, [dispatch, userAssignmentId])

  const annotations = useSelector(getAnnotationsForUserAssignment({ userAssignmentId }))

  // to get them in the same order as the chapter sections
  /* istanbul ignore next */
  const subsectionsWithAnnotations = compose(
    filter(({ id }) => annotations[id]?.length),
    reduce((acc, { children }) => [
      ...acc,
      ...compose(flatten, map(getSubsectionContent))(children)], []),
  )(sections)

  return { annotations, subsectionsWithAnnotations }
}

export default useAnnotations
