import PropTypes from 'prop-types'
import { compose } from 'redux'
import { inputVariantShape, interactiveTypeShape } from 'core/shapes'

export const allowedInteractivesShape = PropTypes.oneOfType([
  PropTypes.arrayOf(interactiveTypeShape),
  PropTypes.oneOf(['none']),
])

export const fauxEvent = () => ({ preventDefault: f => f })

export const getWordList = compose(
  cleanedText => cleanedText.match(/\S+/g) || [],
  plainText => plainText.replace(/(?:\r\n|\r|\n)/g, ' ').trim(),
  content => content.getPlainText(''),
  editorState => editorState.getCurrentContent(),
)

export const getCharacterCount = compose(
  a => a.join(' ').length,
  getWordList,
)

export const toolbarPropTypes = {
  allowedInteractives: allowedInteractivesShape,
  changeIndent: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  editorState: PropTypes.object.isRequired,
  excludeToolButtons: PropTypes.arrayOf(PropTypes.string),
  features: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
  toggleBlockType: PropTypes.func.isRequired,
  toggleIndentFirstLine: PropTypes.func.isRequired,
  toggleInlineStyle: PropTypes.func.isRequired,
  toggleLinkDialog: PropTypes.func.isRequired,
  variant: inputVariantShape.isRequired,
}

export const toolbarButtonPropTypes = {
  editorState: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
  toggleBlockType: PropTypes.func.isRequired,
}
