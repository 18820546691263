import { Maybe } from 'monet'
import { useSelector } from 'react-redux'
import { getParentOfContentByType } from 'selectors/contentViewer'
import { CONTENT_TYPE_CHAPTER } from 'core/consts'
import { pick, renameKeys, set } from 'fp/objects'
import useContent from 'hooks/useContent'
import { getAggregatedStandardsFromContentChildren } from 'selectors/standards'
import { curry } from 'fp/utils'

const useChapterStandards = ({ contentType, contentId }) => {
  const chapterParams = Maybe.fromFalsy(useSelector(getParentOfContentByType({
    contentType,
    contentId,
    parentContentType: CONTENT_TYPE_CHAPTER,
  })))
    .map(c => set('contentId', c.id)(c))
    .map(pick('contentId', 'contentType'))
    .map(set('queryParams.childDepth', 999)) // Content may not already be loaded, e.g. when previewing inside block editor
    .orJust({ disableFetch: true })

  const standardsParams = Maybe.fromFalsy(useContent(chapterParams))
    .map(pick('contentType', 'id'))
    .map(curry(renameKeys, 2, { id: 'contentId' }))
    .orJust({})

  return useSelector(getAggregatedStandardsFromContentChildren(standardsParams))
}

export default useChapterStandards
