import { useCallback, useContext, useEffect, useId, useLayoutEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import MuiRating from '@mui/material/Rating'
import { styled } from '@mui/material/styles'
import { Star } from 'react-feather'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import { compose } from 'redux'
import Typography from '@mui/material/Typography'
import Square from 'hss/images/controls/checkbox/square.svg'
import SquareFilled from 'hss/images/controls/checkbox/square-filled.svg'
import { curryRight, fallbackTo, matches, when, whenPresent } from 'fp/utils'
import { numberOrString, rubricCriteriaShape } from 'core/shapes'
import { a11yContext } from 'core/a11y'
import { getAt } from 'fp/arrays'
import { get } from 'fp/objects'
import withProps from 'hoc/withProps'

const StarFilled = withProps(Star, { className: 'filled' })

const IndividualRatingLabel = ({
  getLabelText,
  ratings,
  showScores = false,
  value,
  ...rest
}) => {
  const tooltipText = useMemo(() => getLabelText(value), [getLabelText, value])

  return (
    <Tooltip
      arrow
      title={tooltipText}
    >
      <Box>
        {Boolean(showScores) && (
          <Typography
            align="center"
            color="primary"
            flex="1"
            pb={1}
            sx={{ display: 'block' }}
            variant="toc-title"
          >
            {ratings[value - 1]?.score || 0}
          </Typography>
        )}
        <span {...rest} />
      </Box>
    </Tooltip>
  )
}

IndividualRatingLabel.propTypes = {
  getLabelText: PropTypes.func.isRequired,
  ratings: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    score: PropTypes.number.isRequired,
  })).isRequired,
  showScores: PropTypes.bool,
  value: PropTypes.number.isRequired,
}

const Container = styled(
  Box,
  { name: 'rubrics-Container' },
)(({ theme: { mixins: { transition }, palette } }) => ({
  svg: {
    fill: 'transparent',
    ...transition('fill'),
    '&.filled': {
      fill: palette.common.yellow,
      stroke: palette.common.yellow,
      boxShadow: 'none',
    },
  },
}))

const Rating = (props) => {
  const {
    disabled = false,
    nonRubric = false,
    onChange,
    readOnly = false,
    row,
    showScores = true,
    style,
    value: selectedRatingId,
    variant = 'star',
    ...rest
  } = props

  // Don't start with value undefined, else MuiRating will complain about going from uncontrolled to controlled.
  const [value, setValue] = useState(null)
  const [ratingVariant, setRatingVariant] = useState('')
  const { tabModeEnabled } = useContext(a11yContext) || {}
  const { options: ratings } = row
  const ratingName = useId()

  useLayoutEffect(
    () => {
      const newValue = ratings.findIndex(matches('id', selectedRatingId)) + 1 // MuiRating's values are 1-based.

      setValue(newValue || null)
    },
    [ratings, selectedRatingId],
  )

  const EmptyIcon = variant === 'square' ? Square : Star
  const FilledIcon = variant === 'square' ? SquareFilled : StarFilled

  const getLabelText = useCallback((ratingIdx) => {
    if (nonRubric && ratingIdx > 0) return ratings[ratingIdx - 1]?.description

    const score = ratings[ratingIdx - 1]?.score
    return ratingIdx > 0
      ? `${score} Point${score > 1 ? 's' : ''}: ${ratings[ratingIdx - 1].description}`
      : ''
  }, [nonRubric, ratings])

  useEffect(() => {
    when(tabModeEnabled, setRatingVariant, 'tab-mode')
  }, [tabModeEnabled])

  const onRatingChange = useCallback((event, newValue) => {
    setValue(newValue)
    const newRatingId = compose(
      get('id'),
      fallbackTo({}),
      curryRight(getAt, newValue - 1),
    )(ratings)
    whenPresent(onChange, newRatingId)
  }, [onChange, ratings])

  const handleMouseMove = useCallback(() => {
    when(ratingVariant !== '', setRatingVariant, '')
  }, [ratingVariant])

  return (
    <Container onMouseMove={handleMouseMove}>
      <MuiRating
        disabled={disabled || (!onChange && !readOnly)}
        emptyIcon={<EmptyIcon />}
        getLabelText={getLabelText}
        icon={<FilledIcon />}
        IconContainerComponent={withProps(IndividualRatingLabel, { getLabelText, ratings, showScores, value })}
        max={ratings.length}
        name={ratingName}
        onChange={onRatingChange}
        readOnly={readOnly}
        value={value}
        variant={ratingVariant}
        {...rest}
        style={readOnly ? { ...style, pointerEvents: 'auto' } : style}
      />
    </Container>
  )
}

Rating.propTypes = {
  disabled: PropTypes.bool,
  nonRubric: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  row: rubricCriteriaShape.isRequired,
  showScores: PropTypes.bool,
  style: PropTypes.object,
  value: numberOrString,
  variant: PropTypes.oneOf(['square', 'star']),
}

export default Rating
