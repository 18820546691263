import { useLayoutEffect, useState } from 'react'

const useSVGTextLength = (ref) => {
  const [widths, setWidths] = useState()
  const [calculating, setCalculating] = useState(true)

  useLayoutEffect(() => {
    /* istanbul ignore else */
    if (ref.current && ref.current.nodeName === 'text') {
      const textWidths = ref.current.children.length === 0 && ref.current.textContent
        ? { [ref.current.textContent]: ref.current.getComputedTextLength() }
        : [...ref.current.children]
          .reduce((acc, node) => (node.textContent && node.nodeName === 'tspan' && !acc[node.textContent]
            ? { ...acc, [node.textContent]: node.getComputedTextLength() } : acc), {})

      setWidths(textWidths)
      setCalculating(false)
    }
  }, [ref, calculating])
  return [widths, calculating, setCalculating]
}

export default useSVGTextLength
