import { useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import { isObject } from 'fp/objects'

const useStateWithDynamicDefault = (defaultVal) => {
  const [state, setState] = useState(defaultVal)

  useEffect(() => {
    if (!isObject(defaultVal) || !isEqual(defaultVal, state)) {
      setState(defaultVal)
    }
    /**
     * We're disabling this rule here because we ONLY want this to fire if defaultVal
     * changes, NOT when state does.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVal])

  return [state, setState]
}

export default useStateWithDynamicDefault
