import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { actions } from 'reducers/notifications'
import { stateNotifications } from 'selectors/index'
import { filter, push } from 'fp/arrays'
import { whenPresent } from 'fp/utils'
import { notEquals } from 'fp/objects'

export const anchorOrigin = {
  horizontal: 'left',
  vertical: 'bottom',
}

const Alerts = () => {
  const dispatch = useDispatch()
  const [displayed, setDisplayed] = useState([])
  const { alerts } = useSelector(stateNotifications)
  const { closeSnackbar, enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    alerts.forEach(({
      key,
      message,
      options = {
        variant: 'success',
      },
      dismissed = false,
    }) => {
      if (dismissed) {
        closeSnackbar(key)
        return
      }

      if (displayed.includes(key)) return

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, k) => whenPresent(options.onClose, event, reason, k),
        onExited: (event, k) => {
          dispatch(actions.removeAlert({ key: k }))
          setDisplayed(filter(notEquals(k)))
        },
      })

      setDisplayed(push(key))
    })
  }, [alerts, closeSnackbar, dispatch, displayed, enqueueSnackbar])

  return null
}

export default Alerts
