import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import { useRoutes } from 'react-router-dom'
import { useContext } from 'react'
import { useSelector } from 'react-redux'
import NavLink from 'common/navigation/links/NavLink'
import {
  ABILITY_TEACHER_INTERFACE,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_SOURCE,
  TOGGLE_STATE_PRESENTER_MODE,
} from 'core/consts'
import useContent from 'hooks/useContent'
import { assignmentEditContext } from 'hss/AssignmentEditor/assignmentEditContext'
import { getLocalSetting } from 'selectors/localSettings'
import { getContextualAssignment } from 'selectors/assignments'
import useAbilityChecker from 'hooks/useAbilityChecker'

const TeachContent = () => {
  const { contentType } = useContent({ disableFetch: true }) || {}
  const isEditingAssignment = Boolean(useContext(assignmentEditContext))
  const { id: assignmentId } = useSelector(getContextualAssignment) || {}

  const presenterModeEnabled = useSelector(getLocalSetting(TOGGLE_STATE_PRESENTER_MODE))

  const has = useAbilityChecker()
  const hasContentViewTeach = has(ABILITY_TEACHER_INTERFACE)
  const presentingAssignment = hasContentViewTeach && presenterModeEnabled

  return presentingAssignment
    ? null
    : (
      <List>
        {Boolean(isEditingAssignment && !presenterModeEnabled) && (
          <>
            <ListItem variant="nav">
              <NavLink to="settings">
                Assignment Settings
              </NavLink>
            </ListItem>
            {Boolean(contentType === CONTENT_TYPE_CHAPTER && assignmentId) && (
              <ListItem variant="nav">
                <NavLink to="grading">
                  Chapter Grading
                </NavLink>
              </ListItem>
            )}
          </>
        )}
      </List>
    )
}

const assignableContentRoutePaths = [
  `${CONTENT_TYPE_CHAPTER}/:contentId/*`,
  `${CONTENT_TYPE_SOURCE}/:contentId/*`,
  `${CONTENT_TYPE_ECHO}/:echoId/*`,
  '*',
]

const Teach = () => {
  const isInAssignment = !!useSelector(getContextualAssignment)

  /**
   * The nav links in TeachContent are relative.
   *
   * If we're looking at an assignment, links need to be relative to the current route
   * (e.g. assignment-editor/{assignment id}).
   *
   * If we're not, then we're probably looking at a page of assignable content that isn't in an assignment (yet),
   * and the links need to be relative to the content
   * (e.g. assignment-creator/chapter/{chapter id}).
   * */
  const routePaths = isInAssignment
    ? ['*']
    : assignableContentRoutePaths

  return useRoutes(routePaths.map(path => ({
    path,
    element: <TeachContent />,
  })))
}

export default Teach
