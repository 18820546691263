import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/styled-engine'
import loaderData from 'hss/images/indicators/loader.svg'

const dashAnimation = keyframes`
0% {
  stroke-dashoffset: 600;
}

100% {
  stroke-dashoffset: 0;
}
`

const gradientAnimation = keyframes`
  0% {
    stroke: #1C5BFF;
    offset: 0%;
  }
  20% {
    stroke: #7367F0; }
  40% {
    stroke: #FD8D43; }
  60% {
    stroke: #FFCE13; }
  80% {
    stroke: #16C8C8; }
  100% {
    stroke: #1C5BFF;
    offset: 100%;
  }
`

const PageLoader = styled(
  loaderData,
  { name: 'pageloader' },
)(() => ({
  width: 96,
  height: 96,

  '& path, & stop': {
    stopColor: '#1C5BFF',
    offset: 0,
    strokeDasharray: 300,
    animation: `${dashAnimation} 1.5s linear infinite, ${gradientAnimation} 6s linear infinite`,

  },
}))

export default PageLoader
