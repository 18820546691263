import { call, put, takeEvery, takeLeading } from 'redux-saga/effects'
import { compose } from 'redux'
import actionTypes from 'reducers/actionTypes'
import { buildUrl } from 'fp/internet'
import { restEndpoint } from 'reducers/utils'
import { actions } from 'reducers/notifications'
import { get, omit } from 'fp/objects'
import { map } from 'fp/arrays'
import { dangerouslyCallApi } from './api'
import { success } from './utils'

export function* handleFetch(action) {
  const { userAssignmentId } = action

  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(`${restEndpoint.userAssignments}/${userAssignmentId}`),
  })
}

export function* handleFetchList(action) {
  const { queryParams } = action
  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(restEndpoint.userAssignments, queryParams, false),
  })
}

export function* handleFetchListSuccess(action) {
  // The response from this fetch will include `assignment` and `content` data for each userAssignment;
  // This shallow data needs to flow into the appropriate areas of the store (i.e. into course, chapter, etc.)
  const { response: { data } } = action
  const assignmentData = map(compose(
    omit('content'),
    get('assignment'),
  ))(data)
  const contentData = data
    .reduce((acc, item) => {
      const content = get('assignment.content')(item)

      // Only items with contentType of `chapter` potentially have parent data
      // Note: if SECTION or SUBSECTION content types become assignable, this will need to be updated
      const parentData = content?.parent
      const grandparentData = content?.parent?.parent

      return (
        [
          acc,
          content,
          parentData,
          grandparentData,
        ]
          .flat()
          .filter(Boolean)
      )
    }, [])

  yield put({
    type: success(actionTypes.ASSIGNMENT_FETCH_LIST),
    response: { data: assignmentData, metadata: {} },
  })

  yield put({
    type: success(actionTypes.CONTENT_FETCH_LIST),
    response: { data: contentData, metadata: {} },
  })
}

function* patchAssignment(action, body) {
  const { userAssignmentId } = action

  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: 'PATCH',
      body,
    },
    url: `${restEndpoint.userAssignments}/${userAssignmentId}`,
  })
}

export function* handleSubmit(action) {
  yield patchAssignment(action, { submittedDate: new Date() })
}

export function* handleReopen(action) {
  yield patchAssignment(action, { submittedDate: null })
}

export function* handleSubmitSuccess() {
  yield put(actions.addAlert({
    message: 'Assignment submitted',
  }))
}

export function* handleReopenSuccess() {
  yield put(actions.addAlert({
    message: 'Assignment reopened',
  }))
}

export function* handleUpdateProgress(action) {
  const { payload: { progress, userAssignmentId } } = action

  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: 'PATCH',
      body: {
        progress,
      },
    },
    url: `${restEndpoint.userAssignments}/${userAssignmentId}`,
  })
}

export function* handleUpdateLastViewedContent(action) {
  const { payload: { lastViewedContentId, userAssignmentId } } = action

  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: 'PATCH',
      body: {
        lastViewedContentId,
      },
    },
    url: `${restEndpoint.userAssignments}/${userAssignmentId}`,
  })
}

/* istanbul ignore next line */
function* userAssignmentSaga() {
  yield takeEvery(actionTypes.USER_ASSIGNMENT_FETCH, handleFetch)
  yield takeEvery(actionTypes.USER_ASSIGNMENT_FETCH_LIST, handleFetchList)
  yield takeEvery(success(actionTypes.USER_ASSIGNMENT_FETCH_LIST), handleFetchListSuccess)
  yield takeLeading(actionTypes.USER_ASSIGNMENT_SUBMIT, handleSubmit)
  yield takeLeading(actionTypes.USER_ASSIGNMENT_REOPEN, handleReopen)
  yield takeLeading(success(actionTypes.USER_ASSIGNMENT_REOPEN), handleReopenSuccess)
  yield takeLeading(success(actionTypes.USER_ASSIGNMENT_SUBMIT), handleSubmitSuccess)
  yield takeEvery(actionTypes.USER_ASSIGNMENT_UPDATE_PROGRESS, handleUpdateProgress)
  yield takeEvery(actionTypes.USER_ASSIGNMENT_UPDATE_LAST_VIEWED_CONTENT, handleUpdateLastViewedContent)
}

export default userAssignmentSaga
