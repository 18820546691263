import { switches } from './FormControlLabel'

const overrides = (theme) => {
  const { mixins: { borderS, important, rem, sizeR }, palette } = theme
  return {
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
        'data-color': 'secondary',
      },

      styleOverrides: {
        root: {
          ...sizeR(2.4, 4.8),
          padding: 0,
          borderRadius: rem(1.2),
          marginRight: 10,
          marginLeft: 10,
        },

        switchBase: {
          padding: 0,

          '&.Mui-checked': {
            left: 2.5,

            '.MuiSwitch-thumb': {
              boxShadow: 'none',
            },

            '&+.MuiSwitch-track': {
              opacity: 1,
            },
          },

          '&.Mui-disabled': {
            '.MuiSwitch-thumb': {
              borderColor: palette.grey[4],
            },

            '&+.MuiSwitch-track': {
              backgroundColor: palette.grey[4],
              borderColor: palette.grey[4],
              opacity: 1,
            },
          },

          '&:hover, &:hover.Mui-checked': {
            '&+.MuiSwitch-track, .MuiSwitch-thumb': {
              background: important(palette.common.white),
            },
          },
        },

        thumb: {
          ...sizeR(2.4),
          backgroundColor: palette.common.white,
          boxShadow: 'none',
          ...borderS(palette.grey[2], 2),
        },

        track: {
          backgroundColor: palette.grey[2],
          borderRadius: rem(1.2),
          opacity: 1,
          ...borderS(palette.grey[2], 2),
        },
      },

      variants: [
        {
          props: { 'data-color': 'primary' },
          style: switches(theme, palette.cobalt),
        },

        {
          props: { 'data-color': 'secondary' },
          style: switches(theme, palette.grey),
        },
      ],
    },
  }
}

export default overrides
