import { forwardRef } from 'react'
import { componentShape, numberOrString } from 'core/shapes'
import { isDefined } from 'fp/utils'
import ExternalLink from './ExternalLink'

const launchHelpCenter = guideId => (e) => {
  e.preventDefault()
  window.open(`/legacy-shell/#!/help-center/${isDefined(guideId) ? `guide/${guideId}` : ''}`, 'help-center', `height=${window.screen.height},width=1300,resizable=yes,scrollbars=yes,toolbar=yes,menubar=yes,location=yes`)
}

const HelpCenterLink = forwardRef(({ Renderer = ExternalLink, guideId, ...rest }, ref) => (
  <Renderer
    onClick={launchHelpCenter(guideId)}
    ref={ref}
    rel="noopener noreferrer"
    target="_blank"
    {...rest}
  />
))

HelpCenterLink.propTypes = {
  Renderer: componentShape,
  guideId: numberOrString,
}

export default HelpCenterLink
