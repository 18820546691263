/* istanbul ignore file */
import { createSelector as origCreateSelector } from '@comfy/redux-selectors'
import { when } from 'fp/utils'

export const isDevEnv = () => process.env.NODE_ENV === 'development'
export const isTestEnv = () => process.env.NODE_ENV === 'test'
export const isPreviewEnv = () => window.location.hostname.includes('traverse-test.studysync.xyz')
export const isProductionEnv = () => ['app.traverse-prod.com', 'learn.traverse-app.com'].some(prodHost => window.location.hostname === prodHost)

export const getBuildNumber = () => process.env.BUILD_NUM || 'dev'

export const createSelector = name => (...args) => {
  const selector = origCreateSelector(...args)

  when(
    [isDevEnv(), isPreviewEnv()].some(Boolean),
    Object.defineProperty,
    selector,
    'name',
    { value: name },
  )

  return selector
}

export const stateAssignments = createSelector('stateAssignments')('assignments')
export const stateContent = createSelector('stateContent')('content')
export const stateCredits = createSelector('stateCredits')('credits')
export const stateDistricts = createSelector('stateDistricts')('districts')
export const stateEchoRanking = createSelector('stateEchoRanking')('echoRanking')
export const stateErrorLog = createSelector('stateErrorLog')('errorLog')
export const stateGroups = createSelector('stateGroups')('groups')
export const stateInteractions = createSelector('stateInteractions')('interactions')
export const stateLocalSettings = createSelector('stateLocalSettings')('localSettings')
export const stateNotifications = createSelector('stateNotifications')('notifications')
export const stateReactions = createSelector('stateReactions')('reactions')
export const stateRouter = createSelector('stateRouter')('router') // react-router data
export const stateRouting = createSelector('stateRouting')('routing')
export const stateSession = createSelector('stateSession')('session')
export const stateSchools = createSelector('stateSchools')('schools')
export const stateStandards = createSelector('stateStandards')('standards')
export const stateUserAlerts = createSelector('stateUserAlerts')('userAlerts')
export const stateUserAssignments = createSelector('stateUserAssignments')('userAssignments')
export const stateUsers = createSelector('stateUsers')('users')
