import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { floatTypesShape } from 'core/shapes'
import PlayButton from './PlayButton'
import TimeLabel from './TimeLabel'
import PositionSlider from './PositionSlider'
import Volume from './Volume'
import RateControl from './RateControl'
import MuteButton from './MuteButton'

const StyledBox = styled(Box)(({ theme: { mixins: { borderS, rem }, palette } }) => ({
  ...borderS(palette.grey[4]),
  display: 'block',
  padding: rem(1.5),
  boxSizing: 'border-box',
}))

const RegularPlayer = ({
  contentWrappingAllowed = false,
  float = 'none',
  narrow = false,
}) => {
  const isSmallScreen = (contentWrappingAllowed && float !== 'none') || narrow

  return (
    <StyledBox
      sx={isSmallScreen
        ? null
        : { display: 'flex' }}
    >
      <Stack
        alignItems="center"
        direction="row"
        flexGrow="1"
        gap={2}
        mr={3}
      >
        <PlayButton />

        <TimeLabel showCurrentTime />

        <PositionSlider
          sx={{
            borderRadius: '0 !important',
            p: '0 !important',
            display: 'block',
            flexGrow: 1,
            minWidth: 48,
          }}
        />

        <TimeLabel />

        <Stack
          alignItems="center"
          direction="row"
        >
          <MuteButton />

          <Volume />
        </Stack>
      </Stack>

      {Boolean(isSmallScreen) && (
        <Divider sx={{ flexBasis: '100%', marginTop: '1rem', marginBottom: '2rem' }} />
      )}

      <Stack
        alignItems="center"
        direction="row"
        gap={3}
        sx={isSmallScreen
          ? { width: '100%', justifyContent: 'center' }
          : null}
      >
        <RateControl />

        {/* TODO: Put this back in when we have transcripts */}
        {/* <TranscriptSwitch /> */}

      </Stack>

    </StyledBox>
  )
}

RegularPlayer.propTypes = {
  contentWrappingAllowed: PropTypes.bool,
  float: floatTypesShape,
  narrow: PropTypes.bool,
}

export default RegularPlayer
