import { useCallback } from 'react'
import { isTestEnv } from 'selectors/index'
import { get } from 'fp/objects'
import useCurrentUser from './useCurrentUser'

const useSpeech = () => {
  const { user: { preferences = {} } } = useCurrentUser()
  const defaultRate = get('audioDescriptionRate')(preferences)
  const defaultVolume = get('audioDescriptionVolume')(preferences)

  const say = useCallback((text, rate = defaultRate, volume = defaultVolume) => {
    if (window.speechSynthesis && rate && volume) {
      const utterance = new SpeechSynthesisUtterance(text)
      utterance.rate = rate
      utterance.volume = volume
      window.speechSynthesis.speak(utterance)

      return utterance
    }

    /* istanbul ignore next */
    if (rate && volume && !isTestEnv()) {
    // eslint-disable-next-line no-console
      console.warn('This web browser does not support speech synthesis.')
    }
    return null
  }, [defaultRate, defaultVolume])

  return { say }
}

export default useSpeech
