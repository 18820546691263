import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { position } from 'polished'
import { X as Close } from 'react-feather'
import { styled } from '@mui/material/styles'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'

const Button = styled(
  IconButton,
  { name: 'dialogs-CloseButton' },
)(({ theme: { mixins: { px, rem, transition } } }) => ({
  color: 'currentColor',
  cursor: 'pointer',
  fontSize: rem(3),
  lineHeight: 0,
  margin: 0,
  ...position('absolute', rem(1.5), rem(1.2), null, null),
  ...transition('all', 150),
  zIndex: 1,
  '&::before': {
    fontSize: rem(2),
    margin: 0,
    position: 'relative',
    lineHeight: px(1),
  },
}))

const CloseButton = forwardRef(({ color, dense = false, ...rest }, ref) => (
  <Button
    ref={ref}
    tabIndex={0}
    title="Close"
    {...rest}
    size="large"
    sx={dense
      ? {
        top: '0.5rem',
        right: '0.2rem',
      }
      : null}
  >
    <Icon><Close color={color} /></Icon>
  </Button>
))

CloseButton.propTypes = {
  color: PropTypes.string,
  dense: PropTypes.bool,
}

export default CloseButton
