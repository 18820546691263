import PropTypes from 'prop-types'
import { useCallback, useContext } from 'react'
import actionTypes from 'reducers/actionTypes'
import { get } from 'fp/objects'
import { context as searchContext } from 'hss/views/Search/SearchProvider'
import useReduxPromise from 'hooks/useReduxPromise'
import { whenPresent } from 'fp/utils'

const withSaving = (reducer, fieldName = undefined, rowKeyField = 'id', items = undefined) => (WrappedComponent) => {
  const Enhanced = ({ disabled = false, value, ...props }) => {
    const valueField = fieldName || get('column.id')(props)
    const dispatch = useReduxPromise(actionTypes.TABLE_CELL_FIELD_CHANGE)
    const id = get(`row.original.${rowKeyField}`)(props)

    const {
      busySaving,
      optimisticSaveComplete,
      startingOptimisticSave,
      updateSearchResults,
    } = useContext(searchContext) || {}

    const handleChange = useCallback(({ target }) => {
      whenPresent(startingOptimisticSave, {
        id,
        newValue: target.value,
        rowKeyField,
        valueField,
      })

      dispatch({
        id,
        newValue: target.value,
        performSave: true,
        previousValue: value,
        reducer,
        rowKeyField,
        type: actionTypes.TABLE_CELL_FIELD_CHANGE,
        valueField,
      })
        .then(() => {
          whenPresent(optimisticSaveComplete)
        })
        .catch(() => {
          whenPresent(updateSearchResults) // this essentially rolls back the failed save
        })
    }, [
      dispatch,
      id,
      optimisticSaveComplete,
      startingOptimisticSave,
      updateSearchResults,
      value,
      valueField,
    ])

    return (
      <WrappedComponent
        {...props}
        {...{ items, rowKeyField, valueField }}
        disabled={busySaving || disabled}
        onChange={handleChange}
        value={value}
      />
    )
  }

  Enhanced.propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.any,
  }

  return Enhanced
}

export default withSaving
