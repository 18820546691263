import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import Box from '@mui/material/Box'
import { forwardRef, useCallback, useState } from 'react'
import { componentShape } from 'core/shapes'
import useReduxCallback, { BUSY } from 'hooks/useReduxCallback'
import { isDefined, when, whenPresent } from 'fp/utils'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import { mapValues } from 'fp/objects'
import { isTestEnv } from 'selectors/index'
import AppBusy from 'common/indicators/AppBusy'
import RequiredLabel from './RequiredLabel'
import { AdditionalContextProvider } from './additionalContext'
import DirtyNavigationWarning from './DirtyNavigationWarning'
import SuccessCallback from './SuccessCallback'
import { maxFormWidth } from './utils'

const inputsCannotHaveNulls = mapValues(value => isDefined(value) ? value : '')

const Form = forwardRef((props, ref) => {
  const {
    actionType,
    children,
    defaultValues = {},
    dirtyNavigationWarning = 'You have unsaved changes that would be lost if you leave this page.',
    fullWidth = false,
    initialAdditionalProviderStateForTests,
    margin = 'none',
    mode = 'onChange',
    name,
    onBeforeSubmit,
    onError,
    onSubmit,
    onSuccess,
    preventEnterSubmits = false,
    requiredLabelPosition = 'above',
    suppressDirtyNavigationWarning,
    suppressRequiredLabel = false,
    variant = 'outlined',
    ...rest
  } = props

  /**
   * If for some reason you're here to change the `mode` passed to `useForm` to
   * be "onBlur" instead of the default of "onChange", then please first see the
   * comments within withHookForm.js
   */
  const methods = useForm({ defaultValues: inputsCannotHaveNulls(defaultValues), mode })
  const { getValues, handleSubmit, reset } = methods

  const [resetting, setResetting] = useState({
    waiting: false,
    payload: null,
  })

  const handleSuccess = useCallback((payload) => {
    /**
     * Need to clear IsDirty so DirtyNavigationWarning doesn't kick in.
     * This happens in <SuccessCallback />
     */

    setResetting({
      waiting: true,
      payload,
    })

    /**
     * Clear isDirty and errors by resetting, but keep the current form values.
     *
     * Most pages will navigate away once save is successful, but some curriculum
     * editors stay resident and allow the user to continue interacting with the
     * form, hence the need to keep the current values.
     */
    reset(undefined, { keepValues: true })
  }, [reset])

  const [dispatch, status] = useReduxCallback({ actionType, onSuccess: handleSuccess, onError })

  const handleKeyDown = (e) => {
    if (preventEnterSubmits && (e.keyCode
      ? e.keyCode
      : /* istanbul ignore next */ e.which) === 13
    ) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  const doSubmit = useCallback((data, event) => {
    event.preventDefault()
    const payload = onBeforeSubmit ? onBeforeSubmit(data) : data
    when(actionType, dispatch, { payload })
    whenPresent(onSubmit, { payload }, event)
  }, [actionType, dispatch, onBeforeSubmit, onSubmit])

  return (
    <ErrorBoundary moduleName={`form ${name || '(unnamed)'}`}>
      <FormProvider {...methods}>
        <AdditionalContextProvider
          {...{
            actionType,
            dirtyNavigationWarning,
            getValues,
            margin,
            status,
            suppressDirtyNavigationWarning,
            variant,
            initialStateForTests: initialAdditionalProviderStateForTests,
          }}
        >
          <Box
            component="form"
            data-testid="form"
            name={name}
            onKeyDown={handleKeyDown}
            onSubmit={handleSubmit(doSubmit)}
            ref={ref}
            {...fullWidth ? {} : { maxWidth: maxFormWidth }}
            sx={{ '.MuiFormControl-root, .outlined': { maxWidth: maxFormWidth, display: 'block' } }}
            {...rest}
          >
            {Boolean(!suppressRequiredLabel && requiredLabelPosition === 'above')
              && <RequiredLabel className="above" />}

            {children}

            {Boolean(!suppressRequiredLabel && requiredLabelPosition === 'below')
              && <RequiredLabel className="below" />}
          </Box>

          <SuccessCallback
            {...{
              methods,
              onSuccess,
              setResetting,
              ...resetting,
            }}
          />

          {!isTestEnv() && <DirtyNavigationWarning />}

        </AdditionalContextProvider>
      </FormProvider>

      <AppBusy open={status === BUSY} />

    </ErrorBoundary>
  )
})

Form.propTypes = {
  actionType: PropTypes.string,
  children: componentShape.isRequired,
  defaultValues: PropTypes.object,
  dirtyNavigationWarning: PropTypes.string,
  fullWidth: PropTypes.bool,
  initialAdditionalProviderStateForTests: PropTypes.object,
  margin: PropTypes.oneOf([
    'dense',
    'none',
    'normal',
  ]),
  mode: PropTypes.oneOf([
    'onBlur',
    'all',
    'onSubmit',
    'onChange',
  ]),
  name: PropTypes.string,
  onBeforeSubmit: PropTypes.func,
  onError: PropTypes.func,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  preventEnterSubmits: PropTypes.bool,
  requiredLabelPosition: PropTypes.oneOf([
    'above',
    'below',
  ]),
  suppressDirtyNavigationWarning: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string), // property paths to ignore when determining dirtiness
  ]),
  suppressRequiredLabel: PropTypes.bool,
  variant: PropTypes.oneOf([
    'filled',
    'outlined',
  ]),
}

export default Form
