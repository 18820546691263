import { useContext } from 'react'
import Slider from 'common/formControls/sliders/Slider'
import { audioContext } from './AudioContextProvider'

const Volume = (props) => {
  const { loaded, muted, setVolume, volume } = useContext(audioContext)

  return (
    <Slider
      aria-valuemax="100"
      aria-valuemin="0"
      aria-valuenow={volume}
      aria-valuetext={String(volume)}
      {...props}
      disabled={!loaded || muted}
      onChange={(_, v) => setVolume(v)}
      sx={{ minWidth: 48, maxWidth: 64 }}
      value={volume}
    />
  )
}

export default Volume
