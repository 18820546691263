import PropTypes from 'prop-types'
import SlideContainer from './SlideContainer'

const ClickSlide = (props) => {
  const { index, setCurrentSlide, ...rest } = props
  return (
    <SlideContainer
      onClick={() => { setCurrentSlide(index) }}
      {...rest}
    />
  )
}

ClickSlide.propTypes = {
  index: PropTypes.number.isRequired,
  setCurrentSlide: PropTypes.func.isRequired,
}

export default ClickSlide
