import { useCallback, useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { toInt } from 'fp/strings'
import useLocalSetting from 'hooks/useLocalSetting'

const key = 'annotations-last-used-color'

const useAnnotationColorId = () => {
  const [color, setColor] = useState()

  const { palette } = useTheme()

  const [gotten, setter] = useLocalSetting(key, palette.annotations[0].colorId)

  const storeColor = useCallback((newColor) => {
    setColor(newColor)
    setter(newColor)
  }, [setter])

  useEffect(() => {
    const lastColor = gotten
    setColor(toInt(lastColor))
  }, [gotten])

  return [color, setColor, storeColor]
}

export default useAnnotationColorId
