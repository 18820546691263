import { styled } from '@mui/material/styles'
import useUser from 'hooks/useUser'
import { contentShape } from 'core/shapes'
import { capitalize } from 'fp/strings'
import { getUrlForContentEditor } from 'hss/ContentViewer/Chapter/Block/LinkToEditor'

export const helperText = 'Asset code is already in use!'

const Styled = styled(
  'div',
  { name: 'indicators-AssetCodeAlreadyUsed' },
)(({ theme: { palette } }) => ({
  th: { textAlign: 'left', paddingRight: 20 },
  details: { borderColor: palette.error.main },
  summary: { color: palette.error.main },
}))

const AssetCodeAlreadyUsed = ({ content }) => {
  const {
    contentState,
    contentSubType,
    contentType,
    createdById,
  } = content

  const createdBy = useUser({ userId: createdById })

  return (

    <Styled>

      <details>

        <summary>Details</summary>

        <table>
          <tbody>

            <tr>
              <th>Type</th>
              <td>
                <a
                  href={getUrlForContentEditor({ content })}
                  rel="noreferrer"
                  target="_blank"
                >
                  {capitalize(contentSubType)}{' '}
                  {capitalize(contentType)}
                </a>
              </td>
            </tr>
            <tr>
              <th>Creator</th>
              <td>{createdBy?.firstName} {createdBy?.lastName}</td>
            </tr>
            <tr>
              <th>State</th>
              <td>{contentState}</td>
            </tr>
          </tbody>
        </table>

      </details>

    </Styled>

  )
}

AssetCodeAlreadyUsed.propTypes = {
  content: contentShape.isRequired,
}

export default AssetCodeAlreadyUsed
