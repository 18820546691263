import { Titled } from 'react-titled'
import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider } from '@mui/material/styles'
import { HistoryRouter as Router } from 'redux-first-history/rr6'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { SnackbarProvider } from 'notistack'
import { LiveAnnouncer, LiveMessage } from 'react-aria-live'
import { useState } from 'react'
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ThemeProvider from 'styling/theming/ThemeProvider'
import CssGlobals from 'styling/CssGlobals'
import TraverseRoutes from 'routing/TraverseRoutes'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import Alerts, { anchorOrigin } from 'common/indicators/Alerts'
import { history } from 'core/store/store'
import NavDialogsProvider from 'routing/shells/NavShell/NavDialogsProvider'
import { isMobile } from 'fp/internet'
import PreviewLayer from 'common/Draggable/PreviewLayer'
// Normally when navigating to a new web page, screen readers announce the new page's title.
// But since Traverse is using client-side routing, we never load a whole new page.
// Announce page title changes using a live region instead,
// so that screen reader users will know where they are.
// (The MUI documentation site does this too.)
const MainTitle = (props) => {
  const [fullTitle, setFullTitle] = useState()
  return (
    <>
      <Titled
        title={() => 'Traverse'}
        {...props}
        onChange={setFullTitle}
      />
      {Boolean(fullTitle) && (
        <LiveMessage
          aria-live="assertive"
          message={fullTitle}
        />
      )}
    </>
  )
}
const DndBackend = isMobile() ? TouchBackend : HTML5Backend

const Bootstrap = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider>
      <DndProvider backend={DndBackend}>
        <LiveAnnouncer>

          <ErrorBoundary moduleName="App">

            <CssBaseline />

            <CssGlobals />

            <SnackbarProvider
              anchorOrigin={anchorOrigin}
              dense
              maxSnack={3}
              preventDuplicate
            >

              <Alerts />

              <Router history={history}>

                <MainTitle>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <NavDialogsProvider>
                      <TraverseRoutes />
                    </NavDialogsProvider>
                  </LocalizationProvider>

                </MainTitle>

              </Router>

            </SnackbarProvider>

          </ErrorBoundary>

        </LiveAnnouncer>
        <PreviewLayer />
      </DndProvider>
    </ThemeProvider>
  </StyledEngineProvider>
)

export default Bootstrap
