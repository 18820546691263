import * as Sentry from '@sentry/react'
import PropTypes from 'prop-types'
import { Maybe } from 'monet'
import { styled } from '@mui/material/styles'
import { border, lighten } from 'polished'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import Typography from '@mui/material/Typography'
import Headline from 'common/text/Headline'
import { getBuildNumber, isProductionEnv } from 'selectors/index'

const ErrorReport = styled(
  Paper,
  { name: 'errorHandling-ErrorBoundary' },
)(({ theme: { palette } }) => ({
  ...border(4, 'solid', palette.error.main),
  borderRadius: 4,
  backgroundColor: lighten(0.4, palette.error.main),
  color: palette.text.primary,
  margin: '6rem 3rem',
  padding: 20,
  '&>details': {
    whiteSpace: 'pre-wrap',
    paddingLeft: '2rem',
    '&>div': {
      fontFamily: 'hack, monospace',
      fontSize: '1.3rem',
    },
  },
  '&>footer': {
    marginTop: '2rem',
    fontSize: 'smaller',
  },
  '&>button': {
    marginTop: '2rem',
  },
}))

const FallbackComponent = ({ error, moduleName, resetError, eventId }) => {
  const stack = Maybe.fromNull(error.stack)
    .map(str => str.split('\n'))
    .map(stk => [error, ...stk])
    .orSome([])

  const isProd = isProductionEnv()

  return (
    <ErrorReport>
      <Headline
        mb={2}
        size={2}
        title="Something went wrong"
      >
        <DialogActions>
          {Boolean(!isProd) && (
            <Button
              onClick={resetError}
              variant="primary"
            >
              Try again
            </Button>
          )}

          <Button
            onClick={() => {
              // eslint-disable-next-line @studysync/persnickety/use-pinnable-methods
              window.location.href = '/'
            }}
            variant="primary"
          >
            Go to Traverse Home Page
          </Button>
        </DialogActions>

        <p>This unexpected error has been reported to the Traverse team, please try again later.</p>
        <p>If the issue persists, please contact support with the following error reference ID:</p>
        <Typography
          fontFamily="monospace"
          fontSize={28}
          fontWeight={900}
        >
          {eventId}
        </Typography>

        <p>{error?.toString()}</p>

        <details open={!isProd}>
          <summary>Stack trace</summary>
          {stack
            .slice(1)
            .map((line, idx) => (
              <div key={`${idx}`}>
                {line.toString()}
              </div>
            ))}
        </details>

        <footer>
          <strong>Reported in module:</strong> <i>{moduleName}</i>
          <br />
          <strong>Build:</strong> <i>{getBuildNumber()}</i>
        </footer>

      </Headline>
    </ErrorReport>
  )
}
FallbackComponent.propTypes = {
  error: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
  resetError: PropTypes.func.isRequired,
}

const ErrorBoundary = ({
  moduleName = 'No name provided! Always add `moduleName` to every <ErrorBoundary /> to aid in debugging',
  children,
}) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => {
      scope.setTag('errorBoundaryModuleName', moduleName)
    }}
    // eslint-disable-next-line react/no-unstable-nested-components
    fallback={({ error, resetError, eventId }) => (
      <FallbackComponent
        error={error}
        eventId={eventId}
        moduleName={moduleName}
        resetError={resetError}
      />
    )}
  >
    {children}
  </Sentry.ErrorBoundary>
)

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  moduleName: PropTypes.string,
}

export default ErrorBoundary
