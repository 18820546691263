import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import { whenPresent } from 'fp/utils'
import { context } from './ThemeProvider'

const AccentColorProvider = ({ accentColor, children }) => {
  /* istanbul ignore next */
  const { setAccentColor } = useContext(context) || {}

  useEffect(() => {
    whenPresent(setAccentColor, accentColor)
  }, [accentColor, setAccentColor])

  return children
}

AccentColorProvider.propTypes = {
  accentColor: PropTypes.oneOf([
    'babyBlue',
    'canary',
    'cobalt',
    'emerald',
    'grey',
    'tangerine',
    'teal',
  ]).isRequired,
}

export default AccentColorProvider
