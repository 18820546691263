import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

const ImageContainer = styled(
  Box,
  { name: 'Common-ImageContainer' },
)(({ theme: { mixins: { rem } } }) => ({
  position: 'relative',
  marginTop: rem(3.2),
  userSelect: 'none',
  maxWidth: '96vw',
  lineHeight: 0,
  img: { width: '100%' },
}))

export default ImageContainer
