import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import PropTypes from 'prop-types'
import { isDefined } from 'fp/utils'
import { omit, set } from 'fp/objects'

const availableFields = {
  caption: 'Caption',
  imageAltText: 'Alt text',
  longTextAlternative: 'Long alt',
}

const FieldPicker = (props) => {
  const {
    fieldName,
    metadataAssignments,
    setMetadataAssignments,
  } = props

  const handleChange = (key, checked) => {
    if (checked) {
      setMetadataAssignments(set(key, fieldName))
    } else {
      setMetadataAssignments(omit(key))
    }
  }

  return (
    <FormGroup>
      {Object.entries(availableFields).map(([key, label]) => (
        <FormControlLabel
          control={(
            <Checkbox
              disabled={metadataAssignments[key] !== fieldName
                && isDefined(metadataAssignments[key])}
              onChange={({ target }) => handleChange(key, target.checked)}
            />
          )}
          key={key}
          label={label}
          size="small"
        />
      ))}
    </FormGroup>
  )
}

FieldPicker.propTypes = {
  fieldName: PropTypes.string.isRequired,
  metadataAssignments: PropTypes.object.isRequired,
  setMetadataAssignments: PropTypes.func.isRequired,
}

export default FieldPicker
