/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import { forwardRef, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import useNavigation from 'hooks/useNavigation'

/**
 * Largely derived from react-router-dom/Link
 * https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/modules/Link.js
 *
 * Modified so that it plays nice with pinned routes
 */

export const isModifiedEvent = event => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

const Link = forwardRef(({
  onClick,
  replace,
  skipTransition,
  style: propStyle,
  text,
  to,
  disabled,
  ...rest
}, ref) => {
  const { hrefFromTo, navigate } = useNavigation()

  const href = useMemo(() => hrefFromTo(to), [hrefFromTo, to])

  const handleClick = (event) => {
    try {
      if (onClick) onClick(event)
    } catch (ex) {
      event.preventDefault()
      throw ex
    }

    if (
      !event.defaultPrevented // onClick prevented default
      && event.button === 0 // ignore everything but left clicks
      && !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault()

      if (rest.href !== '#') {
        navigate(to, { replace, skipTransition })
      }
    }
  }

  const style = {
    ...propStyle,
    ...(text ? { color: 'currentColor' } : null),
  }

  return (
    <Typography
      {...{
        ...rest,
        role: 'link',
        ...disabled
          ? {
            disabled: true,
            variant: 'nav-item-uppercase-semibold',
          }
          : {
            component: 'a',
            variant: 'link',
            href,
            onClick: handleClick,
            ref,
            style,
          },
      }}
    />
  )
})

Link.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  replace: PropTypes.bool,
  skipTransition: PropTypes.bool,
  style: PropTypes.object,
  text: PropTypes.bool,
  to: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
}

export default Link
