/* istanbul ignore file */
import { Suspense, forwardRef, lazy, useId } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import BusySpinner from 'common/indicators/BusySpinner'
import { componentShape } from 'core/shapes'

const DraftWrapper = lazy(() => import(/* webpackChunkName: "DraftWrapper" */ './DraftWrapper'))

const RichTextEdit = forwardRef(({
  disabled = false,
  helperText,
  label,
  required = false,
  ...rest
}, ref) => {
  const labelId = useId()
  const descriptionId = useId()

  return (
    <>
      {Boolean(label) && (
        <Box
          mb={1}
          mt={2}
        >
          <InputLabel
            disabled={disabled}
            id={labelId}
            required={required}
          >
            {label}
          </InputLabel>
        </Box>
      )}
      <Suspense fallback={<BusySpinner />}>
        <DraftWrapper
          ariaDescribedBy={helperText ? descriptionId : undefined}
          ariaLabelledBy={label ? labelId : undefined}
          disabled={disabled}
          required={required}
          {...rest}
          ref={ref}
        />
      </Suspense>
      {Boolean(helperText) && <FormHelperText id={descriptionId}>{helperText}</FormHelperText>}
    </>
  )
})

RichTextEdit.propTypes = {
  disabled: PropTypes.bool,
  helperText: componentShape,
  label: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default RichTextEdit
