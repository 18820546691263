import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { componentShape, textAlignShape } from 'core/shapes'
import Headline from './Headline'

const DualHeadline = (props) => {
  const {
    children,
    multiline = false,
    subtitle,
    textAlign = 'inherit',
    title1,
    title2,
    ...rest
  } = props

  return (
    <Headline
      {...rest}
      hasSubtitle={Boolean(subtitle)}
      textAlign={textAlign}
      title={(
        <>
          <span className="tr-typography tr-headline--weight-light">{title1}</span>
          {multiline ? <br /> : ' '}
          {title2}
        </>
      )}
      variant="advanced-heading"
    >

      {Boolean(subtitle) && (
        <Typography
          component="div"
          textAlign={textAlign}
          variant="heading-subtitle"
        >
          {subtitle}
        </Typography>
      )}

      {children}

    </Headline>
  )
}

DualHeadline.propTypes = {
  children: componentShape,
  multiline: PropTypes.bool,
  subtitle: componentShape,
  textAlign: textAlignShape,
  title1: componentShape.isRequired,
  title2: componentShape.isRequired,
}

export default DualHeadline
