const overrides = ({ shadows }) => ({
  MuiAvatar: {
    variants: [
      {
        props: { variant: 'shadow-1' },
        style: {
          boxShadow: shadows[1],
        },
      },
    ],
  },
})

export default overrides
