import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react'
import {
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import PropTypes, { shape } from 'prop-types'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { compose } from 'redux'
import Centered from 'common/layout/Centered'
import { fallbackTo, isDefined, matches } from 'fp/utils'
import { find, first, second } from 'fp/arrays'
import { isString } from 'fp/strings'
import { useContainerQuery } from 'common/layout/ContainerQuery'
import { componentShape, squeryShape } from 'core/shapes'
import useSearchingText from 'hss/views/Search/useSearchingText'
import { alter } from 'core/store/search/squery'
import { get } from 'fp/objects'
import TableView from './TableView'
import CardView from './CardView'

const updateSqueryFromSortingUpdaterFn = (sortingUpdater, prevSort, columns, squery) => {
  const nextSort = sortingUpdater(prevSort)
  let nextSquery

  if (nextSort.length) {
    const [fieldName, direction] = first(nextSort
      .map(({ id, desc }) => [id, desc ? 'desc' : 'asc']))

    const sortFieldName = compose(
      fallbackTo(fieldName),
      get('meta.sortFieldName'),
      find(matches('id', fieldName)),
    )(columns)

    nextSquery = alter.set.orderBy(sortFieldName, direction)(squery)
  } else {
    nextSquery = alter.remove.orderBy()(squery)
  }

  return [nextSort, nextSquery]
}

const DataTable = forwardRef(({
  columns,
  containerProps = {},
  data = [],
  disableCardView = false,
  id: tableId,
  initialSorting = [],
  noDataText = 'There is no data available.',
  onRowSelectionChanged,
  preferCardView = false,
  setSquery,
  squery,
  tableOptions = {},
  viewMode = 'table',
  ...rest
}, ref) => {
  const isSmallScreen = useContainerQuery().down('md')
  const [sorting, setSorting] = useState(initialSorting || [])
  const [expanded, setExpanded] = useState({})

  const [rowSelection, setRowSelection] = useState({})

  const enableRowSelection = isDefined(onRowSelectionChanged)
  const renderAsCards = ((preferCardView || isSmallScreen || viewMode === 'list') && !disableCardView)

  const searchText = useSearchingText()

  const handleSortChange = useCallback((sortingUpdater) => {
    const [nextSort, nextSquery] = updateSqueryFromSortingUpdaterFn(
      sortingUpdater,
      sorting,
      columns,
      squery,
    )

    setSquery(nextSquery)

    setSorting(nextSort)
  }, [columns, setSquery, squery, sorting])

  const table = useReactTable({
    columns,
    data,
    enableRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    onRowSelectionChange: setRowSelection,
    ...tableOptions,
    renderAsCards,
    state: {
      expanded,
      globalFilter: searchText,
      rowSelection,
      sorting,
    },
    onSortingChange: squery ? handleSortChange : setSorting,
    getSortedRowModel: getSortedRowModel(),
    // debugTable: true,
  })

  const allColumns = table.getAllColumns()

  useEffect(() => {
    onRowSelectionChanged?.(Object.entries(rowSelection)
      .filter(second)
      .map(([idx]) => data[idx]))
  }, [data, rowSelection, onRowSelectionChanged])

  const invalidSortColumn = useMemo(
    () => find(column => column.canSort
      && !(
        isString(column.Header)
        || isString(column.accessibleName)
      ))(allColumns),
    [allColumns],
  )
  if (invalidSortColumn) {
    // eslint-disable-next-line no-console
    console.error(
      `%c Invalid column definition! Sortable column '${invalidSortColumn.id}' requires either a string Header prop or a string accessibleName prop.`,
      'color: red',
      '',
    )
    return 'Error — see console for details'
  }

  const Component = renderAsCards ? CardView : TableView

  return data.length
    ? (
      <Box
        mt={containerProps.noMarginTop ? 0 : 4}
        ref={ref}
      >
        <Component
          table={table}
          tableId={tableId}
          {...rest}
        />
      </Box>
    )
    : <Centered ref={ref}><Typography role="status">{noDataText}</Typography></Centered>
})

DataTable.propTypes = {
  CardRenderer: componentShape,
  cardRendererProps: PropTypes.object,
  columns: PropTypes.array.isRequired,
  containerProps: PropTypes.shape({
    noMarginTop: PropTypes.bool,
  }),
  data: PropTypes.array,
  disableCardView: PropTypes.bool,
  id: PropTypes.string.isRequired,
  initialSorting: PropTypes.arrayOf(shape({
    id: PropTypes.string.isRequired,
    desc: PropTypes.bool,
  })),
  noDataText: PropTypes.string,
  onRowSelectionChanged: PropTypes.func,
  preferCardView: PropTypes.bool,
  setSquery: PropTypes.func,
  squery: squeryShape,
  tableOptions: PropTypes.object,
  viewMode: PropTypes.oneOf(['list', 'grid', 'table']),
}

export default DataTable
