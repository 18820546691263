import {
  call,
  put,
  race,
  select,
  spawn,
  take,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'
import {
  LOCATION_CHANGE,
  replace as replaceHistory,
} from 'redux-first-history'
import { compressToEncodedURIComponent } from 'lz-string'
import { compose } from 'redux'
import { buildUrl, cleanUrlParam } from 'fp/internet'
import {
  getLocation,
  getPreviousLocation,
  getRestEndpoint,
  getSquery,
} from 'selectors/routing'
import actionTypes from 'reducers/actionTypes'
import { actions as assignmentActions } from 'reducers/assignments'
import { actions as contentActions } from 'reducers/content'
import { actions as districtActions } from 'reducers/districts'
import {
  SETTING_KEY_CONTENT_VIEWER_EXIT,
  SETTING_KEY_CONTENT_VIEWER_LAST_VIEWED,
  localSettingsActions,
} from 'reducers/localSettings'
import { actions as groupActions } from 'reducers/groups'
import { actions as schoolActions } from 'reducers/schools'
import { actions as userActions } from 'reducers/users'
import { get, set } from 'fp/objects'
import { restEndpoint as restEndpoints } from 'reducers/utils'
import { getRootSettingsKey } from 'selectors/localSettings'
import { userAssignmentActions } from 'reducers/userAssignments'
import {
  isInContentViewer,
  wasInContentViewer,
  wouldBeInContentViewer,
} from 'selectors/contentViewerParams'
import { fallbackTo } from 'fp/utils'
import { stateRouting } from 'selectors/index'
import { getFirstSubsectionOrEcho } from 'selectors/contentViewer'
import { CONTENT_TYPE_ECHO, CONTENT_TYPE_SOURCE, CONTENT_TYPE_SUBSECTION } from 'core/consts'
import { isEmptyString } from 'fp/strings'
import userAlerts from 'reducers/userAlerts'
import { gradingUrlFrag } from 'routing/consts'
import { failure, success } from './utils'
import { getLastViewedContentState } from './routingUtils'

const SPLITTER_LAYOUT_RIGHT_SIDE_ID = 2112

const viewport = () => compose(
  fallbackTo(window),
  fallbackTo(document.getElementsByTagName('main')[0]),
  fallbackTo(document.getElementById(SPLITTER_LAYOUT_RIGHT_SIDE_ID)),
)

export function* handleLoggingRouteChange({ payload }) {
  const { location: { pathname } } = payload
  if (pathname === '/iframe.html') return // assume running within storybook

  yield put({
    type: actionTypes.LOG_SYSTEM_EVENT,
    payload: {
      type: 'route-change',
      route: pathname,
    },
  })
}

export function* handleRecordingContentViewerEntryPoint() {
  const wereInViewer = yield select(wasInContentViewer)
  const isInViewer = yield select(isInContentViewer)
  const enteringViewer = !wereInViewer && isInViewer

  if (!enteringViewer) return

  const previousLocation = yield select(getPreviousLocation)
  const rootKey = yield select(getRootSettingsKey)

  if (previousLocation) {
    // Entering content viewer -- record url for when it's time to exit

    yield put(localSettingsActions.set(
      rootKey,
      SETTING_KEY_CONTENT_VIEWER_EXIT,
      { to: previousLocation.pathname + previousLocation.search },
    ))
  }
}

export function* handleRecordLastViewForContentId() {
  const wereInViewer = yield select(wasInContentViewer)

  const previousLocation = yield select(getPreviousLocation)

  const urlOtherThanCurrent = previousLocation?.pathname || ''

  if (!wereInViewer) return

  /**
   * We were in the content viewer and have now navigated to a new subsection or
   * away entirely.  It doesn't matter where we went, we just need to record the
   * last url that was visited for that contentId.
   *
   * The timestamp is also recorded so that we can mark and sweep the settings in
   * order to help keep local storage from getting too bloated over time.
   *
   * The scroll state was already stored when we initially left the content viewer.
   */

  if (urlOtherThanCurrent.endsWith('/settings')) return // assignment editing page

  const { currentState, rootKey, stateKey } = yield call(getLastViewedContentState, urlOtherThanCurrent)

  const nextState = compose(
    set(`${stateKey}.pathname`, urlOtherThanCurrent),
    set(`${stateKey}.search`, previousLocation?.search),
    set(`${stateKey}.hash`, previousLocation?.hash),
    set(`${stateKey}.timestamp`, Date.now()),
    fallbackTo({}),
  )(currentState)

  yield put(localSettingsActions.set(rootKey, SETTING_KEY_CONTENT_VIEWER_LAST_VIEWED, nextState))
}

export function* handleRecordContentViewerScrollState({ nextLocation, isRelative }) {
  const wasInViewer = yield select(isInContentViewer)
  const wouldBeInViewer = yield select(wouldBeInContentViewer(nextLocation.pathname))
  const willBeInViewer = wouldBeInViewer || (wasInViewer && isRelative)
  const leavingViewer = wasInViewer && !willBeInViewer

  if (!leavingViewer) return

  const { currentState, rootKey, stateKey } = yield call(getLastViewedContentState)

  const scrollTop = compose(
    fallbackTo(0),
    get('scrollTop'),
    viewport(),
  )()

  const nextState = set(`${stateKey}.scrollTop`, scrollTop)(currentState || {})

  yield put(localSettingsActions.set(rootKey, SETTING_KEY_CONTENT_VIEWER_LAST_VIEWED, nextState))
}

export function* handleRestoringLastStateForContentWhenEnteringContentViewer() {
  const wasInViewer = yield select(wasInContentViewer)
  const isInViewer = yield select(isInContentViewer)
  const enteringViewer = !wasInViewer && isInViewer

  const { currentLocation, nextLocation, queryParams } = yield select(stateRouting)

  if (!enteringViewer) return

  if (currentLocation?.pathname.endsWith('/settings')) return // assignment editing page

  if (currentLocation?.pathname.endsWith(`/${gradingUrlFrag}`)) return // chapter grading page

  const isInCurrentPathName = String.prototype.includes.bind(currentLocation.pathname)
  if (['/subsection', '/echo'].some(isInCurrentPathName)) return // no need to go deeper

  const {
    assignmentId,
    contentId,
    currentState,
    stateKey,
    userAssignmentId,
  } = yield call(getLastViewedContentState)

  const lastViewed = currentState?.[stateKey]

  if (!isEmptyString(lastViewed?.pathname)) {
    const { pathname, scrollTop = 0, search = '' } = lastViewed

    yield put(replaceHistory(`${pathname}${search}`))

    /**
     * There's a slightly tricky bit here
     *
     * We want to wait until the content has rendered before scrolling the viewport.
     * Since the HTTP middleware debounces dispatching a SESSION_RESET_TIMEOUT action
     * for up to a second, we can use that as a rough indicator that the page is done.
     * Perfect?  No.  Good enough?   Seems to work fine in practice.
     *
     * Note that we don't need a race condition here because the only way we wouldn't
     * receive the SESSION_RESET_TIMEOUT action is if the browser crashed.
     */
    // eslint-disable-next-line func-names
    yield spawn(function* () {
      yield take(actionTypes.SESSION_RESET_TIMEOUT)

      /* istanbul ignore next line */
      viewport()().scrollTo(0, scrollTop)
    })
  } else {
    // We need to wait for the content to finish loading before continuing
    const { failed, succeeded } = yield race({
      succeeded: take(success(actionTypes.CONTENT_FETCH)),
      failed: take(failure(actionTypes.CONTENT_FETCH)),
    })

    if (failed) return
    const fetchedContent = succeeded.response
    let contentUrl = null

    if (fetchedContent.contentType === CONTENT_TYPE_SOURCE) {
      const firstChild = fetchedContent.children[0]
      const subsection = firstChild?.contentType === CONTENT_TYPE_SUBSECTION
        ? firstChild
        : undefined
      if (subsection) {
        contentUrl = userAssignmentId || assignmentId
          ? `${CONTENT_TYPE_SOURCE}/${fetchedContent.id}/${CONTENT_TYPE_SUBSECTION}/${subsection.id}`
          : `${CONTENT_TYPE_SUBSECTION}/${subsection.id}`
      }
    } else {
      const subsection = yield select(getFirstSubsectionOrEcho({ contentId: fetchedContent.id }))
      if (subsection) {
        contentUrl = `${CONTENT_TYPE_SUBSECTION}/${subsection.id}`
      }
    }

    const isSinglePageAssignment = (userAssignmentId || assignmentId)
      && !contentUrl
      && [CONTENT_TYPE_SOURCE, CONTENT_TYPE_ECHO].includes(fetchedContent.contentType)
    if (isSinglePageAssignment) {
      contentUrl = `${fetchedContent.contentType}/${fetchedContent.id}`
    }

    if (contentUrl) {
      /**
       * Redirect to the first subsection or echo (which btw at some point needs to
       * be expanded to include sources and anything else assignable)
       */
      const targetId = userAssignmentId || assignmentId || contentId
      const url = `${targetId}/${contentUrl}`
      if (
        !currentLocation.pathname.endsWith(url)
        && !nextLocation?.pathname.endsWith(url)
      ) {
        yield put(replaceHistory(buildUrl(url, queryParams, false), {
          replace: true,
          skipTransition: true,
        }))
      }
    }
  }
}

function* applySquery({ restEndpoint, squery }) {
  if (!restEndpoint || !squery) return

  let actionFactory

  switch (restEndpoint) {
    case restEndpoints.assignments:
      actionFactory = assignmentActions.fetchAssignments
      break

    case restEndpoints.content:
      actionFactory = contentActions.fetchContentList
      break

    case restEndpoints.districts:
      actionFactory = districtActions.fetchDistricts
      break

    case restEndpoints.groups:
      actionFactory = groupActions.fetchGroups
      break

    case restEndpoints.schools:
      actionFactory = schoolActions.fetchSchools
      break

    case restEndpoints.students:
      actionFactory = userActions.fetchMyStudents
      break

    case restEndpoints.userAlerts:
      actionFactory = userAlerts.fetchNewUserAlertsByType
      break

    case restEndpoints.userAssignments:
      actionFactory = userAssignmentActions.fetchUserAssignments
      break

    case restEndpoints.users:
      actionFactory = userActions.fetchUsers
      break

    /* istanbul ignore next line */
    default:
  }

  if (actionFactory) {
    yield put(actionFactory({ queryParams: { search: squery } }))
  }
}

export function* handleInterpretSquery() {
  const squery = yield select(getSquery)
  const restEndpoint = yield select(getRestEndpoint)

  const location = yield select(getLocation)
  const searchParams = new URLSearchParams(location.search)

  if (searchParams.has('squery')) {
    yield call(applySquery, { squery, restEndpoint })
  }
}

export function* handleRehydrateContent() {
  const { rehydrateContentNextLocationChange } = yield select(stateRouting)
  if (rehydrateContentNextLocationChange) {
    yield put(contentActions.fetchContentById(rehydrateContentNextLocationChange))
  }
}

const removeSplitScreenContentFromLocation = (location) => {
  const params = new URLSearchParams(location.search)
  params.delete('ssc')
  return set('search', params.toString())(location)
}

export function* handleRouteTransitions({ execute, nextLocation, waitForTransition }) {
  /**
   * If a transition is specified, the RouteTransitionProvider component
   * will dispatch an event when all animations (if any) have ended, at which point
   * we can proceed with the LOCATION_CHANGE action that execute() will generate.
   * This makes it possible to show more immediate visual feedback before starting
   * to load lots of data/content/styles.
   */
  if (waitForTransition) {
    while (true) {
      const { nextPath } = yield take(actionTypes.ROUTE_LOCATION_CHANGE_MAY_PROCEED)
      if (nextLocation.pathname === nextPath) {
        break
      }
    }
  }

  yield call(execute)
}

export function* handleAddPin() {
  /**
   * Pin the current route to the right-side. This is accomplished by encoding
   * the current url and placing it into the 'ssc' (split-screen content) search
   * param.  The presence of this param is what opens the right-side, and happens
   * over in PinnableRoute.
   */

  const location = yield select(getLocation)

  const searchParams = new URLSearchParams(location.search)
  searchParams.delete('ssc')
  searchParams.delete('pane')
  searchParams.append('ssc', compressToEncodedURIComponent(JSON.stringify(location)))
  yield put(replaceHistory(`${location.pathname }?${ searchParams.toString()}`))
}

export function* handleRemovePin() {
  const location = yield select(getLocation)
  const l = removeSplitScreenContentFromLocation(location)
  yield put(replaceHistory(l.pathname))
}

export function* handleSetSqueryEndpoint({ restEndpoint, squery, searchUrl }) {
  const location = yield select(getLocation)
  const searchParams = new URLSearchParams(location.search)

  searchParams.delete('squery')
  searchParams.append('squery', cleanUrlParam(true)({
    sq: squery,
    re: restEndpoint,
  }))

  const newUrl = `${searchUrl || location.pathname}?${searchParams.toString()}`
  yield put(replaceHistory(newUrl))
}

export function* handleStudentNotebook() {
  const location = yield select(getLocation)

  const searchParams = new URLSearchParams(location.search)

  if (searchParams.has('pane') && searchParams.has('ssc')) {
    searchParams.delete('ssc')
    yield put(replaceHistory(`${location.pathname }?${ searchParams.toString()}`))
  }
}

/* istanbul ignore next line */
function* routingSaga() {
  yield takeLatest(LOCATION_CHANGE, handleLoggingRouteChange)
  yield takeLeading(LOCATION_CHANGE, handleRecordingContentViewerEntryPoint)
  yield takeLatest(actionTypes.ROUTE_LOCATION_WILL_CHANGE, handleRecordContentViewerScrollState)
  yield takeLeading(LOCATION_CHANGE, handleRestoringLastStateForContentWhenEnteringContentViewer)
  yield takeLeading(LOCATION_CHANGE, handleRecordLastViewForContentId)
  yield takeLatest(LOCATION_CHANGE, handleInterpretSquery)
  yield takeLeading(LOCATION_CHANGE, handleStudentNotebook)
  yield takeLeading(LOCATION_CHANGE, handleRehydrateContent)
  yield takeLatest(actionTypes.ROUTE_LOCATION_WILL_CHANGE, handleRouteTransitions)
  yield takeLatest(actionTypes.ROUTE_PIN_CURRENT_PAGE, handleAddPin)
  yield takeLatest(actionTypes.ROUTE_REMOVE_PIN, handleRemovePin)
  yield takeLatest(actionTypes.ROUTE_SET_SQUERY_ENDPOINT, handleSetSqueryEndpoint)
}

export default routingSaga
