import { useContext } from 'react'
import Slider from 'common/formControls/sliders/Slider'
import { msToTimeText } from 'locale/i18n'
import { audioContext } from './AudioContextProvider'

const PositionSlider = ({ className, ...rest }) => {
  const { duration, loaded, position, setPosition } = useContext(audioContext)

  return (
    <Slider
      aria-label="Progress"
      aria-valuemax={duration}
      aria-valuemin={0}
      aria-valuenow={position}
      aria-valuetext={msToTimeText(position)}
      {...rest}
      disabled={!loaded}
      max={duration}
      onChange={(_, v) => setPosition(v)}
      value={position || (duration * 0.008)}
    />
  )
}

export default PositionSlider
