import { convertFromHTML } from 'draft-convert'
import { CompositeDecorator, EditorState } from 'draft-js'
import { compose } from 'redux'
import { moveCaretToEnd, trimEditorState } from '@studysync/draft-js-modifiers'
import { curryRight } from 'fp/utils'
import { callWith } from 'fp/call'
import { get } from 'fp/objects'
import { assertWhitespaceAroundPlugins } from '../plugins/helpers/utils'

const compositeDecorator = (features, plugins) => {
  const decorators = plugins
    .map(get('decorator'))
    .filter(Boolean)
    .map(callWith(features))
    .flat()

  return new CompositeDecorator(decorators)
}

const htmlToBlock = (features, plugins) => (nodeName, node) => plugins
  .map(get('htmlToBlock'))
  .filter(Boolean)
  .map(callWith(features))
  .reduce((acc, f) => f(acc, nodeName, node), undefined)

const htmlToEntity = (features, plugins) => (nodeName, node, createEntity) => plugins
  .map(get('htmlToEntity'))
  .filter(Boolean)
  .map(callWith(features))
  .reduce((acc, f) => f(nodeName, node, createEntity) || acc, undefined)

const htmlToStyle = (features, plugins) => (nodeName, node, currentStyle) => plugins
  .map(get('htmlToStyle'))
  .filter(Boolean)
  .map(callWith(features))
  .reduce((acc, f) => f(nodeName, node, acc), currentStyle)

// We currently don't have any plugins that implement textToEntity.
// const textToEntity = (features, plugins) => (text, createEntity) => plugins
//   .map(get('textToEntity'))
//   .filter(Boolean)
//   .map(callWith(features))
//   .reduce((acc, f) => f(text, createEntity, acc), [])

const importingPostProcess = (features, plugins) => editorState => plugins
  .map(get('importingPostProcess'))
  .filter(Boolean)
  .map(callWith(features))
  .reduce((acc, f) => f(acc), editorState)

const htmlToContent = (value, features, plugins) => {
  const handlers = {
    htmlToBlock: htmlToBlock(features, plugins),
    htmlToEntity: htmlToEntity(features, plugins),
    htmlToStyle: htmlToStyle(features, plugins),
    // textToEntity: textToEntity(features, plugins),
  }

  const decorators = compositeDecorator(features, plugins)

  return compose(
    moveCaretToEnd,
    assertWhitespaceAroundPlugins,
    trimEditorState,
    importingPostProcess(features, plugins),
    curryRight(EditorState.createWithContent, decorators),
    // enable to debug the raw content
    // (contentState) => {
    //   console.log('contentState', JSON.stringify(convertToRaw(contentState), null, 2))
    //   return contentState
    // },
    convertFromHTML(handlers),
  )(value)
}

export default htmlToContent
