/* eslint-disable no-global-assign */
import { actions as sessionActions } from 'reducers/session'
import { actions as errorActions } from 'reducers/errorLog'
import { debounce } from 'fp/utils'
import actionTypes from 'reducers/actionTypes'
import { deepMerge } from 'fp/objects'

const cacheControl = {
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
    'If-Modified-Since': 0,
  },
}

const replaceFetch = (store, resetTimeout) => {
  const originalFetch = fetch

  fetch = (url, { failSilently, ...options } = {}) => originalFetch
    .apply(this, [url, deepMerge(cacheControl, options)])
    .then((data) => {
      if (url.startsWith('/api')) {
        switch (data.status) {
          case 200:
            resetTimeout()
            break

          case 401:
            store.dispatch(sessionActions.logout())
            break

            /* istanbul ignore next */
          default:
        }
      }

      return data
    })
    .catch((error) => {
      if (String(error).includes('Failed to fetch') && !failSilently) {
        const message = 'Action failed.  Check Internet connection'
        store.dispatch(errorActions.reportError({ message }))
      }

      return Promise.reject(error) // allow sagas a chance to process the error
    })
}

export const proceedIfOk = (response) => {
  if (!response.ok) {
    throw Error(response.statusText)
  }
  return response
}

const httpMiddleware = (store, resetTimeout = false, /* istanbul ignore next */ doReplaceFetch = process.env.NODE_ENV !== 'test') => {
  /* istanbul ignore else */
  if (doReplaceFetch) {
    replaceFetch(
      store,
      resetTimeout || debounce(
        1000,
        /* istanbul ignore next */ () => store.dispatch({
          type: actionTypes.SESSION_RESET_TIMEOUT,
        }),
      ),
    )
  }

  return next => action => next(action)
}

export default httpMiddleware
