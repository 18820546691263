import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import withProps from 'hoc/withProps'

const Styled = styled(
  Typography,
  { name: 'TeacherEdition' },
)(() => ({
  fontSize: 'inherit',
  fontWeight: 600,
  lineHeight: 'inherit',
}))

const TE = withProps(Styled, { children: 'TE', component: 'span' })

export default TE
