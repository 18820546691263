import { useId, useMemo } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/styled-engine'
import { position } from 'polished'
import { Pause, Play } from 'react-feather'

const fadeInOut = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
`

const StateChangeIndicator = styled(({ className, playState }) => {
  const key = useId()
  return useMemo(() => (
    <div
      className={className}
      key={key}
    >
      <span>
        {playState === 'playing' && <Play data-testid="play" />}
        {playState === 'paused' && <Pause data-testid="pause" />}
      </span>
    </div>
  ), [className, key, playState])
}, {
  name: 'video-StateChangeIndicator',
})(({ theme: { mixins: { rem, sizeR }, palette } }) => ({
  ...position('absolute', 0, 0, 0, 0),
  color: palette.common.white,
  textAlign: 'center',
  lineHeight: 1,
  paddingTop: '20%',
  pointerEvents: 'none',
  display: 'inline-block',
  fontFamily: 'Material Icons',
  fontSize: rem(6),
  transform: 'scale(0.8)',
  opacity: 0,
  animation: `${fadeInOut} 0.5s forwards 0s ease-in-out`,
  textShadow: '0 0 8px #555',
  svg: sizeR(6),
}))

StateChangeIndicator.propTypes = {
  playState: PropTypes.string.isRequired,
}

export default StateChangeIndicator
