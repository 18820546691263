import { styled } from '@mui/material/styles'
import { lighten } from 'polished'

const withStyledAnnotations = WrappedComponent => styled(
  WrappedComponent,
  { name: 'annotations-withRenderedAnnotations' },
)(({ theme: { palette } }) => ({
  '.annotation': {
    cursor: 'pointer',
  },
  '.annotation-note': {
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
  },
  ...palette.annotations
    .map(({ colorId, value }) => ({
      [`.annotation.annotation-${colorId}`]: {
        backgroundColor: lighten(0.27, value),
        borderColor: value,
      },
    }))
    .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
}))

export default withStyledAnnotations
