import { useCallback, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { whenPresent } from 'fp/utils'
import { a11yContext } from 'core/a11y'

const isManaged = node => node.dataset?.managedtabmode
  ? true
  : node.parentNode
    ? isManaged(node.parentNode)
    : false

const withTabModeSupport = (WrappedComponent) => {
  const Enhanced = ({ className, ...rest }) => {
    // istanbul ignore next
    const { setTabModeEnabled, tabModeEnabled } = useContext(a11yContext) || {}

    const handleKeyDown = useCallback(({ key }) => {
      if (key === 'Tab') {
        whenPresent(setTabModeEnabled, true)
      }
    }, [setTabModeEnabled])

    const handleMouseDown = useCallback(({ target }) => {
      if (!isManaged(target)) {
        whenPresent(setTabModeEnabled, false)
      }
    }, [setTabModeEnabled])

    useEffect(() => {
      document.addEventListener('keydown', handleKeyDown)
      document.addEventListener('mousedown', handleMouseDown)
      return () => {
        document.removeEventListener('keydown', handleKeyDown)
        document.removeEventListener('mousedown', handleMouseDown)
      }
    }, [handleKeyDown, handleMouseDown])

    return (
      <WrappedComponent
        className={classNames({ [className]: true, 'tab-mode-enabled': tabModeEnabled })}
        {...rest}
      />
    )
  }
  Enhanced.propTypes = { className: PropTypes.string }

  return Enhanced
}

export default withTabModeSupport
