import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'
import { AlignCenter, AlignLeft, AlignRight } from 'react-feather'
import { getCurrentBlock } from '@studysync/draft-js-modifiers'
import { useMemo } from 'react'
import { inputVariantShape } from 'core/shapes'
import { set } from 'fp/objects'
import DraftMenuButton from '../../toolbar/DraftMenuButton'
import useBlockInsight from '../../utils/useBlockInsight'
import { setAlignmentInTable, tableBlocksInSelection } from './utils'

const left = 'left'
const center = 'center'
const right = 'right'

const options = [
  { label: () => (<><AlignLeft style={{ marginRight: 10 }} /> Left</>), key: left },
  { label: () => (<><AlignCenter style={{ marginRight: 10 }} /> Center</>), key: center },
  { label: () => (<><AlignRight style={{ marginRight: 10 }} /> Right</>), key: right },
]

const icons = {
  [left]: <AlignLeft />,
  [center]: <AlignCenter />,
  [right]: <AlignRight />,
}

const AlignmentDropdown = (props) => {
  const {
    editorState,
    features,
    setEditorState,
    variant,
  } = props

  const currentBlock = getCurrentBlock(editorState)
  const currentAlignment = currentBlock.getData().get('textAlign') || left
  const blockType = useBlockInsight(editorState)

  const disabled = !tableBlocksInSelection(editorState)

  const menuItems = useMemo(
    () => options.map(item => set('selected', item.key === currentAlignment)(item)),
    [currentAlignment],
  )

  const handleChange = ({ key }) => {
    const newEditorState = setAlignmentInTable(editorState, key)

    setEditorState(newEditorState, true)
  }

  return blockType.includes('blockquote')
    ? null
    : (
      <DraftMenuButton
        disabled={disabled}
        editorState={editorState}
        featureMenuKey="tables"
        features={features}
        label="Table cell alignment"
        onChange={handleChange}
        options={menuItems}
        variant={variant}
      >
        <Icon>{icons[currentAlignment]}</Icon>
      </DraftMenuButton>
    )
}

AlignmentDropdown.propTypes = {
  editorState: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
  variant: inputVariantShape.isRequired,
}

export default AlignmentDropdown
