import scrollbarMeasure from 'scrollbar-size'

export const add = (key, value) => theme => ({ ...theme, [key]: value })

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
}

export const containedPaddingLeft = '7.1rem'
export const containedPaddingRight = '6rem'

export const echoTabSize = 96
export const echoMaxPaneWidth = 567

export const featuredContentMaxWidth = 718

export const scrollbarSize = scrollbarMeasure()
