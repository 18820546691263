import * as Sentry from '@sentry/react'
import { call, cancel, delay, fork, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { LOCATION_CHANGE, push } from 'redux-first-history'
import isPast from 'date-fns/isPast'
import { actions } from 'reducers/session'
import actionTypes from 'reducers/actionTypes'
import { restEndpoint } from 'reducers/utils'
import { stateSession } from 'selectors/index'
import { getCurrentUser } from 'selectors/users'
import { appUrl } from 'routing/consts'
import { generateId } from 'fp/utils'
import { resolveAuthChannel } from 'core/middleware/sessionHelper'
import { dangerouslyCallApi, getAuthValue, removeAuthInfo, storeAuthInfo } from './api'
import { failure, success } from './utils'

const broadcastAuthAction = (action) => {
  if (action.broadcast !== false) {
    resolveAuthChannel().postMessage({
      type: action.type,
      authSelector: getAuthValue('authSelector'),
    })
  }
}

if (!sessionStorage.frontendId) {
  sessionStorage.frontendId = generateId('s')
}

const setToken = (response) => {
  const { authSelector } = response
  storeAuthInfo({ authSelector })
}

export function* handleLoginSuccess({ response }) {
  yield call(setToken, response)
  yield put(push(appUrl))
}

export function* handleSessionCheckSuccess({ response }) {
  if (response.contextAuthSelector) {
    const authSelector = getAuthValue('authSelector')
    if (response.contextAuthSelector !== authSelector) {
      yield storeAuthInfo({ authSelector: response.contextAuthSelector })
    }
  }
}

function* establishSession() {
  const { sessionChecked } = yield select(stateSession)
  if (/* getAuthValue('authSelector') || */ !sessionChecked) {
    yield put(actions.sessionCheck())
  }
}

function* handleLogoutOrPasswordReset({ payload }) {
  const { location: { pathname } } = payload
  if (pathname === '/iframe.html') return // assume running within storybook

  if (pathname === '/logout' || pathname === '/reset-password') {
    yield call(removeAuthInfo)
  } else { // if (!publicLandingPoints.includes(payload.location.pathname)) {
    yield call(establishSession, payload)
  }
}

export function* handleSessionCheck(action) {
  broadcastAuthAction(action)

  const { id } = yield select(stateSession)

  const url = `${restEndpoint.sessions}/${id || ''}?state=${encodeURIComponent(window.location.pathname)}`

  yield call(dangerouslyCallApi, {
    action,
    url,
  })
}

export function* handleLogout(action) {
  broadcastAuthAction(action)

  const { id, logoutLink } = yield select(stateSession)

  if (id) {
    yield call(dangerouslyCallApi, {
      action,
      url: `${restEndpoint.sessions}/${id}`,
      options: {
        method: 'delete',
        // if body is undefined, api says "Body cannot be empty when content-type is set to 'application/json'""
        body: {},
      },
    })
  }

  yield call(removeAuthInfo)

  const redirectLocation = action.redirect || logoutLink
  if (redirectLocation) {
    // eslint-disable-next-line @studysync/persnickety/use-pinnable-methods
    window.location = redirectLocation
  } else {
    yield put(push('/logout'))
  }
}

export function* handleLogin(action) {
  const { payload: { username, password } } = action

  yield call(dangerouslyCallApi, {
    action,
    url: restEndpoint.sessions,
    options: {
      method: 'post',
      body: { username, password },
    },
  })
}

/**
 * Don't be tempted to use a timer for these. Remember that timers do not function
 * while the computer is sleeping.
 *
 * It's better to poll every second to see if we've passed the threshold. Polling
 * here is super cheap and does not cause any re-rendering.
 */
export function* timeoutWarning() {
  let currentSession

  do {
    yield delay(1000) // just to keep isPast() from chugging the cpu
    currentSession = yield select(stateSession)
    const { sessionActive, warnAt, warningVisible } = currentSession
    if (sessionActive && !warningVisible && isPast(warnAt)) {
      yield put(actions.showWarning())
    }
  } while (currentSession.sessionActive)
}

export function* timeoutSession() {
  let currentSession = yield select(stateSession)
  let alive = !isPast(currentSession.endsAt)

  while (currentSession.sessionActive && alive) {
    yield delay(1000) // just to keep isPast() from chugging the cpu
    currentSession = yield select(stateSession)
    alive = !isPast(currentSession.endsAt)
  }

  if (currentSession.sessionActive) {
    yield put(actions.logout())
  }
}

export function* sessionFlow() {
  while (true) {
    const warningTask = yield fork(timeoutWarning)
    const timeoutTask = yield fork(timeoutSession)

    yield take(/* istanbul ignore next */({ type }) => type.endsWith('_SUCCESS'))

    yield cancel(warningTask)
    yield cancel(timeoutTask)
  }
}

export function* handleSessionCheckFailed() {
  yield put(push('/login'))
}

export function* handleLoggingEvent(action) {
  try {
    const authSelector = getAuthValue('authSelector')
    yield call(
      fetch,
      '/api/data',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(authSelector && { Authorization: `Session ${authSelector}` }),
        },
        body: JSON.stringify({ ...action.payload, frontendId: sessionStorage.frontendId }),
      },
    )
  // eslint-disable-next-line no-empty
  } catch (err) {}
}

export function* setSentryUser() {
  const { districtId, id } = yield select(getCurrentUser)
  yield call(Sentry.setUser, { districtId, id })
}

export function* clearSentryUser() {
  yield call(Sentry.setUser, null)
}

/* istanbul ignore next line */
function* sessionSaga() {
  yield takeLatest(LOCATION_CHANGE, handleLogoutOrPasswordReset)
  yield takeLatest(actionTypes.SESSION_CHECK, handleSessionCheck)
  yield takeEvery(failure(actionTypes.SESSION_CHECK), handleSessionCheckFailed)
  yield takeLatest(actionTypes.SESSION_KEEP_ALIVE, handleSessionCheck)
  yield takeLatest(success(actionTypes.SESSION_CHECK), handleSessionCheckSuccess)

  yield takeEvery(actionTypes.SESSION_LOGIN, handleLogin)
  yield takeLatest(success(actionTypes.SESSION_LOGIN), handleLoginSuccess)
  yield takeEvery(actionTypes.SESSION_LOGOUT, handleLogout)

  yield takeEvery(actionTypes.LOG_SYSTEM_EVENT, handleLoggingEvent)

  yield takeEvery(success(actionTypes.SESSION_CHECK), setSentryUser)
  yield takeEvery(success(actionTypes.SESSION_LOGIN), setSentryUser)
  yield takeEvery(actionTypes.SESSION_LOGOUT, clearSentryUser)
  yield takeEvery(failure(actionTypes.SESSION_CHECK), clearSentryUser)

  yield fork(sessionFlow)
}

export default sessionSaga
