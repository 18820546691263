import { call, put, select, take, takeEvery, takeLeading } from 'redux-saga/effects'
import { createSelector } from '@comfy/redux-selectors'
import { compose } from 'redux'
import { push } from 'redux-first-history'
import actionTypes from 'reducers/actionTypes'
import { buildUrl } from 'fp/internet'
import { restEndpoint } from 'reducers/utils'
import { isEmptyString } from 'fp/strings'
import { actions } from 'reducers/notifications'
import { CONTENT_TYPE_ECHO, CONTENT_TYPE_SOURCE, CONTENT_TYPE_SUBSECTION, ROLE_STUDENT } from 'core/consts'
import { isUndefined, matches } from 'fp/utils'
import { omitReduxMetadata } from 'selectors/utils'
import { matchPathSelector } from 'selectors/routing'
import { getCurrentRoleId } from 'selectors/users'
import { assignmentEditorUrl, studentAssignmentsUrl } from 'routing/consts'
import { get, omit, set } from 'fp/objects'
import { filter, first } from 'fp/arrays'
import { isTestEnv, stateUserAssignments } from 'selectors/index'
import { getContentAncestry, getContentById } from 'selectors/content'
import { getAnyAncestorIsTe } from 'selectors/contentViewer'
import { success } from './utils'
import { dangerouslyCallApi } from './api'

export function* handleFetch(action) {
  const { assignmentId, includeScoringData } = action

  const url = `${restEndpoint.assignments}/${assignmentId}`
  const queryParams = includeScoringData
    ? { addScoreableContent: true }
    : undefined

  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(url, queryParams, includeScoringData ? false : undefined),
  })
}

export function* handleFetchList(action) {
  const { queryParams } = action

  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(restEndpoint.assignments, set('search.modifiers.addContentType', true)(queryParams || {}), false),
  })
}

export function* handleSave(action) {
  const { payload: { id } } = action
  const editing = !isEmptyString(id)

  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: editing ? 'PATCH' : 'POST',
      body: {
        ...omit(
          'createdBy',
          'createdDate',
          'relatedData',
          'scoreableContentIds',
          'userAssignmentIds',
        )(action.payload),
        id,
      },
    },
    passThrough: {
      editing,
    },
    url: editing
      ? `${restEndpoint.assignments}/${id}`
      : restEndpoint.assignments,
  })
}

export function* handleSaveSuccess({ passThrough: { editing } }) {
  yield put(actions.addAlert({
    message: editing
      ? 'Assignment updated'
      : 'Assignment created',
  }))
}

/**
 * This watches for instances where a student may be trying to visit an assignment
 * editing page.
 * This could happen if they copy it from the projector, are are mistakenly given
 * a link to it, etc.
 *
 * If they get this far then we redirect them to the corresponding user-assignment
 * and attempt to land them on the correct content.  If the content is TE only, it
 * will be the same as just opening the chapter.  So it will either go to the first
 * available subsection or the last viewed content id stored on the userAssignment.
 */
export function* handleStudentRedirect() {
  const roleId = yield select(getCurrentRoleId)

  if (roleId !== ROLE_STUDENT) return

  // find all possible params (could be subsection, echo, source or any other assignable content)
  const params = yield select(createSelector(
    matchPathSelector({ path: `${assignmentEditorUrl}/:assignmentId/${CONTENT_TYPE_ECHO}/:echoId` }),
    matchPathSelector({ path: `${assignmentEditorUrl}/:assignmentId/${CONTENT_TYPE_SOURCE}/:sourceId` }),
    matchPathSelector({ path: `${assignmentEditorUrl}/:assignmentId/${CONTENT_TYPE_SUBSECTION}/:subsectionId` }),
    matchPathSelector({ path: `${assignmentEditorUrl}/:assignmentId/*` }),
    (...all) => compose(
      get('params'),
      first,
      filter(Boolean),
    )(all),
  ))

  if (isUndefined(params)) return

  /**
   * A student is visiting an assignment editing url.
   * Redirect them to the corresponding user-assignment.
   */

  const ua = Object
    .values(omitReduxMetadata(yield select(stateUserAssignments)))
    .find(matches('assignmentId', params.assignmentId))

  if (ua) {
    // look up the contentType based on the type of assigned content.
    const [contentIdType, contentId] = first(Object.entries(omit('assignmentId')(params)))
    const contentType = {
      echoId: CONTENT_TYPE_ECHO,
      sourceId: CONTENT_TYPE_SOURCE,
      subsectionId: CONTENT_TYPE_SUBSECTION,
    }[contentIdType]

    /**
     * Double check that it's not TE content(!)
     *
     * First we need to wait until the content tree is loaded, which we can safely
     * assume has happened once the interactions list has successfully loaded.
     */
    /* istanbul ignore next */if (!isTestEnv()) {
      yield take(({ type }) => type === success(actionTypes.INTERACTION_FETCH_LIST))
    }
    const content = yield select(getContentById({ contentType, contentId }))
    const ancestry = yield select(getContentAncestry(content))
    const isTe = yield select(getAnyAncestorIsTe({ content: ancestry }))

    const dest = isTe
      ? `${studentAssignmentsUrl}/${ua.id}` // just lop off the subsection id
      : `${studentAssignmentsUrl}/${ua.id}/${contentType}/${contentId}`

    yield put(push(dest))
  }
}

export function* handleContentReopen(action) {
  const { assignmentId, contentId } = action

  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: 'POST',
      body: { contentId },
    },
    url: `${restEndpoint.assignments}/${assignmentId}/reopen`,
  })
}

export function* handleReopenSuccess() {
  yield put(actions.addAlert({
    message: 'Assignment reopened',
  }))
}

/* istanbul ignore next line */
function* assignmentSaga() {
  yield takeEvery(actionTypes.ASSIGNMENT_FETCH, handleFetch)
  yield takeEvery(actionTypes.ASSIGNMENT_FETCH_LIST, handleFetchList)
  yield takeLeading(actionTypes.ASSIGNMENT_SAVE, handleSave)
  yield takeLeading(actionTypes.ASSIGNMENT_CONTENT_REOPEN, handleContentReopen)
  yield takeLeading(success(actionTypes.ASSIGNMENT_SAVE), handleSaveSuccess)
  yield takeEvery(success(actionTypes.ASSIGNMENT_FETCH), handleStudentRedirect)
  yield takeLeading(success(actionTypes.ASSIGNMENT_CONTENT_REOPEN), handleReopenSuccess)
}

export default assignmentSaga
