import Bowser from 'bowser'
import { compose } from 'redux'
import { curry, matches, unless } from 'fp/utils'
import { hasProperty, omit, renameKeys } from 'fp/objects'
import { isMobile } from 'fp/internet'
import { isString } from 'fp/strings'
import { INTERACTION_SUBTYPE_VIDEO } from 'core/consts'
import { sortBy } from 'fp/arrays'

/**
 * TODO:
 * Possibly rework audio player to use this scheme too, rather than tracking state
 * like it currently does.
 */
export const stopAllOtherPlayers = (ref) => {
  const id = [
    ref.id,
    ref.tagAttributes?.id,
    // eslint-disable-next-line no-underscore-dangle
    ref.id_,
  ].filter(isString)[0]
  Array
    .prototype
    .slice
    .apply(document.querySelectorAll('audio,video'))
    .forEach(media => unless((id === media.id || `${id}_html5_api` === media.id), () => {
      media.pause()
    }))
}

const videoTypes = {
  dashUrl: 'application/dash+xml',
  url: 'application/x-mpegurl',
  fallbackUrl: 'video/mp4',
}

export const buildSources = (sources) => {
  const bowser = Bowser.getParser(window.navigator.userAgent)
  const isSafari = bowser.getBrowserName() === 'Safari'
  const isMobileDevice = isMobile()
  const isSafariDesktop = isSafari && !isMobileDevice
  const isIEClassic = (() => {
    const ua = bowser.getUA()
    return ua.includes('trident') && ua.includes('windows nt')
  })()

  const standardizedSources = compose(
    curry(renameKeys)({
      dash: 'dashUrl',
      hls: 'url',
      mp4: 'fallbackUrl',
    }),
    omit(isSafari || isIEClassic ? ['dashUrl'] : []),
    omit(isSafariDesktop || isIEClassic ? ['url'] : []),
  )(sources || {})

  return Object.entries(videoTypes)
    .map(([name, type]) => ({ type, src: standardizedSources[name] }))
    .filter(hasProperty('src'))
}

export const getImageUrlFromUploadsMap = (uploadsMap, size = 'full', uploadsMapKey = 'image') => {
  const fullUrl = uploadsMap?.[uploadsMapKey]?.url
  const thumbUrl = uploadsMap?.[`${uploadsMapKey}Thumb`]?.url

  switch (size) {
    case 'full': return fullUrl || thumbUrl
    case 'thumb': return thumbUrl || fullUrl

    default: {
      throw new Error('getImageUrlFromUploadsMap only accepts values of `full` or `thumb` for the size argument')
    }
  }
}

export const filterAndSortVideoAnnotations = annotations => annotations
  ?.filter(matches('interactionSubType', INTERACTION_SUBTYPE_VIDEO))
  ?.sort(sortBy('interactionData.time', 'asc', 'numeric')) || []
