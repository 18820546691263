import PropTypes from 'prop-types'
import { useFormState } from 'react-hook-form'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useContext } from 'react'
import { useDeepCompareMemo } from 'hooks/useDeepCompare'
import { scrollIntoView } from 'fp/dom'
import ErrorWell from 'common/layout/ErrorWell'
import { additionalContext } from './additionalContext'

const flattenIssue = ([key, value]) => {
  const childKey = Object.keys(value)[0]
  return (Object.prototype.hasOwnProperty.call(value, 'ref'))
    ? { [key]: value }
    : flattenIssue([`${key}.${childKey}`, value[childKey]])
}

const Issue = ({ issue, prettyNames }) => {
  const [[name, details]] = Object.entries(issue)

  const prettyName = prettyNames[name]

  const focus = details.ref?.focus || (() => {
    scrollIntoView(document.querySelector(`[name="${details.ref.name}"]`), true)
  })

  return (
    <Button
      color="error.dark"
      data-subvariant="error"
      onClick={focus}
      size="small"
      variant="tertiary"
    >
      {details.message
        ? details.message
        : details.type === 'required'
          ? prettyName
            ? `'${prettyName}' is required`
            : 'A required field needs attention'
          : prettyName
            ? `'${prettyName}' needs attention`
            : 'An unknown issue exists'}
    </Button>
  )
}

Issue.propTypes = {
  issue: PropTypes.object.isRequired,
  prettyNames: PropTypes.object.isRequired,
}

const ErrorRollup = (props) => {
  const { errors } = useFormState({ name: '' })

  const { prettyNames } = useContext(additionalContext)

  const issues = useDeepCompareMemo(
    () => Object
      .entries(errors)
      .map(flattenIssue),
    [errors],
  )

  return issues.length
    ? (
      <ErrorWell id="form-error-rollup">
        <Box
          component="ul"
          {...props}
        >
          {issues.map((issue, key) => (
            <li key={key}>
              <Issue
                issue={issue}
                prettyNames={prettyNames}
              />
            </li>
          ))}
        </Box>
      </ErrorWell>
    )
    : null
}

export default ErrorRollup
