import { PROFICIENCIES } from 'core/consts'
import { findObj } from 'fp/arrays'
import { get } from 'fp/objects'

// eslint-disable-next-line import/prefer-default-export
export const proficiencyLabels = proficiencyIds => proficiencyIds
  .map(i => findObj('id', i)(PROFICIENCIES))
  .filter(Boolean)
  .map(get('name'))
  .join(' / ')
