/* istanbul ignore file */
import TagCloud from 'react-wordcloud'
import PropTypes from 'prop-types'
import { useTheme } from '@mui/material/styles'
import { size } from 'polished'
import { useMemo } from 'react'
import Box from '@mui/material/Box'
import { compose } from 'redux'
import { nameValuePairShape } from 'core/shapes'
import { get } from 'fp/objects'
import { isNotEmptyString } from 'fp/strings'

const filterEmptyPhrases = compose(
  isNotEmptyString,
  get('label'),
)

const WordCloud = (props) => {
  const {
    maxFontSize = 72,
    minFontSize = 14,
    phrases,
    size: [width, height] = [600, 300],
  } = props

  const { palette: { wordCloud: colors } } = useTheme()

  const entries = useMemo(
    () => phrases
      .filter(filterEmptyPhrases)
      .map(({ label: text, value }) => ({ text, value })),
    // .toSpliced(   // enable to test with a large number of words
    //   3,
    //   0,
    //   { text: 'jQuery', value: 25 },
    //   { text: 'MongoDB', value: 18 },
    //   { text: 'JavaScript', value: 38 },
    //   { text: 'React', value: 30 },
    //   { text: 'Nodejs', value: 28 },
    //   { text: 'Express.js', value: 25 },
    //   { text: 'HTML5', value: 33 },
    //   { text: 'CSS3', value: 20 },
    //   { text: 'Webpack', value: 22 },
    //   { text: 'Babel.js', value: 7 },
    //   { text: 'ECMAScript', value: 25 },
    //   { text: 'Jest', value: 15 },
    //   { text: 'Mocha', value: 17 },
    //   { text: 'React Native', value: 27 },
    //   { text: 'Angular.js', value: 30 },
    //   { text: 'TypeScript', value: 15 },
    //   { text: 'Flow', value: 30 },
    //   { text: 'NPM', value: 11 },
    // ),
    [phrases],
  )

  const options = useMemo(() => ({
    colors,
    deterministic: true,
    enableTooltip: false,
    fontFamily: 'Inter',
    fontSizes: [minFontSize, maxFontSize],
    padding: 3,
    rotations: 0,
  }), [colors, maxFontSize, minFontSize])

  return (
    <Box sx={size(height, width)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: 1000,
          ...size('100%'),
        }}
      >
        <TagCloud
          className="tagCloud"
          options={options}
          words={entries}
        />
      </Box>
    </Box>
  )
}

WordCloud.propTypes = {
  maxFontSize: PropTypes.number,
  minFontSize: PropTypes.number,
  phrases: nameValuePairShape.isRequired,
  size: PropTypes.arrayOf(PropTypes.number),
}

export default WordCloud
