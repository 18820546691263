import cl from 'classnames'
import { useMatch } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { navItemChildShape } from 'core/shapes'

const TreeEntryLabel = ({ className, entry }) => {
  const match = useMatch({ path: entry.to || '/404' })

  const leaf = !entry.items

  return (
    <Typography
      className={cl(className, { leaf })}
      component="div"
      variant={match
        ? leaf ? 'nav-item-semibold' : 'nav-item-uppercase-semibold'
        : leaf ? 'nav-item' : 'nav-item-uppercase'}
    >
      {entry.label}
    </Typography>
  )
}

TreeEntryLabel.propTypes = {
  entry: navItemChildShape.isRequired,
}

export default TreeEntryLabel
