import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import CorrectnessIcon from 'common/indicators/CorrectnessIcon'
import { componentShape } from 'core/shapes'
import { SCORING_RESULT_CORRECT, SCORING_RESULT_INCORRECT, SCORING_RESULT_NEEDS_GRADING } from 'core/consts'
import { isDefined } from 'fp/utils'

const getPaletteColor = scoringResult => scoringResult === SCORING_RESULT_NEEDS_GRADING
  ? 'grey.0'
  : scoringResult === SCORING_RESULT_CORRECT
    ? 'success.main'
    : scoringResult === SCORING_RESULT_INCORRECT
      ? 'error.main'
      : null

export const MaybeScoringResultIcon = ({ scoringResult }) => {
  const isCorrect = scoringResult === SCORING_RESULT_CORRECT
    ? true
    : scoringResult === SCORING_RESULT_INCORRECT
      ? false
      : null
  const needsScoring = scoringResult === SCORING_RESULT_NEEDS_GRADING

  return Boolean(isDefined(isCorrect) || needsScoring) && (
    <Icon sx={{ color: getPaletteColor(scoringResult) }}>
      <CorrectnessIcon {...{ isCorrect, needsScoring }} />
    </Icon>
  )
}
MaybeScoringResultIcon.propTypes = {
  scoringResult: PropTypes.oneOf([
    SCORING_RESULT_CORRECT,
    SCORING_RESULT_INCORRECT,
    SCORING_RESULT_NEEDS_GRADING,
  ]),
}
const ItemWithFeedback = (props) => {
  const { children, scoringResult, ...rest } = props

  const barColor = getPaletteColor(scoringResult)

  return (
    <Stack
      alignItems="center"
      bgcolor="common.white"
      borderLeft="8px solid"
      borderLeftColor={barColor}
      direction="row"
      mb={1.5}
      p={1.5}
      pl={1}
      {...rest}
    >
      <Box
        color={barColor}
        mx={2}
        sx={{ svg: { stroke: 'currentColor' } }}
      >
        <MaybeScoringResultIcon scoringResult={scoringResult} />
      </Box>
      {children}
    </Stack>
  )
}

ItemWithFeedback.propTypes = {
  children: componentShape.isRequired,
  scoringResult: PropTypes.oneOf([
    SCORING_RESULT_CORRECT,
    SCORING_RESULT_INCORRECT,
    SCORING_RESULT_NEEDS_GRADING,
  ]),
}

export default ItemWithFeedback
