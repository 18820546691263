import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import TextField from './TextField'

const CommentInput = ({
  characterLimit = 150,
  disabled = false,
  onChange,
  value: initialValue = '',
  ...rest
}) => {
  const [statusText, setStatusText] = useState('')
  const [value, setValue] = useState(initialValue)

  const handleChange = useCallback((event) => {
    if (event.target.value.length <= characterLimit) {
      setValue(event.target.value)
      onChange(event)
    }
  }, [characterLimit, onChange, setValue])

  useEffect(() => {
    setStatusText(`${characterLimit - value.length} characters remaining`)
  }, [characterLimit, value.length])

  useEffect(() => {
    if (disabled) setValue('')
  }, [disabled])

  return (
    <>
      <TextField
        {...{
          minRows: 2,
          multiline: true,
          onChange: handleChange,
          value,
          ...rest,
        }}
      />

      <Typography
        component="div"
        textAlign="right"
        variant="small"
      >
        {statusText || <>&nbsp;</>}
      </Typography>
    </>
  )
}

CommentInput.propTypes = {
  characterLimit: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default CommentInput
