import { call, put, takeLatest } from 'redux-saga/effects'
import actionTypes from 'reducers/actionTypes'
import { restEndpoint } from 'reducers/utils'
import { noop } from 'fp/utils'
import { actions } from 'reducers/content'
import { dangerouslyCallApi } from './api'
import { success } from './utils'

export function* handleRequest(action) {
  const { file, file: { type: mimeType }, isAudioDescription, uploadType } = action

  // Request approval to upload to s3
  const either = yield call(dangerouslyCallApi, {
    action,
    options: {
      body: {
        mimeType,
        type: uploadType,
      },
      method: 'POST',
    },
    passThrough: { isAudioDescription },
    suppressBroadcast: true,
    url: restEndpoint.uploads,
  })

  // We can't pass a File object through callApi, so manually process the either
  yield either.cata(
    noop, // The transport layer handles errors for us
    response => put({
      file,
      response,
      type: success(action.type),
    }),
  )
}

export function* handleRequestSuccess({ file, response }) {
  yield put(actions.initiateUpload({ file, ...response }))
}

export function* handleInitiate(action) {
  const {
    file,
    id,
    passThrough: { isAudioDescription } = {},
    uploadFields,
    uploadUrl,
  } = action

  const formData = new FormData()

  Object.entries(uploadFields).map(([key, value]) => formData.append(key, value))
  formData.append('file', file)

  yield call(dangerouslyCallApi, {
    action,
    options: {
      accept: '*/*',
      body: formData,
      credentials: 'omit',
      leaveBodyIntact: true,
      method: 'POST',
    },
    passThrough: { id, isAudioDescription },
    url: uploadUrl,
  })
}

export function* handleInitiateSuccess({ passThrough }) {
  yield put(actions.markUploadComplete(passThrough))
}

export function* handleMarkComplete(action) {
  const { id, isAudioDescription } = action
  yield call(dangerouslyCallApi, {
    action,
    options: {
      body: { complete: true, isAudioDescription },
      method: 'PATCH',
    },
    url: `${restEndpoint.uploads}/${id}`,
  })
}

/* istanbul ignore next line */
function* uploadSaga() {
  yield takeLatest(actionTypes.UPLOAD_REQUEST, handleRequest)
  yield takeLatest(success(actionTypes.UPLOAD_REQUEST), handleRequestSuccess)
  yield takeLatest(actionTypes.UPLOAD_INITIATE, handleInitiate)
  yield takeLatest(success(actionTypes.UPLOAD_INITIATE), handleInitiateSuccess)
  yield takeLatest(actionTypes.UPLOAD_MARK_COMPLETE, handleMarkComplete)
}

export default uploadSaga
