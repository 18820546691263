import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { useDispatch } from 'react-redux'
import Icon from 'hss/images/nav/pin.svg'
import actionTypes from 'reducers/actionTypes'

const InsertPinButton = (props) => {
  const dispatch = useDispatch()
  const handleClick = () => dispatch({ type: actionTypes.ROUTE_PIN_CURRENT_PAGE })

  return (
    <Tooltip
      placement="left-end"
      title="Pin current page to the right"
    >
      <IconButton
        {...props}
        data-bodyvariant="body1"
        onClick={handleClick}
        size="large"
        sx={{ padding: 0 }}
      >
        <Icon />
      </IconButton>
    </Tooltip>
  )
}

export default InsertPinButton
