const outlineSelectors = [
  '*:focus:not(.MuiOutlinedInput-inputMultiline):not(.tree-nav):not(textarea)',
  '*:focus + span[class^=RadioButton]',
  'input:focus + label::before',
  '.MuiOutlinedInput-root:focus-within',
  '.MuiInputBase-root.Mui-focused',
  '.MuiSlider-thumb.Mui-focusVisible',
  'label:focus-within',
  '.MuiCheckbox-root.no-label:focus-within',
  '.tree-nav .Mui-focused',
]

const plugin = ({ palette, mixins: { important, importantRem, rem } }) => ({
  '.hidden': {
    height: 1,
    width: 1,
    left: -10000,
    overflow: 'hidden',
    position: 'absolute',
    top: 'auto',
  },
  '.tab-mode-enabled': {
    [outlineSelectors.join(', ')]: {
      outlineColor: important(palette.focused),
      outlineStyle: important('solid'),
      outlineOffset: rem(0.5),
      outlineWidth: importantRem(0.5),
      zIndex: 9999,
    },
    svg: {
      'a:focus *': {
        fill: important(palette.focused),
      },
      '*:focus ~ text': {
        fill: important(palette.primary.contrastText),
      },
    },
    '.MuiAutocomplete-root .MuiInputBase-input.MuiInputBase-input.MuiInputBase-input': {
      outlineWidth: important(0),
    },
    '.MuiSlider-thumb.Mui-focusVisible': {
      boxShadow: 'none',
    },
  },
})

export default plugin
