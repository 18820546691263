import { Maybe } from 'monet'
import { compose } from 'redux'
import { isEmptyString } from 'fp/strings'
import { noop } from 'fp/utils'
import { callWith } from 'fp/call'
import { formatDateStrShort } from 'locale/i18n'
import { CHANGE_HANDLER_SIMPLE } from '../Form/withHookForm'
import { propBagsShape, withPropBags } from '../Form/withPropBags'
import DatePicker from './DatePicker'

// These match MUI's current defaults.
// Defining these here so we can provide accurate error messages.
// eslint-disable-next-line max-len
// https://github.com/mui/material-ui/blob/7b9d7b5d39b4b0780c5c8762ac6e13b8cabdcaf7/lib/src/DatePicker/components/Calendar.tsx#L65
const defaultMinDate = new Date(1900, 0, 1)
const defaultMaxDate = new Date(2100, 0, 1)

const errorMessages = {
  maxDate: ({ maxDate }) => `Value must be no later than ${formatDateStrShort(maxDate || defaultMaxDate)}.`,
  minDate: ({ minDate }) => `Value must be no earlier than ${formatDateStrShort(minDate || defaultMinDate)}.`,
}

const HFDatePicker = withPropBags([
  'disabled',
  'inputRef',
  'name',
  'onBlur',
  'onFocus',
  'readOnly',
], {
  changeHandlerType: CHANGE_HANDLER_SIMPLE,
  enableCustomErrorMessage: true,
})((props) => {
  const { componentProps, controlProps, rest: { setCustomErrorMessage, ...rest } } = props
  const { onChange, value } = componentProps

  const handleError = (errorType) => {
    setCustomErrorMessage(Maybe.fromFalsy(errorType)
      .map(type => errorMessages[type] || noop)
      .flatMap(compose(Maybe.fromFalsy, callWith(rest)))
      .orNull())
  }

  return (
    <DatePicker
      {...{
        ...componentProps,
        ...controlProps,
        maxDate: defaultMaxDate,
        minDate: defaultMinDate,
        onChange,
        onError: handleError,
        value: isEmptyString(value) ? undefined : value,
        ...rest,
      }}
    />
  )
})

HFDatePicker.propTypes = propBagsShape.isRequired

export default HFDatePicker
