import { Outlet } from 'react-router-dom'
import Banner from 'routing/shells/NavShell/Banner'
import ContainerQuery from 'common/layout/ContainerQuery'

const CurriculumNavShell = () => (
  <>

    <Banner />

    <ContainerQuery>
      <Outlet />
    </ContainerQuery>

  </>
)

export default CurriculumNavShell
