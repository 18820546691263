import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import Box from '@mui/material/Box'
import { getUserAssignment } from 'selectors/userAssignments'
import { actions } from 'reducers/interactions'
import { getContextualAssignment } from 'selectors/assignments'
import { formatDateStrShort } from 'locale/i18n'
import { INTERACTION_TYPE_FEEDBACK } from 'core/consts'
import { getInteractionByType } from 'selectors/interactions'
import { get } from 'fp/objects'
import Html from 'common/text/Html'

const GridRow = ({ left, right }) => (
  <>
    <Grid
      item
      xs={6}
    >
      {left}
    </Grid>
    <Grid
      item
      textAlign="end"
      xs={6}
    >
      {right}
    </Grid>
  </>
)

GridRow.propTypes = {
  left: PropTypes.string,
  right: PropTypes.string,
}

const Grade = () => {
  const dispatch = useDispatch()
  const { createdBy: { firstName, lastName }, endDate, startDate } = useSelector(getContextualAssignment)
    || { createdBy: {} }
  const { id: userAssignmentId, submittedDate } = useSelector(getUserAssignment) || {}

  const feedbackInteraction = useSelector(getInteractionByType({
    type: INTERACTION_TYPE_FEEDBACK,
    userAssignmentId,
  }))
  const feedback = get('interactionData.value')(feedbackInteraction)

  useEffect(() => {
    dispatch(actions.fetchInteractions({ interactionType: INTERACTION_TYPE_FEEDBACK, userAssignmentId }))
  }, [dispatch, userAssignmentId])

  return (
    <Grid
      container
      padding="2rem"
      spacing={2}
    >
      <GridRow
        left="Completed On"
        right={formatDateStrShort(submittedDate)}
      />
      <GridRow
        left="Created By"
        right={`${firstName} ${lastName}`}
      />
      <GridRow
        left="Start"
        right={formatDateStrShort(startDate)}
      />
      <GridRow
        left="End"
        right={formatDateStrShort(endDate)}
      />
      {Boolean(feedback) && (
        <Grid item>
          <Box mb={2}>
            Comment
          </Box>
          <Box color="grey.3">
            <Html body={feedback} />
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default Grade
