import PropTypes from 'prop-types'
import Link from '@mui/material/Link'

const SeeAlsoHelperLink = ({ children, href }) => (
  <>
    See also:&nbsp;
    <Link
      href={href}
      rel="noreferrer"
      target="_blank"
    >
      {children}
    </Link>
  </>
)
SeeAlsoHelperLink.propTypes = {
  children: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
}

export default SeeAlsoHelperLink
