import { select } from 'redux-saga/effects'
import { getLocalSetting, getRootSettingsKey } from 'selectors/localSettings'
import { getContentViewerParams } from 'selectors/contentViewerParams'
import { CONTENT_TYPE_SOURCE } from 'core/consts'
import { SETTING_KEY_CONTENT_VIEWER_LAST_VIEWED } from 'reducers/localSettings'

export function* getLastViewedContentState(urlOtherThanCurrent = undefined) {
  const rootKey = yield select(getRootSettingsKey)

  const currentState = yield select(getLocalSetting(SETTING_KEY_CONTENT_VIEWER_LAST_VIEWED))

  const {
    assignmentId,
    contentId,
    contentType,
    userAssignmentId,
  } = yield select(getContentViewerParams({ urlOtherThanCurrent })) || {}

  const stateKey = assignmentId
    ? `ASMT_${ assignmentId }`
    : userAssignmentId
      ? `UASMT_${ userAssignmentId }`
      : contentType === CONTENT_TYPE_SOURCE
        ? `SRC_${ contentId }`
        : `CHAP_${ contentId }`

  return {
    assignmentId,
    contentId,
    contentType,
    currentState,
    rootKey,
    stateKey,
    userAssignmentId,
  }
}

export default undefined
