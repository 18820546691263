/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { EditorState } from 'draft-js'
import { removeBlock } from '@studysync/draft-js-modifiers'
import { deepMerge } from 'fp/objects'
import CustomBlockWrapper from './helpers/CustomBlockWrapper'
import { customBlockPropsShape } from './helpers/utils'
import PluginInput from './helpers/PluginInput'

const NumberCrunchPlaceholder = ({ block, blockProps }) => {
  const { getEditorState, onChange, readOnly } = blockProps

  const handleRemove = () => {
    const editorState = getEditorState()
    const newContentState = removeBlock(editorState.getCurrentContent(), block.getKey())
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'remove-block',
    )
    onChange(newEditorState)
  }

  return (
    <CustomBlockWrapper
      block={block}
      blockProps={blockProps}
      onRemove={handleRemove}
      readOnly={readOnly}
      title="Number Crunch"
    >
      <table>
        <tbody>

          <tr>
            <th>Number</th>
            <td>
              <PluginInput
                block={block}
                blockProps={blockProps}
                component="input"
                dataField="number"
                getEditorState={getEditorState}
                setEditorState={onChange}
              />
            </td>
          </tr>

          <tr>
            <th>Body text</th>
            <td>
              <PluginInput
                block={block}
                blockProps={blockProps}
                component="input"
                dataField="bodytext"
                getEditorState={getEditorState}
                setEditorState={onChange}
              />
            </td>
          </tr>

        </tbody>
      </table>
    </CustomBlockWrapper>
  )
}

NumberCrunchPlaceholder.propTypes = {
  block: PropTypes.object.isRequired,
  blockProps: customBlockPropsShape.isRequired,
}

const blockRendererFn = (contentBlock, {
  // features,
  getEditorState,
  onChange,
  readOnly,
  setPluginHasFocus,
}) => {
  if (contentBlock.getType() === 'atomic'
    && contentBlock.getData().get('type') === 'number-crunch'
  ) {
    return {
      component: NumberCrunchPlaceholder,
      editable: false,
      props: {
        getEditorState,
        onChange,
        readOnly,
        setPluginHasFocus,
      },
    }
  }

  return undefined
}

const blockToHTML = (/* features */) => (current, { data, type }) => {
  if (type === 'atomic' && data.type === 'number-crunch') {
    return (
      <div
        data-bodytext={String(data.bodytext)}
        data-number={String(data.number)}
        data-variant="number-crunch"
      />
    )
  }

  return current
}

const htmlToBlock = (/* features */) => (current, nodeName, node) => {
  if (node?.getAttribute?.('data-variant') === 'number-crunch') {
    return deepMerge(current, {
      type: 'atomic',
      mutability: 'IMMUTABLE',
      data: {
        number: node.getAttribute('data-number'),
        bodytext: node.getAttribute('data-bodytext'),
        type: 'number-crunch',
      },
    })
  }

  return current
}

const numberCrunchPlugin = {
  blockRendererFn,
  blockToHTML,
  htmlToBlock,
}

export default numberCrunchPlugin
