const overrides = () => ({
  MuiCardHeader: {
    variants: [
      {
        props: { variant: 'grow' },
        style: { alignItems: 'flex-start', flexGrow: 1 },
      },
    ],
  },
})

export default overrides
