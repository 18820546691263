import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { buildUrl } from 'fp/internet'
import { alter, assert } from 'core/store/search/squery'
import { get } from 'fp/objects'
import actionTypes from 'reducers/actionTypes'

const space = String.fromCharCode(32)
const noBreakSpace = String.fromCharCode(160)

export const createItems = (newItems, createType, startAdd, type) => {
  let items

  switch (createType) {
    case 'TAG':
      items = newItems.map(item => ({
        name: item,
        shortName: item,
      }))
      break
    case 'VOCAB':
      items = newItems.map(item => ({
        contentType: 'vocab',
        contentSubType: type,
        name: item,
      }))
      break
    default:
      throw Error('Unsupported createType')
  }

  return startAdd({ items })
}

export const formatForParsing = (delimiters, preserveSpaces, val) => {
  let newVal = preserveSpaces
    ? val
    : val.replaceAll(space, '').replaceAll(noBreakSpace, '')

  delimiters.forEach((d) => { newVal = newVal.replaceAll(d, ',') })

  return newVal
}

export const getActionType = (createType) => {
  let actionType
  switch (createType) {
    case 'TAG':
      actionType = actionTypes.TAG_ADD
      break
    case 'VOCAB':
      actionType = actionTypes.VOCAB_ADD
      break
    default:
      break
  }

  return actionType
}

const processItemSearch = (either, labelField) => ({
  options: [],
  hasMore: false,
  ...(either.isRight() ? either
    .map((result) => {
      const {
        data,
        metadata: { squery: { limit, offset }, count },
      } = result

      const hasMore = count > offset + limit

      const options = data.map(item => ({
        ...item,
        value: item.id,
        label: item[labelField],
      }))

      return { options, hasMore }
    })
    .right() : {}),
})

export const performPickerSearch = async (props) => {
  const { callApi, labelField, limit, loadedOptions, name, orderBy, type, uri } = props

  const offset = loadedOptions.length

  let search = alter
    .set
    .modifier('keywordSearch')
    .is(name)(assert({ limit: limit || 20, offset }))

  if (orderBy) {
    search = alter
      .set
      .orderBy(orderBy, 'asc')(search)
  }

  if (type) {
    search = alter
      .set
      .where('contentSubType')
      .is(type)(search)
  }

  const url = buildUrl(uri, { search }, false)

  const either = await callApi({ url })

  return processItemSearch(either, labelField)
}

export const renderOption = (shortFieldName = 'label', longFieldName = '') => (
  // eslint-disable-next-line react/prop-types
  { key, ...props },
  option,
) => {
  const shortField = get(shortFieldName)(option)
  const longField = get(longFieldName)(option)

  return (
    <Box
      component="li"
      key={key}
      {...props}
    >
      {longField
        ? (
          <div>
            <div><strong>{shortField}</strong></div>
            <Typography variant="small">{longField}</Typography>
          </div>
        ) : (
          <span>{shortField}</span>
        )}
    </Box>
  )
}
