import { compose } from 'redux'
import { filter, flatten, map, reduce } from 'fp/arrays'
import { get, omit, set } from 'fp/objects'
import { success } from 'sagas/utils'
import { binary, fallbackTo } from 'fp/utils'
import { alter, assert } from '../search/squery'
import actionTypes from './actionTypes'
import {
  createReducer,
  handleFetchListSuccess,
  listReducerInitialState,
  updateListed,
  updateLoaded,
} from './utils'

export const userAssignmentActions = {
  fetchUserAssignments: ({ queryParams }) => ({
    type: actionTypes.USER_ASSIGNMENT_FETCH_LIST,
    queryParams,
  }),

  fetchUserAssignmentById: ({ userAssignmentId }) => ({
    userAssignmentId,
    type: actionTypes.USER_ASSIGNMENT_FETCH,
  }),

  fetchOpenAssignments: () => ({
    type: actionTypes.USER_ASSIGNMENT_FETCH_LIST,
    queryParams: { search: compose(
      alter.set.modifier('addAssignmentAndContent').is(true),
      alter.set.modifier('isOpen').is(true),
      alter.set.orderBy('assignment.endDate', 'asc'),
    )(assert()) },
  }),

  fetchClosedAssignments: () => ({
    type: actionTypes.USER_ASSIGNMENT_FETCH_LIST,
    queryParams: { search: compose(
      alter.set.modifier('addAssignmentAndContent').is(true),
      alter.set.modifier('isClosed').is(true),
      alter.set.orderBy('assignment.endDate', 'desc'),
    )(assert()) },
  }),

  submitUserAssignment: ({ userAssignmentId }) => ({
    payload: { userAssignmentId },
    type: actionTypes.USER_ASSIGNMENT_SUBMIT,
  }),

  updateProgress: ({ userAssignmentId, progress }) => ({
    payload: { userAssignmentId, progress },
    type: actionTypes.USER_ASSIGNMENT_UPDATE_PROGRESS,
  }),

  updateLastViewedContent: ({ userAssignmentId, lastViewedContentId }) => ({
    payload: { userAssignmentId, lastViewedContentId },
    type: actionTypes.USER_ASSIGNMENT_UPDATE_LAST_VIEWED_CONTENT,
  }),
}

const handleUserAssignmentFetchItemSuccess = (state, { response }) => updateLoaded(state, omit('assignment')(response))

const handleUserAssignmentFetchListSuccess = (state, args) => {
  const prunedData = compose(
    // userAssignment saga emits ASSIGNMENT_FETCH_LIST success action that takes care of `assignment` data
    map(omit('assignment')),
    get('response.data'),
  )(args)
  return handleFetchListSuccess(state, set('response.data', prunedData)(args), { merge: true })
}

// Merge with existing loaded item instead of replacing,
// because the patch response includes less data than what Assignments/Report/StudentsTable.js loads.
const handleUserAssignmentPatchSuccess = (state, { response }) => updateLoaded(state, response, {}, { merge: true })

// The assignments fetch can sometimes include userAssignments via the addUserAssignmentsWithInteractions modifier.
const loadUserAssignmentsWithInteractions = (state, updateFn) => compose(
  reduce(binary(updateFn), state),
  map(omit(['interactions'])), // interactions reducer will handle these
  map(userAssignment => userAssignment.interactions
    ? ({
      ...userAssignment,
      interactionIds: userAssignment.interactions.map(get('id')),
    })
    : userAssignment),
)
const handleAssignmentFetchItemSuccess = (state, { response }) => compose(
  loadUserAssignmentsWithInteractions(state, updateLoaded),
  fallbackTo([]),
  get('userAssignments'),
)(response)

const handleAssignmentFetchListSuccess = (state, args) => compose(
  loadUserAssignmentsWithInteractions(state, updateListed),
  flatten,
  filter(Boolean),
  map(get('userAssignments')),
  get('response.data'),
)(args)

const handleUserAssignmentUpdateProgress = (state, {
  payload: {
    progress,
    userAssignmentId,
  },
}) => set(`${userAssignmentId}.progress`, progress)(state)

const handleUserAssignmentUpdateLastViewedContent = (state, {
  payload: {
    lastViewedContentId,
    userAssignmentId,
  },
}) => set(`${userAssignmentId}.lastViewedContentId`, lastViewedContentId)(state)

const assignments = createReducer(
  listReducerInitialState(),
  {
    [success(actionTypes.USER_ASSIGNMENT_FETCH)]: handleUserAssignmentFetchItemSuccess,
    [success(actionTypes.USER_ASSIGNMENT_FETCH_LIST)]: handleUserAssignmentFetchListSuccess,
    [success(actionTypes.USER_ASSIGNMENT_SUBMIT)]: handleUserAssignmentPatchSuccess,
    [success(actionTypes.USER_ASSIGNMENT_REOPEN)]: handleUserAssignmentPatchSuccess,
    [success(actionTypes.ASSIGNMENT_FETCH)]: handleAssignmentFetchItemSuccess,
    [success(actionTypes.ASSIGNMENT_CONTENT_REOPEN)]: handleAssignmentFetchItemSuccess,
    [success(actionTypes.ASSIGNMENT_FETCH_LIST)]: handleAssignmentFetchListSuccess,
    [actionTypes.USER_ASSIGNMENT_UPDATE_PROGRESS]: handleUserAssignmentUpdateProgress,
    [actionTypes.USER_ASSIGNMENT_UPDATE_LAST_VIEWED_CONTENT]: handleUserAssignmentUpdateLastViewedContent,
  },
)

export default assignments
