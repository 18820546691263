import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import { useCallback, useContext } from 'react'
import Checkbox from 'common/formControls/switches/Checkbox'
import { additionalContext } from '../Form/additionalContext'
import { propBagsShape, withPropBags } from '../Form/withPropBags'

const HFCheckbox = withPropBags([
  'checked',
  'disabled',
  'error',
  'helperText',
  'label',
  'value',
])((props) => {
  const { componentProps: { onChange, ...componentProps }, controlProps, rest } = props
  const { checked, disabled, error, helperText, label } = controlProps
  const { color } = componentProps

  const { setForcedDirty } = useContext(additionalContext)

  const handleOnChange = useCallback((...args) => {
    setForcedDirty(true)
    onChange(...args)
  }, [onChange, setForcedDirty])

  return (
    <FormControl
      disabled={disabled}
      error={error}
      {...rest}
    >
      <Checkbox
        {...{ ...componentProps, checked }}
        data-color={color}
        label={label}
        onChange={handleOnChange}
      />
      {Boolean(helperText?.length) && <FormHelperText sx={{ ml: 4.5 }}>{helperText}</FormHelperText>}
    </FormControl>
  )
})

HFCheckbox.propTypes = propBagsShape.isRequired

export default HFCheckbox
