import PropTypes from 'prop-types'
import withHookForm, { CHANGE_HANDLER_NUMERIC } from '../Form/withHookForm'
import HFTextField from './HFTextField'

const HFNumberField = withHookForm(({
  inputProps,
  max = Number.MAX_SAFE_INTEGER,
  min = Number.MIN_SAFE_INTEGER,
  ...rest
}) => (
  <HFTextField
    {...rest}
    changeHandlerType={CHANGE_HANDLER_NUMERIC}
    inputProps={{ min, max, ...inputProps }}
    type="number"
  />
), { changeHandlerType: CHANGE_HANDLER_NUMERIC })

HFNumberField.propTypes = {
  inputProps: PropTypes.object,
  max: PropTypes.number,
  min: PropTypes.number,
}

export default HFNumberField
