import { sortBy } from 'fp/arrays'

export const pullListed = ({ listed, ...objs }) => listed
  ? listed
    .map(id => ({ ...objs[id] }))
    .sort(sortBy('displayOrder'))
  : []

export const pullLoaded = ({ loaded, ...objs }) => Object.keys(loaded)
  .map(id => ({ ...objs[id] }))
  .sort(sortBy('displayOrder'))
