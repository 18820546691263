import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { useContext } from 'react'
import { createSelector } from '@comfy/redux-selectors'
import { matchPathSelector } from 'selectors/routing'
import { assignmentEditorUrl, contentViewerUrl, studentAssignmentsUrl, studentBinderUrl } from 'routing/consts'
import { CONTENT_TYPE_ECHO } from 'core/consts'
import { sidebarContext } from './SidebarProvider'

const testForEcho = createSelector(
  matchPathSelector({ path: `${assignmentEditorUrl}/:assignmentId/${CONTENT_TYPE_ECHO}/:echoId` }),
  matchPathSelector({ path: `${contentViewerUrl}/:contentType/:contentId/${CONTENT_TYPE_ECHO}/:echoId` }),
  matchPathSelector({ path: `${contentViewerUrl}/${CONTENT_TYPE_ECHO}/:echoId` }),
  matchPathSelector({ path: `${studentAssignmentsUrl}/:contentId/${CONTENT_TYPE_ECHO}/:echoId` }),
  matchPathSelector({ path: `${studentBinderUrl}/:userAssignmentId/${CONTENT_TYPE_ECHO}/:echoId` }),
  (...args) => args.some(Boolean),
)

const OuterViewport = (props) => {
  const { viewportWidth: maxWidth } = useContext(sidebarContext)
  const isEcho = !!useSelector(testForEcho)
  /**
     * This container's first child div MUST use flex-box for Echo due to the
     * dueling sidebars.
     * Ideally this check wouldn't need to happen here in this generic wrapper
     * but it seems to be the cleanest place to handle it.
     */

  return (
    <Box
      {...{ ...props, maxWidth }}
      component="main"
      sx={
        isEcho
          ? { '> div:first-of-type': {
            display: 'flex',
          } }
          : null
      }
      tabIndex={-1} // for <SkipToMainContent />
    />
  )
}

export default OuterViewport
