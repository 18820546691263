import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Maybe } from 'monet'
import {
  getContentByAssetCode,
  getContentById,
  isContentFetching,
  isContentLoaded,
} from 'selectors/content'
import { actions } from 'reducers/content'
import { isDefined, when } from 'fp/utils'
import { newlyAddedChildId } from 'hss/ContentBuilder/consts'
import { getContentViewerParams } from 'selectors/contentViewerParams'
import { first } from 'fp/arrays'
import useIsPinnedContent from './useIsPinnedContent'
import useReduxCallback, { IDLE } from './useReduxCallback'

const useContent = (props = {}) => {
  const routeParams = useParams()
  const [needsFetching, setNeedsFetching] = useState(false)
  const [reduxAction, setReduxAction] = useState(null)
  const matchParams = useSelector(getContentViewerParams())
  const isPinned = useIsPinnedContent()
  const {
    assetCode,
    disableFetch,
    paramName = 'contentId',
    queryParams,
    refresh,
  } = props
  const params = isPinned ? routeParams : { ...routeParams, ...matchParams }

  const byAssetCode = useSelector(getContentByAssetCode({ assetCode }))

  const contentType = props.contentType || params.contentType
  /**
   * Try to use the TE version of the contentId if it exists.  This is largely to
   * support chapter summaries, but can be used anytime there could be duplicates
   * within the chapter nav tree.
   */
  const availableParam = first([paramName].flat().map(p => params[p]).filter(isDefined))
  const contentId = Maybe.fromUndefined(byAssetCode?.id || props.contentId || availableParam)
    .map(v => String(v).replace('TE-', ''))
    .orJust(undefined)

  const isLoaded = useSelector(isContentLoaded({ contentType, contentId, queryParams }))
  const isFetching = useSelector(isContentFetching({ contentType, contentId: assetCode || contentId }))

  useEffect(() => {
    setReduxAction(isDefined(assetCode)
      ? actions.fetchContentByAssetCode({ assetCode, contentType, queryParams })
      : actions.fetchContentById({ contentType, contentId, queryParams }))
    // Purposely excluding queryParams from the dependencies as we'd go into an
    // infinite loop otherwise.
    // SHOULD be safe, but something to keep an eye on if you see weirdness.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetCode, contentId, contentType])

  const [startFetch, fetchStatus] = useReduxCallback({ actionType: reduxAction?.type })

  useEffect(
    () => {
      setNeedsFetching(Boolean(isDefined(assetCode || contentId)
      && fetchStatus === IDLE
      && !disableFetch
      && (!isLoaded || refresh)
      && !isFetching
      && !String(contentId).startsWith(newlyAddedChildId)))
    },
    [
      assetCode,
      contentId,
      contentType,
      disableFetch,
      fetchStatus,
      isFetching,
      isLoaded,
      refresh,
    ],
  )

  useEffect(() => {
    when(needsFetching, startFetch, reduxAction)
  }, [reduxAction, needsFetching, startFetch])

  return useSelector(getContentById({ contentType, contentId }))
}

export default useContent
