import { forwardRef, useId } from 'react'
import PropTypes from 'prop-types'
import MuiSelect from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import visuallyHidden from '@mui/utils/visuallyHidden'
import { omit, pick } from 'fp/objects'
import { isEmptyString } from 'fp/strings'

const controlFields = ['margin']

const Select = forwardRef((props, inputRef) => {
  const {
    controlProps,
    helperText,
    hideLabel = false,
    id,
    label,
    name,
    ...rest
  } = props

  const generatedId = useId()
  const controlId = id || generatedId
  const labelId = `${controlId }-label`

  return (
    <FormControl
      {...{
        ...pick(controlFields)(rest),
        ...controlProps,
      }}
    >

      <InputLabel
        id={labelId}
        sx={hideLabel ? visuallyHidden : {}}
      >
        {label || name}
      </InputLabel>

      <MuiSelect
        {...{
          autoWidth: true,
          id: controlId,
          name,
          inputRef,
          labelId,
          ...(omit(controlFields))(rest),
        }}
      />

      {isEmptyString(helperText)
        ? null
        : <FormHelperText>{helperText}</FormHelperText>}

    </FormControl>
  )
})

Select.propTypes = {
  controlProps: PropTypes.object,
  helperText: PropTypes.node,
  hideLabel: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export default Select
