const overrides = ({ mixins: { size }, palette }) => ({
  MuiFab: {
    variants: [
      {
        props: { variant: 'toc-menu' },
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          svg: size(24),
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },

      {
        props: { color: 'common.black', variant: 'toc-menu' },
        style: {
          color: palette.text.primary,
        },
      },

      {
        props: { color: 'common.white', variant: 'toc-menu' },
        style: {
          color: palette.common.white,
        },
      },

      {
        props: { variant: 'xs' },
        style: {
          ...size(30),
          svg: size(18),
        },
      },
    ],
  },
})

export default overrides
