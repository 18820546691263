import { styled } from '@mui/material/styles'

/** TODO: make this voiceover friendly in mobile
  * Safari doesn't read as a table when the display properties are messed with
  * https://adrianroselli.com/2018/05/functions-to-add-aria-to-tables-and-lists.html
*/
const ResponsiveTable = styled(
  'table',
  { name: 'responsive-table' },
)(({ theme: { breakpoints, mixins: { borderS, important, rem }, palette, typography: { variants } } }) => ({
  borderCollapse: 'collapse',
  counterReset: 'number',
  padding: rem(2),
  tableLayout: ' fixed',
  width: '100%',
  background: important(palette.background.paper),
  th: {
    padding: rem(1),
    ...variants['nav-item-uppercase-semibold'],
    background: important(palette.background.paper),
    ...borderS(palette.border[0]),
    textAlign: 'center',
  },
  td: {
    ...borderS(palette.border[0]),
    padding: 10,
    position: 'relative',
    verticalAlign: 'top',
    '&:last-child.remove': {
      background: important('none'),
      border: important('none'),
    },
    wordWrap: 'break-word',
    p: { margin: 0 },
  },
  textarea: {
    background: 'transparent',
    border: 0,
    minHeight: 0,
    maxHeight: 'unset',
  },
  '.MuiInput-root::before': {
    border: 0,
  },
  '.DraftEditor-root': {
    // Make room for the help button by padding the right instead of the bottom.
    // This results in less empty space inside table cells.
    paddingRight: rem(4),
    '.DraftEditor-editorContainer [data-contents="true"]': {
      paddingBottom: 0,
    },
  },
  [breakpoints.down('sm')]: {
    // experiment showing table as stacked columns on smaller screens
    td: {
      border: 0,
      display: 'block',
      paddingTop: 25,
      position: 'relative',
      height: important('auto'),
      '&::before': {
        content: 'attr(data-title)',
        display: 'block',
        fontWeight: 700,
        lineHeight: 'normal',
        marginBottom: 10,
        textAlign: 'center',
      },
    },
    thead: { display: 'none' },
    th: {
      padding: 0,
      display: 'block',
      textAlign: 'left',
    },
    tr: {
      display: 'block',
      padding: rem(4, 0),
      '&::before': {
        counterIncrement: 'number',
        content: '"#" counter(number)',
        display: 'block',
        fontSize: rem(4),
        marginBottom: rem(3),
      },
      '&:not(:last-child)': {
        borderBottom: `1px solid ${palette.border[0]}`,
      },
    },
  },
}))

export default ResponsiveTable
