import PropTypes from 'prop-types'
import { createContext, useMemo } from 'react'

/** ****************************************************************************
 *                                                                             *
 *                                N O T I C E                                  *
 *                                                                             *
 * This component allows you to maintain the correct nesting and progression   *
 * of headings (h1, h2, etc.) but with the ability to skip one or move levels  *
 * of styling.  So instead of an h2 looking like an h2, you can have it appear *
 * to be an h3 while still being an h2 as far as the DOM and screen readers    *
 * are concerned.                                                              *
 *                                                                             *
 * The applied offset affects any other downstream nested headlines, so take   *
 * care when using it.  The escape hatch for individual <Headline /> instances *
 * is to hardcode their `size` prop.                                           *
 *                                                                             *
 * If you came here looking for a way to ACTUALLY skip heading numbers, then   *
 * see the sister component <HeadlineLevelOffset /> (and it's own caveats)     *
 *                                                                             *
 * ************************************************************************** */

export const headlineStyleOffsetContext = createContext()

const HeadlineStyleOffset = ({ children, offset }) => {
  const value = useMemo(() => ({
    offset,
  }), [offset])

  return (
    <headlineStyleOffsetContext.Provider value={value}>
      {children}
    </headlineStyleOffsetContext.Provider>
  )
}

HeadlineStyleOffset.propTypes = {
  children: PropTypes.node.isRequired,
  offset: PropTypes.oneOf([0, 1, 2, 3, 4, 5]).isRequired,
}

export default HeadlineStyleOffset
