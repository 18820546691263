/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types'
import { forwardRef, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { useDragDropManager } from 'react-dnd'
import { componentShape } from 'core/shapes'
import useDragDrop from 'common/LineReader/useDragDrop'

const DRAGGABLE_TYPE = 'DRAGGABLE_TYPE'

const Draggable = forwardRef(({
  children,
  initialCoord = { x: 0, y: 0 },
  ...rest
}, ref) => {
  const localRef = useRef()
  const [left, setLeft] = useState(initialCoord.x)
  const [top, setTop] = useState(initialCoord.y)

  if (!ref) {
    ref = localRef
  }

  const dragDropManager = useDragDropManager()
  const monitor = dragDropManager.getMonitor()

  useEffect(() => {
    const unsubscribe = monitor.subscribeToOffsetChange(() => {
      if (monitor.getItemType() === DRAGGABLE_TYPE) {
        const offset = monitor.getSourceClientOffset()
        if (offset?.x) setLeft(offset.x)
        if (offset?.y) setTop(offset.y)
      }
    })
    return () => { unsubscribe() }
  }, [monitor])

  useDragDrop(ref, DRAGGABLE_TYPE)

  const draggableStyle = {
    position: 'absolute',
    left: `${left}px`,
    top: `${top}px`,
  }

  return (
    <Box
      data-testid="draggable"
      ref={ref}
      style={draggableStyle}
      {...rest}
    >
      {children}
    </Box>
  )
})

Draggable.propTypes = {
  children: componentShape.isRequired,
  initialCoord: PropTypes.object,
}

export default Draggable
