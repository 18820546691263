const overrides = ({ palette }) => ({
  MuiPaper: {
    variants: [
      {
        props: { color: 'secondary' },
        style: {
          backgroundColor: palette.background.paperSecondary,
          color: palette.accent.contrastText,
        },
      },

      {
        props: { variant: 'simple-stack' },
        style: {
          backgroundColor: 'transparent',
          borderRadius: 0,
          minWidth: 200,
          position: 'relative',

          'img.banner': {
            width: '100%',
          },
        },
      },
    ],
  },
})

export default overrides
