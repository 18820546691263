import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'
import Box from '@mui/material/Box'
import { forwardRef } from 'react'
import { isDefined } from 'fp/utils'

const Well = forwardRef((props, ref) => {
  const {
    bgcolor,
    children,
    color = 'inherit',
    fontSize = 'inherit',
    icon,
    margin = 'dense',
    padding = 'dense',
    sxProps = {},
    variant = 'outlined',
    ...rest
  } = props

  return (
    <Box
      {...rest}
      ref={ref}
      sx={{
        bgcolor: bgcolor || (variant in ['contained', 'solid'] ? 'grey.5' : undefined),
        borderColor: 'border.1',
        borderStyle: variant === 'outlined' ? 'solid' : 'none',
        borderWidth: 1,
        boxShadow: variant === 'contained' ? 'inset 2px 2px 1px rgba(0,0,0,.05)' : undefined,
        margin: ({ mixins: { rem } }) => margin === 'dense'
          ? rem(1.2, 0)
          : margin === 'loose'
            ? rem(2.4, 0)
            : undefined,
        padding: ({ mixins: { rem } }) => padding === 'dense'
          ? rem(1.2)
          : padding === 'loose'
            ? rem(2.4)
            : undefined,
        position: 'relative',
        ...(isDefined(icon) ? {
          display: 'flex',
          'span:first-of-type': {
            display: 'block',
            margin: ({ mixins: { rem } }) => padding === 'dense'
              ? rem(0, 1, 0, 0)
              : padding === 'loose'
                ? rem(2.4)
                : rem(1.2, 1, 0.75),
          },
        } : null),
        ...sxProps,
      }}
    >
      {isDefined(icon)
        ? (
          <>
            <Icon {...{ color, fontSize }}>
              {icon}
            </Icon>
            <div>{children}</div>
          </>
        )
        : children}
    </Box>
  )
})

Well.propTypes = {
  bgcolor: PropTypes.string,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    'inherit',
    'action',
    'disabled',
    'primary',
    'secondary',
    'error',
    'info',
    'success',
    'warning',
  ]),
  fontSize: PropTypes.oneOf([
    'default',
    'inherit',
    'large',
    'medium',
    'small',
  ]),
  icon: PropTypes.element,
  margin: PropTypes.oneOf([
    'dense',
    'loose',
    'none',
  ]),
  padding: PropTypes.oneOf([
    'dense',
    'loose',
    'none',
  ]),
  sxProps: PropTypes.object,
  variant: PropTypes.oneOf([
    'contained',
    'outlined',
    'solid',
    'text',
  ]),
}

export default Well
