/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { fallbackTo, identity, not } from 'fp/utils'
import { getLocalSetting } from 'selectors/localSettings'
import useLocalSetting from './useLocalSetting'

/**
 * Provide 'storageKey' to 'remember' the value in local storage whenever it
 * changes.  Omit 'storageKey' for normal, ephemeral state.
 */

const useToggleState = (defaultVal = false, storageKey = undefined) => {
  const [storedValue, storeValue] = storageKey
    ? useLocalSetting(storageKey, defaultVal)
    : useState(defaultVal)

  const reduxValue = storageKey
    ? fallbackTo(defaultVal)(useSelector(getLocalSetting(storageKey)))
    : identity(storedValue)

  const toggle = useCallback(
    () => {
      storeValue(not(storedValue))
    },
    [storeValue, storedValue],
  )

  /**
   * sometimes it may be handy to explicitly set the value rather than toggling,
   * so storeValue is included here as well.
   */
  return [reduxValue, toggle, storeValue]
}

export default useToggleState
