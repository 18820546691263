import { useMemo } from 'react'
import Bowser from 'bowser'

/**
 * See https://lancedikson.github.io/bowser/docs/index.html for available methods
 */

const useBrowser = () => useMemo(
  () => Bowser.getParser(window.navigator.userAgent),
  [],
)

export default useBrowser
