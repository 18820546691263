import PropTypes from 'prop-types'
import { Settings } from 'react-feather'
import SettingsFilled from 'hss/images/controls/video/settings-filled.svg'

const SettingsIcon = ({ settingsOpen, ...rest }) => (
  settingsOpen
    ? <SettingsFilled {...rest} />
    : <Settings {...rest} />
)

SettingsIcon.propTypes = {
  settingsOpen: PropTypes.bool.isRequired,
}

export default SettingsIcon
