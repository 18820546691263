import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { useContext } from 'react'
import { componentShape } from 'core/shapes'
import Headline from './Headline'
import ScreenReaderText from './ScreenReaderText'
import { headlineStyleOffsetContext } from './HeadlineStyleOffset'

const EyebrowHeadline = ({ component, eyebrow, title, children, headlineVariant, HeadlineProps }) => {
  const { offset: headlineStyleOffset = 1 } = useContext(headlineStyleOffsetContext) || {}

  return (
    <Headline
      Component={component}
      title={<ScreenReaderText>{eyebrow}, {title}</ScreenReaderText>}
    >
      <div aria-hidden="true">

        {Boolean(eyebrow) && (
          <Typography
            component="div"
            variant="eyebrow"
          >
            {eyebrow}
          </Typography>
        )}

        <Typography
          component="div"
          variant={headlineVariant || `h${headlineStyleOffset}`}
          {...HeadlineProps}
        >
          {title}
        </Typography>

      </div>

      {children}

    </Headline>
  )
}

EyebrowHeadline.propTypes = {
  HeadlineProps: PropTypes.object,
  component: componentShape,
  eyebrow: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: componentShape,
  headlineVariant: PropTypes.string,
}

export default EyebrowHeadline
