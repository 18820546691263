const overrides = ({ mixins: { rem } }) => ({
  MuiFormControl: {
    styleOverrides: {
      root: {
        marginTop: rem(3.2),
        width: '100%',
        '*[variant="data-table"] table &': {
          marginTop: 0,
        },
      },
    },

    variants: [
      {
        props: { margin: 'dense' },
        style: { marginTop: 0 },
      },
    ],
  },
})

export default overrides
