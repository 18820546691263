/* eslint-disable no-console */
/* istanbul ignore file */

const originalError = console.error.bind(console)
const originalWarn = console.warn.bind(console)

console.error = (...args) => {
  if (
    typeof args[0] === 'string'
      && args[0].includes('potentially unsafe when doing server-side rendering')
  ) {
    return
  }

  if (
    typeof args[0] === 'string'
      && args[0].includes('Support for defaultProps will be removed')
      && (
        args[2].includes('recharts/lib')
        || args[2].includes('XAxis')
        || args[2].includes('YAxis'))
  ) {
    return
  }

  if (process.env.NODE_ENV === 'test') {
    throw args
  }

  originalError(...args)
}

console.warn = (...args) => {
  if (
    typeof args[0] === 'string'
      && args[0].includes('No supported version of EME detected on this user agent')
  ) {
    return
  }

  if (
    typeof args[0] === 'string'
      && args[0].includes('maybe you don\'t need to use a ResponsiveContainer')
      && args[0].includes('The width(1000) and height(600) are both fixed numbers') // Poll/ChartResults.js
  ) {
    return
  }

  if (process.env.NODE_ENV === 'test') {
    throw args
  }

  originalWarn(...args)
}
