/* eslint-disable no-param-reassign */
import actionTypes from 'reducers/actionTypes'
import { success } from 'sagas/utils'
import { get } from 'fp/objects'
import { toInt } from 'fp/strings'

const markInsertedContent = ({ contentInsertions }) => (item) => {
  const processItem = parent => (content, _, siblings = []) => {
    // add insertionData to content if it was marked as such by the metadata
    contentInsertions.forEach((insertion) => {
      if (insertion.insertionContentId === content.id) {
        if ([
          insertion.parentContentId === parent.id,
          (siblings
            .map(get('id'))
            .includes(insertion.afterContentId))
              || (toInt(insertion.afterContentId) === 0),
        ].every(Boolean)) {
          content.insertionData = { ...insertion }
        }
      }
    })

    content.children = content.children?.map(processItem(content))

    return content
  }

  return processItem(null)(item)
}

const processor = (draft) => {
  // presently this is only needed within the content viewer, so only worrying
  // about straight-up content fetches for now and not the assorted or list fetches
  if ((draft.type === success(actionTypes.CONTENT_FETCH))) {
    draft.response = markInsertedContent(draft)(draft.response)
  }

  return draft
}

export default processor
