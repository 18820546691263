import CardMedia from '@mui/material/CardMedia'
import CardActionArea from '@mui/material/CardActionArea'
import PropTypes from 'prop-types'

const CardRenderer = ({ onComplete, row: { original } }) => (
  <CardActionArea>
    <CardMedia
      alt={`Image that is tagged with the following terms: ${original.tags.join(', ')}`}
      component="img"
      image={original.url}
      onClick={() => onComplete(original)}
    />
  </CardActionArea>
)

CardRenderer.propTypes = {
  onComplete: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
}

export default CardRenderer
