import PropTypes from 'prop-types'
import { TreeItem } from '@mui/x-tree-view'
import { navItemChildShape } from 'core/shapes'
import TreeEntryContent from './TreeEntryContent'

const TreeEntry = ({ children, entry, nodeId, ...rest }) => (
  <TreeItem
    {...rest}
    ContentComponent={TreeEntryContent}
    ContentProps={{ entry }}
    nodeId={nodeId}
    variant="nav-shell"
  >
    {children}
  </TreeItem>
)

TreeEntry.propTypes = {
  entry: navItemChildShape.isRequired,
  children: PropTypes.node,
  nodeId: PropTypes.string.isRequired,
}

export default TreeEntry
