
import { compose } from 'redux'
import actionTypes from 'reducers/actionTypes'
import { CONTENT_TYPE_BLOCK, CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { get, set } from 'fp/objects'
import { dedupe, difference, filter } from 'fp/arrays'
import { toInt } from 'fp/strings'

const processChildren = (data) => {
  const extractIdsFromBody = (s) => {
    // In order to determine hierarchical children of a block,
    // given a string of html `s`:
    // 1. find all elements with a `data-contentid` attribute
    // 2. filter out elements that are links and have a `data-contenttype` attribute that is not `interactive`
    // 3. return the `data-contentid` attribute value of each element

    const div = document.createElement('div')
    div.innerHTML = s

    return Array.from(div.querySelectorAll('[data-contentid]'))
      .filter(el => el.localName !== 'a' || el.getAttribute('data-contenttype') === CONTENT_TYPE_INTERACTIVE)
      .map(el => el.getAttribute('data-contentid'))
      .filter(toInt)
  }

  const refIds = dedupe([
    ...extractIdsFromBody(data.body),
    ...extractIdsFromBody(data.leveledBody),
    ...extractIdsFromBody(data.spanishBody),
  ])

  const childIds = data.children.map(get('id'))

  const missing = difference(refIds)(childIds)
  const extra = difference(childIds)(refIds)

  const removeExtra = filter(({ id }) => !extra.includes(id))
  const addMissing = items => [...items, ...missing.map(id => ({ id }))]

  return set('children', compose(
    addMissing,
    removeExtra,
  )(data.children))(data)
}

const processor = (draft) => {
  if ((draft.type === actionTypes.CONTENT_SAVE) && (draft.payload?.contentType === CONTENT_TYPE_BLOCK)) {
    draft.payload = processChildren(draft.payload)
  }

  return draft
}

export default processor
