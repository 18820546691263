import cl from 'classnames'
import PropTypes from 'prop-types'
import { flexRender } from '@tanstack/react-table'
import { get } from 'fp/objects'
import { identity } from 'fp/utils'
import { hasData } from '../Cells'
import DiagonalColumnHeader from './DiagonalColumnHeader'

const HeadData = ({ headers, rows }) => {
  const headersWithData = headers.filter(hasData)
  const startColumnIndex = headers.length - headersWithData.length

  return (
    <>
      <tr>
        <td
          className="blank"
          rowSpan={rows.length + 1}
        />
        {Boolean(startColumnIndex > 1) && (
          <td
            className="blank"
            colSpan={startColumnIndex - 1}
          />
        )}
        {headersWithData.map(header => (
          <DiagonalColumnHeader key={header.id}>
            {flexRender(
              header.column.columnDef.header,
              header.getContext(),
            )}
          </DiagonalColumnHeader>
        ))}
      </tr>
      {rows.map(({ name, accessorFn, accessorKey }, rowIndex) => (
        <tr
          className={cl({ 'even-row': rowIndex % 2 === 0 })}
          key={name}
        >
          {Boolean(!rowIndex && startColumnIndex > 2) && (
            <td
              className="blank"
              colSpan={startColumnIndex - 2}
              rowSpan={rows.length}
            />
          )}
          <th
            headers=""
            scope="row"
          >
            {name}
          </th>
          {headersWithData.map(({ id, column: { columnDef: { data } } }) => (
            <td key={id}>
              {(accessorFn || get(accessorKey) || identity)(data)}
            </td>
          ))}
        </tr>
      ))}
    </>
  )
}

HeadData.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({
    accessorFn: PropTypes.func,
    accessorKey: PropTypes.string,
    name: PropTypes.string.isRequired,
  })).isRequired,
}

export default HeadData
