import PropTypes from 'prop-types'
import { useContext } from 'react'
import Box from '@mui/material/Box'
import Link from 'common/navigation/links/Link'
import { sidebarContext } from 'routing/shells/SidebarProvider'
import TraverseLarge from 'hss/images/logo-full.svg'
import TraverseSmall from 'hss/images/logo-small.svg'
import Centered from 'common/layout/Centered'

const Logo = ({ homeUrl }) => {
  const { isSidebarShrunk } = useContext(sidebarContext)

  return (
    <Box component={isSidebarShrunk ? Centered : 'div'}>
      <Link
        // without this, the drawer will open unexpectedly
        onClick={e => e.stopPropagation()}
        to={homeUrl}
      >
        {
          isSidebarShrunk
            ? <TraverseSmall data-testid="TraverseSmall" />
            : <TraverseLarge data-testid="TraverseLarge" />
        }
      </Link>
    </Box>
  )
}

Logo.propTypes = {
  homeUrl: PropTypes.string.isRequired,
}

export default Logo
