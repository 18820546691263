import { useContext } from 'react'
import Icon from '@mui/material/Icon'
import { EyeOff } from 'react-feather'
import { CONTENT_SUBTYPE_CHAPTER_SUMMARY } from 'core/consts'
import { noop } from 'fp/utils'
import { assignmentEditContext } from 'hss/AssignmentEditor/assignmentEditContext'
import { contentShape } from 'core/shapes'
import ContentRestrictionIndicator from 'hss/views/Curriculum/ContentRestrictionIndicator'

const AssignmentExclusionIndicator = ({ item }) => {
  const context = useContext(assignmentEditContext)
  const { includeChapterSummary, isContentIdExcluded = noop } = context || {}
  const isMyContentIdExcluded = isContentIdExcluded(item.id) && !item.teacherEdition

  return Boolean(isMyContentIdExcluded || (
    !!context
    && item.contentSubType === CONTENT_SUBTYPE_CHAPTER_SUMMARY
    && !includeChapterSummary
  )) && (
    <Icon
      aria-hidden={false}
      aria-label="(excluded from assignment)"
      role="img"
      title="Excluded from assignment"
    >
      <EyeOff />
    </Icon>
  )
}

AssignmentExclusionIndicator.propTypes = {
  item: contentShape.isRequired,
}

const ItemExclusionIndicator = ({ item }) => {
  const isEditingAssignment = Boolean(useContext(assignmentEditContext))
  return isEditingAssignment
    ? <AssignmentExclusionIndicator item={item} />
    : <ContentRestrictionIndicator content={item} />
}

ItemExclusionIndicator.propTypes = {
  item: contentShape.isRequired,
}

export default ItemExclusionIndicator
