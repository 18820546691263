import { components } from 'react-select'
import { X as Close } from 'react-feather'

const ClearIndicator = props => (
  <components.ClearIndicator {...props}>
    <Close />
  </components.ClearIndicator>
)

export default ClearIndicator
