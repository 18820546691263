import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { mergeBlockDataByKey } from '@studysync/draft-js-modifiers'
import { customBlockPropsShape } from './utils'

const PluginInput = (props) => {
  const {
    block,
    blockProps: { setPluginHasFocus },
    component = 'input',
    dataField,
    getEditorState,
    options = [],
    setEditorState,
    ...rest
  } = props

  const [value, setValue] = useState('')

  useEffect(() => {
    const data = block.getData()

    setValue(data.get(dataField))
  }, [block, dataField, getEditorState])

  const updateEditor = (newValue) => {
    try {
      if (dataField && setEditorState) {
        const newEditorState = mergeBlockDataByKey(
          getEditorState(),
          block.getKey(),
          { [dataField]: newValue },
        )

        setEditorState(newEditorState)
      }
    } finally {
      setPluginHasFocus(false)
    }
  }

  const handleBlur = () => {
    updateEditor(value)
  }

  const handleFocus = () => setPluginHasFocus(true)
  const handleChange = ({ target }) => {
    setValue(target.value)
    if (component === 'select') {
      updateEditor(target.value)
    }
  }

  if (component === 'input') {
    return (
      <input
        {...rest}
        className="plugin-input"
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        value={value || ''}
      />
    )
  }

  if (component === 'select') {
    return (
      <select
        {...rest}
        className="plugin-input"
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        value={value}
      >
        {options.map(([label, val]) => (
          <option
            key={label}
            value={val}
          >
            {label}
          </option>
        ))}
      </select>
    )
  }

  return <div>BAD INPUT CONFIG</div>
}

PluginInput.propTypes = {
  block: PropTypes.object.isRequired,
  blockProps: customBlockPropsShape.isRequired,
  component: PropTypes.string,
  data: PropTypes.object,
  dataField: PropTypes.string,
  getEditorState: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.array),
  setEditorState: PropTypes.func,
}

export default PluginInput
