import PropTypes from 'prop-types'
import TableContainer from '@mui/material/TableContainer'
import { useState } from 'react'
import { LiveMessage } from 'react-aria-live'
import cl from 'classnames'
import ScreenReaderText from 'common/text/ScreenReaderText'
import { componentShape } from 'core/shapes'
import { callOwn } from 'fp/call'
import { get } from 'fp/objects'
import { tableShape } from '../CardView'
import Headers, { ariaSortValue } from './Headers'
import Rows from './Rows'

const TableView = ({
  caption,
  CardRenderer,
  cardRendererProps,
  className,
  headDataRows,
  table,
  table: {
    getAllColumns,
    getHeaderGroups,
    getRowModel,
    options: { enableRowSelection },
  },
  tableId,
  variant = 'data-table',
  ...rest
}) => {
  const [announceSortStatus, setAnnounceSortStatus] = useState(false)

  const allColumns = getAllColumns()
  const { rows } = getRowModel()

  const canBeSorted = allColumns
    .map(callOwn('getCanSort'))
    .some(Boolean)

  const sortedColumn = allColumns.find(callOwn('getIsSorted'))

  const sortStatus = sortedColumn
    ? `Sorting by ${sortedColumn.columnDef.accessibleName || sortedColumn.columnDef.header} ${ariaSortValue(sortedColumn)}.`
    : 'Using default sort order.'

  const handleSort = handler => (event) => {
    handler(event)
    setAnnounceSortStatus(true)
  }

  const hasDiagonalColumnHeader = Boolean(allColumns.filter(get('columnDef.data')).length)

  return (
    <>
      {Boolean(canBeSorted) && (
        <>
          {Boolean(announceSortStatus) && (
            <LiveMessage
              aria-live="assertive"
              message={sortStatus}
            />
          )}

          {Boolean(sortedColumn) && (
            <ScreenReaderText id={`${tableId}-sort-status`}>
              {sortStatus}
            </ScreenReaderText>
          )}

          <ScreenReaderText id={`${tableId}-sort-instructions`}>
            To change table sort order, click a button in a column header.
          </ScreenReaderText>
        </>
      )}

      <TableContainer variant={variant}>

        <table
          aria-describedby={`${tableId}-sort-status ${tableId}-sort-instructions`}
          {...rest}
          className={cl(
            {
              'selectable-rows': enableRowSelection,
              'has-diagonal-header': hasDiagonalColumnHeader,
              'has-head-data-rows': headDataRows?.length,
            },
            className,
          )}
        >

          {Boolean(caption) && (<caption>{caption}</caption>)}

          <Headers
            {...{
              enableRowSelection,
              handleSort,
              headDataRows,
              headerGroups: getHeaderGroups(),
              tableId,
            }}
          />

          <Rows rows={rows} />

        </table>

      </TableContainer>
    </>
  )
}

TableView.propTypes = {
  caption: componentShape,
  CardRenderer: componentShape,
  cardRendererProps: PropTypes.object,
  headDataRows: PropTypes.array, // TableView doesn't care what's in the array.
  table: tableShape.isRequired,
  tableId: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

export default TableView
