import PropTypes from 'prop-types'
import { createElement, useId, useMemo, useState } from 'react'
import Menu from '@mui/material/Menu'
import { isFunction, noop } from 'fp/utils'
import { distill } from 'fp/call'
import { preventDefault } from 'fp/dom'
import DraftToolButton from './DraftToolButton'
import DraftMenuItem from './DraftMenuItem'

const DraftMenuButton = (props) => {
  const { excludeToolButtons = [], featureMenuKey, features, onChange, options, setEditorState, ...rest } = props
  const [anchorEl, setAnchorEl] = useState(null)

  const id = useId()

  const { editorState } = rest

  const handleClick = (event) => {
    preventDefault(event)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const combinedOptions = useMemo(
    () => options
      .filter(Boolean)
      .filter(({ key }) => !excludeToolButtons.includes(key)),
    [
      excludeToolButtons,
      options,
    ],
  )

  const handleChange = (item) => {
    if (item.handleMenuChange) {
      item.handleMenuChange(item, { editorState, setEditorState })
    } else {
      onChange(item)
    }
    handleClose()
  }

  return (
    <>
      <DraftToolButton
        {...rest}
        excludeToolButtons={excludeToolButtons}
        onMouseDown={handleClick}
        showDropdownIndicator
      />

      <Menu
        anchorEl={anchorEl}
        autoFocus={false}
        className="DraftEditor-blockStylesOnly"
        disableAutoFocus
        disableAutoFocusItem
        disableEnforceFocus
        disableRestoreFocus
        id={`${featureMenuKey}-${id}`}
        keepMounted
        onClose={handleClose}
        onMouseDown={preventDefault}
        open={Boolean(anchorEl)}
      >
        {combinedOptions.map((item, idx) => {
          if (isFunction(item)) {
            return createElement(item, {
              editorState,
              features,
              handleClose,
              key: idx,
              setEditorState,
              toggleBlockType: noop,
            })
          }

          return (
            <DraftMenuItem
              data-key={item.key}
              disabled={item.disabled}
              key={item.key || idx}
              onClick={() => handleChange(item)}
              selected={item.selected}
            >
              {distill(item.label)}
            </DraftMenuItem>
          )
        })}
      </Menu>
    </>
  )
}

DraftMenuButton.propTypes = {
  excludeToolButtons: PropTypes.arrayOf(PropTypes.string),
  features: PropTypes.object.isRequired,
  featureMenuKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ])).isRequired,
  setEditorState: PropTypes.func,
}

export default DraftMenuButton
