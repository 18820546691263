import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { memo } from 'react'
import Popper from 'common/indicators/Popper'
import { standardShape } from 'core/shapes'
import Headline from 'common/text/Headline'

const paperStyle = {
  minWidth: 495,
}

const StandardItem = memo(({ standard }) => (
  <Popper
    disableCloseButton
    paperStyle={paperStyle}
  >
    <Popper.Control>
      <Button variant="mock-link">
        {standard.shortCode}
      </Button>
    </Popper.Control>

    <Popper.Content className="standard-item-popper">
      <Headline
        light
        size={4}
        title={standard.shortCode}
      >
        <Box
          mt={3}
          textAlign="left"
        >
          {standard.statement}
        </Box>
      </Headline>
    </Popper.Content>
  </Popper>
), (prevProps, nextProps) => prevProps.standard === nextProps.standard)

StandardItem.propTypes = {
  standard: standardShape.isRequired,
}

export default StandardItem
