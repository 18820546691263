/* istanbul ignore file */
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import AppBusy from 'common/indicators/AppBusy'
import withTabModeSupport from 'hoc/withTabModeSupport'
import ErrorLog from './errorHandling/ErrorLog'
import ErrorBanner from './errorHandling/ErrorBanner'

const ManagedRoot = withTabModeSupport(Typography)

const App = ({ children }) => (
  <>

    <ManagedRoot
      className="app-container-active"
      component="div"
      variant="body1"
    >
      {children}

      <ErrorLog />

      <ErrorBanner />

    </ManagedRoot>

    <AppBusy className="app-container-busy" />

  </>
)

App.propTypes = {
  children: PropTypes.node.isRequired,
}

export default App
