import { useDispatch } from 'react-redux'
import { compose } from 'redux'
import { useCallback, useMemo } from 'react'
import { apiCall } from 'sagas/utils'

/**
 * useApiFromEffect is a relatively safe way to call the api from outside of redux.
 *
 * The function it returns should only be used within an effect as it does cause
 * side-effects.
 */

const useApiFromEffect = () => {
  const dispatch = useDispatch()
  const caller = useMemo(() => compose(dispatch, apiCall), [dispatch])
  return useCallback(payload => new Promise((callback) => {
    caller({ ...payload, callback })
  }), [caller])
}

export default useApiFromEffect
