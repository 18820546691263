import { useTheme } from '@mui/material/styles'
import useStateWithDynamicDefault from './useStateWithDynamicDefault'

const useAccentColor = () => {
  const { palette } = useTheme()
  const [accentColor] = useStateWithDynamicDefault(palette.accent)

  return accentColor
}

export default useAccentColor
