import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import LogIcon from '@mui/icons-material/InfoOutlined'
import Button from '@mui/material/Button'
import DialogContentText from '@mui/material/DialogContentText'
import Fab from '@mui/material/Fab'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Tooltip from '@mui/material/Tooltip'
import DialogTitle from '@mui/material/DialogTitle'
import { actions } from 'reducers/errorLog'
import { stateErrorLog } from 'selectors/index'
import Dialog from 'common/dialogs/Dialog'

const IndicatorButton = styled(
  Fab,
  { name: 'errorHandling-ErrorLog' },
)(({ theme: { mixins: { rem }, palette } }) => ({
  background: palette.error.main,
  position: 'fixed',
  bottom: rem(2),
  right: rem(2),
  color: 'white',
  '&:hover': { background: palette.error.dark },
}))

const ErrorLog = () => {
  const dispatch = useDispatch()
  const { logVisible, messages } = useSelector(stateErrorLog)
  const closeLog = () => dispatch(actions.showLog({ logVisible: false }))

  const renderButton = () => (
    <Tooltip
      placement="top-end"
      title="Review error messages..."
    >
      <IndicatorButton
        aria-label="add"
        onClick={() => dispatch(actions.showLog({ logVisible: true }))}
        size="small"
      >
        <LogIcon />
      </IndicatorButton>
    </Tooltip>
  )

  return (
    <>
      {messages.length > 0 && renderButton()}

      <Dialog
        onClose={closeLog}
        open={logVisible}
        showCloseButton
      >
        <DialogTitle>Error Log</DialogTitle>

        <DialogContent>
          <ol>
            {messages.map(({ text, count }, key) => (
              <DialogContentText key={`error${key}`}>
                <li>{`${text} ${count === 1 ? '' : ` (x${count})`}`}</li>
              </DialogContentText>
            ))}
          </ol>
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            onClick={closeLog}
            variant="secondary"
          >
            Close
          </Button>

          <Button
            color="secondary"
            onClick={() => dispatch(actions.clearAll())}
            variant="primary"
          >
            Clear
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ErrorLog
