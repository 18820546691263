import { Maybe } from 'monet'
import { useSelector } from 'react-redux'
import { getParentOfContentByType } from 'selectors/contentViewer'
import { CONTENT_TYPE_CHAPTER } from 'core/consts'
import { pick, set } from 'fp/objects'
import useContent from 'hooks/useContent'
import { getAggregatedSkillVocabFromContentChildren, getAggregatedVocabFromContentChildren } from 'selectors/vocabulary'

const useChapter = ({ contentType, contentId }) => {
  const chapterParams = Maybe.fromFalsy(useSelector(getParentOfContentByType({
    contentType,
    contentId,
    parentContentType: CONTENT_TYPE_CHAPTER,
  })))
    .map(c => set('contentId', c.id)(c))
    .map(pick('contentId', 'contentType'))
    .map(set('queryParams.childDepth', 999)) // Content may not already be loaded, e.g. when previewing inside block editor
    .orJust({ disableFetch: true })

  return useContent(chapterParams)
}

export const useChapterSkillVocabPhrases = ({ contentType, contentId }) => {
  const chapter = useChapter({ contentType, contentId })
  return useSelector(getAggregatedSkillVocabFromContentChildren({ content: chapter }))
}

export const useChapterVocabPhrases = ({ contentType, contentId }) => {
  const chapter = useChapter({ contentType, contentId })
  return useSelector(getAggregatedVocabFromContentChildren({ content: chapter, vocabType: 'content' }))
}
