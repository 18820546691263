import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { getImageUrlFromUploadsMap } from 'common/avclub/utils'

const UploadsPreview = styled(
  (props) => {
    const { className, uploadsMap } = props

    const url = getImageUrlFromUploadsMap(uploadsMap)

    return url
      ? (
        <div className={className}>
          <img
            alt="Preview"
            src={url}
          />
        </div>
      )
      : null
  },
  { name: 'interactivePlugin-UploadsPreview' },
)({
  maxWidth: 120,
  maxHeight: 120,
  img: {
    objectFit: 'cover',
    width: '100%',
  },
})

UploadsPreview.propTypes = {
  uploadsMap: PropTypes.object.isRequired,
}

export default UploadsPreview
