import Box from '@mui/material/Box'
import { useParams } from 'react-router-dom'
import { appUrl } from 'routing/consts'
import PinnedRouteProvider from 'routing/TraverseRoute/Pinning'
import withContent from 'hoc/withContent'
import { AssignmentEditContextProvider } from 'hss/AssignmentEditor/assignmentEditContext'
import withFetchedItem from 'hoc/withFetchedItem'
import actionTypes from 'reducers/actionTypes'
import withOmittedProps from 'hoc/withOmittedProps'
import ProgressTracker from 'hss/sections/Assignments/ProgressTracker'
import LastViewedContentTracker from 'hss/sections/Assignments/LastViewedContentTracker'
import RouteTransitionProvider from 'routing/RouteTransition/RouteTransitionProvider'
import { SidebarProvider } from '../SidebarProvider'
import OuterViewport from '../OuterViewport'
import TocSidebar from './TocSidebar'
import MainViewport from './MainViewport'

const ContentGate = withContent(withOmittedProps(Box, 'content', 'contentType', 'editing'))
const AssignmentGate = withFetchedItem(Box, { actionType: actionTypes.ASSIGNMENT_FETCH, includeScoringData: true })
const UserAssignmentGate = withFetchedItem(Box, { actionType: actionTypes.USER_ASSIGNMENT_FETCH })

const TocShell = props => (

  <SidebarProvider variant="toc">

    <ContentGate
      busy="app"
      display="flex"
      queryParams={{
        ...props,
        childDepth: 999,
      }}
    >

      <RouteTransitionProvider transitionType="leftBump">

        <TocSidebar homeUrl={appUrl} />

        <OuterViewport>

          <PinnedRouteProvider>

            <MainViewport />

          </PinnedRouteProvider>

        </OuterViewport>

      </RouteTransitionProvider>

    </ContentGate>

  </SidebarProvider>

)

const TempTocShellWithParams = () => {
  const { userAssignmentId } = useParams()
  return <TocShell forUserAssignmentId={userAssignmentId} />
}

export const AssignmentEditorTocShell = () => (
  <AssignmentGate>
    <AssignmentEditContextProvider>
      <TocShell />
    </AssignmentEditContextProvider>
  </AssignmentGate>
)

export const AssignmentCreatorTocShell = () => (
  <AssignmentEditContextProvider>
    <TocShell />
  </AssignmentEditContextProvider>
)

export const StudentAssignmentTocShell = () => (
  <UserAssignmentGate>
    <LastViewedContentTracker />
    <ProgressTracker />
    <TempTocShellWithParams />
  </UserAssignmentGate>
)

export default TocShell
