import { success } from 'sagas/utils'
import actionTypes from './actionTypes'
import {
  createReducer,
} from './utils'

const creditsReducerInitialState = () => ({ data: [], fetched: false })

export const actions = {
  fetchCredits: payload => ({
    type: actionTypes.CREDITS_FETCH,
    payload,
  }),
}

const formattedCreditsData = creditsData => creditsData.map(({ data: sectionData, description, title }) => ({
  data: sectionData.map(arrData => ({
    title: arrData[0],
    imgNum: arrData[1],
    credit: arrData[2],
  })),
  description,
  title,
}))

const handleFetchSuccess = (state, { response }) => ({
  ...state,
  data: response.data.length ? formattedCreditsData(response.data) : [],
  fetched: true,
})

const credits = createReducer(
  creditsReducerInitialState(),
  {
    [success(actionTypes.CREDITS_FETCH)]: handleFetchSuccess,
  },
)

export default credits
