import PropTypes from 'prop-types'
import { CameraOff } from 'react-feather'
import Container from '@mui/material/Container'
import useContentCardVariant from 'hooks/useContentCardVariant'
import { viewModeShape } from 'hss/views/Search/consts'

const FallbackImage = ({ sx, viewMode = 'list', ...rest }) => {
  const { aspectRatio, maxImageWidth: maxWidth } = useContentCardVariant({ viewMode })

  return (
    <Container
      aria-hidden="true"
      {...rest}
      sx={{
        aspectRatio,
        backgroundColor: 'grey.3',
        maxWidth,
        alignItems: 'center',
        svg: {
          width: '100%',
          height: '80%',
          color: 'grey.5',
        },
        margin: 0,
        ...sx,
      }}
    >
      <CameraOff />
    </Container>
  )
}

FallbackImage.propTypes = {
  sx: PropTypes.object,
  viewMode: viewModeShape,
}

export default FallbackImage
