import { useEffect, useMemo, useRef } from 'react'
import deepClone from 'rfdc/default'
import compare from 'just-compare'

const useDeepCompareMemoize = (value) => {
  const ref = useRef()

  if (!compare(value, (ref.current))) {
    ref.current = deepClone(value)
  }

  return ref.current
}

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useDeepCompareEffect = (callback, dependencies) => useEffect(
  callback,
  dependencies.map(useDeepCompareMemoize),
)

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useDeepCompareMemo = (callback, dependencies) => useMemo(
  callback,
  dependencies.map(useDeepCompareMemoize),
)
