import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Link from 'common/navigation/links/Link'

const withHoverIcon = (WrappedComponent, Icon, HoverIcon) => {
  const Enhanced = styled(({ children, className, ...rest }) => (
    <WrappedComponent
      className={className}
      {...rest}
    >
      <div className="icon-container">
        <Icon className={HoverIcon ? 'icon' : null} />
        {Boolean(HoverIcon) && (
          <HoverIcon className="icon-hover" />
        )}
      </div>
      {children}
    </WrappedComponent>
  ), {
    name: 'EasingHoverIcon',
  })(({ theme: { mixins: { absHeight, size } } }) => ({
    '.icon-container': {
      position: 'relative',
      ...absHeight(24),
      svg: size(24),
    },
    '.icon, .icon-hover': {
      position: 'absolute',
      top: 0,
      left: 0,
    },
    '.icon': { opacity: 1 },
    '.icon-hover': { opacity: 0 },
    '&:hover': {
      '.icon': { opacity: 0 },
      '.icon-hover': { opacity: 1 },
    },
  }))

  return Enhanced
}

const IconLinkCell = ({ HoverIcon, Icon, row, urlAccessor, value }) => {
  const IconLink = withHoverIcon(Link, Icon, HoverIcon)
  return (
    <IconLink
      aria-label={value}
      to={urlAccessor(row.original)}
    />
  )
}

IconLinkCell.propTypes = {
  HoverIcon: PropTypes.any,
  Icon: PropTypes.any.isRequired,
  row: PropTypes.object.isRequired,
  urlAccessor: PropTypes.func.isRequired,
  value: PropTypes.any,
}

export default IconLinkCell
