const overrides = ({ palette }) => ({
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        height: 10,
      },
    },

    variants: [
      {
        props: { 'data-subvariant': 'nav' },
        style: {
          backgroundColor: palette.grey[0],
          '.MuiLinearProgress-bar': {
            backgroundColor: palette.navigation.tocLight,
          },
        },
      },
    ],
  },
})

export default overrides
