import { darken } from 'polished'

const overrides = ({ mixins: { important, size }, palette }) => ({
  MuiToggleButtonGroup: {
    variants: [
      {
        props: { variant: 'overlay' },
        style: {
          backgroundColor: palette.cobalt[0],
          svg: {
            color: palette.cobalt.contrastText,
          },
          '.Mui-selected': {
            backgroundColor: important(darken(0.3, palette.cobalt[0])),
            color: palette.cobalt.contrastText,
          },
          '.Mui-disabled': {
            opacity: 0.5,
          },
        },
      },

      {
        props: { variant: 'overlay', size: 'small' },
        style: {
          button: size(35),
        },
      },

      {
        props: { orientation: 'vertical' },
        style: {
          button: {
            borderTopColor: important(palette.border[1]),
          },
        },
      },

      {
        props: { variant: 'chart-grading' },
        style: {
          display: 'flex',
          justifyContent: 'center',
          button: {
            background: 'none',
            border: 'none',
          },
        },
      },
    ],
  },
})

export default overrides
