import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import { compose } from 'redux'
import { useRef, useState } from 'react'
import Dialog from 'common/dialogs/Dialog'
import { alter, assert } from 'core/store/search/squery'
import useDataset from 'hooks/useDataset'
import { restEndpoint } from 'reducers/utils'
import DialogPagination from 'common/formControls/textInputs/RichTextEdit/plugins/interactivePlugin/DialogPagination'
import Filter from './Filter'
import SearchResults from './SearchResults'

const initialSquery = compose(
  alter.set.limit(12),
  alter.set.where('type').is('image'),
  alter.set.where('group').is('echo'),
)(assert())

const ImageBankSelector = ({ onClose, onComplete: originalOnComplete, open }) => {
  const resultsRef = useRef()
  const [squery, setSquery] = useState(initialSquery)
  const dataset = useDataset({ restEndpoint: restEndpoint.bankAssets, squery })

  const onComplete = (...args) => {
    onClose()
    originalOnComplete(...args)
  }

  return (
    <Dialog
      onClose={onClose}
      open={open}
      showCloseButton
      swatch
      title="Images"
      variant="maximized-vertically"
    >

      <DialogContent style={{ overflow: 'hidden' }}>

        <Filter {...{ squery, setSquery }} />

        <SearchResults {...{ dataset, onComplete, ref: resultsRef }} />

      </DialogContent>

      <DialogPagination {...{ dataset, squery, setSquery, scrollToResultsRef: resultsRef }} />

    </Dialog>
  )
}

ImageBankSelector.propTypes = {
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default ImageBankSelector
