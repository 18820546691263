const overrides = ({ mixins: { size, transition } }) => ({
  MuiIcon: {
    variants: [
      {
        props: { 'data-subvariant': 'exit' },
        style: {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          width: 55,
          paddingLeft: 20,
          svg: {
            ...size(24),
            ...transition('stroke-width'),
            '&:hover': {
              strokeWidth: 2,
            },
          },
        },
      },

      {
        props: { variant: 'xl' },
        style: {
          height: 'unset',
          width: 'unset',
          svg: size(48),
        },
      },

      {
        props: { variant: 'sm' },
        style: {
          height: 'unset',
          width: 'unset',
          svg: size(12),
        },
      },
    ],
  },
})

export default overrides
