import PropTypes from 'prop-types'
import RubricRating from 'common/rubrics/Rating'
import { numberOrString, rubricCriteriaShape } from 'core/shapes'

const SimpleStarRating = ({
  disabled = false,
  readOnly = false,
  rubricCriteria,
  value,
  ...rest
}) => {
  const row = rubricCriteria || { options: [], title: '' }

  return (
    <RubricRating
      data-subvariant="peer-review"
      nonRubric
      precision={readOnly ? 0.5 : 1}
      showScores={false}
      {...rest}
      {...{
        disabled,
        readOnly,
        row,
        value,
      }}
    />
  )
}

SimpleStarRating.propTypes = {
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  rubricCriteria: rubricCriteriaShape,
  value: numberOrString,
}

export default SimpleStarRating
