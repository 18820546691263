import { styled } from '@mui/material/styles'
import { forwardRef } from 'react'
import { omit } from 'fp/objects'

// is there a prettier way to forward ref and styled?
const Wrapper = forwardRef((props, ref) => (
  <div
    {...omit('darkBackdropImage')(props)}
    ref={ref}
  />
))

export const CalloutRenderer = styled(Wrapper)(({ theme: { palette }, darkBackdropImage }) => {
  const defaultColor = darkBackdropImage ? palette.common.white : palette.grey[0]

  return {
    transform: 'translate(-50%, -50%)',

    circle: {
      fill: defaultColor,
    },

    '&:hover circle': {
      fill: defaultColor,
    },
  }
})

export const TextRenderer = styled(Wrapper)(({ theme: { breakpoints, mixins: { borderS, rem }, palette } }) => ({
  alignItems: 'center',
  background: palette.background.default,
  cursor: 'move',
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  userSelect: 'none',
  ...borderS(palette.border[0]),
  transform: 'translate(-50%, -50%)',
  minWidth: rem(10),
  minHeight: rem(4.8),
  [breakpoints.down('xs')]: { minWidth: rem(7), minHeight: rem(3) },
  [breakpoints.up('sm')]: { minWidth: rem(10), minHeight: rem(4.8) },
}))
