import { deepMerge } from 'fp/objects'

const elementMap = {
  blockquote: 'blockquote',
  'header-one': 'h1',
  'header-two': 'h2',
  'header-three': 'h3',
  'header-four': 'h4',
  'header-five': 'h5',
  'header-six': 'h6',
  paragraph: 'p',
  sub: 'sub',
}

const blockToHTML = (/* features */) => (current, { data, type }) => {
  const variants = String(data?.variants?.join?.(' ') || '').trim()
  const { float } = data
  const hasFloat = ['left', 'right'].includes(float)

  const tag = elementMap[type]

  if (variants.length && elementMap[type]) {
    return {
      start: hasFloat
        ? `<${tag} data-typography="${variants}" data-float="${float}">`
        : `<${tag} data-typography="${variants}">`,
      end: `</${tag}>`,
    }
  }

  return hasFloat
    ? {
      start: hasFloat
        ? `<${tag} data-float="${float}">`
        : `<${tag}>`,
      end: `</${tag}>`,
    }
    : current
}

const htmlToBlock = (/* features */) => (current, nodeName, node) => {
  const float = node?.getAttribute('data-float')

  if (node?.getAttribute?.('data-typography') && Object.values(elementMap).includes(nodeName)) {
    const type = Object.entries(elementMap).find(([, tag]) => tag === nodeName)[0]
    return deepMerge(current, {
      type,
      mutability: 'IMMUTABLE',
      data: {
        variants: node?.getAttribute?.('data-typography').split(' '),
        ...float ? { float } : null,
      },
    })
  }

  if (float) {
    return deepMerge(current, {
      mutability: 'IMMUTABLE',
      data: { float },
    })
  }

  return current
}

const htmlToStyle = (/* features */) => (nodeName, node, currentStyle) => {
  if (nodeName === 'span') {
    const style = node.getAttribute('style')

    /**
     * If we wind up needing more inline styles, this will have to be made smarter
     */
    if (style === 'background-color:accent') {
      return currentStyle.add('HIGHLIGHTED')
    }
    if (node.dataset.typography === 'answer') {
      return currentStyle.add('ANSWER')
    }
  }

  switch (nodeName) {
    case 'sub':
      return currentStyle.add('SUBSCRIPT')
    case 'sup':
      return currentStyle.add('SUPERSCRIPT')
    default:
      return currentStyle
  }
}

const styleToHTML = (/* features */) => (current, styleName) => {
  switch (styleName) {
    case 'HIGHLIGHTED':
      return <span style={{ backgroundColor: 'accent' }} />
    case 'ANSWER':
      return <span data-typography="answer" />
    case 'SUBSCRIPT':
      return <sub />
    case 'SUPERSCRIPT':
      return <sup />
      /* istanbul ignore next */
    default:
      return current
  }
}

const extendedFormattingPlugin = {
  blockToHTML,
  htmlToBlock,
  htmlToStyle,
  styleToHTML,
}

export default extendedFormattingPlugin
