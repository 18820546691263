import MuiRadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { createElement } from 'react'
import { propBagsShape, withPropBags } from '../Form/withPropBags'
import Radio from './Radio'

const HFRadioGroup = withPropBags([
  'ItemRenderer',
  'children',
  'disabled',
  'error',
  'helperText',
  'indeterminate',
  'inputProps',
  'inputRef',
  'label',
  'options',
  'value',
])((props) => {
  const { componentProps, controlProps, rest } = props
  const {
    ItemRenderer = Radio,
    children,
    disabled,
    error,
    helperText,
    label,
    options,
    value,
  } = controlProps
  const { formLabelProps, outlined, ...spread } = rest

  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      error={error}
      {...spread}
      sx={outlined
        ? {
          border: '1px solid',
          borderColor: 'grey.0',
          borderRadius: 0,
          padding: '.5rem 2rem',
        }
        : null}
    >
      <FormLabel
        className="hidden"
        component="legend"
        {...formLabelProps}
      >
        {label}
      </FormLabel>
      <MuiRadioGroup
        aria-label={label}
        value={value}
        {...componentProps}
      >
        {options?.map((option) => {
          const { id, value: val } = option
          return createElement(ItemRenderer, {
            ...option,
            key: id,
            value: String(val || id),
          })
        })}
        {children}
      </MuiRadioGroup>
      <FormHelperText data-variant="group">{helperText}</FormHelperText>
    </FormControl>
  )
})

HFRadioGroup.propTypes = propBagsShape.isRequired

export default HFRadioGroup
