import { all, call, put } from 'redux-saga/effects'
import { compose } from 'redux'
import {
  filterKeyedObject,
  get,
  mapKeys,
  merge,
  nullIfBlank,
  omit,
  omitIfBlank,
  pick,
  set,
} from 'fp/objects'
import { isEmpty } from 'fp/arrays'
import { isEmptyString, isString, startsWith, unwrap } from 'fp/strings'
import { identity, isDefined, not, pipe } from 'fp/utils'
import { actions as contentActions } from 'reducers/content'
import { TEACHER_EDITION_PREFIX } from 'core/consts'
import { restEndpoint } from 'reducers/utils'
import { contentRecordBoolFields } from './utils'

const assertArray = item => Array.isArray(item) ? item : []

const trimIfString = fieldName => (obj) => {
  const v = get(fieldName)(obj)
  return set(fieldName, isString(v) ? v.trim() : v)(obj)
}

const readOnlyFields = [
  'createdById',
  'dateCreated',
  'dateDeleted',
  'dateModified',
  'displayOrder',
  'modifiedById',
  'rubric',
]

// actual db/api fields
const topLevelFields = [
  'applicationStandardIds',
  'assetCode',
  'children',
  'childrenCount',
  'contentState',
  'contentSubType',
  'contentType',
  'id',
  'instructionStandardIds',
  'name',
  'parent',
  'proficiencyIds',
  'scoring',
  'tagIds',
  'teacherEdition',
  'teacherEditionContent',
  'territorySelections',
  'territorySelectionType',
  'uploadsMap',
  'vocabContentIds',
]

// these will all be ignored in the db
const ephemeralFields = [
  'academicVocabPhraseIds',
  'academicVocabPhrases',
  'applicationStandards',
  'contentRestriction',
  'contentVocabPhraseIds',
  'contentVocabPhrases',
  'footnoteVocabPhraseIds',
  'footnoteVocabPhrases',
  'insertionData',
  'instructionStandards',
  'isSectionLike',
  'tags',
  `${TEACHER_EDITION_PREFIX}academicVocabPhraseIds`,
  `${TEACHER_EDITION_PREFIX}academicVocabPhrases`,
  `${TEACHER_EDITION_PREFIX}contentVocabPhraseIds`,
  `${TEACHER_EDITION_PREFIX}contentVocabPhrases`,
  `${TEACHER_EDITION_PREFIX}footnoteVocabPhraseIds`,
  `${TEACHER_EDITION_PREFIX}footnoteVocabPhrases`,
  'vocabContent',
]

const nullableFields = [
  'assetCode',
  'contentSubType',
  'createdById',
  'dateCreated',
  'dateDeleted',
  'dateModified',
  'modifiedById',
  'name',
  'scoring',
  'teacherEdition',
  'teacherEditionContent',
]

const withTe = compose(
  mapKeys(unwrap(TEACHER_EDITION_PREFIX)),
  pick(startsWith(TEACHER_EDITION_PREFIX)),
)
const withoutTe = omit(startsWith(TEACHER_EDITION_PREFIX))

const teContentIsEmpty = (te, prev) => isEmpty(te.vocabContentIds)
  && isEmpty(te.applicationStandardIds)
  && isEmpty(te.instructionStandardIds)
  && isEmpty(te.tagIds)
  && (isEmptyString(te.data.body) || te.data.body === '<div></div>')
  && isEmptyString(te.data.notes)
  && (!prev.teacherEditionContent || prev.teacherEditionContent.data?.body === '<div></div>')

const clean = compose(
  omit('deleting'),
  obj => obj.uploadsMap
    ? set(
      'uploadsMap',
      filterKeyedObject(obj.uploadsMap, identity),
    )(obj)
    : obj,
  obj => nullableFields.reduce((acc, field) => nullIfBlank(field, contentRecordBoolFields.includes(field))(acc), obj),
  omitIfBlank('data'),
  trimIfString('assetCode'),
  pick(...topLevelFields, 'data'),
  omit(readOnlyFields),
  omit(ephemeralFields),
)

function* processChildren(children = []) {
  const isDeleting = get('deleting')
  const notDeleting = compose(
    not,
    isDeleting,
  )

  const toDelete = children.filter(isDeleting)

  yield all(toDelete.map(({ id: contentId }) => put(contentActions.deleteContentById({ contentId }))))

  return children
    .filter(notDeleting)
    .map(clean)
}

export function* preparePayloadForSave({ payload, suppressAlert, suppressUpdate }) {
  const { children, contentType, id, proficiencyIds, ...rest } = payload

  const vocabContentIds = [
    rest.academicVocabPhraseIds,
    rest.contentVocabPhraseIds,
    rest.footnoteVocabPhraseIds,
  ]
    .flat()
    .filter(isDefined)

  const vocabContent = [
    rest.academicVocabPhrases,
    rest.contentVocabPhrases,
    rest.footnoteVocabPhrases,
  ]
    .flat()
    .filter(isDefined)

  const teVocabPhraseIds = [
    rest[`${TEACHER_EDITION_PREFIX}academicVocabPhraseIds`],
    rest[`${TEACHER_EDITION_PREFIX}contentVocabPhraseIds`],
    rest[`${TEACHER_EDITION_PREFIX}footnoteVocabPhraseIds`],
  ]
    .flat()
    .filter(isDefined)

  const teVocabPhrases = [
    rest[`${TEACHER_EDITION_PREFIX}academicVocabPhrases`],
    rest[`${TEACHER_EDITION_PREFIX}contentVocabPhrases`],
    rest[`${TEACHER_EDITION_PREFIX}footnoteVocabPhrases`],
  ]
    .flat()
    .filter(isDefined)

  // PUT FIELDS BACK TOGETHER
  const updatedRest = pipe(
    set('proficiencyIds', assertArray(proficiencyIds)),
    set(`${TEACHER_EDITION_PREFIX}proficiencyIds`, []),
    set(`${TEACHER_EDITION_PREFIX}vocabPhraseIds`, teVocabPhraseIds),
    set(`${TEACHER_EDITION_PREFIX}vocabPhrases`, teVocabPhrases),
    set('vocabContentIds', vocabContentIds),
    set('vocabContent', vocabContent),
  )(rest)

  const scoringFields = Object.keys(updatedRest).filter(startsWith('scoring.'))
  const fieldsToOmit = [...readOnlyFields, ...topLevelFields, ...ephemeralFields, ...scoringFields]
  const data = omit(fieldsToOmit)(withoutTe(updatedRest))
  const teData = omit(fieldsToOmit)(withTe(updatedRest))
  const editing = !isEmptyString(id)

  const teacherEditionContent = {
    data: teData,
    ...clean(withTe(updatedRest)),
    proficiencyIds: [],
  }

  const newChildren = yield call(processChildren, children)

  return {
    options: {
      method: editing ? 'PATCH' : 'POST',
      body: {
        children: newChildren,
        contentType,
        data,
        id,
        ...clean(updatedRest),
        teacherEditionContent: teContentIsEmpty(teacherEditionContent, updatedRest)
          ? null
          : merge(
            updatedRest.teacherEditionContent,
            set('teacherEdition', true)(teacherEditionContent),
          ),
      },
    },
    passThrough: {
      childIds: newChildren.map(get('id')),
      contentType,
      editing,
      suppressAlert,
      suppressUpdate,
    },
    url: editing
      ? `${restEndpoint.content}/${id}`
      : `${restEndpoint.content}`,
  }
}

export default undefined
