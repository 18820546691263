import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'
import { createElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Maybe } from 'monet'
import { assertProp, get, isObject, mapValues, set } from 'fp/objects'
import { identity, isDefined, isFunction, isUndefined, noop, pipe, when } from 'fp/utils'
import { componentShape } from 'core/shapes'
import { additionalContext } from 'common/formControls/Form/additionalContext'
import { BUSY } from 'hooks/useReduxCallback'
import { camelCaseToWords, isEmptyString, isString, titleCase } from 'fp/strings'
import { assertRange } from 'fp/numbers'
import useIsMounted from 'hooks/useIsMounted'
import { hasCantoDirective, unwrapCantoDirective } from '../selects/AssetUploader/utils'
import { formIslandContext } from './FormIsland'

/**
 * In theory, adding these attributes to input controls will disable grammarly.
 * They don't seem to work very well in practice, and not at all for contentEditable
 * elements.
 */
export const grammarlySupport = enabled => [
  'data-gramm',
  'data-gramm_editor',
  'data-enable-grammarly',
].reduce((acc, key) => set(key, String(enabled))(acc), {})

export const lastPassSupport = enabled => ({ 'data-lpignore': !enabled })

const CHANGE_HANDLER_DEFAULT = 'default'
export const CHANGE_HANDLER_NUMERIC = 'numeric'
export const CHANGE_HANDLER_REACT_SELECT = 'react-select'
export const CHANGE_HANDLER_SIMPLE = 'simple'

const Base = (props) => {
  const {
    Component,
    autoFocus = false,
    changeHandlerType = CHANGE_HANDLER_DEFAULT,
    defaultValue = '',
    enableCustomErrorMessage = false,
    helperText: originalHelperText,
    inputProps = {},
    label,
    mandatory = false,
    name,
    noLabel = false,
    onFocus,
    placeholder,
    preventCantoDirectiveCleaning = false,
    readOnly = false,
    required: originallyRequired = false,
    rules: originalRules = {},
    valueIsJson = false,
    ...rest
  } = props

  const {
    actionType,
    disabled: disabledInContext,
    margin,
    registerMandatoryField,
    registerPrettyName,
    registerRequiredField,
    requiredFieldsAreMandatory,
    status,
    variant,
  } = useContext(additionalContext)
  const { registerName } = useContext(formIslandContext) || {}
  const isMounted = useIsMounted()
  const { control, formState, trigger: triggerValidation } = useFormContext()
  const [registered, setRegistered] = useState(false)
  const { errors } = formState

  const required = requiredFieldsAreMandatory
    ? originallyRequired || mandatory
    : mandatory

  const prettyName = label || titleCase(camelCaseToWords(name))

  useEffect(
    () => {
      when(isMounted && !registered, registerRequiredField, name, required)
      when(isMounted && !registered, registerMandatoryField, name, mandatory)
      when(isMounted && !registered, setRegistered, true)
      when(isMounted && !registered, registerName, name)
      when(isMounted && !registered, registerPrettyName, name, prettyName)
    },
    [
      isMounted,
      mandatory,
      name,
      prettyName,
      registered,
      registerName,
      registerMandatoryField,
      registerPrettyName,
      registerRequiredField,
      required,
    ],
  )

  // Allows Component to add custom validation.
  // Whenever it calls setComponentErrorMessage,
  // react-hook-form validation will re-run and block form submission if necessary.
  const [componentErrorMessage, setComponentErrorMessage] = useState()
  const componentHasSetErrorMessage = useRef(false)

  useEffect(() => {
    // Don't call `triggerValidation` until after the user has had a chance to enter something.
    if (componentErrorMessage && !componentHasSetErrorMessage.current) {
      componentHasSetErrorMessage.current = true
    }
    when(componentHasSetErrorMessage.current, triggerValidation, name)
  }, [componentErrorMessage, name, triggerValidation])

  // Some custom validation functions may need to know:
  // a) whether the field is also required and/or
  // b) which mode we're in -- that is, which of these:
  //    • publish mode (requiredFieldsAreMandatory = true)
  //    • draft mode (requiredFieldsAreMandatory = false)
  const enhanceValidator = fn => (value) => {
    if (required && isString(value) && isEmptyString(value)) {
      // received only whitespace for an apparent required string field
      return `'${label || placeholder || prettyName}' is required`
    }
    return fn({
      required,
      requiredFieldsAreMandatory,
      value,
    })
  }
  const validate = componentErrorMessage
    ? () => componentErrorMessage
    : Maybe.fromUndefined(originalRules.validate)
      .map(validatorFunctionOrObject => isFunction(validatorFunctionOrObject)
        ? enhanceValidator(validatorFunctionOrObject)
        : mapValues(enhanceValidator)(validatorFunctionOrObject))
      .orJust(enhanceValidator(noop))

  const rules = pipe(
    set('required', required),
    set('validate', validate),
  )(originalRules)
  const error = get(name)(errors)
  const errorMessage = error?.message

  const disabled = Boolean(disabledInContext || rest.disabled || (isDefined(actionType)
    ? status === BUSY
    // eslint-disable-next-line react/destructuring-assignment
    : props.disabled))

  const helperText = useMemo(() => {
    if (errorMessage) return errorMessage
    if (error?.type === 'required') return `'${label || placeholder || prettyName}' is required`
    if (originalHelperText) return originalHelperText
    return ''
  }, [error, errorMessage, label, prettyName, originalHelperText, placeholder])

  const getValue = useCallback(value => typeof value === 'boolean'
    ? { checked: value }
    : valueIsJson && (isObject(value) || Array.isArray(value))
      ? { value: JSON.stringify(value) }
      : { value }, [valueIsJson])

  const changeHandlers = {
    [CHANGE_HANDLER_DEFAULT]: onChange => (e, val) => {
      const useChecked = Object.prototype.hasOwnProperty.call(e.target, 'checked') && e.target.type === 'checkbox'
      const newValue = isDefined(val) && isUndefined(e.target.value)
        ? val
        : useChecked
          ? e.target.checked
          : e.target.value
      onChange({ ...e, target: { ...e?.target, value: newValue } })
    },

    [CHANGE_HANDLER_REACT_SELECT]: onChange => (items, { action }) => {
      if (['clear', 'paste-values', 'remove-value', 'select-option'].includes(action)) {
        onChange({
          target: {
            value: items.map(get('item')),
          },
        })
      }
    },

    [CHANGE_HANDLER_NUMERIC]: onChange => (e, val) => {
      const { max, min } = inputProps

      let newValue = isDefined(val) && isUndefined(e.target.value)
        ? +val
        : isEmptyString(e.target.value)
          ? null // allow to clear the field
          : +e.target.value

      if (isDefined(newValue) && (isDefined(max) || isDefined(min))) {
        newValue = assertRange(
          newValue,
          min || 0,
          max || Math.Infinity,
        )
      }

      onChange({ ...e, target: { ...e?.target, value: newValue } })
    },

    [CHANGE_HANDLER_SIMPLE]: identity, // just call react-hook-form's onChange handler directly
  }

  const handleChange = changeHandlers[changeHandlerType]

  return (
    <Controller
      {...{ control, defaultValue, name }}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        const cleanValue = !preventCantoDirectiveCleaning && hasCantoDirective(value)
          ? unwrapCantoDirective(value)
          : value

        return (
          <Component
            {...rest}
            helperText={helperText}
            inputProps={assertProp('data-testid', `input-${name}`)({ ...inputProps, readOnly })}
            inputRef={ref}
            label={noLabel || label === '' ? undefined : label || prettyName}
            onChange={readOnly ? noop : handleChange(onChange)}
            {...{
              autoFocus,
              disabled,
              error: isDefined(error),
              margin: rest.margin || margin,
              name,
              /**
               * Adding onBlur to either the Component or via inputProps has a
               * weird side-effect when using autoFocus.  The control will get
               * focus as expected, however the next mouse click sends focus to
               * document.body and negates the onClick event of whatever was
               * clicked.
               *
               * It seems to be safe to omit onBlur.  I looked through the source
               * code and changelogs of react-hook-form and nothing really jumped
               * out at me. It looks like it only would have an effect if we were
               * using the "onBlur" type of form validation (we use "onChange").
               *
               * Just to be safe I'm only removing onBlur if the control has
               * autoFocus set.
               */
              ...autoFocus ? null : { onBlur },
              onFocus,
              placeholder,
              readOnly,
              required,
              variant: rest.variant || variant,
              ...getValue(cleanValue),
            }}
            selected={cleanValue}
            {...enableCustomErrorMessage ? { setCustomErrorMessage: setComponentErrorMessage } : null}
          />
        )
      }}
      rules={rules}
    />
  )
}

Base.propTypes = {
  autoFocus: PropTypes.bool,
  changeHandlerType: PropTypes.oneOf([
    CHANGE_HANDLER_DEFAULT,
    CHANGE_HANDLER_NUMERIC,
    CHANGE_HANDLER_REACT_SELECT,
    CHANGE_HANDLER_SIMPLE,
  ]),
  Component: componentShape.isRequired,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  enableCustomErrorMessage: PropTypes.bool,
  helperText: componentShape,
  inputProps: PropTypes.object,
  label: componentShape,
  mandatory: PropTypes.bool,
  name: PropTypes.string.isRequired,
  noLabel: PropTypes.bool,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  preventCantoDirectiveCleaning: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  rules: PropTypes.object,
  valueIsJson: PropTypes.bool,
}

const withHookForm = (Component, injectUpfront) => props => createElement(Base, {
  ...injectUpfront,
  ...props,
  Component,
})

export default withHookForm
