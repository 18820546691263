import { styled } from '@mui/material/styles'
import SignInImg from 'apps/hss/images/login/sign-in-with-google.svg'

const SignInButton = styled(
  SignInImg,
  { name: 'google-SignInButton' },
)(({ theme: { shadows } }) => ({
  cursor: 'pointer',
  boxShadow: shadows[2],
  borderRadius: 2,
}))

export default SignInButton
