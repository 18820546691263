/* eslint-disable react/destructuring-assignment */
import { forwardRef, useMemo } from 'react'
import { debounce } from 'fp/utils'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'

const withDebounce = ({ eventName = 'onChange', timeout = 250, valueField = 'value' } = {}) => (WrappedComponent) => {
  const Enhanced = forwardRef((props, ref) => {
    const [managedValue, setManagedValue] = useStateWithDynamicDefault(props[valueField])

    const propsEventName = props[eventName]

    const debouncedEvent = useMemo(() => debounce(timeout, propsEventName), [propsEventName])

    const handleEvent = (event) => {
      event.persist?.()
      setManagedValue(event.target[valueField])
      debouncedEvent(event)
    }
    const pass = {
      [eventName]: handleEvent,
      [valueField]: managedValue,
      ref,
    }
    return (
      <WrappedComponent
        {...props}
        {...pass}
      />
    )
  })

  return Enhanced
}

export default withDebounce
