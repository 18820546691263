import { withOptions } from '@comfy/redux-selectors'
import { compose } from 'redux'
import { get, hasProperty } from 'fp/objects'
import { createSelector, stateSchools } from '.'

export const getSchoolById = withOptions(({ schoolId }) => createSelector('getSchoolById')(
  stateSchools,
  get(String(schoolId)),
))

export const isSchoolLoaded = withOptions(({ schoolId }) => createSelector('isSchoolLoaded')(
  stateSchools,
  compose(
    hasProperty(String(schoolId)),
    get('loaded'),
  ),
))
