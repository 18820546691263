import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import useContent from 'hooks/useContent'
import { CONTENT_TYPE_COURSE } from 'core/consts'
import MaybeDraft from 'common/indicators/Draft'
import CollapseButton from 'routing/shells/NavShell/NavSidebar/CollapseButton'
import { get } from 'fp/objects'
import ExitButton from './ExitButton'

const offsetFromCenterline = { position: 'relative' }

const Header = () => {
  const chapter = useContent({ disableFetch: true })
  const subtitle = get('data.subtitle')(chapter)
  const courseId = get('parent.parent.id')(chapter)
  const { name: courseName } = useContent({ contentId: courseId, contentType: CONTENT_TYPE_COURSE }) || {}

  return (
    <Box
      display="flex"
      sx={{
        borderBottom: '1px solid',
        borderBottomColor: 'grey.0',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        padding: '16px 15px 16px 0',
      }}
    >
      <ExitButton
        content={chapter}
        style={offsetFromCenterline}
      />

      <Box
        ml="13px"
        width="100%"
      >
        <Typography variant="toc-title">
          {courseName} {subtitle}
        </Typography>

        <Typography variant="toc-title-semibold">
          {chapter?.name}

          <MaybeDraft
            content={chapter}
            small
          />
        </Typography>
      </Box>

      <CollapseButton style={offsetFromCenterline} />
    </Box>
  )
}

export default Header
