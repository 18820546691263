import { alter } from 'core/store/search/squery'
import useLocalSetting from 'hooks/useLocalSetting'

export const setDefaultLimit = limit => alter.set.limit(limit)

const usePaginationLimit = (
  tableId,
  defaultLimit = 50,
) => useLocalSetting(
  `${tableId}-pagination`,
  defaultLimit,
)

export default usePaginationLimit
