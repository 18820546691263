import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

const VocabularyPopperContent = ({
  vocabPhrase: {
    contentSubType,
    data: { definition } = { definition: undefined },
    label,
    name,
  },
}) => (
  <Stack
    sx={{
      ol: {
        my: 1.5,
        paddingInlineStart: '17px',
      },
      li: {
        display: 'list-item',
      },
      textTransform: 'none',
      letterSpacing: 0,
    }}
    textAlign="left"
  >

    {contentSubType === 'content' && (
      <Typography
        component="div"
        variant="popup-label"
      >
        Chapter Vocabulary
      </Typography>
    )}

    <Typography
      component="div"
      variant="feature-label-popup"
    >
      {name || label}
    </Typography>

    <Box mt={2}>
      {/* TODO: Multiple definitions */}
      <Typography
        component="div"
        variant="body1"
      >
        {definition}
      </Typography>
    </Box>

    {/* TODO: Where will this link go? */}
    {/* {Boolean(href) && (
      <Box textAlign="right">
        <Typography variant="link-plain">
          <NavLink to={href}>
            Read more{'  '}
            <RightArrowIcon style={{ position: 'relative', top: 7 }} />
          </NavLink>
        </Typography>
      </Box>
    )} */}

  </Stack>
)

VocabularyPopperContent.propTypes = {
  vocabPhrase: PropTypes.shape({
    contentSubType: PropTypes.string,
    data: PropTypes.object,
    label: PropTypes.string,
    name: PropTypes.string,
    href: PropTypes.string,
  }),
}

export default VocabularyPopperContent
