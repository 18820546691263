/* eslint-disable import/prefer-default-export */
import { withOptions } from '@comfy/redux-selectors'
import { compose } from 'redux'
import { fallbackTo, isUndefined } from 'fp/utils'
import { get } from 'fp/objects'
import { maybeParseJSON } from 'fp/strings'
import { getCurrentUser } from './users'
import { createSelector, stateLocalSettings } from '.'

export const getRootSettingsKey = createSelector('getRootSettingsKey')(
  getCurrentUser,
  compose(
    fallbackTo('global'),
    get('id'),
  ),
)

export const getLocalSetting = withOptions(key => createSelector('getLocalSetting')(
  stateLocalSettings,
  getRootSettingsKey,
  (settings, rootKey) => {
    if (isUndefined(key)) throw new Error('getLocalSetting requires a key')
    const value = get(`${rootKey}.${key}`)(settings)
    return maybeParseJSON(value)
  },
))
