import PropTypes from 'prop-types'
import { Fragment } from 'react'
import cl from 'classnames'
import { flexRender } from '@tanstack/react-table'
import { get } from 'fp/objects'
import { filterTopLevelColumns } from '../Cells'
import DetailRow from './DetailRow'

const Rows = ({ rows }) => (
  <tbody>
    {rows
      .map((row, index) => {
        const rowClassName = index % 2 === 0 ? 'even-row' : null
        const isExpanded = row.getIsExpanded()

        return (
          <Fragment key={row.index}>
            <tr className={cl(rowClassName, { expanded: isExpanded })}>
              {row
                .getAllCells()
                .filter(filterTopLevelColumns)
                .map((cell) => {
                  const { column: { columnDef } } = cell
                  const { cellProps, isCellHeader } = columnDef
                  const className = get('className', { fallback: '' })(cellProps)
                  const Wrapper = get('cellWrapper', { fallback: null })(cellProps)
                  let Component = isCellHeader ? 'th' : 'td'

                  if (Wrapper && !isCellHeader) {
                    Component = Wrapper
                  }

                  return (
                    <Component
                      cell={cell}
                      className={className}
                      id={cell.id}
                      key={cell.id}
                    >
                      {flexRender(columnDef.cell, cell.getContext())}
                    </Component>
                  )
                })}
            </tr>

            {Boolean(isExpanded) && (
              <DetailRow {...{ row, rowClassName }} />
            )}
          </Fragment>
        )
      })}
  </tbody>
)

Rows.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Rows
