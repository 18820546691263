import { useContext } from 'react'
import useEffectOnce from 'hooks/useEffectOnce'
import { context, withProvider } from './context'
import SignIn from './SignIn'
import Account from './Account'
import Error from './Error'
import Settings from './Settings'

const AuthWorkflow = withProvider(() => {
  const {
    checkClassroomStatus,
    handleError,
    startWorkflow,
    updateStatus,
  } = useContext(context)

  useEffectOnce(() => {
    startWorkflow()
      .then(checkClassroomStatus)
      .then(updateStatus)
      .catch(handleError)
  }, [])

  return (
    <>
      <Error />
      <SignIn />
      <Account />
      <Settings />
    </>
  )
})

export default AuthWorkflow
