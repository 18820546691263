import GlobalStyles from '@mui/material/GlobalStyles'
import { useTheme } from '@mui/material/styles'
import forms from './forms'
import a11y from './a11y'
import misc from './misc'
import printing from './printing'

const CssGlobals = () => {
  const theme = useTheme()
  const {
    breakpoints,
    globals,
    mixins: { important, px, rem },
    palette,
    thirdParty,
    typography: { variants },
  } = theme

  return (
    <GlobalStyles
      styles={{
        html: {
          textRendering: 'optimizeLegibility',
          fontSize: 8.5,
          lineHeight: px(8.5),
          [breakpoints.up('sm')]: {
            fontSize: 9.5,
            lineHeight: px(9.5),
          },
          [breakpoints.up('md')]: {
            fontSize: 10,
            lineHeight: px(10),
          },

          '&.wf-loading': {
            '.app-container-active': { display: 'none' },
            '.app-container-busy': { display: 'block' },
          },

          '&.wf-active, &.wf-inactive': {
            '.app-container-active': { display: 'block' },
            '.app-container-busy': { display: 'none' },
          },
        },
        body: {
          '*:focus': {
            outlineStyle: 'none',
          },
          background: palette.background.default,
          height: '100vh',
          margin: 0,
          overflow: 'overlay',
          ...variants.body1,
        },
        a: variants.link,
        section: {
          paddingTop: rem(4),
          paddingBottom: rem(4),
        },
        '#react-container': {
          minHeight: '100vh',
        },
        'ul.standard, ol.standard': {
          position: 'relative',
          left: rem(-2.2),

          'li:last-child': {
            marginBottom: rem(2.4),
          },

          'li > div': {
            margin: rem(1.4, 0),
            padding: important(0),
          },
        },
        ...a11y(theme),
        ...forms(theme),
        ...misc(theme),
        ...printing(theme),
        ...globals,
        ...thirdParty,
      }}
    />
  )
}

export default CssGlobals
