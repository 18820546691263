/* eslint-disable @studysync/persnickety/jsx-use-headline-not-headings */
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { DRAFTJS_BLOCK_KEY, moveCaretAfterBlock } from '@studysync/draft-js-modifiers'
import CloseButton from 'common/dialogs/CloseButton'
import { componentShape } from 'core/shapes'
import { customBlockPropsShape } from './utils'

const CustomBlockWrapper = styled(
  (props) => {
    const {
      block,
      blockProps,
      children,
      className,
      draggable = false,
      onRemove,
      readOnly,
      title,
      titleAddendum,
    } = props

    const { getEditorState, onChange } = blockProps

    const handleClick = () => {
      const editorState = getEditorState()

      const newEditorState = moveCaretAfterBlock(editorState, block)
      onChange(newEditorState)
    }

    const handleDragStart = (e) => {
      if (!readOnly) {
        // eslint-disable-next-line no-param-reassign
        e.dataTransfer.dropEffect = 'move'
        e.dataTransfer.setData(
          'text',
          `${DRAFTJS_BLOCK_KEY}:${block.getKey()}`,
        )
      }
    }

    return (
      <Box
        className={className}
        component="section"
        onClick={handleClick}
      >
        <Box
          draggable={draggable}
          onClick={e => e.stopPropagation()}
          onDragStart={handleDragStart}
        >
          <Box>
            <h6>{title}</h6>
            {titleAddendum}
          </Box>

          <article>
            {children}
          </article>

          <CloseButton
            dense
            onClick={onRemove}
            style={{ right: '1rem', top: '.5rem' }}
            title="Remove"
          />
        </Box>
      </Box>
    )
  },
  { name: 'helpers-CustomBlockWrapper' },
)(({ theme: { mixins: { borderS, rem }, palette } }) => ({
  ...borderS(palette.border[0]),
  position: 'relative',
  borderStyle: 'dashed',
  marginBottom: rem(2),
  padding: 0,
  maxWidth: rem(60),
  th: {
    textAlign: 'left',
    color: palette.grey[1],
    fontWeight: 'normal',
  },
  'th, td': {
    padding: 0,
  },
  td: {
    padding: 0,
    position: 'relative',
  },
  h6: {
    display: 'inline-block',
    margin: rem(1),
    padding: rem(1, 1.5),
    fontFamily: 'Inter',
    fontSize: rem(1.4),
    fontWeight: 'normal',
    textTransform: 'none',
    color: palette.grey.contrastText,
    backgroundColor: palette.grey[1],
  },
  article: {
    display: 'flex',
    margin: rem(0, 1),
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
    borderSpacing: rem(0, 1),
  },
  'table th': {
    width: '40%',
  },
  footer: { textAlign: 'right' },
}))

CustomBlockWrapper.propTypes = {
  blockProps: customBlockPropsShape.isRequired,
  draggable: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titleAddendum: componentShape,
}

export default CustomBlockWrapper
