import PropTypes from 'prop-types'
import Accordion from '@mui/material/Accordion'
import { styled } from '@mui/material/styles'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { useEffect, useId, useRef, useState } from 'react'
import { componentShape } from 'core/shapes'

const focusable = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

const CollapsibleSection = (props) => {
  const {
    children,
    className,
    dataSubvariant,
    empty = false,
    hasErrors = false,
    initiallyExpanded = false,
    label,
    variant = 'form-section',
  } = props
  const [expanded, setExpanded] = useState(initiallyExpanded)
  const id = useId()
  const ref = useRef()

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded)
  }

  useEffect(() => {
    if (expanded && ref?.current) {
      const elms = ref.current.querySelectorAll(focusable)
      if (elms.length)elms[0].focus()
    }
  }, [expanded])

  useEffect(() => {
    if (hasErrors && !expanded) {
      setExpanded(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasErrors]) // this specifically needs to only get called when hasErrors changes

  return (
    <Accordion
      className={className}
      expanded={expanded}
      onChange={handleChange}
      variant={variant}
    >

      <AccordionSummary
        aria-controls={`panel-${id}-content`}
        data-subvariant={dataSubvariant}
        id={`panel-${id}-header`}
        variant={variant}
      >
        {label}
        {empty
          ? <Typography variant="small">&nbsp;[empty]</Typography>
          : null}
      </AccordionSummary>

      <AccordionDetails
        ref={ref}
        variant={variant}
      >
        {children}
      </AccordionDetails>

    </Accordion>
  )
}

CollapsibleSection.propTypes = {
  children: componentShape.isRequired,
  dataSubvariant: PropTypes.oneOf(['feature-paragraph']),
  empty: PropTypes.bool,
  hasErrors: PropTypes.bool,
  initiallyExpanded: PropTypes.bool,
  label: PropTypes.node.isRequired,
  variant: PropTypes.oneOf([
    'form-section',
    'collapsed-heading',
    'interactive-grading',
  ]),
}

export const StylerForSingleField = Component => styled(Component, {
  name: 'CollapsibleSection-Single',
})({
  '[class*=-MuiTextField-root]': {
    marginTop: 0,
  },
})

export default CollapsibleSection
