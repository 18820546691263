const overrides = ({ mixins: { important }, palette, shadows, typography }) => ({
  MuiOutlinedInput: {
    defaultProps: {
      margin: 'dense',
      notched: false,
    },

    styleOverrides: {
      root: {
        '&.Mui-disabled': {
          '.MuiInputAdornment-root': {
            color: palette.grey[3],
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderStyle: 'dashed',
            borderColor: palette.grey[5],
          },
        },
        fieldset: { borderColor: important(palette.grey[4]) },
        '&:not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          boxShadow: shadows.borderInset,
          transition: '225ms',
        },
        '&.Mui-focused fieldset': {
          border: 'none',
          boxShadow: shadows.borderInset,
          transition: '225ms',
        },
      },

      input: {
        lineHeight: 'unset',
        padding: 12,
        '&.MuiInputBase-inputAdornedStart': {
          paddingLeft: 0,
          position: 'relative',
          left: -2,
        },
      },

      notchedOutline: {
        borderRadius: 0,
        borderColor: palette.grey[4],
        minHeight: 55,
        height: '100%',
      },
    },

    variants: [{
      props: { 'data-variant': 'collapsed-tablist' },
      style: {
        ...typography.variants['tab-title'],
        fieldset: {
          borderColor: palette.grey[4],
        },
        '.MuiSelect-select': {
          paddingTop: 15,
          height: 'unset',
          paddingBottom: 12,
        },
      },
    }],
  },
})

export default overrides
