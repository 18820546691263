import PropTypes from 'prop-types'

const withKeyboardClicks = (WrappedComponent) => {
  const Enhanced = ({
    disabled = false,
    enter = false,
    onClick,
    spacebar = true,
    ...rest
  }) => {
    const handleKeyPress = (event) => {
      const { key } = event
      /* istanbul ignore next */
      if ((enter && key === 'Enter') || (spacebar && key === ' ')) {
        event.preventDefault()
        onClick(event)
      }
    }
    return (
      <WrappedComponent
        disabled={disabled}
        onClick={disabled ? null : onClick}
        onKeyPress={disabled ? null : handleKeyPress}
        role="button"
        style={{ pointerEvents: 'auto' }}
        tabIndex={0}
        {...rest}
      />
    )
  }
  Enhanced.propTypes = {
    disabled: PropTypes.bool,
    enter: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    spacebar: PropTypes.bool,
  }

  return Enhanced
}

export default withKeyboardClicks
