import { withOptions } from '@comfy/redux-selectors'
import { compose } from 'redux'
import { get, hasProperty, pick, set } from 'fp/objects'
import { pullListed } from 'projections/index'
import { fallbackTo, isDefined, matches, not } from 'fp/utils'
import { filter, map } from 'fp/arrays'
import { callWith } from 'fp/call'
import { getContentViewerParams } from './contentViewerParams'
import { getCollapsedContentBags } from './collapsedContent'
import { createSelector, stateAssignments, stateUserAssignments } from '.'

const getClosedUserAssignments = createSelector('getClosedUserAssignments')(
  stateUserAssignments,
  compose(
    filter(not(matches('submittedDate', null))),
    pullListed,
  ),
)

export const getListedUserAssignmentIds = createSelector('getListedUserAssignmentIds')(
  stateUserAssignments,
  get('listed'),
)

// const getStudentUserAssignments = withOptions(({ studentId }) => createSelector('getStudentUserAssignments')(
//   stateUserAssignments,
//   compose(
//     filter(matches('userId', studentId)),
//     pullListed,
//   ),
// ))

// export const getStudentAssignments = withOptions(({ studentId }) => createSelector('getStudentAssignments')(
//   getStudentUserAssignments({ studentId }),
//   stateAssignments,
//   (userAssignments, assignments) => map(ua => set('assignment', assignments[ua.assignmentId])(ua))(userAssignments),
// ))

const getOpenUserAssignments = createSelector('getOpenUserAssignments')(
  stateUserAssignments,
  compose(
    filter(matches('submittedDate', null)),
    pullListed,
  ),
)

export const getUserAssignment = createSelector('getUserAssignment')(
  stateUserAssignments,
  getContentViewerParams(),
  (userAssignments, { userAssignmentId } = {}) => get(userAssignmentId)(userAssignments),
)

export const getAssignmentBehindUserAssignment = createSelector('getAssignmentBehindUserAssignment')(
  stateAssignments,
  getUserAssignment,
  (assignments, { assignmentId } = {}) => get(assignmentId)(assignments),
)

export const getUserAssignmentById = withOptions(({ userAssignmentId }) => createSelector('getUserAssignmentById')(
  stateUserAssignments,
  get(String(userAssignmentId)),
))

export const getUserAssignmentsById = withOptions(({ userAssignmentIds }) => createSelector('getUserAssignmentsById')(
  stateUserAssignments,
  compose(
    Object.values,
    pick(map(String)(userAssignmentIds)),
  ),
))

export const getUserAssignmentsByAssignmentId = withOptions(({ assignmentId }) => createSelector('getUserAssignmentsByAssignmentId')(
  stateAssignments,
  stateUserAssignments,
  (allAssignments, allUserAssignments) => compose(
    Object.values,
    callWith(allUserAssignments),
    pick,
    fallbackTo([]),
    get('userAssignmentIds'),
    fallbackTo({}),
    callWith(allAssignments),
    get,
  )(assignmentId),
))

export const getUserAssignmentIsSubmittable = withOptions(({ userAssignmentId }) => createSelector('getUserAssignmentIsSubmittable')(
  getUserAssignmentById({ userAssignmentId }),
  compose(not, get('submittedDate')),
))

// Parent content is used to filter userAssignments on the Binder and Assignments pages for Students
const getWithParentContent = withOptions(userAssignmentsSelector => createSelector('getWithParentContent')(
  getCollapsedContentBags,
  stateAssignments,
  userAssignmentsSelector,
  (collapsedContent, allAssignments, selectedUserAssignments) => {
    const openUserAssignmentsWithParentContent = selectedUserAssignments.reduce((acc, userAssignment) => {
      const { assignmentId } = userAssignment
      const { contentId } = get(assignmentId)(allAssignments)
      const { contentType, parent = {} } = collapsedContent.find(({ id }) => id === contentId)

      const userAssignmentWithParentContent = compose(
        set('content.contentType', contentType),
        set('content.parent', parent),
      )(userAssignment)

      return [...acc, userAssignmentWithParentContent]
    }, [])
    return openUserAssignmentsWithParentContent
  },
))

export const getOpenUserAssignmentsWithParentContent = getWithParentContent(getOpenUserAssignments)
export const getClosedUserAssignmentsWithParentContent = getWithParentContent(getClosedUserAssignments)

export const getCurrentAssignmentSubmitted = createSelector('getCurrentAssignmentSubmitted')(
  getUserAssignment,
  compose(isDefined, get('submittedDate')),
)

export const isUserAssignmentLoaded = withOptions(({ userAssignmentId }) => createSelector('isUserAssignmentLoaded')(
  stateUserAssignments,
  compose(
    hasProperty(String(userAssignmentId)),
    get('loaded'),
  ),
))
