/* eslint-disable no-use-before-define */
import { withOptions } from '@comfy/redux-selectors'
import { compose } from 'redux'
import {
  ABILITY_STUDENT_INTERFACE,
  CONTENT_SUBTYPE_CHAPTER_SUMMARY,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_SCAFFOLD,
  CONTENT_TYPE_SECTION,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_SUBSECTION,
  SECTION_CONTENT_TYPES,
  TOGGLE_STATE_PRESENTER_MODE,
  TOP_LEVEL_CONTENT_VIEWER_TYPES,
  isSubsectionLike,
} from 'core/consts'
import {
  COMPLETION_DONE,
  assembleNav,
  buildContentUrl,
  findSiblings,
} from 'projections/content'
import {
  filter,
  find,
  findObj,
  first,
  flatten,
  last,
  map,
  reduce,
  toKeyedObject,
} from 'fp/arrays'
import {
  curryRight,
  fallbackTo,
  identity,
  isDefined,
  matches,
  matchesOneOf,
  not,
} from 'fp/utils'
import { equals, filterKeyedObject, get, hasProperty, omit, set } from 'fp/objects'
import {
  getAllContentAsObject,
  getContentById,
  getContentByIds,
  getContentHierarchy,
  getFlattenedChildren,
  getFlattenedChildrenOfTypes,
  isBlock,
} from './content'
import { createAbilityChecker } from './userAbility'
import { getUserAssignment } from './userAssignments'
import { getCurrentRoleId } from './users'
import { getContentViewerParams } from './contentViewerParams'
import { getInteractionsForAssignment } from './interactions'
import { getLocation } from './routing'
import { getLocalSetting } from './localSettings'
import { getCollapsedContentBags, getContentForType } from './collapsedContent'
import { createSelector } from '.'

const some = A => A.some(Boolean)

const findParent = ({
  contentType,
  contentId,
  parent,
}) => find(item => item.id === parent?.id
  || item.children?.find(child => child.contentType === contentType && child.id === String(contentId)))

const searchAncestors = withOptions(({ matchFn, ...rest }) => createSelector('findAncestor')(
  getContentById(rest),
  getCollapsedContentBags,
  (content, allContent) => {
    let currentItem = { ...content }
    const found = []
    while (currentItem) {
      if (matchFn(currentItem)) {
        found.push(currentItem)
      }

      currentItem = findParent({
        contentType: currentItem.contentType,
        contentId: currentItem.id,
        parent: currentItem.parent,
      })(allContent) || currentItem.parent
    }
    return found
  },
))

export const getViewerTopLevelContent = createSelector('getViewerTopLevelContent')(
  getContentViewerParams(),
  getContentForType(CONTENT_TYPE_CHAPTER),
  getContentForType(CONTENT_TYPE_ECHO),
  getContentForType(CONTENT_TYPE_SOURCE),
  (params, chapters, echoes, sources) => {
    const orphan = compose(not, hasProperty('parent'))
    const allTopLevelContent = {
      ...chapters,
      ...filterKeyedObject(echoes, orphan),
      ...filterKeyedObject(sources, orphan),
    }

    return allTopLevelContent[params?.contentId]
  },
)

export const getTopLevelItemsForViewerContent = withOptions((options = {}) => createSelector('getTopLevelItemsForViewerContent')(
  getViewerTopLevelContent,
  identity,
  (c, s) => getFlattenedChildrenOfTypes({ contentTypes: SECTION_CONTENT_TYPES, options })(c || {})(s),
))

export const getCurrentViewerContentId = options => createSelector('getCurrentViewerContentId')(
  getContentViewerParams({ isPinned: options?.isPinned }),
  getLocalSetting(TOGGLE_STATE_PRESENTER_MODE),
  (params, inPresenterMode) => {
    /**
     * The option `preferTeCopy` is used to find the special TE version of the
     * chapter summary.  This is important so that the back/next buttons work as
     * intended around the chapter summary.
     */
    const paramName = (inPresenterMode || options?.preferTeCopy)
      ? 'navTreeSubsectionId'
      : 'subsectionId'

    return get(paramName)(params) || get('echoId')(params) || get('contentId')(params)
  },
)

export const getCurrentViewContent = createSelector('getCurrentViewContent')(
  getCurrentViewerContentId(),
  getCollapsedContentBags,
  (contentId, bags) => bags.find(matches('id', contentId)),
)

export const childBlocksHaveTeContent = createSelector('childBlocksHaveTeContent')(
  identity,
  getCurrentViewContent,
  (store, { contentType, id: contentId } = {}) => {
    const children = getFlattenedChildren({ contentType, contentId })(store)

    return children.some(compose(
      isDefined,
      get('teacherEditionContent.data.body'),
    ))
  },
)

const contentIsTabbed = compose(
  Boolean,
  get('data.tabbed'),
)

export const getAnyAncestorIsChapterSummary = withOptions(options => createSelector('getAnyAncestorIsChapterSummary')(
  identity,
  () => {
    let cursor = options?.content
    while (cursor?.parent && cursor?.contentSubType !== CONTENT_SUBTYPE_CHAPTER_SUMMARY) cursor = cursor.parent
    return cursor?.contentSubType === CONTENT_SUBTYPE_CHAPTER_SUMMARY
  },
))

export const getAnyAncestorIsTe = withOptions(options => createSelector('getAnyAncestorIsTe')(
  searchAncestors({ ...options, matchFn: get('teacherEdition') }),
  some,
))

export const getBlocksForSubsectionOrEcho = withOptions((
  { children = [] } = {},
  options = {},
) => createSelector('getBlocksForSubsectionOrEcho')(
  getContentForType(CONTENT_TYPE_BLOCK),
  getContentForType(CONTENT_TYPE_INTERACTIVE),
  getContentForType(CONTENT_TYPE_SCAFFOLD),
  (blocks, interactives, scaffolds) => {
    const result = children
      .filter(compose(
        isBlock,
        contentType => ({ contentType }),
        get('contentType'),
      ))
      // TODO: will need additional filtering for things like packages, etc.
      .map(({ id }) => blocks[id] || scaffolds[id])

    return options.expandInteractives
      ? compose(
        filter(block => block?.contentType !== CONTENT_TYPE_SCAFFOLD
          || !options.currentUserIsStudent
          || block?.proficiencyIds?.includes(options.userProficiencyId)),
        map(item => ({
          ...item,
          children: item.children.map(child => child.contentType === CONTENT_TYPE_INTERACTIVE
            ? interactives[child.id]
            : /* istanbul ignore next */ child),
        })),
      )(result)
      : result
  },
))

export const getContentNav = withOptions(options => createSelector('getContentNav')(
  getContentHierarchy(options),
  getInteractionsForAssignment(options),
  getAllContentAsObject,
  getCurrentRoleId,
  assembleNav(options),
))

const isIncompleteSubsection = item => isSubsectionLike(item.contentType)
  ? item.calculatedCompletion !== COMPLETION_DONE
  : false

// Similar to getIncompleteInteractivesContentNav, but lighter --
// returns true immediately when it finds one incomplete submittable interactive,
// rather than always traversing the entire content tree
export const getContentHasIncompleteInteractives = withOptions(options => createSelector('getContentHasIncompleteInteractives')(
  getContentNav({ ...options, leafContentTypes: [CONTENT_TYPE_INTERACTIVE] }),
  (nav) => {
    const itemHasIncompleteInteractive = item => isIncompleteSubsection(item)
      ? true
      : item.children?.length
        ? itemsHaveIncompleteInteractive(item.children)
        : false

    const itemsHaveIncompleteInteractive = items => items.some(itemHasIncompleteInteractive)

    return itemsHaveIncompleteInteractive(nav)
  },
))

// Similar to getContentNav, but only includes items that contain an incomplete interactive
export const getIncompleteInteractivesContentNav = withOptions(options => createSelector('getIncompleteInteractivesContentNav')(
  getContentNav({ ...options, leafContentTypes: [CONTENT_TYPE_INTERACTIVE] }),
  (nav) => {
    const flagIncompleteInteractives = map((item) => {
      const flaggedChildren = compose(
        flagIncompleteInteractives,
        fallbackTo([]),
        get('children'),
      )(item)
      return ({
        ...item,
        hasIncompleteInteractive: isIncompleteSubsection(item) || flaggedChildren.some(get('hasIncompleteInteractive')),
        children: flaggedChildren,
      })
    })

    const filterIncompleteInteractivesOnly = compose(
      map(item => set(
        'children',
        compose(
          filterIncompleteInteractivesOnly,
          fallbackTo([]),
          get('children'),
        )(item),
      )(item)),
      map(omit('hasIncompleteInteractive')),
      filter(get('hasIncompleteInteractive')),
    )

    const isLeaf = matchesOneOf('contentType', SECTION_CONTENT_TYPES)
    const recursivePrune = map(item => ({
      ...item,
      children: isLeaf(item) ? [] : compose(recursivePrune, fallbackTo([]), get('children'))(item),
    }))

    return compose(
      recursivePrune,
      filterIncompleteInteractivesOnly,
      flagIncompleteInteractives,
    )(nav)
  },
))

export const getPercentageOfContentCompleted = withOptions(options => createSelector('getPercentageOfContentCompleted')(
  getContentNav(options),
  (nav) => {
    /**
     * Per Jason, it should be the percentage of [sub]sections that are complete,
     * not the number of interactions.
     * We also never show this in the library, only within assignments
     */

    const pullChildren = compose(
      reduce((acc, child) => [
        ...acc,
        child,
        ...pullChildren(child),
      ], []),
      fallbackTo([]),
      get('children'),
    )

    const relevant = contentType => [
      CONTENT_TYPE_ECHO,
      CONTENT_TYPE_SECTION,
      CONTENT_TYPE_SOURCE,
      CONTENT_TYPE_SUBSECTION,
    ].includes(contentType)

    const sectionCompletion = pullChildren({ children: nav })
      .flat()
      .filter(compose(
        relevant,
        get('contentType'),
      ))
      .map(get('calculatedCompletion'))

    const result = (sectionCompletion.filter(equals(COMPLETION_DONE)).length / sectionCompletion.length) * 100

    // return result unless it's NaN, in which case return null
    // this is to distinguish between 0% and there not yet being any completion data
    return Number.isNaN(result) ? null : result
  },
))

/**
 * context can either be:
 * 'library' - link suitable for the content-viewer
 * 'current' - tries to use the current context, which is either an assignment or
 *             the library
 *
 * Be aware that at the time of this writing, only sections and subsections will
 * try to use the current assignment when the context is 'current' since these are
 * the only items where it makes sense.  We probably won't need to include echo,
 * as they use subsection urls while within the context of an assignment.
 */
export const getContentViewerUrl = withOptions((
  { contentId, contentType } = {},
  context = 'library',
) => createSelector('getContentViewerUrl')(
  compose(
    fallbackTo({ id: contentId, contentType }),
    getContentById({ contentId, contentType }),
  ),
  getParentOfContentByType({
    contentId,
    contentType,
    parentContentType: TOP_LEVEL_CONTENT_VIEWER_TYPES,
  }),
  getParentFromContentId({ contentId, contentType }),
  getFirstChildFromContentId({ contentId, contentType }),
  getLocation,
  getUserAssignment,
  buildContentUrl(context),
))

export const getCurrentSection = withOptions(({ isPinned } = {}) => createSelector('getCurrentSection')(
  identity,
  getContentViewerParams({ isPinned }),
  (state, { contentId, echoId, navTreeSubsectionId } = {}) => compose(
    find(section => Boolean(findObj('id', navTreeSubsectionId)(section.children))
      || Boolean(findObj('id', echoId)(section.children))),
    reduce((acc, item) => {
      // Only looking two levels deep -- there is no use-case that warrants recursion
      // istanbul ignore else
      if ([CONTENT_TYPE_SECTION, CONTENT_TYPE_SOURCE].includes(item.contentType)) {
        acc.push(item)
        item.children.forEach((child) => {
          if ([CONTENT_TYPE_SECTION, CONTENT_TYPE_SOURCE].includes(child.contentType)) {
            acc.push(child)
          }
        })
      }
      return acc
    }, []),
    fallbackTo([]),
    getContentNav({ contentId }),
  )(state),
))

export const getCurrentSectionIsTabbed = withOptions(({ isPinned } = {}) => createSelector('getCurrentSectionIsTabbed')(
  getCurrentSection({ isPinned }),
  contentIsTabbed,
))

export const getFeaturesForEcho = withOptions(({ contentIds }) => createSelector('getFeaturesForEcho')(
  getContentByIds({ contentIds }),
  curryRight(toKeyedObject, 'contentSubType'),
))

/**
 * NOTE:
 * This is a utility method for selectors further down the page and there's no
 * guarantee that it will return the whole object, just the id and contentType.
 *
 * Invoke the getContentById() selector on the result if that's what you're really
 * after.
 */
const getFirstChildFromContentId = withOptions(({ contentType, contentId }) => createSelector('getFirstChildFromContentId')(
  getContentById({ contentType, contentId }),
  ({ children } = {}) => first(children),
))

export const getFirstSubsectionOrEcho = withOptions(({ contentId }) => createSelector('getFirstSubsectionOrEcho')(
  getContentNav({ contentId }),
  compose(
    get('0'),
    filter(matchesOneOf('contentType', [CONTENT_TYPE_SUBSECTION, CONTENT_TYPE_ECHO])),
    flatten,
    map(get('children')),
  ),
))

export const getNextPrevForContentViewer = withOptions(options => createSelector('getNextPrevForContentViewer')(
  getTopLevelItemsForViewerContent(options),
  getCurrentViewerContentId(options),
  (items, contentId) => findSiblings({ contentId: options?.contentId || contentId, items }),
))

export const getInsertedAncestor = withOptions(options => createSelector('getInsertedParent')(
  searchAncestors({ ...options, matchFn: get('insertionData') }),
  first,
))

export const getParentContentIsTabbed = withOptions(({ contentType, contentId }) => createSelector('getParentContentIsTabbed')(
  getParentFromContentId({ contentType, contentId }),
  contentIsTabbed,
))

export const getParentFromContentId = withOptions(({ contentType, contentId, id }) => createSelector('getParentFromContentId')(
  getCollapsedContentBags,
  findParent({ contentType, contentId: contentId || id }),
))

export const getParentOfContentByType = withOptions(({
  contentType,
  contentId,
  parentContentType,
}) => createSelector('getParentOfContentByType')(
  searchAncestors({
    contentType,
    contentId,
    /**
     * NOTE: `parentContentType` is not consumed nor needed within `searchAncestors`,
     * however since it's used below within matchFn, it must be passed in; apparently
     * for the stability of the closure
     */
    parentContentType,
    matchFn: item => Array.isArray(parentContentType)
      ? parentContentType.includes(item?.contentType)
      : item?.contentType === parentContentType,
  }),
  last,
))

export const getSubsectionOrEchoAccentColor = withOptions(({
  contentId,
  sectionAccents,
  options,
}) => createSelector('getSubsectionOrEchoAccentColor')(
  getTopLevelItemsForViewerContent(options),
  (subsections) => {
    const idx = subsections.findIndex(matches('id', contentId))

    return subsections[idx]?.contentType === CONTENT_TYPE_ECHO
      ? 'canary'
      : sectionAccents[idx % sectionAccents.length]
  },
))

export const getSubsectionOrEchoHeroImage = withOptions(({ contentId }, options) => createSelector('getSubsectionOrEchoHeroImage')(
  getTopLevelItemsForViewerContent(options),
  (subsections) => {
    const subsection = subsections.find(matches('id', contentId))
    return subsection?.uploadsMap?.image?.url
  },
))

export const includeProgress = createSelector('includeProgress')(
  createAbilityChecker,
  getUserAssignment,
  (has, ua) => has(ABILITY_STUDENT_INTERFACE) && !!ua,
)
