import { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { EditorState, Modifier, SelectionState } from 'draft-js'
import ViewInAr from '@mui/icons-material/ViewInAr'
import { useWatch } from 'react-hook-form'
import { get } from 'fp/objects'
import { maybeParseJSON } from 'fp/strings'
import { customBlockPropsShape } from '../helpers/utils'
import ChooseInteractiveDialog from './ChooseInteractiveDialog'

const EmbeddedInteractiveChooser = ({ block, blockProps }) => {
  const { getEditorState, onChange, readOnly, setPluginHasFocus } = blockProps
  const editorState = getEditorState()

  const subContentTypes = maybeParseJSON(block.getData().get('contentSubtypes'))
  const label = block.getData().get('label')

  const [open, setOpen] = useState(false)
  const children = useWatch({ name: 'children' })

  const handleInsert = useCallback(({ id }) => {
    const key = block.getKey()
    const selection = SelectionState.createEmpty(key)

    const newContentState = Modifier.setBlockData(
      editorState.getCurrentContent(),
      selection,
      {
        type: 'interactive',
        contentId: id,
      },
    )
    const newEditorState = EditorState.push(editorState, newContentState, 'change-block-data')

    onChange(newEditorState, false)

    setOpen(false)
    setPluginHasFocus(false)
  }, [block, editorState, onChange, setPluginHasFocus])

  const handleClick = useCallback(() => {
    setPluginHasFocus(true)
    setOpen(true)
  }, [setPluginHasFocus])

  return (
    <>
      <Box sx={{ padding: 3, paddingBottom: 0 }}>
        <Button
          disabled={readOnly || open}
          onClick={handleClick}
          startIcon={<ViewInAr />}
          variant="primary"
        >
          {label}
        </Button>
      </Box>

      {Boolean(open) && (
        <ChooseInteractiveDialog
          excludeIds={children.map(get('id'))}
          onClose={() => setOpen(false)}
          onComplete={handleInsert}
          subContentTypes={subContentTypes}
        />
      )}
    </>
  )
}

EmbeddedInteractiveChooser.propTypes = {
  block: PropTypes.object.isRequired,
  blockProps: customBlockPropsShape.isRequired,
}

export default EmbeddedInteractiveChooser
