import FormControl from '@mui/material/FormControl'
import { forwardRef, useId } from 'react'
import { componentShape } from 'core/shapes'
import { propBagsShape, withPropBags } from 'common/formControls/Form/withPropBags'
import withProps from 'hoc/withProps'
import { CHANGE_HANDLER_REACT_SELECT } from 'common/formControls/Form/withHookForm'

const Wrapper = forwardRef((props, ref) => {
  const {
    Component,
    componentProps,
    controlProps,
    rest: originalRest,
  } = props

  const { helperText } = componentProps
  const { style, sx, ...rest } = originalRest

  const labelId = useId()
  const descriptionId = useId()

  return (
    <FormControl
      {...{ ...controlProps, sx }}
      style={{ ...style, width: '100%' }}
    >
      <Component
        aria-labelledby={labelId}
        inputAriaDescribedby={helperText ? descriptionId : null}
        ref={ref}
        {...{
          ...controlProps,
          ...componentProps,
          ...rest,
        }}
      />
    </FormControl>
  )
})

Wrapper.propTypes = {
  ...propBagsShape.isRequired,
  Component: componentShape.isRequired,
}

const withFormControl = Component => options => withPropBags(
  [
    'disabled',
    'error',
    'required',
  ],
  {
    changeHandlerType: CHANGE_HANDLER_REACT_SELECT,
    ...options,
  },
)(withProps(Wrapper, { Component }))

export default withFormControl
