import { call, put, takeEvery, takeLeading } from 'redux-saga/effects'
import actionTypes from 'reducers/actionTypes'
import { buildUrl } from 'fp/internet'
import { actions } from 'reducers/notifications'
import { restEndpoint } from 'reducers/utils'
import { isEmptyString } from 'fp/strings'
import { dangerouslyCallApi } from './api'
import { success } from './utils'

function* handleFetch(action) {
  const { groupId } = action

  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(`${restEndpoint.groups}/${groupId}`),
  })
}

function* handleFetchList(action) {
  const { queryParams } = action
  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(restEndpoint.groups, queryParams, false),
  })
}

function* handleSave(action) {
  const { payload: { id, ...group } } = action
  const editing = !isEmptyString(id)

  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: editing ? 'PATCH' : 'POST',
      body: {
        ...group,
        id,
      },
    },
    passThrough: {
      editing,
    },
    url: editing
      ? `${restEndpoint.groups}/${id}`
      : restEndpoint.groups,
  })
}

export function* handleSaveSuccess({ passThrough: { editing } }) {
  yield put(actions.addAlert({
    message: editing
      ? 'Group updated'
      : 'Group created',
  }))
}

/* istanbul ignore next line */
function* groupSaga() {
  yield takeEvery(actionTypes.GROUP_FETCH, handleFetch)
  yield takeEvery(actionTypes.GROUP_FETCH_LIST, handleFetchList)
  yield takeLeading(actionTypes.GROUP_SAVE, handleSave)
  yield takeLeading(success(actionTypes.GROUP_SAVE), handleSaveSuccess)
}

export default groupSaga
