
import { compose } from 'redux'
import { map } from 'fp/arrays'
import { get, omit, set, set as setProp } from 'fp/objects'
import { success } from 'sagas/utils'
import { curry, fallbackTo } from 'fp/utils'
import { alter, assert } from '../search/squery'
import actionTypes from './actionTypes'
import {
  createReducer,
  handleFetchListSuccess,
  listReducerInitialState,
  updateLoaded,
} from './utils'

export const actions = {
  fetchAssignments: ({ queryParams }) => ({
    type: actionTypes.ASSIGNMENT_FETCH_LIST,
    queryParams,
  }),
  fetchStudentAssignments: ({ studentId }) => ({
    type: actionTypes.ASSIGNMENT_FETCH_LIST,
    queryParams: set('search', compose(
      alter.set.modifier('forStudentId').is(studentId),
      alter.set.limit(999),
    )(assert()))({}),
  }),
  fetchAssignmentById: ({ assignmentId }) => ({
    assignmentId,
    type: actionTypes.ASSIGNMENT_FETCH,
  }),
  saveAssignment: ({ payload }) => ({
    payload,
    type: actionTypes.ASSIGNMENT_SAVE,
  }),
}

const getUserAssignmentIds = compose(map(get('id')), fallbackTo([]), get('userAssignments'))

const handleFetchItemSuccess = (state, { response: assignment }) => compose(
  curry(updateLoaded, 2, state),
  omit('userAssignments'), // userAssignments reducer will take care of putting this into redux
  set('userAssignmentIds', getUserAssignmentIds(assignment)),
)(assignment)

// Modifiers can sometimes include extra data, which will be handled by other reducers.
// For example, see modifiers `addUserAssignmentsWithInteractions` and `addScoreableContent`.
const handleAssignmentFetchListSuccess = (state, args) => {
  const assignments = compose(
    map(omit('userAssignments')), // userAssignments reducer will take care of putting this into redux
    map(assignment => assignment.userAssignments
      ? set('userAssignmentIds', getUserAssignmentIds(assignment))(assignment)
      : assignment),
    get('response.data'),
  )(args)
  return handleFetchListSuccess(state, setProp('response.data', assignments)(args))
}

const handleUserAssignmentFetchItemSuccess = (state, args) => {
  const assignment = compose(
    omit(['content']), // content reducer will take care of putting this into redux
    get('response.assignment'),
  )(args)
  return updateLoaded(state, assignment)
}

const assignments = createReducer(
  listReducerInitialState(),
  {
    [success(actionTypes.ASSIGNMENT_FETCH_LIST)]: handleAssignmentFetchListSuccess,
    [success(actionTypes.ASSIGNMENT_FETCH)]: handleFetchItemSuccess,
    [success(actionTypes.ASSIGNMENT_SAVE)]: handleFetchItemSuccess,
    [success(actionTypes.ASSIGNMENT_CONTENT_REOPEN)]: handleFetchItemSuccess,
    [success(actionTypes.USER_ASSIGNMENT_FETCH)]: handleUserAssignmentFetchItemSuccess,
  },
)

export default assignments
