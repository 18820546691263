
import LightbulbCircleOutlined from '@mui/icons-material/LightbulbCircleOutlined'
import { isDefined } from 'fp/utils'
import { toolbarPropTypes } from '../../utils/misc'
import FunFactMenuButton from './FunFactMenuButton'

const decorator = () => {
  const strategy = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity()
        return (
          entityKey !== null
          && contentState.getEntity(entityKey).getType() === 'FUNFACT'
        )
      },
      callback,
    )
  }
  const component = ({ children }) => (
    <span className="decorator-link">
      <LightbulbCircleOutlined /> {children}
    </span>
  )
  return { strategy, component }
}

const entityToHTML = (/* features */) => (entity) => {
  const { data, type } = entity
  if (type !== 'FUNFACT') return undefined
  if (isDefined(data.contentId)) {
    return (
      <span
        data-contentid={data.contentId}
        data-name={data.name}
        data-variant="fun-fact"
      >
        {data.name}
      </span>

    )
  }
  return undefined
}

const htmlToEntity = (/* features */) => (nodeName, node, createEntity) => {
  if (node?.getAttribute?.('data-variant') === 'fun-fact') {
    const props = {
      contentId: node.getAttribute('data-contentid'),
      contentType: 'INTERACTIVE',
      name: node.outerText,
    }
    return createEntity('FUNFACT', 'IMMUTABLE', props)
  }
  return undefined
}

const ToolBarItem = ({ features }) => features.funFacts
  ? [FunFactMenuButton]
  : []

ToolBarItem.propTypes = toolbarPropTypes

const funFactsPlugin = {
  decorator,
  entityToHTML,
  htmlToEntity,
  ToolBarItem,
}

export default funFactsPlugin
