import { put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { compose } from 'redux'
import actionTypes from 'reducers/actionTypes'
import { getCurrentUser } from 'selectors/users'
import { INTERACTION_STATE_COMPLETED } from 'core/consts'
import { get, set } from 'fp/objects'
import { unwrap } from 'fp/strings'
import { success } from './utils'

export function* handleMessageReceived({ payload }) {
  if (payload.type === 'data') {
    const ranked = payload.data.filter(data => String(data.id).startsWith('ranked-'))
    const interacted = payload.data.filter(data => data.interactionType === 'interactive')

    /**
     * The socket could send us many items at once, depending on the class size,
     * the number of interactives on a page, etc.
     *
     * We need to split these up into two groups:
     *  - ranked: interactions that represent a peer rating
     *  - interacted: pure interactions (needs a better name)
     *
     * To reduce the amount of work that redux has to do (as well as avoiding
     * unnecessary re-renders), we batch these are dispatch ONE message for each
     * type
     */

    if (ranked.length) {
      yield put({
        type: actionTypes.SOCKET_INTERACTION_RANKING_RECEIVED,
        payload: ranked.map(compose(
          set('id', unwrap('ranked-')),
          get('id'),
        )),
      })
    }

    if (interacted.length) {
      yield put({
        type: actionTypes.SOCKET_INTERACTION_RECEIVED,
        payload: interacted,
      })
    }
  }
}

export function* handleInteractionReceived({ payload = [] }) {
  const { id: userId } = yield select(getCurrentUser)

  const { myItemsWithReactions, otherUsersCompletedItems } = payload.reduce(
    (acc, item) => {
      if (item.userId === userId && item.reactions) {
        acc.myItemsWithReactions.push(item)
      } else if (item.state === INTERACTION_STATE_COMPLETED) {
        acc.otherUsersCompletedItems.push(item)
      }
      return acc
    },
    { myItemsWithReactions: [], otherUsersCompletedItems: [] },
  )

  if (myItemsWithReactions.length) {
    yield put({
      type: actionTypes.SOCKET_REACTIONS_RECEIVED,
      payload: myItemsWithReactions,
    })
  }

  if (otherUsersCompletedItems.length) {
    yield put({
      type: success(actionTypes.INTERACTION_POST_MULTIPLE),
      passThrough: { suppressAlert: true },
      responses: otherUsersCompletedItems,
    })
  }
}

export function* handleReactionsReceived({ payload }) {
  yield put({
    type: success(actionTypes.REACTIONS_RECEIVED),
    response: {
      data: payload.map(({ id, reactions }) => ({ ...reactions, id })),
      metadata: {},
    },
  })
}

/* istanbul ignore next line */
function* backendSocketSaga() {
  yield takeEvery(actionTypes.SOCKET_MESSAGE_RECEIVED, handleMessageReceived)

  yield takeEvery(actionTypes.SOCKET_INTERACTION_RECEIVED, handleInteractionReceived)
  yield takeLatest(actionTypes.SOCKET_REACTIONS_RECEIVED, handleReactionsReceived)
}

export default backendSocketSaga
