import { Either, Maybe } from 'monet'
// import ct from 'countries-and-timezones'
// import { compose } from 'redux'
// import lookup from 'timezones.json'
import parseISO from 'date-fns/fp/parseISO'
import formatInTimeZone from 'date-fns-tz/formatInTimeZone'
// import { get, set } from 'fp/objects'
// import { find, first } from 'fp/arrays'
import { isString } from 'fp/strings'

const currentTzName = () => Intl.DateTimeFormat().resolvedOptions().timeZone

// const lookupTz = name => find(({ utc }) => utc.includes(name))(lookup)

// const currentTz = () => Maybe
//   .fromNull(compose(
//     ct.getTimezone,
//     currentTzName,
//   )())
//   .map(tz => set(
//     'abbr',
//     compose(
//       get('abbr'),
//       lookupTz,
//       get('name'),
//     )(tz),
//   )(tz))
//   .orUndefined()

// const currentTzAbbr = compose(
//   get('abbr'),
//   currentTz,
// )

// eslint-disable-next-line import/prefer-default-export
export const tzAbbr = date => Maybe.fromFalsy(date)
  .map(d => isString(d) ? parseISO(d) : d)
  .flatMap(d => Either.fromTry(() => formatInTimeZone(d, currentTzName(), 'z')).toMaybe())
  .orNull()

// const currentCountry = compose(
//   ct.getCountry,
//   first,
//   get('countries'),
//   currentTz,
// )
