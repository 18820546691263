/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import { EditorState } from 'draft-js'
import { removeBlock } from '@studysync/draft-js-modifiers'
import { deepMerge } from 'fp/objects'
import CustomBlockWrapper from './helpers/CustomBlockWrapper'
import { customBlockPropsShape } from './helpers/utils'
import PluginInput from './helpers/PluginInput'

const DualHeadlinePlaceholder = ({ block, blockProps }) => {
  const { getEditorState, onChange, readOnly } = blockProps

  const data = block.getData()

  const hasDividerBelow = data.get('dividerbelow') === 'true'
  const isCenterAligned = data.get('textalign') === 'center'

  const handleRemove = () => {
    const editorState = getEditorState()
    const newContentState = removeBlock(editorState.getCurrentContent(), block.getKey())
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'remove-block',
    )
    onChange(newEditorState)
  }

  return (
    <CustomBlockWrapper
      block={block}
      blockProps={blockProps}
      onRemove={handleRemove}
      readOnly={readOnly}
      title="Standalone Question"
    >
      <table>
        <tbody>

          <tr>
            <th>Title 1</th>
            <td>
              <PluginInput
                block={block}
                blockProps={blockProps}
                component="input"
                dataField="title1"
                getEditorState={getEditorState}
                setEditorState={onChange}
              />
            </td>
          </tr>

          <tr>
            <th>Title 2</th>
            <td>
              <PluginInput
                block={block}
                blockProps={blockProps}
                component="input"
                dataField="title2"
                getEditorState={getEditorState}
                setEditorState={onChange}
              />
            </td>
          </tr>

          <tr>
            <th>Subtitle</th>
            <td>
              <PluginInput
                block={block}
                blockProps={blockProps}
                component="input"
                dataField="subtitle"
                getEditorState={getEditorState}
                setEditorState={onChange}
              />
            </td>
          </tr>

          <tr>
            <th>Align</th>
            <td>
              <PluginInput
                block={block}
                blockProps={blockProps}
                component="select"
                dataField="textalign"
                disabled={hasDividerBelow}
                getEditorState={getEditorState}
                options={[
                  ['Left', 'left'],
                  ['Center', 'center'],
                  ['Right', 'right'],
                ]}
                setEditorState={onChange}
              />
            </td>
          </tr>

          <tr>
            <th>Multiline</th>
            <td>
              <PluginInput
                block={block}
                blockProps={blockProps}
                component="select"
                dataField="multiline"
                getEditorState={getEditorState}
                options={[['Yes', true], ['No', false]]}
                setEditorState={onChange}
              />
            </td>
          </tr>

          <tr>
            <th>Divider below</th>
            <td>
              <PluginInput
                block={block}
                blockProps={blockProps}
                component="select"
                dataField="dividerbelow"
                disabled={!isCenterAligned}
                getEditorState={getEditorState}
                options={[['Yes', true], ['No', false]]}
                setEditorState={onChange}
              />
            </td>
          </tr>

        </tbody>
      </table>
    </CustomBlockWrapper>
  )
}

DualHeadlinePlaceholder.propTypes = {
  block: PropTypes.object.isRequired,
  blockProps: customBlockPropsShape.isRequired,
}

const blockRendererFn = (contentBlock, {
  // features,
  getEditorState,
  onChange,
  readOnly,
  setPluginHasFocus,
}) => {
  if (contentBlock.getType() === 'atomic'
    && contentBlock.getData().get('type') === 'advanced-heading'
  ) {
    return {
      component: DualHeadlinePlaceholder,
      editable: false,
      props: {
        getEditorState,
        onChange,
        readOnly,
        setPluginHasFocus,
      },
    }
  }

  return undefined
}

const blockToHTML = (/* features */) => (current, { data, type }) => {
  if (type === 'atomic' && data.type === 'advanced-heading') {
    return (
      <div
        data-dividerbelow={String(data.dividerbelow)}
        data-multiline={String(data.multiline)}
        data-subtitle={data.subtitle}
        data-textalign={data.textalign}
        data-title1={data.title1}
        data-title2={data.title2}
        data-variant="advanced-heading"
      />
    )
  }

  return current
}

const htmlToBlock = (/* features */) => (current, nodeName, node) => {
  if (node?.getAttribute?.('data-variant') === 'advanced-heading') {
    return deepMerge(current, {
      type: 'atomic',
      mutability: 'IMMUTABLE',
      data: {
        dividerbelow: String(node.getAttribute('data-dividerbelow') || false),
        multiline: String(node.getAttribute('data-multiline') || false),
        subtitle: node.getAttribute('data-subtitle'),
        textalign: node.getAttribute('data-textalign') || 'left',
        title1: node.getAttribute('data-title1'),
        title2: node.getAttribute('data-title2'),
        type: 'advanced-heading',
      },
    })
  }

  return current
}

const dualHeadlinePlugin = {
  blockRendererFn,
  blockToHTML,
  htmlToBlock,
}

export default dualHeadlinePlugin
