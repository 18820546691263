import withConnectedMultiSelect from 'common/formControls/selects/ConnectedMultiSelect/withConnectedMultiSelect'
import { renderOption } from 'common/formControls/selects/ConnectedMultiSelect/utils'
import { restEndpoint } from 'reducers/utils'

const ConnectedVocabularyPicker = withConnectedMultiSelect({
  renderOption: renderOption('label'),
  label: 'Add phrase',
  labelField: 'name',
  orderBy: 'name',
  placeholder: 'Search for phrase...',
  uri: restEndpoint.content,
  valueField: 'vocabContentIds',
})

export default ConnectedVocabularyPicker
