// eslint-disable-next-line @studysync/persnickety/use-pinnable-components
import { Route, Routes } from 'react-router-dom'
import Box from '@mui/material/Box'
import { getBuildNumber } from 'selectors/index'

const BuildNumber = () => (
  <Routes>
    <Route
      element={(
        <Box
          sx={{
            bottom: 10,
            fontSize: '1.2rem',
            left: 5,
            lineHeight: 1,
            opacity: 0.5,
            position: 'absolute',
          }}
        >
          <strong>Build:</strong> {getBuildNumber()}
        </Box>
      )}
      path="/settings"
    />

    <Route
      // here to silence console warnings
      element={null}
      path="*"
    />
  </Routes>
)

export default BuildNumber
