import LocationOn from '@mui/icons-material/LocationOn'
import { size, transparentize } from 'polished'
import { forwardRef } from 'react'
import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/styled-engine'

const MARKER_SIZE = 14

const pulsate = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.1, 0.1);
  }
  50% { opacity: 1 }
  75% {
    opacity: 0;
    transform: scale(1.2, 1.2);
  }
  100% { }
`

const Shadow = styled(
  'div',
  { name: 'indicators-MapMarkerShadow' },
)(({ theme: { palette } }) => ({
  background: transparentize(0.8, palette.shadow),
  borderRadius: '50%',
  ...size(MARKER_SIZE),
  position: 'absolute',
  left: '50%',
  top: '50%',
  marginLeft: -(MARKER_SIZE / 2),
  transform: 'rotateX(55deg)',
  zIndex: -2,
  '&:after': {
    content: '""',
    borderRadius: '50%',
    ...size(30),
    position: 'absolute',
    marginTop: -(MARKER_SIZE / 2),
    marginLeft: -(MARKER_SIZE),
    animation: `${pulsate} 2.5s infinite 1s ease-out`,
    opacity: 0,
    boxShadow: `0 0 1px 1px ${transparentize(0.6, palette.shadow)}`,
  },
}))

const Marker = styled(
  'div',
  { name: 'indicators-MapMarker' },
)(({ theme: { palette } }) => ({
  color: palette.common.red,
  position: 'relative',
  textAlign: 'center',
  zIndex: 0,
}))

const MapMarker = forwardRef((props, ref) => (
  <Marker
    ref={ref}
    {...props}
  >
    <LocationOn />
    <Shadow />
  </Marker>
))

export default MapMarker
