import { withOptions } from '@comfy/redux-selectors'
import { compose } from 'redux'
import { Maybe } from 'monet'
import { dedupeById, filter } from 'fp/arrays'
import { equals, get } from 'fp/objects'
import { identity, not } from 'fp/utils'
import { VOCAB_TERM_TYPE_SKILL } from 'core/consts'
import { getFlattenedChildren } from './content'
import { createSelector } from '.'

const aggregateVocabFromContentChildren = ({ content, vocabFilter, vocabType }) => (allContent) => {
  const empty = {
    academicVocabPhrases: [],
    contentVocabPhrases: [],
    footnoteVocabPhrases: [],
  }

  const applyFilters = compose(
    Maybe.fromUndefined(vocabFilter).map(filter).orJust(identity),
    filter(compose(Boolean, get('data.definition'))),
  )

  const pool = content
    ? allContent
      .reduce((acc, { contentVocabPhrases = [], academicVocabPhrases = [], footnoteVocabPhrases = [] }) => ({
        contentVocabPhrases: dedupeById(acc.contentVocabPhrases.concat(applyFilters(contentVocabPhrases))),
        academicVocabPhrases: dedupeById(acc.academicVocabPhrases.concat(applyFilters(academicVocabPhrases))),
        footnoteVocabPhrases: dedupeById(acc.footnoteVocabPhrases.concat(applyFilters(footnoteVocabPhrases))),
      }), empty)
    : empty

  return vocabType
    ? pool?.[`${vocabType}VocabPhrases`] || []
    : pool
}

const isSkill = compose(equals(VOCAB_TERM_TYPE_SKILL), get('data.termType'))

export const getAggregatedVocabFromContentChildren = withOptions(({ content, vocabType }) => createSelector('getAggregatedVocabFromContentChildren')(
  getFlattenedChildren({ contentId: content?.id }),
  aggregateVocabFromContentChildren({
    content,
    vocabType,
    vocabFilter: compose(not, isSkill),
  }),
))

export const getAggregatedSkillVocabFromContentChildren = withOptions(({ content }) => createSelector('getAggregatedSkillVocabFromContentChildren')(
  getFlattenedChildren({ contentId: content?.id }),
  aggregateVocabFromContentChildren({
    content,
    vocabType: 'content',
    vocabFilter: isSkill,
  }),
))
