import { compose } from 'redux'
import { flatten, reduce } from 'fp/arrays'
import { get, pick } from 'fp/objects'
import { binary, fallbackTo } from 'fp/utils'
import { success } from 'sagas/utils'
import actionTypes from './actionTypes'
import {
  createReducer,
  listReducerInitialState,
  updateLoaded,
} from './utils'

// The assignments fetch can sometimes include related standards via the addScoreableContent modifier.
const handleAssignmentFetchListSuccess = (state, args) => compose(
  reduce(binary(updateLoaded), state),
  fallbackTo([]),
  get('response.relatedData.standards'),
)(args)

const processFetchedContent = (state, item) => {
  const { children } = item

  const result = compose(
    reduce(binary(updateLoaded), state),
    flatten,
    Object.values,
    pick('applicationStandards', 'instructionStandards'),
  )(item)

  return children?.reduce(processFetchedContent, result) || result
}

const handleContentFetchSuccess = (state, { passThrough, response }) => {
  if (passThrough?.suppressUpdate) return state
  return processFetchedContent(state, response)
}

const handleContentFetchByAssetCodeSuccess = (state, { response, passThrough }) => (response?.data?.length)
  ? handleContentFetchSuccess(state, {
    response: response.data[0],
    passThrough,
  })
  : state

const standards = createReducer(
  listReducerInitialState(),
  {
    [success(actionTypes.ASSIGNMENT_FETCH_LIST)]: handleAssignmentFetchListSuccess,
    [success(actionTypes.CONTENT_FETCH)]: handleContentFetchSuccess,
    [success(actionTypes.CONTENT_FETCH_BY_ASSET_CODE)]: handleContentFetchByAssetCodeSuccess,
  },
)

export default standards
