import { useContext } from 'react'
import { context } from './context'
import SignInButton from './SignInButton'

const SignIn = () => {
  const { signIn, status } = useContext(context)

  return status === 'SIGNIN'
    ? (
      <SignInButton
        onClick={() => signIn()}
        style={{ marginTop: '4rem' }}
      />
    )
    : null
}

export default SignIn
