import PropTypes from 'prop-types'
import { useRef } from 'react'
import { CONTENT_TYPE_CHAPTER, CONTENT_TYPE_ECHO, CONTENT_TYPE_SOURCE } from 'core/consts'
import useContent from 'hooks/useContent'
import ChapterTree from './ChapterTree'
import TreeWithRoot from './TreeWithRoot'

const Components = {
  [CONTENT_TYPE_CHAPTER]: ChapterTree,
  [CONTENT_TYPE_ECHO]: TreeWithRoot,
  [CONTENT_TYPE_SOURCE]: TreeWithRoot,
}

const SectionTree = ({ actionsRef }) => {
  const { contentType, id: contentId } = useContent({ disableFetch: true }) || {}

  const treeRef = useRef()

  const Component = Components[contentType]

  const { top: treeTop } = treeRef.current?.getBoundingClientRect() || {}
  const { height: actionsHeight } = actionsRef.current?.getBoundingClientRect() || {}

  const maxHeight = `calc(100vh - ${treeTop}px - 4px)`

  return Component
    ? (
      <Component
        actionsHeight={actionsHeight}
        contentId={contentId}
        maxHeight={maxHeight}
        ref={treeRef}
      />
    )
    : null
}

SectionTree.propTypes = { actionsRef: PropTypes.object.isRequired }

export default SectionTree
