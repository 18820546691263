/* eslint-disable react/no-typos */
import { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'

const withTooltip = (Component) => {
  const wrapped = ({ disabled = false, title, TooltipProps, ...rest }, ref) => (
    <Tooltip
      title={disabled ? '' : title}
      {...TooltipProps}
    >
      <Component
        disabled={disabled}
        ref={ref}
        {...rest}
      />
    </Tooltip>
  )

  wrapped.PropTypes = {
    disabled: PropTypes.bool,
    title: PropTypes.string,
    TooltipProps: PropTypes.object,
  }

  wrapped.PropTypes = {
    disabled: false,
    title: '',
    TooltipProps: undefined,
  }

  return forwardRef(wrapped)
}

withTooltip.propTypes = {
  Component: PropTypes.element,
}

export default withTooltip
