/* eslint-disable no-use-before-define */
const NODE_TYPE_NODE = 1
const NODE_TYPE_TEXT = 3
const NODE_TYPE_DOCUMENT = 9
const NODE_TYPE_FRAGMENT = 11

export const findAncestor = (rootNode, filterFn) => {
  let result = rootNode
  while (result?.parentNode) {
    if (filterFn(result.parentNode)) {
      return result.parentNode
    }
    result = result.parentNode
  }

  return undefined
}

export const findChildTextNodes = (rootNode, filterFn) => {
  if (!rootNode) return []

  let result = []

  rootNode.childNodes.forEach((node) => {
    const { nodeType } = node

    /* istanbul ignore else */
    if (nodeType === NODE_TYPE_TEXT) {
      if (!filterFn || filterFn(node, rootNode)) {
        result.push(node)
      }
    } else if ([NODE_TYPE_NODE, NODE_TYPE_DOCUMENT, NODE_TYPE_FRAGMENT].includes(nodeType)) {
      result = result.concat(findChildTextNodes(node, filterFn))
    }
  })

  return result
}

export const getNodeByContentId = contentId => document.querySelector(`[data-contentid="${contentId}"]`)

export const getParentContentId = rootNode => findAncestor(
  rootNode,
  node => node.hasAttribute?.('data-contentid'),
)?.getAttribute('data-contentid')

export const getTextNodesThatContain = (rootNode, str) => {
  const result = findChildTextNodes(
    rootNode,
    ({ wholeText }) => (wholeText).includes(str),
  )

  if (!result.length && rootNode?.textContent?.includes?.(str)) {
    /**
     * If we couldn't find a child node that contains the text, then chances are
     * that the selection spans multiple nodes (or blocks).
     *
     * When this happens, we can try to fallback to the parent (containing) node.
     * There are probably edge cases where even this will fail.
     *
     * One thing to be aware of is that while textNodes will have a `wholeText`
     * property, the parent will not.  Instead we use `textContent` for the parent.
     * Technically `textContent` exists in both the parent and child, but `wholeText`
     * feels like the safer choice.  It also makes it possible to detect from outside
     * this function, whether or not we fell back to using the parent, since the
     * result will or won't have `wholeText`.
     */
    return [rootNode]
  }

  return result
}

export const hasClass = className => node => Boolean(node.classList?.contains(className))

const isWithinClass = className => (node) => {
  let cursor = node
  while (cursor) {
    if (hasClass(className)(cursor)) return true
    cursor = cursor.parentNode
  }
  return false
}

export const isWithinTextControl = isWithinClass('public-DraftStyleDefault-block')

export const preventDefault = (event) => {
  event.stopImmediatePropagation?.()
  event.stopPropagation()
  event.preventDefault()
}

export const scrollIntoView = /* istanbul ignore next */ (element, focusToo = false) => {
  element?.scrollIntoView?.({ behavior: 'smooth', block: 'start', inline: 'nearest' })
  // eslint-disable-next-line no-empty, no-unused-expressions
  try { focusToo && element?.focus() } catch (e) { }
}

export const unwrap = node => node.replaceWith(...node.childNodes)
