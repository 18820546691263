import PropTypes from 'prop-types'
import { Minus } from 'react-feather'
import { styled } from '@mui/material/styles'
import cl from 'classnames'
import { element } from 'core/shapes'
import Centered from 'common/layout/Centered'

const Pagination = styled(
  ({ className, currentSlide, slides }) => (
    <Centered className={className}>
      {slides.map((slide, index) => (
        <Minus
          className={cl({ current: index === currentSlide })}
          key={slide.key}
        />
      ))}
    </Centered>
  ),
  { name: '3d-Pagination' },
)(({ theme: { palette, mixins: { transition } } }) => ({
  height: 24,
  svg: {
    color: palette.grey[3],
    strokeLinecap: 'square',
    ...transition(),
    width: 28,
    height: 'auto',
    '&.current': {
      color: palette.pagination,
      strokeWidth: 2,
      width: 40,
      height: 'auto',
    },
  },

}))

Pagination.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.shape({
    content: element.isRequired,
  })).isRequired,
  currentSlide: PropTypes.number.isRequired,
}

export default Pagination
