/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import Tooltip from '@mui/material/Tooltip'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import { Volume2, VolumeX } from 'react-feather'
import Typography from '@mui/material/Typography'

const MuteButton = ({ vjsComponent, onChange, isMuted = false }) => {
  const label = isMuted ? 'Unmute' : 'Mute'

  useEffect(() => {
    vjsComponent.player_?.muted(isMuted)
  }, [vjsComponent, isMuted])

  return useMemo(() => (
    <Tooltip
      arrow
      title={<Typography fontSize="1.4rem">{label}</Typography>}
    >
      <IconButton
        aria-label={label}
        aria-pressed={isMuted}
        onClick={() => {
          onChange(!isMuted)
        }}
        size="small"
        sx={{ '& .material-icons': { height: 'auto' } }}
      >
        <Icon style={{ overflow: 'visible' }}>
          {isMuted
            ? <VolumeX />
            : <Volume2 />}
        </Icon>
      </IconButton>
    </Tooltip>
  ), [label, isMuted, onChange])
}

MuteButton.propTypes = {
  isMuted: PropTypes.bool.isRequired,
  vjsComponent: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default MuteButton
