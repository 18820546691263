import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

const ActionLink = styled(
  Button,
  { name: 'buttons-ActionLink' },
)(({ theme: { mixins: { important, rem }, palette } }) => ({
  backgroundColor: 'transparent',
  color: palette.accent.dark,
  lineHeight: 1.625,
  border: 'none',
  cursor: 'pointer',
  textDecoration: 'underline',
  textTransform: 'none',
  fontSize: rem(1.7),
  display: 'inline',
  margin: 0,
  padding: 0,
  '&:hover': {
    backgroundColor: important('transparent'),
    color: important(palette.accent.dark),
  },
  '&:focus': { backgroundColor: important('transparent') },
  '&:active': { boxShadow: 'none' },
}))

export default ActionLink
