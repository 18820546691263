import PropTypes from 'prop-types'
import { isEmptyString, isNotJSON, maybeParseJSON } from 'fp/strings'
import { isObject } from 'fp/objects'
import HFTextArea from './HFTextArea'

export const JSON_TYPE_ARRAY = 'ARRAY'
export const JSON_TYPE_OBJECT = 'OBJECT'
const JSON_TYPE_ARRAY_OR_OBJECT = 'ARRAY_OR_OBJECT'

export const basicJsonValidator = ({ required, value, jsonType }) => {
  const parsed = maybeParseJSON(value)
  const isArr = Array.isArray(parsed)
  const isObj = isObject(parsed)
  const isEmpty = isEmptyString(value)

  if (!required && isEmpty) return true

  if (!isEmpty && isNotJSON(value)) return 'Please enter a valid JSON configuration.'

  switch (jsonType) {
    case JSON_TYPE_ARRAY:
      if (!isArr) return 'Value must be an array'
      break
    case JSON_TYPE_OBJECT:
      if (!isObj) return 'Value must be an object'
      break
    case JSON_TYPE_ARRAY_OR_OBJECT:
      if (!isArr && !isObj) return 'Value must be an array or an object'
      break
    default:
  }

  return true
}

const HFJsonTextArea = ({ jsonType = JSON_TYPE_OBJECT, ...rest }) => (
  <HFTextArea
    multiline
    rules={
      {
        validate: ({ required, value }) => basicJsonValidator({ required, value, jsonType }),
      }
    }
    valueIsJson
    {...rest}
  />
)

HFJsonTextArea.propTypes = {
  jsonType: PropTypes.oneOf([
    JSON_TYPE_ARRAY,
    JSON_TYPE_OBJECT,
    JSON_TYPE_ARRAY_OR_OBJECT,
  ]),
}

export default HFJsonTextArea
