import {
  HANDLED,
  NOT_HANDLED,
  atEndOfBlock as isAtEndOfBlock,
  atStartOfBlock as isAtStartOfBlock,
  getCurrentBlock,
} from '@studysync/draft-js-modifiers'
import { RichUtils } from 'draft-js'
import { intersection, isEmpty } from 'fp/arrays'
import { fauxEvent } from '../utils/misc'
import { INTERACTIVE_WHITESPACE } from './helpers/utils'

const blockToHTML = (/* features */) => (current, { type }) => {
  switch (type) {
    case 'header-one': return { start: '<h1>', end: '</h1>' }
    case 'header-two': return { start: '<h2>', end: '</h2>' }
    case 'header-three': return { start: '<h3>', end: '</h3>' }
    case 'paragraph': return { start: '<p>', end: '</p>' }
    case INTERACTIVE_WHITESPACE: return { start: '', end: '' }
    case 'unstyled': return { start: '<p>', end: '</p>' }
    default: return current
  }
}

const handleKeyCommand = (/* features */) => ({
  changeIndent,
  toggleBlockType,
  toggleIndentFirstLine,
  toggleInlineStyle,
  toggleLink,
}) => (command/* , editorState, eventTimeStamp */) => {
  switch (command) {
    case 'Bold':
    case 'Underline':
    case 'Italic':
    case 'Strikethrough':
      toggleInlineStyle(String(command).toUpperCase())()
      return HANDLED
    case 'header-one':
    case 'header-two':
    case 'header-three':
    case 'ordered-list-item':
    case 'unordered-list-item':
    case 'blockquote':
    case 'P':
      toggleBlockType(command)()
      return HANDLED
    case 'increase':
    case 'decrease':
      changeIndent(fauxEvent(), command)
      return HANDLED
    case 'indentFirstLine':
      toggleIndentFirstLine()
      return HANDLED
    case 'link':
      toggleLink()
      return HANDLED
    /* istanbul ignore next */
    default:
      return NOT_HANDLED
  }
}

const handleReturn = (/* features */) => ({ setEditorState }) => ({ shiftKey }, editorState) => {
  const block = getCurrentBlock(editorState)
  const data = block.getData()
  const variants = data.get('variants')
  const hasVariants = !isEmpty(variants)
  const atEndOfBlock = isAtEndOfBlock(editorState)
  const atStartOfBlock = isAtStartOfBlock(editorState)

  const permittedVariants = ['drop-cap']
  const allowedToBreakBlock = atStartOfBlock
    || atEndOfBlock
    || !hasVariants
    || !isEmpty(intersection(variants)(permittedVariants))

  if (shiftKey) {
    setEditorState(RichUtils.insertSoftNewline(editorState))
    return HANDLED
  }

  if (!allowedToBreakBlock) {
    return HANDLED
  }

  return NOT_HANDLED
}

const htmlToBlock = (/* features */) => (current, nodeName) => {
  if (nodeName === 'p') {
    return {
      type: 'unstyled',
    }
  }

  return current
}

const styleToHTML = (/* features */) => (current, style) => {
  switch (style) {
    case 'BOLD':
      return <strong />
    case 'ITALIC':
      return <em />
    case 'STRIKETHROUGH':
      return <del />
    case 'UNDERLINE':
      return <u />
    /* istanbul ignore next */
    default:
      return undefined
  }
}

const simpleFormattingPlugin = {
  blockToHTML,
  handleKeyCommand,
  handleReturn,
  htmlToBlock,
  styleToHTML,
}

export default simpleFormattingPlugin
