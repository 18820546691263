import { useSelector } from 'react-redux'
import { uploadShape } from 'core/shapes'
import { formatRelativeTime } from 'locale/i18n'
import { getUserById } from 'selectors/users'

const Provenance = ({ value }) => {
  const { cantoFileName, createdById, dateModified, gdriveFileName, url } = value || {}

  const origin = cantoFileName
    ? 'Canto'
    : gdriveFileName
      ? 'Google Drive'
      : ''
  const originFilename = cantoFileName || gdriveFileName

  const user = useSelector(getUserById({ userId: createdById }))

  if (!origin) return null

  return (
    <div>
      {user?.firstName} {user?.lastName} {' '}
      uploaded {' '}
      <a
        href={url}
        rel="noreferrer"
        target="_blank"
      >
        {originFilename}
      </a> {' '}
      from {origin}{', '}
      {formatRelativeTime(dateModified)}
    </div>
  )
}

Provenance.propTypes = {
  value: uploadShape,
}

export default Provenance
