import PropTypes, { arrayOf } from 'prop-types'
import { createConfirmation } from 'react-confirm'
import { useCallback, useEffect, useRef, useState } from 'react'
// eslint-disable-next-line @studysync/material-ui/tree-shakeable-imports
import { createFilterOptions } from '@mui/material/Autocomplete'
import { compose } from 'redux'
import useApiFromEffect from 'hooks/useApiFromEffect'
import { curry, debounce, identity } from 'fp/utils'
import { isObject, renameKeys } from 'fp/objects'
import { dedupeById, last, map } from 'fp/arrays'
import useReduxPromise from 'hooks/useReduxPromise'
import { pluralize, trim } from 'fp/strings'
import ConfirmationDialog from 'common/dialogs/ConfirmationDialog'
import MultiPicker from '../MultiPicker'
import {
  createItems,
  formatForParsing,
  getActionType,
  performPickerSearch,
} from './utils'
import SimpleDialog from './SimpleDialog'

const confirm = createConfirmation(ConfirmationDialog, 0)

const filter = createFilterOptions()

const defaultNoOptionsText = 'Enter a value to begin searching'

const ConnectedMultiSelect = (props) => {
  const {
    createType,
    delimiters = ['\n'],
    indeterminate,
    inputAriaDescribedby,
    labelField = 'label',
    onChange,
    orderBy,
    preserveSpaces = true,
    type = null,
    uri,
    value,
    valueField,
    ...rest
  } = props

  const [options, setOptions] = useState([])
  const [allOptions, setAllOptions] = useState()
  const callApi = useApiFromEffect()
  const [open, setOpen] = useState(false)
  const [noOptionsText, setNoOptionsText] = useState(defaultNoOptionsText)
  const [loading, setLoading] = useState(false)
  const [creating, setCreating] = useState(false)
  const [dialogMsg, setDialogMsg] = useState('')
  const [selectedOptions, setSelectedOptions] = useState([])
  const selectRef = useRef()

  // using useRef instead of useState for this value because
  // we don't need/want it to trigger a re-render
  const dialogWasClosed = useRef(false)

  const onCloseDialog = useCallback(() => {
    dialogWasClosed.current = true
    setOpen(false)
  }, [])

  useEffect(() => {
    // we only want to focus the select if the dialog was just closed
    // after the initial load, "open" will only go back to false after the dialog closes
    if (!open && selectRef.current && dialogWasClosed.current) {
      selectRef.current.focus()
    }
  }, [open])

  const rename = arr => dedupeById((arr || [])
    .map(curry(renameKeys, 2, { shortCode: 'label', name: 'label', id: 'value' })), 'value')

  const allowCreate = Boolean(createType)

  const startAdd = useReduxPromise(getActionType(createType))

  const getNewOption = response => ({
    id: response.id,
    value: response.id,
    name: response.name,
    label: response.name,
    shortName: response.name,
  })

  const createItem = useCallback(async (newValue) => {
    setCreating(true)
    try {
      const { payload } = await createItems([newValue], createType, startAdd, type)

      const result = payload[0]

      if (result.isRight()) {
        const response = result.right()
        const newSelectedOptions = [
          ...selectedOptions,
          getNewOption(response),
        ]
        setSelectedOptions(newSelectedOptions)
        onChange?.(newSelectedOptions)
      }
    } finally {
      setCreating(false)
    }
  }, [createType, onChange, selectedOptions, startAdd, type])

  // TODO: no current way to test within react-confirm
  // istanbul ignore next line
  const createNewItems = useCallback(async (proceed, invalidOptions) => {
    const newOptions = []
    let numErrors = 0

    if (proceed) {
      setCreating(true)
      try {
        const { payload } = await createItems(invalidOptions, createType, startAdd, type)

        payload.forEach((result) => {
          result.map(r => newOptions.push(getNewOption(r)))
          result.leftMap(() => {
            numErrors += 1
          })
        })

        if (newOptions.length) {
          const newSelectedOptions = [
            ...selectedOptions,
            ...newOptions,
          ]
          // wipe this out so it pulls down a fresh list (with the new items in it) on the next go-round
          // TODO: Get the API to do this work instead
          setAllOptions(null)
          setSelectedOptions(newSelectedOptions)
          onChange?.(newSelectedOptions)
        }
        if (numErrors > 0) {
          setDialogMsg(`${pluralize('item')(numErrors)} could not be added to the database. Please check your results and try again.`)
          setOpen(true)
        }
      } finally {
        setCreating(false)
      }
    }
  }, [createType, onChange, selectedOptions, startAdd, type])

  const processInvalidOptions = useCallback(async (invalidOptions) => {
    if (invalidOptions.length) {
      if (allowCreate) {
        const secondaryText = invalidOptions.length === 1
          ? `The following item could not be found. Would you like to create it as a new item? ${invalidOptions}`
          : `The following items could not be found. Would you like to create them as new items? ${invalidOptions.join(', ')}`

        confirm({
          primaryText: 'Create Items?',
          secondaryText,
          proceed: proceed => createNewItems(proceed, invalidOptions),
          cancel: identity,
          confirmLabel: 'Create',
          cancelLabel: 'Cancel',
        })
      } else {
        setDialogMsg(`The following items could not be found: ${invalidOptions.join(', ')}`)
        setOpen(true)
      }
    }
  }, [allowCreate, createNewItems])

  const parseList = useCallback(async (list) => {
    let localAllOptions
    if (!allOptions) {
      // we're going to be looking for existing items to match the values they pasted in,
      // so we need all of the options in the database to do that
      setLoading(true)
      try {
        const { options: searchOptions } = await performPickerSearch({
          callApi,
          labelField,
          limit: 999999999,
          name: '',
          loadedOptions: [],
          type,
          uri,
        })
        setAllOptions(searchOptions)
        localAllOptions = searchOptions
      } finally {
        setLoading(false)
      }
    } else {
      localAllOptions = allOptions
    }

    const invalidOptions = []

    const values = compose(
      map(s => ({ lowerCase: s.toLowerCase(), origCase: s })),
      map(trim),
    )(list.split(','))

    const uniqValues = dedupeById(values, 'lowerCase').map(s => s.origCase)

    let validOptions = selectedOptions
    let option

    uniqValues.forEach((val) => {
      option = localAllOptions.find(lao => lao[labelField].toLowerCase() === val.toLowerCase())
      if (option) {
        validOptions.push(option)
      } else if (val) {
        invalidOptions.push(val)
      }
    })

    validOptions = rename(validOptions)

    setSelectedOptions(validOptions)
    onChange(validOptions, { action: 'paste-values' })
    processInvalidOptions(invalidOptions)
  }, [allOptions, callApi, labelField, onChange, processInvalidOptions, selectedOptions, type, uri])

  const onInputChange = useCallback((event, newInputValue) => {
    if (!newInputValue) {
      setNoOptionsText(defaultNoOptionsText)
      setOptions([])
      return
    }

    setNoOptionsText('No items found')
    setLoading(true)
    performPickerSearch({
      callApi,
      labelField,
      name: newInputValue,
      loadedOptions: [],
      orderBy,
      type,
      uri,
    }).then(({ options: dbOptions }) => {
      setLoading(false)
      const newOptions = rename(dbOptions)
      setOptions(newOptions)
    }).catch((e) => {
      setLoading(false)
      throw e
    })
  }, [callApi, labelField, orderBy, type, uri])

  const debouncedOnInputChange = debounce(300, onInputChange)

  const handleChange = useCallback((e, newValue) => {
    if (allowCreate
      && Array.isArray(newValue)
      && newValue.length
      && last(newValue)[labelField]
      && !last(newValue).value) {
      createItem(last(newValue)[labelField])
    } else {
      onChange?.(newValue)
      setSelectedOptions(newValue)
    }
  }, [allowCreate, createItem, labelField, onChange])

  useEffect(() => {
    setSelectedOptions(rename(value || []))
  }, [value])

  const handlePaste = useCallback((e) => {
    const clipboardData = e.clipboardData || window.clipboardData
    const pastedData = clipboardData.getData('Text')

    const newValue = formatForParsing(delimiters, preserveSpaces, pastedData)

    if (newValue.includes(',')) {
      // we are going to handle this data manually,
      // so keep it from going into the control
      e.stopPropagation()
      e.preventDefault()

      e.target.blur()
      parseList(newValue)
    }
  }, [delimiters, parseList, preserveSpaces])

  const getOptionLabel = useCallback(
    x => ((isObject(x) ? x[labelField] || x.label : x) || ''),
    [labelField],
  )

  const filterOptions = useCallback((fOptions, params) => {
    if (allowCreate) {
      const filtered = filter(fOptions, params)

      if (params.inputValue !== '' && !options.find(o => o.label === params.inputValue)) {
        filtered.push({
          [labelField]: params.inputValue,
          label: `Create "${params.inputValue}"`,
        })
      }

      return filtered
    }

    return fOptions
  }, [allowCreate, labelField, options])

  const isOptionEqualToValue = useCallback((option, val) => {
    if (Array.isArray(option)) {
      return option.findIndex(o => o.value === val.value) >= 0
    }
    return option.value === val.value
  }, [])

  return (
    <>
      <MultiPicker
        autoComplete
        autoHighlight
        clearOnBlur
        disabled={creating}
        filterOptions={filterOptions}
        filterSelectedOptions
        getOptionLabel={getOptionLabel}
        handleHomeEndKeys
        includeInputInList
        isOptionEqualToValue={isOptionEqualToValue}
        loading={loading}
        onChange={handleChange}
        onInputChange={debouncedOnInputChange}
        onPaste={handlePaste}
        options={options}
        ref={selectRef}
        {...rest}
        noOptionsText={noOptionsText}
        value={selectedOptions}
      />
      <SimpleDialog
        message={dialogMsg}
        onClose={onCloseDialog}
        open={open}
      />
    </>
  )
}

ConnectedMultiSelect.propTypes = {
  createType: PropTypes.oneOf(['TAG', 'VOCAB']),
  delimiters: PropTypes.array,
  indeterminate: PropTypes.any,
  inputAriaDescribedby: PropTypes.string,
  labelField: PropTypes.string,
  onChange: PropTypes.func,
  orderBy: PropTypes.string,
  preserveSpaces: PropTypes.bool,
  type: PropTypes.string,
  uri: PropTypes.string.isRequired,
  value: arrayOf(PropTypes.object).isRequired,
  valueField: PropTypes.string,
}

export default ConnectedMultiSelect
