import { Suspense, forwardRef, lazy, useContext } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import ScreenReaderText from 'common/text/ScreenReaderText'
import { videoPlayerContext } from 'common/avclub/video/context'
import BusySpinner from 'common/indicators/BusySpinner'
import { videoShape } from 'core/shapes'
import { buildSources } from 'common/avclub/utils'

const VideoJsPlayer = lazy(() => import(/* webpackChunkName: "VideoJsPlayer" */ './VideoJsWrapper'))

const buildCC = src => (src ? [{ src, srclang: 'en', label: 'English' }] : null)

const VideoPlayer = forwardRef(({ className, video, ...rest }, ref) => {
  const { ccUrl, description, poster, posterUrl } = video
  const { mixins: { featuredMaxWidth } } = useTheme()
  const { theaterMode } = useContext(videoPlayerContext)

  const wrapperStyles = theaterMode
    ? {
      flexGrow: 1,
      alignSelf: 'center',
    }
    : {
      flexGrow: 1,
      maxWidth: featuredMaxWidth,
      marginLeft: 'auto',
      marginRight: 'auto',
    }

  return (
    <Box
      className={className}
      ref={ref}
      sx={wrapperStyles}
    >
      <Suspense fallback={<BusySpinner />}>
        {Boolean(description) && <ScreenReaderText>{description}</ScreenReaderText>}

        <VideoJsPlayer
          poster={posterUrl || poster}
          src={buildSources(video)}
          tracks={buildCC(ccUrl)}
          {...rest}
        />
      </Suspense>
    </Box>
  )
})

VideoPlayer.propTypes = {
  video: videoShape.isRequired,
}

const Styled = styled(
  VideoPlayer,
  { name: 'lib-VideoPlayer' },
)({ position: 'relative' })

export default Styled
