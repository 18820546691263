import PropTypes from 'prop-types'
import { RichUtils } from 'draft-js'
import { useCallback, useMemo, useRef, useState } from 'react'
import { getEntitiesForSelection, removeEntity } from '@studysync/draft-js-modifiers'
import { compose } from 'redux'
import { pick, renameKeys, set } from 'fp/objects'
import { curry } from 'fp/utils'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import AdvancedLinkDialog from '../plugins/linkPlugin/AdvancedLinkDialog'
import DraftMenuButton from './DraftMenuButton'
import DraftMenuItem from './DraftMenuItem'

const options = [
  { label: 'Insert Link', key: 'insert' },
  { label: 'Unlink', key: 'remove' },
]

const LinkMenuButton = (props) => {
  const { editorState, features, label, setEditorState, toggleLinkDialog, ...rest } = props

  const entities = useMemo(() => getEntitiesForSelection(editorState), [editorState])

  const handleClose = useRef()

  const [advancedDialogOpen, setAdvancedDialogOpen] = useState(false)
  const [currentLinkStub, setCurrentLinkStub] = useState(null)

  const menuItems = useMemo(() => {
    if (!features['links.advanced']) {
      return options.map((item) => {
        switch (item.key) {
          case 'remove':
            return set('disabled', !entities.length)(item)
          default:
            return item
        }
      })
    }

    const linkInteractiveStub = entities
      .find(({ entity }) => entity.getType() === 'LINK')
      ?.entity
      .getData()

    // eslint-disable-next-line react/no-unstable-nested-components, react/prop-types
    const AdvancedApplyLinkMenuItem = ({ handleClose: hc }) => {
      handleClose.current = hc
      return (
        <DraftMenuItem
          disabled={editorState.getSelection().isCollapsed()}
          onClick={() => {
            setCurrentLinkStub(null)
            setAdvancedDialogOpen(true)
          }}
        >
          Apply link
        </DraftMenuItem>
      )
    }

    // eslint-disable-next-line react/no-unstable-nested-components, react/prop-types
    const AdvancedEditLinkMenuItem = ({ handleClose: hc }) => {
      handleClose.current = hc
      return (
        <DraftMenuItem
          // you can only edit external links, which appear as interactives
          disabled={linkInteractiveStub?.contentType !== CONTENT_TYPE_INTERACTIVE}
          onClick={() => {
            setCurrentLinkStub(linkInteractiveStub)
            setAdvancedDialogOpen(true)
          }}
        >
          Edit link
        </DraftMenuItem>
      )
    }

    return [
      AdvancedApplyLinkMenuItem,
      AdvancedEditLinkMenuItem,
      { label: 'Unlink', key: 'remove', disabled: !linkInteractiveStub },
    ]
  }, [editorState, entities, features])

  /* istanbul ignore next */
  const handleChange = ({ key }) => {
    switch (key) {
      case 'insert':
        toggleLinkDialog()
        break
      case 'remove':
        entities.forEach((entity) => {
          const data = entity.entity.get('data')
          if (data.contentId) {
            /**
             * This assumes there is a one-to-one relationship between links and
             * their owner.  There currently is no way for things to be otherwise,
             * however, should that change then we'd want to check if there are
             * any other dependents prior to deletion.
             */
            // NOTE: holding off on deleting links for now
            // dispatch(actions.deleteContentById(data))
          }
          setEditorState(removeEntity(editorState, entity))
        })
        break
      /* istanbul ignore next */
      default:
    }
  }

  const handleInsert = useCallback((data) => {
    if (!data) { // we were editing -- just close the dlg here, the link was saved within the dlg
      setAdvancedDialogOpen(false)
      handleClose.current?.()
      return
    }

    const selection = editorState.getSelection()
    if (selection.isCollapsed()) {
      return
    }

    const contentState = editorState.getCurrentContent()
    contentState.createEntity(
      'LINK',
      'MUTABLE',
      compose(
        curry(renameKeys, 2, { id: 'contentId' }),
        pick('id', 'contentType'),
      )(data),
    )
    const entityKey = contentState.getLastCreatedEntityKey()
    const newEditorState = RichUtils.toggleLink(editorState, selection, entityKey)

    setEditorState(newEditorState)
    setAdvancedDialogOpen(false)

    handleClose.current?.()
  }, [editorState, setEditorState])

  return (
    <>

      <DraftMenuButton
        editorState={editorState}
        features={features}
        label={label}
        onChange={handleChange}
        options={menuItems}
        setEditorState={setEditorState}
        {...rest}
      />

      {Boolean(advancedDialogOpen) && (
        <AdvancedLinkDialog
          currentLinkStub={currentLinkStub}
          onClose={() => setAdvancedDialogOpen(false)}
          onComplete={handleInsert}
        />
      )}

    </>
  )
}

LinkMenuButton.propTypes = {
  editorState: PropTypes.object.isRequired,
  features: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  setEditorState: PropTypes.func.isRequired,
  toggleLinkDialog: PropTypes.func.isRequired,
}

export default LinkMenuButton
