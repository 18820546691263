import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import ColorSwatch from './ColorSwatch'

const Icon = styled(
  ArrowDropDown,
  { name: 'DropDownButton-Icon' },
)(({ theme: { palette } }) => ({
  fill: palette.grey[2],
  position: 'relative',
  left: -3,
}))

const Container = styled(
  Button,
  { name: 'DropDownButton-Container' },
)(({ theme: { mixins: { absHeight, rem }, palette } }) => ({
  margin: 0,
  padding: 0,
  ...absHeight(rem(2.5)),
  minWidth: 0,
  background: 'none',
  color: palette.text.primary,
  '&:hover': {
    background: 'none',
    color: 'unset',
  },
}))

const DropDownButton = ({
  disabled = false,
  includeLabels = false,
  onClick,
  ...rest
}) => {
  const Swatch = (
    <ColorSwatch
      disableAnimation
      hideLabel={!includeLabels}
      {...rest}
    />
  )

  return disabled ? (
    Swatch
  ) : (
    <Container
      data-testid="color-picker-button"
      onClick={onClick}
      variant="tertiary"
    >
      {Swatch}
      <Icon />
    </Container>
  )
}

DropDownButton.propTypes = {
  disabled: PropTypes.bool,
  includeLabels: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}
export default DropDownButton
