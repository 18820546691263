import IconButton from '@mui/material/IconButton'
import { X as Close } from 'react-feather'
import { useDispatch } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import Icon from '@mui/material/Icon'
import actionTypes from 'reducers/actionTypes'

const RemovePinButton = (props) => {
  const dispatch = useDispatch()
  const handleClick = () => dispatch({ type: actionTypes.ROUTE_REMOVE_PIN })

  return (
    <Tooltip
      placement="left-end"
      title="Close the pinned content"
    >
      <IconButton
        {...props}
        data-bodyvariant="body1"
        onClick={handleClick}
        size="large"
      >
        <Icon>
          <Close />
        </Icon>
      </IconButton>
    </Tooltip>
  )
}

export default RemovePinButton
