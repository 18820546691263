import withFormControl from 'common/formControls/selects/ConnectedMultiSelect/withFormControl'
import withProps from 'hoc/withProps'
import ConnectedVocabularyPicker from './ConnectedVocabularyPicker'

const VocabularyPicker = withFormControl(ConnectedVocabularyPicker)({
  createType: 'VOCAB',
  helperText: 'Type a phrase',
  label: 'Vocabulary',
  name: 'vocabulary',
})

export const ContentVocabPicker = withProps(VocabularyPicker, {
  label: 'Content Vocabulary',
  name: 'contentVocabPhrases',
  type: 'content',
  valueField: 'contentVocabPhraseIds',
})

export const AcademicVocabPicker = withProps(VocabularyPicker, {
  label: 'Academic Vocabulary',
  name: 'academicVocabPhrases',
  type: 'academic',
  valueField: 'academicVocabPhraseIds',
})

export const FootnoteVocabPicker = withProps(VocabularyPicker, {
  label: 'Footnotes',
  name: 'footnoteVocabPhrases',
  type: 'footnote',
  valueField: 'footnoteVocabPhraseIds',
})
