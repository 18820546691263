/* istanbul ignore file */

import { useCallback, useRef, useState } from 'react'
import { debounce } from 'fp/utils'

const useHoverFocus = (debounceAmount = 100) => {
  const [isFocused, setIsFocused] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const ref = useRef()

  const debouncedFocus = debounceAmount
    ? debounce(debounceAmount, setIsFocused)
    : setIsFocused
  const debouncedHover = debounceAmount
    ? debounce(debounceAmount, setIsHovered)
    : setIsHovered

  const handleFocus = useCallback(() => debouncedFocus(true), [debouncedFocus])
  const handleBlur = useCallback(() => debouncedFocus(false), [debouncedFocus])
  const handleMouseOver = useCallback(() => debouncedHover(true), [debouncedHover])
  const handleMouseOut = useCallback(() => debouncedHover(false), [debouncedHover])

  const callbackRef = useCallback(
    (node) => {
      if (ref.current) {
        ref.current.removeEventListener('focus', handleFocus)
        ref.current.removeEventListener('blur', handleBlur)
        ref.current.removeEventListener('mouseover', handleMouseOver)
        ref.current.removeEventListener('mouseout', handleMouseOut)
      }

      ref.current = node

      if (ref.current) {
        ref.current.addEventListener('focus', handleFocus)
        ref.current.addEventListener('blur', handleBlur)
        ref.current.addEventListener('mouseover', handleMouseOver)
        ref.current.addEventListener('mouseout', handleMouseOut)
      }
    },
    [handleFocus, handleBlur, handleMouseOver, handleMouseOut],
  )

  return [callbackRef, isHovered, isFocused]
}

export default useHoverFocus
