import { get } from 'fp/objects'
import { createSelector, stateCredits } from '.'

export const getCreditsFetchedStatus = createSelector('getCreditsFetchedStatus')(
  stateCredits,
  get('fetched'),
)

export const getCreditsData = createSelector('getCreditsData')(
  stateCredits,
  get('data'),
)
