import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const Centered = styled(
  Box,
  { name: 'layout-Centered' },
)({
  marginBottom: 0,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
})

export default Centered
