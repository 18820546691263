// istanbul ignore file
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { useCallback, useEffect } from 'react'
import { getLocalSetting } from 'selectors/localSettings'
import { curry, fallbackTo, isDefined, isUndefined } from 'fp/utils'
import { localSettingsActions } from 'reducers/localSettings'
import useCurrentUser from './useCurrentUser'

const useLocalSetting = (key, initialValue) => {
  if (isUndefined(key)) throw new Error('useLocalSetting requires a key')
  const dispatch = useDispatch()

  /**
   * We may or may not be within a Redux context here so we can't rely on
   * useSelector to get the current user.  Instead, we'll try to get the
   * current user from the useCurrentUser hook, and if that fails we'll
   * fallback to 'global'.
   *
   * This mostly is to account for video-js which mounts things like the
   * SettingsButton outside of the Redux context, via mountIndeterminateComponent.
   */

  let rootKey = 'global'
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    rootKey = useCurrentUser()?.user?.id || 'global'
  } catch (E) { /* empty */ }

  const reduxValue = useSelector(getLocalSetting(key))
  const value = fallbackTo(initialValue)(reduxValue)

  const setter = useCallback(
    (v) => {
      compose(
        dispatch,
        curry(localSettingsActions.set, 3, rootKey, key),
      )(v)
    },
    [dispatch, key, rootKey],
  )

  useEffect(() => {
    if (isUndefined(reduxValue && isDefined(value))) {
      setter(value)
    }
  }, [reduxValue, setter, value])

  return [value, setter]
}

export default useLocalSetting
