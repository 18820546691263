import { Outlet } from 'react-router-dom'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import AccentColorProvider from 'styling/theming/AccentColorProvider'
import ContainerQuery from 'common/layout/ContainerQuery'

const MainViewport = () => (
  <ErrorBoundary moduleName="ContentViewer-MainViewport">

    <AccentColorProvider accentColor="teal">

      <ContainerQuery>

        <Outlet />

      </ContainerQuery>

    </AccentColorProvider>

  </ErrorBoundary>
)
export default MainViewport
