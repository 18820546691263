const overrides = () => ({
  MuiCardMedia: {
    variants: [
      {
        props: { variant: 'square' },
        style: { aspectRatio: '1/1' },
      },
    ],
  },
})

export default overrides
