import { ChevronDown } from 'react-feather'

const overrides = ({ mixins: { important }, palette, typography }) => ({
  MuiSelect: {
    defaultProps: {
      IconComponent: ChevronDown,
    },

    styleOverrides: {
      icon: {
        color: palette.grey[0],
      },

      iconFilled: {
        right: 12,
        top: 12,
      },

      iconOutlined: {
        right: 12,
        top: 12,
      },

      iconStandard: {
        right: 10,
        top: 5,
      },

      select: {
        minWidth: 100,
        '&:focus-within': {
          backgroundColor: 'inherit',
        },
      },
    },

    variants: [{
      props: { 'data-variant': 'collapsed-tablist' },
      style: {
        ...typography.variants['tab-title'],
        '.MuiSelect-select': {
          borderBottomColor: important(palette.grey[4]),
        },
        borderBottomColor: important(palette.grey[4]),
      },
    }],
  },
})

export default overrides
