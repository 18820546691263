import { createReducer, listReducerInitialState, updateLoaded } from './utils'
import actionTypes from './actionTypes'

const handleRankingReceived = (state, { payload }) => updateLoaded(state, payload)

const echoRanking = createReducer(listReducerInitialState(), {
  [actionTypes.SOCKET_INTERACTION_RANKING_RECEIVED]: handleRankingReceived,
})

export default echoRanking
