import { Outlet } from 'react-router-dom'
import ContainerQuery from 'common/layout/ContainerQuery'
import Banner from './Banner'

const ClassroomNavShell = () => (
  <>

    <Banner />

    <ContainerQuery>
      <Outlet />
    </ContainerQuery>

  </>
)

export default ClassroomNavShell
