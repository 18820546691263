import cl from 'classnames'
import { compose } from 'redux'
import { get, omit } from 'fp/objects'
import useAccentColor from 'hooks/useAccentColor'
import { firefox, px } from 'styling/theming/base/mixins'

const withColorSwatch = (WrappedComponent) => {
  const Enhanced = ({ className, ...rest }) => {
    const accentColor = useAccentColor()

    return (
      <WrappedComponent
        className={cl(className, 'tr-typography', 'container-swatch')}
        sx={{
          ...compose(omit('&::before'), get('sx'))(rest),
          '&::before': {
            backgroundColor: accentColor[0],
            ...firefox({ top: px(-7.5) }),
            ...get('sx.&::before')(rest),
          },
        }}
        {...omit('sx')(rest)}
      />
    )
  }

  return Enhanced
}

export default withColorSwatch
