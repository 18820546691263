import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'reducers/groups'
import { getListedGroups } from 'selectors/groups'
import useEffectOnce from './useEffectOnce'

const useGroups = () => {
  const dispatch = useDispatch()

  useEffectOnce(() => {
    dispatch(actions.fetchGroups())
  }, [dispatch])

  return useSelector(getListedGroups)
}

export default useGroups
