import { createContext, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { componentShape, reduxCallbackStatusShape } from 'core/shapes'
import { set } from 'fp/objects'
import { identity } from 'fp/utils'
import useIsMounted from 'hooks/useIsMounted'

const additionalContext = createContext()

const AdditionalContextProvider = (props) => {
  const {
    children,
    initialStateForTests,
    ...rest
  } = props
  const isMounted = useIsMounted()
  const [disabled, setDisabled] = useState(false)
  const [prettyNames, setPrettyNames] = useState({})
  const [forcedDirty, setForcedDirty] = useState(false)
  const [requiredFields, setRequiredFields] = useState({})
  const [mandatoryFields, setMandatoryFields] = useState({})
  const [hasRequiredFields, setHasRequiredFields] = useState(false)
  const [hasMandatoryFields, setHasMandatoryFields] = useState(false)
  const [suppressNextDirtyNavigationWarning, setSuppressNextDirtyNavigationWarning] = useState(false)
  const [requiredFieldsAreMandatory, setRequiredFieldsAreMandatory] = useState(true)

  useEffect(() => {
    if (isMounted) {
      setHasRequiredFields(Object.values(requiredFields).some(identity))
      setHasMandatoryFields(Object.values(mandatoryFields).some(identity))
    }
  }, [isMounted, requiredFields, mandatoryFields])

  const value = useMemo(() => {
    const registerRequiredField = (name, required) => {
      /* istanbul ignore else */
      if (isMounted) {
        setRequiredFields(set(name, required, true))
      }
    }

    const registerMandatoryField = (name, mandatory) => {
      /* istanbul ignore else */
      if (isMounted) {
        setMandatoryFields(set(name, mandatory, true))
      }
    }

    const registerPrettyName = (name, prettyName) => {
      /* istanbul ignore else */
      if (isMounted) {
        setPrettyNames(set(name, prettyName, true))
      }
    }

    return {
      ...rest,
      disabled,
      forcedDirty,
      hasMandatoryFields,
      hasRequiredFields,
      prettyNames,
      registerMandatoryField,
      registerPrettyName,
      registerRequiredField,
      requiredFieldsAreMandatory,
      setDisabled,
      setForcedDirty,
      setSuppressNextDirtyNavigationWarning,
      suppressNextDirtyNavigationWarning,
      setRequiredFieldsAreMandatory,
      ...initialStateForTests,
    }
  }, [
    disabled,
    forcedDirty,
    hasMandatoryFields,
    hasRequiredFields,
    initialStateForTests,
    isMounted,
    prettyNames,
    requiredFieldsAreMandatory,
    rest,
    suppressNextDirtyNavigationWarning,
  ])

  return (
    <additionalContext.Provider value={value}>
      {children}
    </additionalContext.Provider>
  )
}

AdditionalContextProvider.propTypes = {
  actionType: PropTypes.string,
  children: componentShape.isRequired,
  initialStateForTests: PropTypes.object,
  status: reduxCallbackStatusShape.isRequired,
}

export { additionalContext, AdditionalContextProvider }
