import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { useRef } from 'react'
import { uploadTypeShape } from 'core/shapes'
import AppBusy from 'common/indicators/AppBusy'
import { BUSY } from 'hooks/useReduxCallback'
import useFileUpload from 'hooks/useFileUpload'
import { acceptMap, openFileDialog } from './utils'

const FileUploader = ({
  disabled = false,
  isAudioDescription = false,
  onUploadSuccess,
  uploadType,
}) => {
  const inputRef = useRef(null)

  const { startUpload, status } = useFileUpload({ onUploadSuccess })

  const handleChange = (fileList) => {
    startUpload({ file: fileList[0], isAudioDescription, uploadType })
  }

  const onInputChange = async ({ target }) => {
    await handleChange(target.files)
    if (inputRef.current) inputRef.current.value = ''
  }

  const handleClick = () => {
    openFileDialog(inputRef)
  }

  return (
    <>
      <input
        accept={acceptMap[uploadType]}
        disabled={disabled}
        onChange={onInputChange}
        ref={inputRef}
        style={{ display: 'none' }}
        type="file"
      />

      <Button
        onClick={handleClick}
        size="small"
        sx={{ textTransform: 'none' }}
        variant="secondary"
      >
        Browse file system...
      </Button>

      <AppBusy open={status === BUSY} />
    </>
  )
}

FileUploader.propTypes = {
  disabled: PropTypes.bool,
  isAudioDescription: PropTypes.bool,
  onUploadSuccess: PropTypes.func,
  uploadType: uploadTypeShape.isRequired,
}

export default FileUploader
