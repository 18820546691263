import { position } from 'polished'

const plugin = ({
  mixins: { borderS, important, rem, transition },
  palette,
  zIndex,
}) => {
  const highlight = palette.cobalt[2]

  return {
    '.drag-hover': { // Class for dragging/dropping
      backgroundColor: important(highlight),
    },

    svg: {
      strokeWidth: 1.3,
      ...transition('opacity'),
    },

    'svg[viewBox="0 0 24 24"]': {
      path: {
        strokeWidth: important(1.3),
        ...transition('opacity'),
      },
    },

    details: {
      ...borderS(palette.border[0]),
      cursor: 'pointer',
      padding: rem(1),
      '&[open]': {
        summary: {
          marginBottom: rem(2),
        },
      },
    },

    '.expandable': {
      marginBottom: rem(3),
      '&.expanded': {
        ...position('fixed', 0, 0, null, 0),
        zIndex: zIndex.modal,
      },
    },
  }
}

export default plugin
