import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { getDecoratedUser } from 'selectors/users'

const useCurrentUser = () => {
  const mapState = useCallback(
    getDecoratedUser,
    [],
  )

  return useSelector(mapState)
}

export default useCurrentUser
