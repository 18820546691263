import PropTypes from 'prop-types'
import { useContext } from 'react'
import ScreenReaderText from 'common/text/ScreenReaderText'
import { msToTime, msToTimeText } from 'locale/i18n'
import { audioContext } from './AudioContextProvider'

const CurrentTime = () => {
  const { duration, loaded, position } = useContext(audioContext)

  return loaded
    ? (
      <>
        <ScreenReaderText>
          Elapsed: {msToTimeText(position)} of {msToTimeText(duration)}
        </ScreenReaderText>
        {msToTime(position)}
      </>
    ) : '00:00'
}

const TimeLabel = ({ showCurrentTime = false }) => {
  const { duration } = useContext(audioContext)

  return showCurrentTime
    ? <CurrentTime />
    : msToTime(duration)
}

TimeLabel.propTypes = {
  showCurrentTime: PropTypes.bool,
}

export default TimeLabel
