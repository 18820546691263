import { Routes } from 'react-router-dom'
import { mapValues, set } from 'fp/objects'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import { configToRoutes } from 'routing/TraverseRoute'
import { ABILITY_ASSESSMENT_INTERFACE, ABILITY_TEACHER_INTERFACE } from 'core/consts'

const config = mapValues(set('abilityFlags', [
  ABILITY_ASSESSMENT_INTERFACE,
  ABILITY_TEACHER_INTERFACE,
]))({

  assessments: {
    element: null,
    path: '/',
  },

})

const routes = configToRoutes(config)

const AssessmentLegacyRoutes = () => (
  <ErrorBoundary moduleName="AssessmentLegacyRoutes">

    <Routes>

      {routes}

    </Routes>

  </ErrorBoundary>
)

export default AssessmentLegacyRoutes
