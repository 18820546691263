import TablePagination from '@mui/material/TablePagination'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { stateRouter } from 'selectors/index'
import { squeryShape } from 'core/shapes'
import { alter, inspect } from 'core/store/search/squery'
import { dedupe } from 'fp/arrays'
import { get } from 'fp/objects'
import { scrollIntoView } from 'fp/dom'
import { when } from 'fp/utils'
import usePaginationLimit from './usePaginationLimit'

const defaultRowsPerPage = [10, 25, 50, 100]
const numericCompare = (a, b) => a - b

const Pagination = (props) => {
  const {
    dataset,
    displayWhenEmpty = false,
    scrollToResultsRef,
    setSquery,
    squery,
    tableId,
    ...rest
  } = props

  const count = get('metadata.count')(dataset) || 0
  const offset = inspect(squery).get.offset()
  const limit = inspect(squery).get.limit()
  const [, setLimit] = usePaginationLimit(tableId)
  const backButtonGotUsHere = (({ action }) => action === 'POP')(useSelector(stateRouter))

  const rowsPerPageOptions = dedupe([limit, ...defaultRowsPerPage].sort(numericCompare))

  const handlePageChange = (e, page) => {
    setSquery(alter.set.offset(page * limit)(squery))
  }

  const handleRowsPerPageChange = ({ target }) => {
    setSquery(alter.set.limit(target.value)(squery))
    when(tableId, setLimit, target.value)
  }

  useEffect(() => {
    /**
     * The page can pass in a ref to the results table/list/whatever.
     * If present, we scroll back to the top of the list whenever the squery changes.
     * If the user is clicking the 'Back' button (action is 'POP'), we do not need to scroll to the top.
     */
    when(!backButtonGotUsHere, scrollIntoView, scrollToResultsRef?.current)
  }, [scrollToResultsRef, squery, backButtonGotUsHere])

  return (
    dataset
      && (displayWhenEmpty || count)
      ? (
        <TablePagination
          component="div"
          {...rest}
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={offset / (limit > 0 ? limit : 1)}
          rowsPerPage={limit}
          rowsPerPageOptions={rowsPerPageOptions}
        >
          Pagination
        </TablePagination>
      )
      : null
  )
}

Pagination.propTypes = {
  dataset: PropTypes.object,
  displayWhenEmpty: PropTypes.bool,
  scrollToResultsRef: PropTypes.object,
  setSquery: PropTypes.func.isRequired,
  squery: squeryShape.isRequired,
  tableId: PropTypes.string,
}

export default Pagination
