import Typography from '@mui/material/Typography'
import { componentShape } from 'core/shapes'

const DiagonalColumnHeader = ({ children, ...rest }) => (
  <th
    className="diagonal"
    scope="col"
    {...rest}
  >
    <Typography
      component="div"
      fontWeight="400"
      letterSpacing="0"
      variant="body1"
    >
      <div className="skewed-container">
        <div className="rotated-content">
          {children}
        </div>
      </div>
    </Typography>

  </th>
)

DiagonalColumnHeader.propTypes = {
  children: componentShape.isRequired,
}

export default DiagonalColumnHeader
