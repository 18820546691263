import SkipToButton from './SkipToButton'

/**
 * This button is normally hidden, but will appear when focused.  It's the first
 * focusable item on the page so the idea is that on a freshly loaded page,
 * tab-shifting focuses this button and allows the user to skip all the nav and
 * instead go straight to the main content of the page.
 *
 * Notes:
 *  1) It's a button and not an anchor so that it doesn't affect browser history.
 *  2) We may or may not want it to skip the top header stuff (breadcrumbs, user menu, etc)
 *  3) It works within both shells.
 *  4) I suspect we'll want an "eye in the sky" focus provider to step in and
 *     manage autofocus along with this "skip navigation" action, scrolling to
 *     the top of the page post navigation, and all other focus related items.
 *
 * Consider this just clay on the table for now.
 */

const SkipToMainContent = () => (
  <SkipToButton
    findTarget={() => document.getElementsByTagName('main')[0]}
    placement="top"
  >
    Skip to content
  </SkipToButton>
)

export default SkipToMainContent
