import { all } from 'redux-saga/effects'
import apiSaga from './api'
import assignmentSaga from './assignment'
import backendSocketSaga from './backendSocket'
import cantoIntegrationSaga from './cantoIntegration'
import contentInsertionsSaga from './contentInsertions'
import contentRestrictionsSaga from './contentRestrictions'
import contentSaga from './content'
import districtSaga from './district'
import echoSaga from './echo'
import gdriveIntegrationSaga from './gdriveIntegration'
import globalAppSaga from './globalApp'
import groupSaga from './group'
import interactionSaga from './interaction'
import localSettingsSaga from './localSettings'
import reactionSaga from './reaction'
import routingSaga from './routing'
import schoolSaga from './school'
import sessionSaga from './session'
import tagSaga from './tag'
import uploadSaga from './upload'
import userAlertSaga from './userAlert'
import userAssignmentSaga from './userAssignment'
import userSaga from './user'
import vocabSaga from './vocab'
import creditsSaga from './credits'

export default function* rootSaga() {
  yield all([
    apiSaga(),
    assignmentSaga(),
    backendSocketSaga(),
    cantoIntegrationSaga(),
    contentInsertionsSaga(),
    contentRestrictionsSaga(),
    contentSaga(),
    creditsSaga(),
    districtSaga(),
    echoSaga(),
    gdriveIntegrationSaga(),
    globalAppSaga(),
    groupSaga(),
    interactionSaga(),
    localSettingsSaga(),
    reactionSaga(),
    routingSaga(),
    schoolSaga(),
    sessionSaga(),
    tagSaga(),
    uploadSaga(),
    userAlertSaga(),
    userAssignmentSaga(),
    userSaga(),
    vocabSaga(),
  ])
}
