import { Outlet } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { useContext } from 'react'
import { styled } from '@mui/material/styles'
import ContainerQuery from 'common/layout/ContainerQuery'
import ContactFormDialog from 'hss/sections/Home/ContactFormDialog'
import InterruptUserAlertsDialog from 'hss/sections/Home/userAlertDialogs/InterruptUserAlertsDialog'
import HomeNav from './HomeNav'
import UserMenu from './Banner/UserMenu'
import { navDialogsContext } from './NavDialogsProvider'
import ProfileUserAlertsMenu from './Banner/ProfileUserAlertsMenu'

const StyledToolbar = styled(
  Toolbar,
  { name: 'ResponsiveToolbar' },
)(({ theme: { breakpoints } }) => ({
  [breakpoints.down('sm')]: {
    flexDirection: 'row-reverse',
  },
}))

const HomeNavShell = () => {
  const {
    contactFormDialogIsOpen: showContactForm,
    toggleContactFormDialogIsOpen,
  } = useContext(navDialogsContext)

  return (
    <>
      <AppBar position="sticky">
        <StyledToolbar variant="home">
          <HomeNav />
          <ProfileUserAlertsMenu />
          <div style={{ display: 'flex' }}>
            <UserMenu />
          </div>
        </StyledToolbar>
      </AppBar>
      <ContainerQuery>
        <Outlet />
      </ContainerQuery>
      {Boolean(showContactForm) && (
        <ContactFormDialog onClose={toggleContactFormDialogIsOpen} />
      )}

      <InterruptUserAlertsDialog />
    </>
  )
}

export default HomeNavShell
