import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { forwardRef, useEffect, useState } from 'react'
import AppBusy from 'common/indicators/AppBusy'
import BusySpinner from 'common/indicators/BusySpinner'
import useContent from 'hooks/useContent'
import { toInt } from 'fp/strings'
import { getContentViewerParams } from 'selectors/contentViewerParams'
import { isContentLoaded } from 'selectors/content'
import { filterKeyedObject } from 'fp/objects'
import { isDefined, not } from 'fp/utils'
import useIsPinnedContent from 'hooks/useIsPinnedContent'
import { CONTENT_TYPE_ECHO, CONTENT_TYPE_SOURCE } from 'core/consts'

const withContent = (WrappedComponent, options = {}) => {
  const Enhanced = forwardRef(({
    busy: origBusy = 'local',
    editing = false,
    queryParams,
    ...rest
  }, ref) => {
    const routeParams = useParams()
    const matchParams = useSelector(getContentViewerParams())
    const isPinned = useIsPinnedContent()
    const [refreshed, setRefreshed] = useState(false)
    const params = isPinned ? routeParams : { ...routeParams, ...matchParams }

    const busy = options.busy || origBusy
    const passedContentType = rest.contentType || options.contentType || params.contentType
    const contentId = rest.contentId || params.contentId || options.contentId

    const content = useContent(filterKeyedObject({
      contentType: passedContentType,
      contentId,
      disableFetch: options.disableFetch,
      queryParams: options.queryParams || queryParams,
      refresh: options.forceRefresh && !refreshed,
    }, isDefined))
    const { contentType = passedContentType } = content || {}

    const isLoaded = useSelector(isContentLoaded({ contentType, contentId }))

    useEffect(() => {
      if (options.forceRefresh && !refreshed) {
        /**
         * Using `refreshed` state to make sure `options.forceRefresh` only
         * applies the first time through.
         */
        setRefreshed(true)
      }
    }, [refreshed])

    const returnable = isLoaded
      // echo and source should wait for content to be created before loading form
      || (Number.isNaN(toInt(contentId)) && not([CONTENT_TYPE_ECHO, CONTENT_TYPE_SOURCE].includes(contentType)))
      || toInt(contentId) === 0

    if (returnable) {
      return (
        <WrappedComponent
          {...{ busy: origBusy, content, contentType, editing, ref }}
          {...rest}
        />
      )
    }

    if (busy === 'app') return <AppBusy />

    if (busy === 'local') {
      return (
        <BusySpinner
          mt={10}
          segments={11}
          size={64}
        />
      )
    }

    return null
  })

  Enhanced.propTypes = {
    busy: PropTypes.oneOf([
      'app',
      'local',
      'silent',
    ]),
    editing: PropTypes.bool,
    queryParams: PropTypes.object,
  }

  return Enhanced
}

export default withContent
