import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

const BusySpinner = ({
  color,
  segments = 9,
  size = 24,
  ...rest
}) => (
  <Box
    data-testid="BusySpinner"
    position="relative"
    segments={segments}
    size={size}
    style={{
      transform: 'translateX(50%)',
    }}
    {...rest}
  >
    <div
      style={{
        pointerEvents: 'none',
      }}
    >
      <CircularProgress />
    </div>
  </Box>
)

BusySpinner.propTypes = {
  color: PropTypes.string,
  segments: PropTypes.number,
  size: PropTypes.number,
}

export default BusySpinner
