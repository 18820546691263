import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import Popover from '@mui/material/Popover'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import { isDefined, matches } from 'fp/utils'
import SwatchSelect from '../SwatchSelect'
import { colorSwatchShape } from './ColorSwatch'
import DropDownButton from './DropDownButton'
import MenuContents from './MenuContents'

const renderSwatch = ({ color, size }) => (
  <Box
    backgroundColor={color}
    height={size}
    width={size}
  />
)

const ColorPicker = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const {
    colorId = 1,
    colors = [],
    disabled = false,
    includeLabels = false,
    name = 'colorPicker',
    onChange,
    variant = 'popper',
    ...rest
  } = props

  const handleOpen = ({ currentTarget }) => {
    setAnchorEl(currentTarget)
  }

  const handleClose = (value) => {
    setAnchorEl(null)
    if (isDefined(value?.colorId)) {
      onChange(value)
    }
  }

  const selectedSwatch = useMemo(
    () => colors.find(matches('colorId', colorId)) || colors[0],
    [colors, colorId],
  )

  return (variant === 'select') && colors.length
    ? (
      <SwatchSelect
        {...{ ...rest,
          disabled,
          name,
          onChange,
          renderSwatch }}
        value={colorId}
      >
        {colors.map(item => (
          <MenuItem
            color={item.value}
            key={item.colorId}
            value={item.colorId}
          >
            {item.label}
          </MenuItem>
        ))}
      </SwatchSelect>
    )
    : (
      <>
        <DropDownButton
          aria-expanded={Boolean(anchorEl)}
          onClick={handleOpen}
          swatch={selectedSwatch}
          {...{ disabled, includeLabels }}
        />

        <Popover
          anchorEl={anchorEl}
          onClose={handleClose}
          open={Boolean(anchorEl)}
        >
          <MenuContents {...{ colors, handleClose }} />
        </Popover>
      </>
    )
}

ColorPicker.propTypes = {
  colors: PropTypes.arrayOf(colorSwatchShape),
  colorId: PropTypes.number,
  disabled: PropTypes.bool,
  includeLabels: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['popper', 'select']),
}

export default ColorPicker
