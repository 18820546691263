import { useSelector } from 'react-redux'
import { produce } from 'immer'
import { useCallback, useRef } from 'react'
import compare from 'just-compare'
import { useParams } from 'react-router-dom'
import { compose } from 'redux'
import {
  INTERACTION_STATE_COMPLETED,
  INTERACTION_STATE_OPEN,
  INTERACTION_STATE_TEACHER_SUBMITTING,
  INTERACTION_TYPE_INTERACTIVE,
  SECTION_CONTENT_TYPES,
} from 'core/consts'
import actionTypes from 'reducers/actionTypes'
import { getParentOfContentByType } from 'selectors/contentViewer'
import { getLatestUserInteraction } from 'selectors/interactions'
import { fallbackTo, isFunction } from 'fp/utils'
import { get, merge, set } from 'fp/objects'
import useSelectedUserAssignmentId from 'hss/sections/contentBlocks/Interactive/useSelectedUserAssignmentId'
import { getUserAssignmentById } from 'selectors/userAssignments'
import useReduxCallback from './useReduxCallback'
import { useDeepCompareMemo } from './useDeepCompare'

const postAction = actionTypes.INTERACTION_POST

/**
 * Arguments are broken out into two sections:
 *
 * contentQuery: Contains the information necessary to identify the interaction,
 *               and the content to apply it to
 *
 * baseState:    Pass any properties here that the interaction should always have by
 *               default.  This is most useful for setting up the structure of
 *               `interactionData` and `scoreData`
 *
 *
 * The object outputted by this hook has the following members:
 *
 * interaction:     Contains the complete interaction returned by the server, or in
 *                  the case of new interactions, just the base required fields.
 *                  In either case, anything passed in `baseState` is used as the
 *                  basis, with the interaction itself overlaid
 *
 * saveInteraction: A method to update the interaction.  Note that this will be
 *                  debounced by the interaction saga.  If you need realtime updates
 *                  between `saveInteraction` and the `interaction` returned from Redux,
 *                  then you should use local state to hold changes.
 *                  This method only makes calls to the API if the interaction has
 *                  actually changed
 *
 * transportStatus: Use this if you need insight into any network calls caused by
 *                  `saveInteraction`.  The values returned are the same defined in
 *                  `src/hooks/useReduxCallback.js`
 *
 */

const useInteraction = (contentQuery, baseState = {}) => {
  const {
    contentId,
    contentType,
  } = contentQuery

  /**
   * This ref is used to track generated ids when creating new interactions.
   * It will start off as undefined, but will then be set to the id of the
   * interaction after the first successful post.  If an id is present within
   * `interactionPrime`, then that will be used instead.  They eventually all
   * sync-up within the memoized `interaction`.
   */
  const interactionId = useRef()

  const onSuccess = ({ response: { id } }) => { interactionId.current = id }

  const [recordInteraction, status] = useReduxCallback({
    actionType: postAction,
    allowParallel: true,
    onSuccess,
  })

  const { id: contextContentId } = useSelector(getParentOfContentByType({
    contentId,
    contentType,
    parentContentType: SECTION_CONTENT_TYPES,
  })) || /* istanbul ignore next */ {}

  // For teacher grading
  const selectedUserAssignmentId = useSelectedUserAssignmentId()
  const selectedUserId = compose(
    get('userId'),
    fallbackTo({}),
    useSelector,
    getUserAssignmentById,
  )({ userAssignmentId: selectedUserAssignmentId })

  const { userAssignmentId = 0 } = useParams() // required by backend, zero is considered null

  const interactionPrime = useSelector(getLatestUserInteraction({
    contentId,
    contextContentId,
    interactionType: 'interactive',
    interactionUserId: selectedUserId,
    userAssignmentId: selectedUserAssignmentId || userAssignmentId,
  }))

  const interaction = useDeepCompareMemo(
    () => ({
    // Assert required fields
      interactionData: {},
      interactionSubType: null,
      interactionType: INTERACTION_TYPE_INTERACTIVE,
      score: null,
      scoreData: {
        rubricSelections: [],
      },
      state: INTERACTION_STATE_OPEN,
      userAssignmentId: selectedUserAssignmentId || userAssignmentId,
      userId: selectedUserId,

      // mix in the base state
      ...baseState,

      // mix in whatever we have from redux
      ...interactionPrime,

      // apply these last so they cannot be changed by baseState or interactionPrime
      contentId,
      contextContentId,

    }),
    [
      baseState,
      contentId,
      contextContentId,
      interactionPrime,
      selectedUserAssignmentId,
      selectedUserId,
      userAssignmentId,
    ],
  )

  const saveInteraction = useCallback((fn) => {
    const updated = set('id', interactionPrime?.id || interactionId.current)(isFunction(fn)
      ? produce(interaction, fn)
      : merge(interaction, { interactionData: fn }))

    if (!compare(updated, interaction)) {
      recordInteraction(updated)
    }
  }, [interaction, interactionId, interactionPrime?.id, recordInteraction])

  const setInteractionState = useCallback((newState, { suppressAlert = false } = {}) => {
    recordInteraction({
      ...interaction,
      scoreData: newState === INTERACTION_STATE_OPEN ? {} : interaction.scoreData,
      state: newState,
      suppressAlert,
    })
  }, [interaction, recordInteraction])

  const markComplete = useCallback((opts) => {
    setInteractionState(
      selectedUserId
        ? INTERACTION_STATE_TEACHER_SUBMITTING
        : INTERACTION_STATE_COMPLETED,
      opts,
    )
  }, [setInteractionState, selectedUserId])

  const markIncomplete = useCallback(
    (opts) => {
      setInteractionState(INTERACTION_STATE_OPEN, opts)
    },
    [setInteractionState],
  )

  return {
    interaction,
    markComplete,
    markIncomplete,
    saveInteraction,
    transportStatus: status,
  }
}

export default useInteraction
