import MenuItem from '@mui/material/MenuItem'
import { useDispatch } from 'react-redux'
import { useContext } from 'react'
import actionTypes from 'reducers/actionTypes'
import { CONTENT_TYPE_ECHO } from 'core/consts'
import { assignmentEditContext } from 'hss/AssignmentEditor/assignmentEditContext'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'

const InsertPinMenuItem = () => {
  const dispatch = useDispatch()
  const { subsection } = useContext(contentViewerContext)
  const isEditingAssignment = Boolean(useContext(assignmentEditContext))
  const { contentType } = subsection || {}

  const pinnable = (contentType !== CONTENT_TYPE_ECHO) && !isEditingAssignment

  const handleClick = () => dispatch({ type: actionTypes.ROUTE_PIN_CURRENT_PAGE })

  return pinnable
    ? (
      <MenuItem onClick={handleClick}>
        Pin to Right
      </MenuItem>
    )
    : null
}

export default InsertPinMenuItem
