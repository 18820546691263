import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import { componentShape } from 'core/shapes'
import Headline from 'common/text/Headline'

const FormSection = ({ children, title }) => (
  <Box
    my={4}
    pb={4}
  >
    <Headline
      size={3}
      title={title}
    >
      {children}
    </Headline>
  </Box>
)

FormSection.propTypes = {
  children: componentShape.isRequired,
  title: PropTypes.string.isRequired,
}

export default FormSection
