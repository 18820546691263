import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import { colorSwatchShape } from '../ColorPicker/ColorSwatch'

const swatchRadius = 12

const Icon = props => (
  <svg
    {...props}
    viewBox={`-1 -1 ${swatchRadius * 2.2} ${swatchRadius * 2.2}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={`M0,0 L${swatchRadius * 2},0 L${swatchRadius * 2},${swatchRadius * 2} L0,${swatchRadius * 2} Z`}
      strokeWidth={2}
    />
  </svg>
)

const ColorSwatch = styled(
  ({ className, color: { colorId, label } }) => (
    <Tooltip title={label}>
      <FormControlLabel
        className={className}
        control={(
          <Radio
            checkedIcon={<Icon className="selected" />}
            icon={<Icon />}
          />
        )}
        label={label}
        value={colorId}
      />
    </Tooltip>
  ),
  { name: 'ColorSwatch-Base' },
)(({
  color: { value },
  selected = false,
  theme: { mixins: { important, transition }, palette },
}) => ({
  ...transition('transform', 150),
  margin: 0,
  transform: 'scale(1)',
  '.MuiFormControlLabel-label': {
    display: 'none',
  },

  '&:hover': selected ? {} : { transform: 'scale(1.2)' },

  svg: {
    width: 32,
    padding: 0,
    path: {
      fill: important(value),
      color: important(value),
    },
    '&.selected': {
      stroke: palette.text.primary,
      strokeWidth: 2,
    },
  },

  '.MuiRadio-root': {
    padding: 0,
    width: 32,
  },
}))

ColorSwatch.propTypes = {
  color: colorSwatchShape.isRequired,
  selected: PropTypes.bool,
}

export default ColorSwatch
