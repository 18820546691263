import { produce } from 'immer'
import { deepMerge, get, notEquals, omit, set, shallowClone } from 'fp/objects'
import { dedupe, orderBy } from 'fp/arrays'
import { curryRight, isDefined, pipe } from 'fp/utils'

export const restEndpoint = {
  assignments: '/assignments',
  bankAssets: '/bank-assets',
  contactForm: '/contact-form',
  content: '/content',
  contentInsertions: '/content-insertions',
  contentRestrictions: '/content-restrictions',
  credits: '/credits',
  districts: '/districts',
  groups: '/groups',
  interactions: '/interactions',
  metadata: '/metadata',
  private: '/private',
  reactions: '/reactions',
  schools: '/schools',
  sessions: '/sessions',
  students: '/students',
  sysadmin: '/sysadmin',
  uploads: '/uploads',
  userAlerts: '/user-alerts',
  userAssignments: '/user-assignments',
  users: '/users',
}

export const createReducer = (initialState, handlers) => (state, action) => Object
  .prototype
  .hasOwnProperty
  .call(handlers, action.type)
  // NOTE: external middleware reducers (such as router) will not appear to be sorted
  ? handlers[action.type](state, action)
  : state || initialState

// *********************** Standard list handling routines *********************

export const listReducerInitialState = () => ({
  fetching: [],
  listed: [],
  loaded: {},
  metadata: null,
})

export const handleFetchListSuccess = (state, { response: { data, metadata, preserveListed } }, options = {}) => {
  const listed = data.map(get('id'))
  const { merge = false } = options

  return ({
    ...state,
    ...data.reduce((acc, item) => ({ ...acc, [item.id]: merge ? deepMerge(state[item.id] || {}, item) : item }), {}),
    metadata,
    // maintain existing ids (keeping natural sort order) when paginating
    listed: (preserveListed || metadata.offset)
      ? [...state.listed, ...listed]
      : listed,
    loaded: omit(listed)(state.loaded),
  })
}

export const handleTableCellFieldChange = forReducer => (state, action) => {
  const {
    id,
    newValue,
    reducer,
    valueField,
  } = action

  return reducer === forReducer
    ? set(`${id}.${valueField}`, newValue)(state)
    : state
}

export const handleTableCellFieldRollback = forReducer => (state, { response, passThrough: { action } }) => {
  const {
    id,
    reducer,
    valueField,
  } = action

  return reducer === forReducer
    ? set(`${id}.${valueField}`, get(valueField)(response))(state)
    : state
}

export const updateFetching = id => state => set(
  'fetching',
  dedupe([...(state.fetching || []), id]),
)(state)

export const updateNotFetching = (id, cloneState = true) => (state) => {
  const curriedSet = curryRight(set, undefined, undefined, undefined, cloneState)

  return isDefined(state.fetching)
    ? curriedSet('fetching', state.fetching.filter(notEquals(id)))(state)
    : state
}

export const updateLoaded = (state, item, ancillary = {}, options = {}) => {
  const { cloneState = true, merge = false } = options

  const curriedSet = curryRight(set, undefined, undefined, undefined, cloneState)

  return item
    ? pipe(
      curriedSet(item.id, merge ? deepMerge(state[item.id] || {}, item) : item),
      curriedSet('loaded', curriedSet(item.id, ancillary)(state.loaded)),
      updateNotFetching(item.id, cloneState),
    )(state)
    : state
}

export const bulkUpdateLoaded = (state, items, ancillary = {}, options = {}) => {
  let newState = shallowClone(state)
  newState.loaded = shallowClone(newState.loaded)
  newState.fetching = shallowClone(newState.fetching)

  const updateOptions = { ...options, cloneState: false }

  items.forEach((item) => {
    newState = updateLoaded(newState, item, ancillary, updateOptions)
  })

  return newState
}

export const removeFromLoaded = (state, item) => ({
  ...state,
  loaded: omit(item.id)(state.loaded),
})

export const updateListed = (state, item) => item
  ? ({
    ...state,
    [item.id]: item,
    listed: dedupe([...state.listed, item.id]),
  })
  : state

export const updateRemoved = (state, { id }) => ({
  ...omit(id)(state),
  listed: state.listed.filter(notEquals(id)),
  loaded: omit(id)(state.loaded),
})

// *****************************************************************************

export const assertThatNullableBooleansHaveLegitValues = fieldNames => produce((draft) => {
  fieldNames.forEach((fieldName) => {
    draft[fieldName] = !!draft[fieldName]
  })
})

export const sortField = (fieldName, sortKey) => item => set(
  fieldName,
  orderBy(sortKey)(get(fieldName)(item) || []),
)(item)
