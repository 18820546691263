import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { compose } from 'redux'
import { fallbackTo, whenPresent } from 'fp/utils'
import useNavigation from 'hooks/useNavigation'
import { get, set } from 'fp/objects'

const withNavigate = (WrappedComponent) => {
  const Enhanced = forwardRef(({ disabled = false, onClick, replace = false, to, ...rest }, ref) => {
    const { navigate } = useNavigation()

    const handleClick = (event) => {
      event.preventDefault()
      /* istanbul ignore else */
      if (!disabled) {
        whenPresent(onClick)
        navigate(to, { replace })
      }
    }
    // Allow explicitly set pointerEvents style to override the default 'auto'
    const propsPointerEvents = get('style.pointerEvents')(rest)
    const style = compose(
      set('pointerEvents', propsPointerEvents || 'auto'),
      fallbackTo({}),
      get('style'),
    )(rest)

    return (
      <WrappedComponent
        disabled={disabled}
        onClick={handleClick}
        ref={ref}
        role="button"
        {...rest}
        style={style}
      />
    )
  })
  Enhanced.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    replace: PropTypes.bool,
    to: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
      PropTypes.string,
    ]).isRequired,
  }

  return Enhanced
}

export default withNavigate
