// NOTE: TreeView is managing these a11y concerns one level above us:
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */

import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import cl from 'classnames'
// eslint-disable-next-line @studysync/material-ui/tree-shakeable-imports
import { useTreeItem } from '@mui/x-tree-view'
import { useSelector } from 'react-redux'
import { navItemChildShape } from 'core/shapes'
import { when } from 'fp/utils'
import useNavigation from 'hooks/useNavigation'
import { get } from 'fp/objects'
import { getLocation } from 'selectors/routing'
import EntryIcon from './EntryIcon'
import TreeEntryLabel from './TreeEntryLabel'

const TreeEntryContent = forwardRef((props, ref) => {
  const {
    className,
    classes,
    entry,
    expansionIcon,
    nodeId,
  } = props

  const leaf = !entry.items

  const {
    disabled,
    expanded,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId)

  const { navigate } = useNavigation()
  const { pathname } = useSelector(getLocation)

  const handleMouseDown = (event) => {
    preventSelection(event)
  }

  const handleLabelClick = (event) => {
    const childIsCurrentRoute = entry.items?.map(get('to')).includes(pathname)
    event.stopPropagation()
    if (entry.href) {
      // eslint-disable-next-line no-restricted-globals
      location.href = entry.href
      return
    }
    handleExpansion(event)
    handleSelection(event)
    when(leaf || !entry.items.length, navigate, entry.to)
    when(
      !expanded
      && entry.items?.length
      && !childIsCurrentRoute,
      navigate,
      get('items.0.to')(entry),
    )
  }

  const handleExpansionClick = (event) => {
    event.stopPropagation()
    handleExpansion(event)
    handleSelection(event)
  }

  const selected = entry.matches || entry.items?.some(get('matches'))

  return (
    <div
      className={cl(
        className,
        classes.root,
        {
          'Mui-expanded': expanded,
          'Mui-selected': selected,
          'Mui-focused': focused,
          'Mui-disabled': disabled,
          leaf,
        },
      )}
      onClick={handleLabelClick}
      onMouseDown={handleMouseDown}
      ref={ref}
      role="link"
    >
      <div>

        {!leaf && <EntryIcon entry={entry} />}

        <TreeEntryLabel
          className={classes.label}
          entry={entry}
        />

      </div>

      {!leaf && (
        <button
          className={classes.iconContainer}
          onClick={handleExpansionClick}
          style={{ display: 'block' }}
          tabIndex={-1}
          type="button"
        >
          {expansionIcon}
        </button>
      )}

    </div>
  )
})

TreeEntryContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  entry: navItemChildShape.isRequired,
  expansionIcon: PropTypes.node,
  nodeId: PropTypes.string.isRequired,
}

export default TreeEntryContent
