import { compose } from 'redux'
import { success } from 'sagas/utils'
import { get } from 'fp/objects'
import { reduce } from 'fp/arrays'
import { fallbackTo } from 'fp/utils'
import actionTypes from './actionTypes'
import {
  bulkUpdateLoaded,
  createReducer,
  handleFetchListSuccess,
  listReducerInitialState,
  updateLoaded,
  updateRemoved,
} from './utils'

export const actions = {
  deleteInteraction: ({ interactionId }) => ({
    type: actionTypes.INTERACTION_DELETE,
    interactionId,
  }),
  fetchInteractions: ({ contentId, queryParams, interactionType, userAssignmentId, userId = 0 }) => ({
    type: actionTypes.INTERACTION_FETCH_LIST,
    contentId,
    queryParams,
    interactionType,
    userId,
    userAssignmentId,
  }),
  postInteraction: args => ({
    ...args,
    type: actionTypes.INTERACTION_POST,
  }),
}

const handlePost = (state, { response }) => updateLoaded(state, response)

const handlePosts = (state, { responses }) => responses.reduce(
  (acc, item) => updateLoaded(acc, item),
  state,
)

const handleRemove = (state, { passThrough }) => updateRemoved(state, { id: passThrough?.action?.interactionId })

// The assignments fetch can sometimes include interactions via the addUserAssignmentsWithInteractions modifier.
const loadInteractionsFromUserAssignments = state => compose(
  interactions => bulkUpdateLoaded(state, interactions),
  reduce((acc, item) => {
    const { interactions } = item

    return interactions
      ? acc.concat(interactions)
      : acc
  }, []),
)
const handleAssignmentFetchItemSuccess = (state, { response }) => compose(
  loadInteractionsFromUserAssignments(state),
  fallbackTo([]),
  get('userAssignments'),
)(response)
const handleAssignmentFetchListSuccess = (state, args) => compose(
  loadInteractionsFromUserAssignments(state),
  reduce((acc, item) => {
    const { userAssignments } = item

    return userAssignments
      ? acc.concat(userAssignments)
      : acc
  }, []),
  get('response.data'),
)(args)

const interactions = createReducer(
  listReducerInitialState(),
  {
    [success(actionTypes.INTERACTION_DELETE)]: handleRemove,
    [success(actionTypes.INTERACTION_FETCH_LIST)]: handleFetchListSuccess,
    [success(actionTypes.INTERACTION_POST)]: handlePost,
    [success(actionTypes.INTERACTION_POST_MULTIPLE)]: handlePosts,
    [success(actionTypes.ASSIGNMENT_FETCH)]: handleAssignmentFetchItemSuccess,
    [success(actionTypes.ASSIGNMENT_CONTENT_REOPEN)]: handleAssignmentFetchItemSuccess,
    [success(actionTypes.ASSIGNMENT_FETCH_LIST)]: handleAssignmentFetchListSuccess,
  },
)

export default interactions
