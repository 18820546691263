import { useMemo } from 'react'
import { getCurrentBlock } from '@studysync/draft-js-modifiers'

const useBlockInsight = (editorState) => {
  const blockType = useMemo(() => {
    const block = getCurrentBlock(editorState)
    const data = block.getData()
    const currentBlockType = block.get('type')
    const variants = data.get('variants') || []
    return [currentBlockType, ...variants]
      .map(variant => variant === 'unstyled' ? 'paragraph' : variant)
      .join(', ')
  }, [editorState])

  return blockType
}

export default useBlockInsight
