import PropTypes from 'prop-types'
import { useState } from 'react'
import { METADATA_TYPE_BANK_ASSETS } from 'core/consts'
import { squeryShape } from 'core/shapes'
import { restEndpoint } from 'reducers/utils'
import { alter } from 'core/store/search/squery'
import { get } from 'fp/objects'
import ConnectedMultiSelect from '../ConnectedMultiSelect'

const Filter = ({ squery, setSquery }) => {
  const [value, setValue] = useState([])

  const handleChange = (tags) => {
    setValue(tags)
    setSquery(alter
      .set
      .where('tags')
      .containsAll(tags.map(get('label')))(squery))
  }

  return (
    <div>
      <ConnectedMultiSelect
        autoFocus
        helperText=""
        label="Search Images"
        labelField="id"
        name="bankAssets"
        onChange={handleChange}
        orderBy="id"
        style={{ marginTop: 24 }}
        uri={`${restEndpoint.metadata}/${METADATA_TYPE_BANK_ASSETS}`}
        value={value}
      />
    </div>
  )
}

Filter.propTypes = {
  setSquery: PropTypes.func.isRequired,
  squery: squeryShape.isRequired,
}

export default Filter
