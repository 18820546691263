/**
 * TODO:
 *
 * This file is a nightmare to work with. Many items are very difficult to override
 * and frankly the whole thing is in dire need of a complete overhaul.
 *
 * It might be a good idea to eject the default video-js stylesheet and just
 * redo it all ourselves.  That's probably the quickest way to get to MVP and
 * definitely easy to maintain in the long run.
 *
 * POSSIBLE ALTERNATIVE:
 *   Drop video-js in favor of https://www.npmjs.com/package/react-player
 */

import {
  important as allImportant,
  linearGradient,
  position,
  size,
  transparentize,
} from 'polished'
import 'video.js/dist/video-js.min.css'

// these codes come from https://github.com/niklauslee/feather-webfont/blob/master/dist/feather-icons.html
const iconPlay = '"\\f1bb"'
const iconPause = '"\\f1af"'
const iconFile = '"\\f168"'

const plugin = ({
  breakpoints,
  mixins: {
    em,
    important,
    percentage,
    px,
    transition,
  },
  palette,
  typography,
}) => ({
  '.video-js': {
    fontFamily: typography.body1.fontFamily,
    fontSize: 16,

    button: {
      display: 'inline-flex',
    },

    '.vjs-control': allImportant({
      width: em(3),
      '.play': {
        overflow: 'visible',
        circle: {
          fill: 'none',
          stroke: 'currentColor',
          strokeWidth: 2,
        },
        path: {
          fill: 'currentColor',
          stroke: 'currentColor',
        },
      },
      '.pause': {
        overflow: 'visible',
        circle: {
          stroke: 'currentColor',
          strokeWidth: 4,
        },
        path: {
          fill: 'currentColor',
        },
      },
    }),

    '.vjs-controls-disabled .vjs-big-play-button': {
      display: important('none'),
    },

    '&:not(.vjs-has-started) .vjs-text-track-display': {
      display: important('none'),
    },

    '&.vjs-has-started .vjs-control-bar': {
      opacity: 0,
      transition: 'opacity 0.5s',
      transitionDelay: '3s',
    },

    '&:hover.vjs-mouse-in-motion': {
      '.vjs-control-bar': {
        opacity: 1,
        transition: 'opacity 0.15s',
      },
    },

    '&.vjs-has-started.vjs-just-started .vjs-control-bar': {
      opacity: 1,
      transition: 'opacity 0s',
    },

    '.vjs-load-progress div, .vjs-seeking .vjs-big-play-button, .vjs-waiting .vjs-big-play-button': {
      display: important('none'),
    },

    '.vjs-slider': {
      backgroundColor: transparentize(0.2, palette.slider.main),
      opacity: 1,
      '.vjs-load-progress': {
        background: 'none',
      },
    },

    '.vjs-big-play-button': {
      cursor: 'pointer',
      backgroundColor: important('transparent'),
      borderStyle: important('none'),
      height: 'auto',
      lineHeight: 'normal',
      width: 'auto',
      marginTop: 'auto',
      marginLeft: 'auto',
      left: '4%',
      bottom: '6%',
      display: 'inline-flex',
      alignItems: 'flex-end',

      button: allImportant({
        svg: {
          overflow: 'visible',
          circle: {
            ...transition(),
            fill: palette.video.contrast,
            stroke: palette.video.contrast,
          },
          path: {
            ...transition(),
            fill: palette.video.main,
            stroke: palette.video.main,
          },
        },

        '&:hover': { background: 'none',
          svg: {
            circle: {
              fill: palette.video.main,
              stroke: palette.video.main,
            },
            path: {
              fill: palette.video.contrast,
              stroke: palette.video.contrast,
            },
          } },
      }),

      '.vjs-icon-placeholder': { display: 'none' },
    },

    '&.vjs-has-started .vjs-big-play-button': {
      display: important('none'),
    },

    '.vjs-text-track-cue': allImportant({
      bottom: em(3),
      fontSize: px(16),
      height: 'unset',
      top: 'unset',
      width: '100%',
      div: {
        borderRadius: em(0.2),
        color: palette.common.white,
        display: 'inline-block',
        font: `150% ${typography.body1.fontFamily}`,
        fontSize: percentage(150),
        lineHeight: 1.625,
        margin: em(0.1),
        padding: em(0.1, 0.3),
      },
    }),

    '.ss-gradient-overlay': {
      pointerEvents: 'none',
      ...position('absolute', 0, 0, 0, 0),
      ...linearGradient({
        fallback: 'transparent',
        toDirection: 'to bottom',
        colorStops: [
          'rgba(0, 0, 0, 0.25) 0%',
          'transparent 15%',
          'transparent 85%',
          'rgba(0, 0, 0, 0.25) 100%',
        ],
      }),
    },
    '.controlButton': {
      cursor: 'pointer',
      color: palette.selected,
      '&::before': {
        opacity: 1,
      },
      '&:hover': {
        color: palette.selection,
      },
      height: em(1.6),
      marginRight: em(0.7),
      minHeight: 'unset',
      minWidth: 'unset',
      '&.depressed': {
        backgroundColor: palette.selected,
        borderRadius: 5,
        color: palette.primary.contrastText,
        '&:hover': {
          backgroundColor: palette.selected,
        },
      },
      '&.hss-transcript-button': {
        ...position('absolute', null, 5, 5, null),
        ...size(27, 35),
        '&::before': {
          fontFamily: 'feather-icons',
          color: palette.primary.contrastText,
          fontSize: 18,
          content: iconFile,
        },
      },
    },

    [breakpoints.down('md')]: {
      '.vjs-big-play-button': { fontSize: em(2) },
    },

    [breakpoints.down('sm')]: {
      '.vjs-big-play-button': {
        fontSize: em(1.5),
        bottom: '4%',
      },
    },
  },

  '.vjs-control-bar': {
    backgroundColor: important(palette.video.bar),

    '.vjs-duration': { display: 'block' },

    '.vjs-play-control': {
      '.vjs-icon-placeholder': {
        position: 'relative',
        top: -20,
      },
      '.vjs-icon-placeholder::before': {
        fontFamily: 'feather-icons',
        fontSize: em(1.8),
        transform: 'translateY(-5px)',
      },
      '&.vjs-paused .vjs-icon-placeholder::before': {
        content: iconPlay,
      },
      '&.vjs-playing span.vjs-icon-placeholder::before': {
        content: iconPause,
      },
    },

    '.vjs-current-time': {
      display: 'inherit',
      textAlign: 'left',
      marginLeft: em(0.5),
      width: em(2.5),
    },

    '.vjs-time-control': {
      padding: 0,
    },

    '.vjs-volume-panel, .vjs-picture-in-picture-control': { display: 'none' },

    '.vjs-captions-toggle': {
      ...size(26, 34),
      marginRight: important(em(1)),
      '.vjs-icon-placeholder::before': {
        content: '"CC"',
        fontSize: px(16),
        fontWeight: 700,
        paddingTop: 1,
        color: palette.primary.contrastText,
      },
    },

    '.ss-transcript-spacer': {
      display: 'inline-block',
      width: 50,
    },

    '.ss-volume-control': {
      alignItems: 'center',
      display: 'flex',
      width: important('auto'),
      '.MuiSlider-rail': {
        backgroundColor: transparentize(0.2, palette.slider.main),
        opacity: 1,
      },
      '.MuiSlider-root': {
        height: px(4),
      },
      '.MuiSlider-thumb': {
        height: px(12),
        width: px(12),
      },
      '.MuiSlider-root:hover': {
        height: px(8),
        '.MuiSlider-thumb': {
          height: px(20),
          width: px(20),
        },
      },
      '.MuiSlider-track': {
        border: 'none',
      },
    },

    '.ss-settings-button': {
      marginLeft: em(0.5),
      '& button': {
        height: '100%',
      },
    },

    '.ss-annotations-toggle': {
      display: 'flex',
      justifyContent: 'center',
    },

    '.ss-theater-toggle': {
      display: 'flex',
      justifyContent: 'center',
      width: em(3),
    },

    [breakpoints.down('md')]: {
      '.vjs-current-time': { display: 'none' },
      '.vjs-duration': { display: 'none' },
    },
  },

  '.vjs-icon': {
    display: 'inline-block',
    '&::before': {
      fontFamily: 'feather-icons',
      fontSize: em(2.1),
    },
  },

  '.vjs-pause-btn::before': {
    content: iconPause,
  },
  '.vjs-play-btn::before': {
    content: iconPlay,
  },

  '.video-js-wrapper': {
    backgroundColor: palette.common.black,
    paddingTop: important(0),
    position: 'relative',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&.vjs-annotations-on': {
      height: '85vh',
      '& .ss-theater-toggle': {
        display: 'none',
      },
    },
  },

  '.vjs-annotation-ticks': {
    opacity: 0,
    transition: 'opacity 0.5s',
    transitionDelay: '3s',

    '&.vjs-mouse-in-motion': {
      opacity: 1,
      transition: 'opacity 0.15s',
    },

    '&.vjs-just-started': {
      opacity: 1,
      transition: 'opacity 0s',
    },
  },

  '*.tab-mode-enabled': {
    '.video-js.vjs-has-started .vjs-control-bar': {
      opacity: 1,
      transition: 'opacity 0.15s',
    },
  },
})

export default plugin
