import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import ReviewAccordion from 'common/reviews/ReviewAccordion'
import { last } from 'fp/arrays'
import { contentShape, rubricCriteriaShape } from 'core/shapes'
import useStaticRenderer from 'hss/ContentViewer/Chapter/Echo/Features/Panels/FeatureRate/useStaticRenderer'
import { noop } from 'fp/utils'

const ReviewsGiven = ({ echo, reviews, rubricCriteria }) => {
  const maxRating = last(rubricCriteria?.options)?.id
  const { responseType } = echo.data

  const StaticRenderer = useStaticRenderer({ responseType })

  return (
    <>
      <Typography
        display="block"
        mb={1}
        mt={6}
        variant="eyebrow"
      >
        Ratings Given
      </Typography>

      {reviews.map(review => (
        <ReviewAccordion
          comment={review.reactionData.comment}
          expandable
          key={review.id}
          ratingOrRatingId={review.reactionData.rubricSelections[0]}
          readOnly
          render={() => (
            <StaticRenderer
              reaction={review}
              reviewed
            />
          )}
          rubricCriteria={rubricCriteria}
        />
      ))}

      {!reviews.length && (
        <ReviewAccordion
          disabled
          ratingOrRatingId={maxRating}
          readOnly
          render={noop}
          rubricCriteria={rubricCriteria}
          title="–"
        />
      )}
    </>
  )
}

ReviewsGiven.propTypes = {
  echo: contentShape.isRequired,
  reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
  rubricCriteria: rubricCriteriaShape,
}

export default ReviewsGiven
