import { produce } from 'immer'
import { LOCATION_CHANGE } from 'redux-first-history'
import { compose } from 'redux'
import { isEmptyString } from 'fp/strings'
import { pick, renameKeys, set } from 'fp/objects'
import { curry, not } from 'fp/utils'
import { getDecodedQueryParams } from 'selectors/routing'
import { success } from 'sagas/utils'
import { createReducer } from './utils'
import actionTypes from './actionTypes'

const initialState = {
  isFirstRendering: true,
  currentLocation: null,
  nextLocation: null,
  pinnedLocation: null,
  previousLocation: null,
  queryParams: {},
  rehydrateContentNextLocationChange: null,
  transition: null,
}

const handleContentAddedChild = produce((draft, { parentContent }) => {
  draft.rehydrateContentNextLocationChange = compose(
    curry(renameKeys, 2, { id: 'contentId' }),
    pick('id', 'contentType'),
  )(parentContent)
})

const handleLocationWillChange = (state, { nextLocation }) => ({
  ...state,
  nextLocation,
})

const handleContentRehydrated = (state, action) => {
  if (
    state.rehydrateContentNextLocationChange?.contentId === action.response.id
    && state.rehydrateContentNextLocationChange?.contentType === action.response.contentType
  ) {
    return {
      ...state,
      rehydrateContentNextLocationChange: null,
    }
  }
  return state
}

const handleSetIsFirstRendering = produce((draft, { isFirstRendering }) => {
  draft.isFirstRendering = isFirstRendering
})

const handleLocationChange = produce((draft, { payload: { location } }) => {
  draft.previousLocation = draft.currentLocation
  draft.currentLocation = location

  draft.queryParams = (location.search || '?')
    .substring(1)
    .split('&')
    .filter(compose(not, isEmptyString))
    .reduce((acc, queryParams) => set(...queryParams.split('='))(acc), {})

  draft.pinnedLocation = draft.queryParams.ssc
    ? getDecodedQueryParams('ssc')({ routing: draft })
    : null
})

const routing = createReducer(initialState, {
  [actionTypes.CONTENT_CREATE_AND_ADD_CHILD]: handleContentAddedChild,
  [actionTypes.ROUTE_LOCATION_WILL_CHANGE]: handleLocationWillChange,
  [actionTypes.ROUTE_SET_IS_FIRST_RENDERING]: handleSetIsFirstRendering,
  [LOCATION_CHANGE]: handleLocationChange,
  [success(actionTypes.CONTENT_FETCH)]: handleContentRehydrated,
})

export default routing
