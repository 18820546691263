import { useContext } from 'react'
import Alert from '@mui/material/Alert'
import * as consts from 'core/consts'
import { contentViewerContext } from 'hss/ContentViewer/ContentViewerProvider'
import { ABILITY_CONTENT_CREATION } from 'core/consts'
import withAbilityCheck from 'hoc/withAbilityCheck'
import { contentShape } from 'core/shapes'

const HasTerritorialRestrictions = withAbilityCheck(({ content = {} }) => {
  const { territorySelectionType, territorySelections } = content

  if (territorySelectionType === consts.TERRITORY_SELECTION_TYPE_EVERYONE
    || !territorySelections?.length
  ) return null

  return (
    <Alert severity="info">
      {territorySelectionType === consts.TERRITORY_SELECTION_TYPE_INCLUDE
        ? 'This content is only available to certain territories.'
        : 'This content is not available within all territories.'}
    </Alert>
  )
}, ABILITY_CONTENT_CREATION)

HasTerritorialRestrictions.propTypes = {
  content: contentShape,
}

const Alerts = () => {
  const { subsection: content } = useContext(contentViewerContext)

  return content && (
    <HasTerritorialRestrictions content={content} />
  )
}

export default Alerts
