import { useTheme } from '@mui/material/styles'
import { useContainerQuery } from 'common/layout/ContainerQuery'

const useWidthBreakpoint = () => {
  const { breakpoints } = useTheme()
  const { only } = useContainerQuery()
  const keys = [...breakpoints.keys].reverse()
  return (keys.reduce((output, key) => {
    const matches = only(key)
    return !output && matches ? key : output
  }, null) || 'xs'
  )
}

export default useWidthBreakpoint
