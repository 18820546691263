import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { isDefined } from 'fp/utils'

const ScrollToTop = ({ querySelector }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    const element = isDefined(querySelector)
      ? document.querySelector(querySelector) || window
      : window
    element?.scrollTo(0, 0)
  }, [pathname, querySelector])

  return null
}

ScrollToTop.propTypes = {
  querySelector: PropTypes.string,
}

export default ScrollToTop
