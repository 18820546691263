import { padding } from 'polished'
import { svgColor, themeColorVariants, white } from './accentColorUtils'

const applicator = ({
  color,
  getProperty,
  theme: { mixins: { borderS } },
  variant,
}) => ({
  props: { color, variant },
  style: {
    backgroundColor: getProperty('backgroundColor'),
    boxShadow: getProperty('boxShadow') || 'none',
    color: getProperty('color'),
    ...svgColor(getProperty('color')),
    ...borderS(getProperty('borderColor')),

    '&:hover': {
      background: getProperty('hoverBackground'),
      borderColor: getProperty('hoverBorderColor'),
      color: getProperty('hoverColor'),
      boxShadow: getProperty('hoverBoxShadow') || /* istanbul ignore next */ 'none',
      ...svgColor(getProperty('hoverColor')),
    },

    '&:active': {
      boxShadow: getProperty('activeBoxShadow') || /* istanbul ignore next */ 'none',
    },

    '&.Mui-disabled': {
      backgroundColor: getProperty('disabledBackground'),
      borderColor: getProperty('disabledBorderColor'),
      color: getProperty('disabledColor'),
      ...svgColor(getProperty('disabledColor')),
    },
  },
})

const overrides = (theme) => {
  const { mixins: { borderS, em, interTextSizeR, px, rem, transition }, palette, typography } = theme

  return {
    MuiButton: {
      defaultProps: {
        variant: 'default',
      },

      variants: [
        ...themeColorVariants(theme, applicator),

        {
          props: { variant: 'primary' },
          style: {
            borderStyle: 'solid',
          },
        },

        {
          props: { variant: 'secondary' },
          style: { },
        },

        {
          props: { variant: 'tertiary' },
          style: {
            textDecoration: 'underline',
          },
        },

        {
          props: { 'data-subvariant': 'error' },
          style: {
            color: palette.error.dark,
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
              color: palette.error.main,
            },
          },
        },

        {
          props: { 'data-subvariant': 'toolbutton' },
          style: {
            borderColor: palette.grey[4],
            backgroundColor: 'transparent',
            height: 48,
            width: 48,
            padding: 0,
          },
        },

        {
          props: { variant: 'full-size-nav' },
          style: {
            display: 'block',
            height: '100%',
            color: 'white',
            ...interTextSizeR(1.4, px(24), 600),
            padding: 0,

            '.accent': {
              ...padding(54, 60, 48, 105),

              height: '100%',
              width: 350,
            },
            '.title': {
              ...typography.h5,
              marginBottom: 18,
            },
            '.label': {
              display: 'flex',
              svg: {
                transform: 'translateX(-4px)',
                ...transition('transform'),
                path: {
                  stroke: 'currentColor',
                },
                '&.MuiCircularProgress-svg': {
                  transform: 'none',
                },
              },
            },
            '&:hover .label': {
              textDecoration: 'underline',
              svg: {
                transform: 'translateX(-8px)',
                '&.MuiCircularProgress-svg': {
                  transform: 'none',
                },
              },
            },
          },
        },
        {
          props: { variant: 'full-size-nav', 'data-subvariant': 'align-left' },
          style: {
            display: 'flex',
            justifyContent: 'flex-start',
            textAlign: 'left',
            paddingRight: 0,
            '.accent': {
              paddingLeft: 40,
            },
            '.label': {
              justifyContent: 'flex-start',
              svg: {
                transform: 'translateX(-4px)',
              },
            },
            '&:hover .label svg': {
              transform: 'translateX(-8px)',
            },
          },
        },
        {
          props: { variant: 'full-size-nav', 'data-subvariant': 'align-right' },
          style: {
            display: 'flex',
            justifyContent: 'flex-end',
            textAlign: 'right',
            paddingLeft: 0,
            '.accent': {
              paddingRight: 40,
            },
            '.label': {
              justifyContent: 'flex-end',
              svg: {
                transform: 'translateX(4px)',
              },
            },
            '&:hover .label svg': {
              transform: 'translateX(8px)',
            },
          },
        },
        {
          props: { 'data-subvariant': 'filter' },
          style: {
            ...borderS(palette.borderColor),
            padding: 0,
            paddingRight: 10,
            '.MuiButton-startIcon': {
              marginRight: 5,
              svg: {
                background: 'unset',
                position: 'relative',
              },
            },
          },
        },
        {
          props: { variant: 'primary', 'data-subvariant': 'filter' },
          style: {
            '.MuiButton-startIcon': svgColor(white(palette)),
          },
        },
        {
          props: { variant: 'mock-link' },
          style: {
            lineHeight: 'inherit',
            textDecoration: 'underline',
            textDecorationColor: 'inherit',
            textUnderlineOffset: rem(0.4),
            textDecorationThickness: 'from-font',
            ...transition('text-decoration-thickness', 250, 'ease-in-out'),

            fontSize: 'inherit',
            minWidth: 'unset',
            padding: 'inherit',
            '&:hover': {
              textDecoration: 'underline',
              textDecorationThickness: rem(0.3),
              svg: {
                fill: 'currentColor',
                '& path, & line': {
                  stroke: white(palette),
                  ...transition(),
                  '&[fill]': {
                    fill: white(palette),
                  },
                  '&[stroke]': {
                    stroke: white(palette),
                  },
                },
              },
            },
          },
        },
        {
          props: { variant: 'mock-link', 'data-subvariant': 'base' },
          style: {
            color: palette.cobalt[0],
          },
        },
        {
          props: { variant: 'mock-link', 'data-subvariant': 'vocab' },
          style: {
            textAlign: 'left',
            textTransform: 'none',
          },
        },

      ],

      styleOverrides: {
        root: {
          borderStyle: 'none',
          borderRadius: 0,
          background: 'transparent',
          fontSize: rem(1.4),
          fontWeight: 'normal',
          letterSpacing: em(-0.02),
          lineHeight: '157%',
          padding: rem(1.2, 2.4),
          textTransform: 'capitalize',
          '&:hover': {
            fontWeight: 'normal',
            background: 'none',
            ...svgColor(white(palette)),
            svg: {
              color: 'inherit',
            },
          },
          '&.Mui-disabled': {
            textDecoration: 'line-through',
          },
        },
        sizeSmall: {
          padding: rem(0.7, 1.5),
        },
      },
    },
  }
}

export default overrides
