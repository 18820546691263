import { Routes } from 'react-router-dom'
import ErrorBoundary from 'common/errorHandling/ErrorBoundary'
import { configToRoutes } from 'routing/TraverseRoute'
import Library from 'hss/views/Library'
import Search from 'hss/views/Library/Search'
import Vocabulary from 'hss/views/Library/Vocabulary'
import { ABILITY_LIBRARY_ACCESS } from 'core/consts'
import Route404 from '../Route404'

const config = {

  base: {
    path: '/',
    element: <Library />,
  },

  search: {
    path: 'search',
    element: <Search />,
  },

  vocab: {
    path: 'vocab/:contentId',
    element: <Vocabulary />,
  },

  404: {
    path: '*',
    element: <Route404 collectionName="Library" />,
  },

}

const routes = configToRoutes(config, { abilityFlags: ABILITY_LIBRARY_ACCESS })

const LibraryRoutes = () => (
  <ErrorBoundary moduleName="LibraryRoutes">

    <Routes>

      {routes}

    </Routes>

  </ErrorBoundary>
)

export default LibraryRoutes
