import PropTypes from 'prop-types'
import DialogContent from '@mui/material/DialogContent'
import { memo, useEffect, useMemo, useState } from 'react'
import { ChevronDown, ChevronRight } from 'react-feather'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import Error from '@mui/icons-material/Error'
import { isDefined, when } from 'fp/utils'
import useEffectOnce from 'hooks/useEffectOnce'
import { dedupeById, findObj } from 'fp/arrays'
import { get } from 'fp/objects'
import { capitalize } from 'fp/strings'
import { buildContentUrl } from 'projections/content'
import Link from 'common/navigation/links/Link'
import Dialog from './Dialog'

const ContentLink = (props) => {
  const { chapter, children, content } = props
  const url = buildContentUrl('library')(content, chapter, content.parent, content.children?.[0])
  const unableToLink = String(url).includes('undefined')

  return unableToLink
    ? children
    : <Link to={url}>{children}</Link>
}

ContentLink.propTypes = {
  chapter: PropTypes.object,
  children: PropTypes.node.isRequired,
  content: PropTypes.object.isRequired,
}

const renderName = ({ contentSubType, contentType, name }) => name || (contentSubType
  ? `${capitalize(contentSubType) } <${contentType}>`
  : `<${contentType}>`)

const Content = memo((props) => {
  const { content } = props
  const [list, setList] = useState([])
  const [tree, setTree] = useState({})
  const [chapter, setChapter] = useState()

  const extractChild = (c) => {
    const {
      contentSubType,
      contentType,
      id,
      name,
      parent,
    } = c
    setList(prev => [...prev, { contentSubType, contentType, id, name, parent }])
    when(parent, extractChild, parent)
  }

  useEffectOnce(() => {
    extractChild(content)
    setList(prev => dedupeById(prev.reverse()))
  })

  useEffect(() => {
    if (!list.length) return

    const built = list[0]
    let cursor = built
    list.forEach((item, idx) => {
      if (idx > 0) {
        cursor.children = [item]
        cursor = item
      }
    })
    setTree(built)
  }, [list])

  useEffect(() => {
    setChapter(findObj('contentType', 'chapter')(list))
  }, [list])

  const rendered = useMemo(() => {
    const renderTree = node => node?.id ? (
      <TreeItem
        key={node.id}
        label={(
          <>
            {node.children
              ? null
              : <Error sx={{ color: 'red', position: 'relative', top: 5, marginRight: 1 }} />}
            <ContentLink
              chapter={chapter}
              content={node}
            >
              {renderName(node)}
            </ContentLink>
          </>
        )}
        nodeId={node.id}
      >
        {Array.isArray(node.children)
          ? node.children.map(childNode => renderTree(childNode))
          : null}
      </TreeItem>
    )
      : null

    return renderTree(tree)
  }, [chapter, tree])

  return (
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ChevronDown />}
      defaultExpandIcon={<ChevronRight />}
      expanded={list.map(get('id'))}
      sx={{ maxHeight: 240, overflowY: 'auto' }}
    >

      {rendered}

    </TreeView>
  )
})

Content.propTypes = {
  content: PropTypes.object.isRequired,
}

const ContentIsInUseDialog = ({ meta }) => {
  const [open, setOpen] = useState(false)

  const { error: { context: { content } = {}, message, statusCode } = {} } = meta

  useEffect(() => {
    setOpen(statusCode === 409 && isDefined(content))
  }, [content, message, statusCode])

  return open
    ? (
      <Dialog
        onClose={() => setOpen(false)}
        open
        showCloseButton
        title="Content Is In Use"
      >
        <DialogContent>
          <p>
            The item could not be removed because it is referenced by at least one
            piece of published content.  The item would have to be removed from
            its immediate parent before it could be deleted.
          </p>
          <p>
            Here are links to the relevant editors:
          </p>

          {!!content && <Content content={content} />}

          <p>
            <strong>NOTE </strong> that there could be additional places where this
            content is used.  This is just the first place it was found.  You
            may need to repeat this process after attempting to delete it again.
          </p>
        </DialogContent>

      </Dialog>
    )
    : null
}

ContentIsInUseDialog.propTypes = {
  meta: PropTypes.object.isRequired,
}

export default ContentIsInUseDialog
