import PropTypes from 'prop-types'
import cl from 'classnames'
import { AtomicBlockUtils, Editor } from 'draft-js'
import { forwardRef, useCallback, useEffect } from 'react'
import Box from '@mui/material/Box'
import { DRAFTJS_BLOCK_KEY, moveCaretToEnd } from '@studysync/draft-js-modifiers'
import { componentShape, inputVariantShape } from 'core/shapes'
import { grammarlySupport, lastPassSupport } from 'common/formControls/Form/withHookForm'

/**
 * NOTE:
 * If we ever start using draft-js-plugin's Editor instead of the stock draft-js
 * one, then we won't be able to apply attributes to the editor element in this
 * manner.
 *
 * Frankly this whole business of features as attributes might not make the cut
 * anyhow.
 */
const applyAttrs = (editor, attrs) => {
  // istanbul ignore else
  if (editor) {
    attrs.forEach(([key, value]) => {
      editor.setAttribute(key, value)
    })
  }
}

const StyledEditor = forwardRef((props, ref) => {
  const {
    children,
    className,
    disabled = false,
    editorState,
    error = false,
    features,
    focus,
    maxHeight,
    minimized = false,
    onChange,
    variant,
    ...rest
  } = props

  const contentState = editorState.getCurrentContent()
  const hidePlaceholder = !contentState.hasText() && contentState.getBlockMap().first().getType() !== 'unstyled'

  useEffect(() => {
    const attrs = Object.entries({
      ...grammarlySupport(features.grammarly),
      ...lastPassSupport(features.lastPass),
    })
    applyAttrs(ref?.current?.editor, attrs)
  }, [features.grammarly, features.lastPass, ref])

  const handleFocus = () => {
    onChange(moveCaretToEnd(editorState))
  }

  /* istanbul ignore next line */ // no way to invoke this handler from jest
  const handleDrop = useCallback((selection, dataTransfer) => {
    const raw = dataTransfer.data.getData('text')
    const data = raw ? raw.split(':') : []

    if (data.length !== 2) {
      return 'not-handled'
    }

    if (data[0] === DRAFTJS_BLOCK_KEY) {
      const blockKey = data[1]
      const cs = editorState.getCurrentContent()
      const block = cs.getBlockForKey(blockKey)

      let newEditorState
      try {
        newEditorState = AtomicBlockUtils.moveAtomicBlock(
          editorState,
          block,
          selection,
        )
      } catch (e) {
        return 'not-handled'
      }

      onChange(newEditorState)

      return 'handled'
    }

    return 'not-handled'
  }, [editorState, onChange])

  const handleClickOutside = ({ target }) => {
    if (target.className === 'DraftEditor-root') {
      onChange(moveCaretToEnd(editorState))
    }
    focus()
  }

  return (
    <Box
      className={cl(
        className,
        variant,
        {
          'RichEditor-hidePlaceholder': hidePlaceholder,
          'Mui-error': error,
        },
      )}
      onClick={handleClickOutside}
      onFocus={handleFocus}
      sx={{
        position: 'relative',
        '.DraftEditor-root': {
          maxHeight: maxHeight || (minimized ? 'initial' : 400),
          overflowY: 'auto',
          minHeight: minimized ? 100 : 400,
        },
      }}
    >
      {children}

      <Editor
        {...rest}
        className="boo"
        disabled={disabled}
        editorState={editorState}
        handleDrop={handleDrop}
        onChange={onChange}
        ref={ref}
        stripPastedStyles
      />
    </Box>
  )
})

StyledEditor.propTypes = {
  children: componentShape.isRequired,
  disabled: PropTypes.bool,
  editorState: PropTypes.object.isRequired,
  error: PropTypes.bool,
  features: PropTypes.object.isRequired,
  focus: PropTypes.func.isRequired,
  maxHeight: PropTypes.string,
  minimized: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  variant: inputVariantShape.isRequired,
}

export default StyledEditor
