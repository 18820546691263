import { all, call, put, takeEvery } from 'redux-saga/effects'
import actionTypes from 'reducers/actionTypes'
import { restEndpoint } from 'reducers/utils'
import { METADATA_TYPE_TAG } from 'core/consts'
import { dangerouslyCallApi } from './api'
import { success } from './utils'

export function* handleAdd(action) {
  const { items } = action
  const url = `${restEndpoint.metadata}/${METADATA_TYPE_TAG}`

  const results = yield all(items.map(item => call(dangerouslyCallApi, {
    // the action param was purposely omitted in order to make the yield all work
    options: {
      method: 'POST',
      body: item,
    },
    url,
  })))

  const returnAction = {
    type: success(actionTypes.TAG_ADD),
    payload: results,
  }

  yield put(returnAction)
}

/* istanbul ignore next line */
function* tagSaga() {
  yield takeEvery(actionTypes.TAG_ADD, handleAdd)
}

export default tagSaga
