import { useCallback, useContext } from 'react'
import Box from '@mui/material/Box'
import ActionLink from 'common/formControls/buttons/ActionLink'
import useCurrentUser from 'hooks/useCurrentUser'
import useStateWithDynamicDefault from 'hooks/useStateWithDynamicDefault'
import TextField from 'common/formControls/textInputs/TextField'
import { context } from './context'

const Account = () => {
  const { deauthorize, signOut, signinEmail, status, updateStatus } = useContext(context)
  const { user: { googleId } } = useCurrentUser()
  const [localEmail] = useStateWithDynamicDefault(signinEmail)
  const handleSignOut = useCallback(() => { signOut(updateStatus) }, [signOut, updateStatus])

  return status !== 'SIGNIN' && status !== 'ERROR' ? (
    <>
      <TextField
        disabled
        label="Google Account"
        margin="dense"
        name="account"
        value={localEmail || ' '}
      />

      <Box
        component="p"
        sx={{
          '&, a': {
            fontSize: '1.3rem',
          },
        }}
      >
        Wrong Google Account?{' '}
        <ActionLink
          component="a"
          onClick={handleSignOut}
        >
          Sign out.
        </ActionLink>
        {Boolean(googleId && status !== 'EMAIL_MISMATCH') && (
          <ActionLink
            component="a"
            onClick={deauthorize}
            sx={{ float: 'right' }}
          >
            Reset Integrations
          </ActionLink>
        )}
      </Box>
    </>
  ) : null
}

export default Account
