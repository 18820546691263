/**
 * We have two hooks available for whenever a visual component needs some insight
 * into a particular Redux action.  Which one to use depends largely on whether you
 * merely need to know if an action succeeded or failed, or whether you also need
 * to know/track when such an action is in flight.
 *
 * useReduxCallback
 * ----------------
 * This hook returns a promise that resolves whenever an action completes (or fails).
 * It also exposes a status variable that tells when the action is idle, busy,
 * successful or errored.
 * You can also pass onSuccess and onError methods to the hook if you'd rather not
 * deal with a promise.  Sometimes that results in cleaner code; it's all rather
 * situational.
 *
 * If you don't need to follow the status, then utilize useReduxPromise instead,
 * as it has less overhead.
 *
 *
 * useReduxPromise
 * ---------------
 * This hook returns a promise that resolves whenever an action completes (or fails).
 * It can also watch more than one action at a time.
 *
 * If you find yourself needing to also track the status of the action, then utilize
 * useReduxCallback instead (and instead of adding additional state to your component)
 *
 */

import useAsyncFunction from '@studysync/react-redux-promise-listener-hook'
import promiseListener from 'core/store/promiseListener'
import { identity } from 'fp/utils'
import { failure, success } from 'sagas/utils'

const useReduxPromise = (actionType, resolveActionType, resolve, reject) => {
  const asyncFunc = useAsyncFunction(
    {
      getError: identity,
      getPayload: identity,
      reject: reject || failure(resolveActionType || actionType),
      resolve: resolve || success(resolveActionType || actionType),
      setPayload: (action, args) => ({ ...action, ...args }),
      start: actionType,
    },
    promiseListener,
  )

  return asyncFunc
}

export default useReduxPromise
