const overrides = ({ mixins: { rem }, palette, typography: { variants } }) => ({
  MuiTypography: {
    styleOverrides: {
      colorPrimary: {
        color: palette.text.primary,
      },
    },

    variants: [
      ...Object.entries(variants)
        .map(([variant, style]) => ({
          props: { variant },
          style,
        })),
      {
        props: { variant: 'heading-subtitle' },
        style: {
          ...variants.paragraph,
          marginBottom: rem(2),
        },
      },
    ],
  },
})

export default overrides
