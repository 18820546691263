import { deepMerge } from 'fp/objects'

let inListType

const blockToHTML = (/* features */) => (current, block) => {
  const { data, type } = block
  const ORDERED_LIST_TYPES = ['1', 'a', 'i']

  switch (type) {
    case 'unordered-list-item':
      return {
        element: <li />,
        nest: <ul />,
      }

    case 'ordered-list-item':
      if (!inListType) {
        inListType = data.variant
      }

      return {
        start: inListType ? `<li data-variant="${inListType}">` : '<li>',
        end: '</li>',
        // eslint-disable-next-line react/no-unstable-nested-components
        nest: depth => <ol type={ORDERED_LIST_TYPES[depth % 3]} />,
      }
    default:
      inListType = undefined
      return current
  }
}

// eslint-disable-next-line react/prop-types
const DecoratorComponent = className => ({ children }) => (
  <div className={className}>
    {children}
  </div>
)

const decoratorStrategy = variant => (contentBlock, callback) => {
  if (contentBlock.getData().get('variant') === variant) {
    callback(0, contentBlock.getLength())
  }
}

const decorator = (/* features */) => [
  {
    strategy: decoratorStrategy('numbered-card'),
    component: DecoratorComponent('numbered-card-decorator'),
  },
  {
    strategy: decoratorStrategy('source-header'),
    component: DecoratorComponent('source-header-decorator'),
  },
  {
    strategy: decoratorStrategy('unstyled'),
    component: DecoratorComponent('unstyled-decorator'),
  },
]

const htmlToBlock = (/* features */) => (current, nodeName, node) => {
  const variant = node.getAttribute('data-variant')

  if (nodeName === 'li' && variant) {
    return deepMerge(current, {
      type: 'ordered-list-item',
      data: {
        variant,
      },
    })
  }

  return current
}

const importingPostProcess = () => (editorState) => {
  inListType = undefined
  return editorState
}

const listItemPlugin = {
  blockToHTML,
  decorator,
  exportingPostProcess: importingPostProcess,
  featureMenuKey: 'list',
  htmlToBlock,
  importingPostProcess,
}

export default listItemPlugin
