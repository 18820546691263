import { Check, HelpCircle, X } from 'react-feather'
import PropTypes from 'prop-types'

const CorrectnessIcon = ({ isCorrect = false, needsScoring = false }) => needsScoring ? (
  <HelpCircle
    aria-label="Not yet scored"
    role="img"
  />
) : isCorrect
  ? (
    <Check
      aria-label="Correct"
      role="img"
    />
  )
  : (
    <X
      aria-label="Incorrect"
      role="img"
    />
  )

CorrectnessIcon.propTypes = {
  isCorrect: PropTypes.bool,
  needsScoring: PropTypes.bool,
}

export default CorrectnessIcon
