import { styled } from '@mui/material/styles'
import { position, transparentize } from 'polished'
import { componentShape } from 'core/shapes'
import Well from './Well'

const Legal = styled(({ children, ...rest }) => (
  <Well
    padding="none"
    variant="outlined"
    {...rest}
  >
    <div>{children}</div>
  </Well>
), {
  name: 'layout-Legal',
})(({ theme: { mixins: { em, rem }, palette } }) => ({
  '&::after': {
    ...position('absolute', null, null, 0, 0),
    content: "''",
    width: '100%',
    height: em(5),
    backgroundImage: `linear-gradient(
      ${transparentize(1, palette.common.white)},
      ${palette.common.white}
    )`,
    pointerEvents: 'none',
  },
  '> div': {
    maxHeight: rem(30),
    overflowY: 'scroll',
    padding: rem(2, 2, 5),
    p: {
      margin: rem(1, 0),
      '&:first-of-type': { marginTop: 0 },
      '&:last-of-type': { marginBottom: 0 },
    },
  },
}))

Legal.propTypes = { children: componentShape.isRequired }

export default Legal
