import { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import MuiDialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { useFocusTrap } from 'react-use-focus-trap'
import cl from 'classnames'
import ScreenReaderText from 'common/text/ScreenReaderText'
import withTabModeSupport from 'hoc/withTabModeSupport'
import { whenPresent } from 'fp/utils'
import Headline from 'common/text/Headline'
import useEffectOnce from 'hooks/useEffectOnce'
import HeadlineLevelOffset from 'common/text/HeadlineLevelOffset'
import withProps from 'hoc/withProps'
import withColorSwatch from 'common/text/withColorSwatch'
import { omit } from 'fp/objects'
import CloseButton from './CloseButton'
import Transition from './Transition'

const TabSupportedDialog = withTabModeSupport(MuiDialog)

const Dialog = (props) => {
  const {
    CloseButtonProps,
    TitleProps,
    children,
    dense = false,
    disableAutoFocus = false,
    onClose,
    onOpen,
    open = false,
    showCloseButton = false,
    swatch = false,
    title,
    ...rest
  } = props

  const closeButtonRef = useRef()
  const headlineRef = useRef()
  const [focusTrapRef] = useFocusTrap()

  const handleEntered = useCallback(() => {
    // No easy way to test the else path in RTL 😒
    /* istanbul ignore else */
    if (!disableAutoFocus) {
      // if headline is present, it's the first piece of content and "wins".
      // fallback to close button otherwise
      closeButtonRef.current?.focus()
      headlineRef.current?.focus()
    }
  }, [disableAutoFocus])

  /**
   * TODO: This is a less-than-ideal fix, but it'll do until this is fixed:
   * https://github.com/mui-org/material-ui/issues/19450
   *
   * Otherwise `aria-hidden="true"` is applied to the root container whenever a
   * dialog is opened.
   */
  useEffectOnce(() => {
    if (open) {
      whenPresent(onOpen)
      document
        .getElementById('react-container')
        ?.removeAttribute('aria-hidden')
    }
  }, [onOpen, open])

  const commonTitleProps = {
    ...TitleProps,
    ...showCloseButton ? null : { tabIndex: -1 },
  }

  const Title = swatch
    ? withColorSwatch(withProps(
      DialogTitle,
      {
        ref: headlineRef,
        variant: 'swatch',
      },
    ), headlineRef)
    : withProps(DialogTitle, { ref: headlineRef })

  return (
    <TabSupportedDialog
      onClose={onClose}
      open={open}
      PaperProps={{ ref: focusTrapRef }}
      TransitionComponent={Transition}
      TransitionProps={{
        onEntered: handleEntered,
      }}
      variant={swatch ? 'swatch' : null}
      {...rest}
    >
      <ScreenReaderText>Start of pop-up content</ScreenReaderText>
      {Boolean(showCloseButton) && (
        <CloseButton
          data-bodyvariant="body1"
          data-testid="dialog-close"
          onClick={onClose}
          ref={closeButtonRef}
          variant="tertiary"
          {...dense
            ? { style: { right: '0.5rem', top: '0.5rem' } }
            : { style: { right: '1.5rem' } }}
          {...CloseButtonProps}
        />
      )}

      {/*
        Start any Headlines at level h2, to match what DialogTitle does.
        (We've gotten conflicting info from auditors in the past on this.
        Sometimes they've said to start with h1 in dialogs; other times they've said h2.
        Since DialogTitle uses h2, we'll go with that.)
      */}
      <HeadlineLevelOffset>
        {title
          ? dense
            ? (
              <Headline
                mt={1}
                ref={headlineRef}
                size={3}
                swatch={swatch}
                textAlign="center"
                title={title}
                {...commonTitleProps}
              >
                {children}
              </Headline>
            )
            : (
              <>
                {/* Headline can accept `TitleProps={{ hr: true }}` as-is, but the hr needs to be added to Title */}
                <Title
                  className={cl({ 'tr-typography tr-headline--hr': TitleProps?.hr === true })}
                  {...omit('hr')(commonTitleProps)}
                >
                  {title}
                </Title>
                {/*
                  DialogTitle pays no attention to HeadlineLevelOffset and is always an h2.
                  Make other headings start at h3.
                */}
                <HeadlineLevelOffset>
                  {children}
                </HeadlineLevelOffset>
              </>
            )
          : children}
      </HeadlineLevelOffset>

      <ScreenReaderText>End of pop-up content</ScreenReaderText>
    </TabSupportedDialog>
  )
}

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  CloseButtonProps: PropTypes.object,
  dense: PropTypes.bool,
  disableAutoFocus: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  swatch: PropTypes.bool,
  title: PropTypes.node,
  TitleProps: PropTypes.object,
}

export default Dialog
