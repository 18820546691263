import PropTypes from 'prop-types'
import { useWatch } from 'react-hook-form'
import CollapsibleSection, { StylerForSingleField } from 'common/layout/CollapsibleSection'
import HFTextField from 'common/formControls/textInputs/HFTextField'
import { isEmptyString } from 'fp/strings'

const Base = ({ className, label = 'Notes', name = 'notes' }) => {
  const value = useWatch({ name })

  return (
    <CollapsibleSection
      className={className}
      empty={isEmptyString(value)}
      label={label}
    >
      <HFTextField
        aria-label="notes"
        label=""
        maxRows={4}
        multiline
        name={name}
        variant="filled"
      />
    </CollapsibleSection>
  )
}

Base.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
}

const HFCollapsedRTE = StylerForSingleField(Base)

export default HFCollapsedRTE
