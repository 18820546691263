import { withOptions } from '@comfy/redux-selectors'
import { CONTENT_TYPE_INTERACTIVE } from 'core/consts'
import { buildAssignmentsGradebook, buildStandardsGradebook, buildStandardsMasteryGradebook } from 'projections/gradebook'
import { getUserAssignmentsById } from './userAssignments'
import { getAssignmentsFromUserAssignments } from './assignments'
import { getAllContentAsObject } from './content'
import { getContentForType } from './collapsedContent'
import { createSelector, stateInteractions, stateStandards } from '.'

export const getAssignmentsGradebook = withOptions(({ userAssignmentIds }) => createSelector('getAssignmentsGradebook')(
  getUserAssignmentsById({ userAssignmentIds }),
  getAssignmentsFromUserAssignments({ userAssignmentIds }),
  stateInteractions,
  getContentForType(CONTENT_TYPE_INTERACTIVE),
  buildAssignmentsGradebook,
))

export const getStandardsGradebook = withOptions(({ userAssignmentIds }) => createSelector('getStandardsGradebook')(
  getUserAssignmentsById({ userAssignmentIds }),
  getAssignmentsFromUserAssignments({ userAssignmentIds }),
  stateStandards,
  stateInteractions,
  getContentForType(CONTENT_TYPE_INTERACTIVE),
  getAllContentAsObject,
  buildStandardsGradebook,
))

export const getStandardsMasteryGradebook = withOptions(({ userAssignmentIds }) => createSelector('getStandardsGradebook')(
  getStandardsGradebook({ userAssignmentIds }),
  buildStandardsMasteryGradebook,
))
