/* eslint-disable no-empty */
/**
 * We have two hooks available for whenever a visual component needs some insight
 * into a particular Redux action.  Which one to use depends largely on whether you
 * merely need to know if an action succeeded or failed, or whether you also need
 * to know/track when such an action is in flight.
 *
 * useReduxCallback
 * ----------------
 * This hook returns a promise that resolves whenever an action completes (or fails).
 * It also exposes a status variable that tells when the action is idle, busy,
 * successful or errored.
 * You can also pass onSuccess and onError methods to the hook if you'd rather not
 * deal with a promise.  Sometimes that results in cleaner code; it's all rather
 * situational.
 *
 * If you don't need to follow the status, then utilize useReduxPromise instead,
 * as it has less overhead.
 *
 *
 * useReduxPromise
 * ---------------
 * This hook returns a promise that resolves whenever an action completes (or fails).
 * It can also watch more than one action at a time.
 *
 * If you find yourself needing to also track the status of the action, then utilize
 * useReduxCallback instead (and instead of adding additional state to your component)
 *
 */

import { useCallback, useState } from 'react'
import useReduxPromise from 'hooks/useReduxPromise'
import { whenPresent } from 'fp/utils'

export const IDLE = 0
export const BUSY = 1
export const SUCCESS = 2
export const ERROR = 3

const useReduxCallback = ({ actionType, allowParallel = false, onError, onSuccess, resolve, reject }) => {
  const [promise, setPromise] = useState(undefined)
  const [status, setStatus] = useState(IDLE)

  const call = useReduxPromise(actionType, undefined, resolve, reject)

  const performAction = useCallback((...payload) => {
    let result = promise

    const execute = () => call(...payload)
      .then((...args) => {
        try { setStatus(SUCCESS) } catch (e) {}
        whenPresent(onSuccess, ...args)
      })
      .catch((...args) => {
        try { setStatus(ERROR) } catch (e) {}
        whenPresent(onError, ...args)
      })

    if (allowParallel || status !== BUSY) {
      try { setStatus(BUSY) } catch (e) {}
      result = execute()
      setPromise(result)
    }

    return result
  }, [allowParallel, call, onError, onSuccess, promise, status])

  return [performAction, status]
}

export default useReduxCallback
